import React, { useState } from 'react';

export default function Toggle({ toggleOn = true, handleChange = () => {}, isSmall = false, toggleColor = null}) {
	const [toggleState, setToggleState] = useState(toggleOn);
	
	const selectToggle = () => {
		const new_state = !toggleState;
		
		setToggleState(new_state);
		handleChange(new_state);
	}
	
	return (
		<div className={`toggle-btn-container${isSmall && '-small'} ${toggleState ? `toggle-btn-container-selected${toggleColor && `-${toggleColor}`}` : ''}`}>
			<div className={`${toggleState ? `toggle-btn-selected${isSmall && '-small'} ${toggleColor && `toggle-btn-border-${toggleColor}`}` : `toggle-btn-unselected${isSmall && '-small'}`}`} onClick={selectToggle}></div>
		</div>
	);
}