import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Button({ text, classes, icon = null, iconPosition = null, onClickFunction, passedLoading = true }) {
	const [isLoading, setIsLoading] = useState(false);
	
	const handleOnClick = () => {
		setIsLoading(true);
		
		onClickFunction();
	}
	
	useEffect(() => {
		setIsLoading(false);
	}, [passedLoading]);
	
	const classesForDiv = (classes) ? classes.join(" ") : '';
	
	return (
		<>
		{(isLoading && passedLoading) ? 
			(
				<div className={`no-select ${classesForDiv}`}>
					<div className='center-text'>
						<img style={{height:'20px'}} alt='loading...' src={require("../images/ajax-loader.gif")} />
					</div>
				</div>
			) :
			(
				<div className={`no-select ${classesForDiv} ${iconPosition === 'left' ? 'btn-icon-left' : ''} ${iconPosition === 'right' ? 'btn-icon-right' : ''}`} onClick={handleOnClick}>
					{iconPosition === 'left' &&
						<FontAwesomeIcon icon={icon}/>
					}
					{text}
					{iconPosition === 'right' &&
						<FontAwesomeIcon icon={icon}/>
					}
				</div>
			)
		}
		</>
	);
}