import React from 'react';

import SpecialsScheduleStructure from './specials_schedule_structure';
import SpecialsCourses from './specials_courses';
import SpecialsReview from './specials_review';

export default function SpecialsSetup({ isLoading, schoolSubscribed, subscreenRestricted, subscreenLocked, subscreen, schoolInfoLoading, schoolInfo, periods, setPeriods, periodsLoading, scheduleInfoLoading, scheduleInfo, setScheduleInfo, coursesLoading, courses, setCourses, teachersLoading, teachers, setTeachers, studentsLoading, students, setStudents, classroomsLoading, classrooms, setClassrooms, blocksLoading, blocks, setBlocks, handleMoveToNextScreen, setLoadScreen }) {
	return (
		<div>
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				) :
				(
					<>
					{(() => {
						switch(subscreen) {
							case 'specials_schedule_structure':
								return <SpecialsScheduleStructure isLoading={isLoading} subscreenLocked={subscreenLocked} subscreenRestricted={subscreenRestricted} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} periods={periods} setPeriods={setPeriods} periodsLoading={periodsLoading} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							case 'specials_courses':
								return <SpecialsCourses isLoading={isLoading} schoolSubscribed={schoolSubscribed} subscreenLocked={subscreenLocked} subscreenRestricted={subscreenRestricted} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} periods={periods} courses={courses} setCourses={setCourses} teachers={teachers} setTeachers={setTeachers} students={students} setStudents={setStudents} classrooms={classrooms} blocks={blocks} setBlocks={setBlocks} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							case 'specials_review':
								return <SpecialsReview isLoading={isLoading} schoolSubscribed={schoolSubscribed} subscreenLocked={subscreenLocked} subscreenRestricted={subscreenRestricted} schoolInfoLoading={schoolInfoLoading} schoolInfo={schoolInfo} scheduleInfoLoading={scheduleInfoLoading} scheduleInfo={scheduleInfo} periodsLoading={periodsLoading} periods={periods} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} studentsLoading={studentsLoading} students={students} setStudents={setStudents} classroomsLoading={classroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} blocksLoading={blocksLoading} blocks={blocks} setBlocks={setBlocks} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							default:
								return null;
						}
					})()}
					</>
				)
			}
		</div>
	);
}