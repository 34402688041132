import React, { useState , useEffect } from 'react';
import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';

import { loadSpecificData, sortArrayOfObjects, formatTime } from '../../../js/Helpers';
import { saveDataToDatabase } from '../../../js/SaveFunctions';

import '../../../css/hsms/edit_modals.css';
import '../../../css/elementary/auto_scheduling_setup.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTimes, faSearch, faSquare, faCheckSquare, faTrashAlt, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';
	
const numTimesNeededOptions = [
	{value:null, display: 'No Limit'},
	{value:'1', display: '1'},
	{value:'2', display: '2'},
	{value:'3', display: '3'},
	{value:'4', display: '4'},
	{value:'5', display: '5'},
	{value:'6', display: '6'},
	{value:'7', display: '7'},
	{value:'8', display: '8'},
	{value:'9', display: '9'},
	{value:'10', display: '10'},
	{value:'11', display: '11'},
	{value:'12', display: '12'},
];

const temp_durations = [...Array(200).keys()];
const durationOptions = temp_durations.reduce((results, value) => {
	results.push({value:`${value}`, display:`${value} min(s)`});
	return results;
},[]);

const temp_max_group_sizes = [...Array(40).keys()];
const maxGroupSizeOptions = temp_max_group_sizes.reduce((results, value) => {
	results.push({value:`${value}`, display:`${value} student(s)`});
	return results;
},[]);

export default function EditCourseData({ dataID, toggleEditData, schoolInfo, scheduleInfo, periods, teachers, students, courses, classrooms, blocks, pulloutGroups, setPulloutGroups, setDatabaseData, filteredResults, setFilteredResults = ()=>{} }) {
	const [isLoading, setIsLoading] = useState(true);
	const [courseData, setCourseData] = useState({});
	const [days, setDays] = useState([]);
	const [addedPulloutGroups, setAddedPulloutGroups] = useState([]);
	const [removedPulloutGroups, setRemovedPulloutGroups] = useState([]);
	
	const [tctResults, setTctResults] = useState([]);
	const [classroomRestrictionResults, setClassroomRestrictionResults] = useState([]);
	const [courseHomeroomResults, setCourseHomeroomResults] = useState([]);
	const [pulloutAllowedCourseResults, setPulloutAllowedCourseResults] = useState([]);
	
	const [addTctOpen, setAddTctOpen] = useState(false);
	const [addClassroomRestrictionOpen, setAddClassroomRestrictionOpen] = useState(false);
	const [addCourseHomeroomOpen, setAddCourseHomeroomOpen] = useState(false);
	const [addPulloutAllowedCourseOpen, setAddPulloutAllowedCourseOpen] = useState(false);
	
	const [changesMade, setChangesMade] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	
	////////////////////////////////
	///// SET DROPDOWN OPTIONS /////
	////////////////////////////////
	const primarySecondaryOptions = [{value:'1', display:'Primary'}, {value:'2', display:'Back Up'}];
	const semesterOptions = [
		{value:null, display: '--'},
		{value:'FY', display: 'FY - Full Year'},
		{value:'S', display: 'S - Semester'},
		{value:'S1', display: 'S1 - Semester 1'},
		{value:'S2', display: 'S1 - Semester 2'},
		{value:'Q', display: 'Q - Quarter'},
		{value:'Q1', display: 'Q1 - Quarter 1'},
		{value:'Q2', display: 'Q2 - Quarter 2'},
		{value:'Q3', display: 'Q3 - Quarter 3'},
		{value:'Q4', display: 'Q4 - Quarter 4'},
	];

	const group_positions = {
		'1':{'0':{start:'1', end:'120'}}, 
		'2':{'0':{start:'1', end:'58'},'1':{start:'62', end:'120'}}, 
		'3':{'0':{start:'1', end:'38'},'1':{start:'42', end:'78'},'2':{start:'82', end:'120'}}, 
		'4':{'0':{start:'1', end:'28'},'1':{start:'32', end:'58'},'2':{start:'62', end:'88'},'3':{start:'92', end:'120'}}
	};
	
	let maxNumSectionsOptions = [];
	for (let i = 0; i < 16; i++) {
		const display = (i === 0) ? 'None' : i;
		maxNumSectionsOptions.push({value:`${i}`, display:display});
	}
	
	let maxClassSizeOptions = [];
	for (let i = 0; i < 300; i++) {
	  maxClassSizeOptions.push({value:`${i}`, display:i});
	}

	const calculatePeriodColumnStartEnd = (period_data, day_schedule, logged_positions, start_time, end_time) => {
		const overlapCheck = (time1, time2) => {
			const start_time1 = time1.start_time;
			const end_time1 = time1.end_time;
			const start_time1_array = start_time1.split(':');
			const end_time1_array = end_time1.split(':');

			const start_time1_number = parseInt(start_time1_array[0] + start_time1_array[1]);
			const end_time1_number = parseInt(end_time1_array[0] + end_time1_array[1]);

			const start_time2 = time2.start_time;
			const end_time2 = time2.end_time;
			const start_time2_array = start_time2.split(':');
			const end_time2_array = end_time2.split(':');

			const start_time2_number = parseInt(start_time2_array[0] + start_time2_array[1]);
			const end_time2_number = parseInt(end_time2_array[0] + end_time2_array[1]);

			if(end_time1_number > start_time2_number && end_time2_number > start_time1_number) return true;

			return false;
		}

		const period_id = period_data.period_id;

		///// Calculate left and right position
		// Get max number of overlaps
		const period_time = {period_id:period_id, start_time:start_time, end_time:end_time};

		let overlapping_time_groups = [[period_time]];
		for(var i = 0; i < day_schedule.length; i++)
		{
			const new_period = day_schedule[i];
			const new_period_id = new_period.period_id;

			if(period_id === new_period_id) continue; //ignore the original period itself

			const new_time = {period_id:new_period_id, start_time:new_period.start_time, end_time:new_period.end_time};
			const new_time_overlaps_original = overlapCheck(period_time, new_time);

			if(!new_time_overlaps_original) continue; //ignore periods that don't even overlap original

			// Go through all overlap groups and see if new period overlaps all of the times in the current groups
			// If yes, add to that group
			// If no, create a new group
			for(var j = 0; j < overlapping_time_groups.length; j++)
			{
				const overlapping_time_group = overlapping_time_groups[j];

				// Ignore a period that has already been added to this group
				const new_time_index = overlapping_time_group.findIndex(overlapping_time => overlapping_time.period_id === new_period_id);
				if(new_time_index !== -1) continue;

				let new_time_overlaps_entire_group = true;
				
				for(var k = 0; k < overlapping_time_group.length; k++)
				{
					const time_to_check = overlapping_time_group[k];
					const time_overlaps = overlapCheck(time_to_check, new_time);

					if(!time_overlaps) new_time_overlaps_entire_group = false;
				}

				if(new_time_overlaps_entire_group)
				{
					overlapping_time_groups[j].push(new_time);
				}
				else
				{
					overlapping_time_groups.push([period_time, new_time]);
				}
			}
		}

		const overall_max_stats = overlapping_time_groups.reduce((results, overlapping_time_group) => {
			const overlapping_time_group_length = overlapping_time_group.length;
			if(overlapping_time_group_length > results.max_overlaps) results = {max_overlaps: overlapping_time_group_length, overlapping_periods:overlapping_time_group};
			return results;
		}, {max_overlaps:0, overlapping_periods:[]});

		/// Use max overlaps to get the possible positions (preset depending on number of overlaps)
		const max_overlaps = overall_max_stats.max_overlaps;
		const overlapping_periods = overall_max_stats.overlapping_periods;

		const possible_positions = group_positions[max_overlaps];
		const possible_positions_indexes = Object.keys(possible_positions);
		const used_positions = overlapping_periods.reduce((results, period_times) => {
			const overlapping_period_id = period_times.period_id;
			const used_position = (overlapping_period_id in logged_positions) ? logged_positions[overlapping_period_id] : null;
			if(used_position) results.push(used_position);
			return results;
		},[]);
		const unused_positions = possible_positions_indexes.filter(x => used_positions.indexOf(x) === -1);
		const position_index = unused_positions[0];
		const start_position = possible_positions[position_index].start;
		const end_position = possible_positions[position_index].end;

		return {position_index:position_index, col_start:start_position, col_end:end_position};
	}
		
	const toggleAddNewConnection = (connection_type) => {
		if(connection_type === 'tct')
		{
			setAddTctOpen(!addTctOpen);
			setTctResults([]);
		}
		else if(connection_type === 'classroom_restriction')
		{
			setAddClassroomRestrictionOpen(!addClassroomRestrictionOpen);
			setClassroomRestrictionResults([]);
		}
		else if(connection_type === 'course_homeroom')
		{
			setAddCourseHomeroomOpen(!addCourseHomeroomOpen);
		}
		else if(connection_type === 'pullout_allowed_course')
		{
			setAddPulloutAllowedCourseOpen(!addPulloutAllowedCourseOpen);
		}
	}

	const toggleGroupStudentSearch = (group_id) => {
		if('student_search_results' in courseData.pullout_groups[group_id])
		{
			delete courseData.pullout_groups[group_id].student_search_results;
		}
		else
		{
			courseData.pullout_groups[group_id].student_search_results = students;
		}

		setCourseData({...courseData});
	}

	const groupStudentSearch = (search_value, group_id) => {
		const fuse = new Fuse(students, {
			keys: ['first_name', 'last_name'],
			threshold: .1
		})
		
		const results = fuse.search(search_value);
		const student_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : students;
		
		courseData.pullout_groups[group_id].student_search_results = student_results;

		setCourseData({...courseData});
	}
	
	const fuzzySearch = (e, dataType) => {
		const search_value = e.target.value;
		
		if(dataType === 'teachers')
		{
			const fuse = new Fuse(teachers, {
				keys: ['name', 'last_name'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			const teacher_results = results.map(result => result.item);
			
			setTctResults(teacher_results);
		}
		else if(dataType === 'classrooms')
		{
			const fuse = new Fuse(classrooms, {
				keys: ['classroom_name', 'classroom_type'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			const classroom_results = results.map(result => result.item);
			
			setClassroomRestrictionResults(classroom_results);
		}
		else if(dataType === 'homeroom_teachers')
		{
			const fuse = new Fuse(teachers, {
				keys: ['name', 'last_name'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			const teacher_results = results.map(result => result.item);
			
			setCourseHomeroomResults(teacher_results);
		}
		else if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			const course_results = results.map(result => result.item);
			
			setPulloutAllowedCourseResults(course_results);
		}
	}
	
	const updateData = (change_type, data_id, data_key, type = null, sub_data_key = null) => {
		if(change_type === 'add')
		{
			const data_to_add = (data_key === 'teachers_can_teach') ? {teacher_id:data_id, type:'1'} : data_id;
			courseData[data_key].push(data_to_add);
		}
		else if(change_type === 'change')
		{
			if(data_key === 'teachers_can_teach')
			{
				courseData[data_key][data_id].type = type;
			}
			else
			{
				courseData[data_key] = data_id;
			}

			if(data_key === 'is_special' && data_id === '1') courseData['is_pullout'] = '0';
			if(data_key === 'is_pullout' && data_id === '1') courseData['is_special'] = '0';
		}
		else if(change_type === 'remove')
		{
			courseData[data_key].splice(data_id, 1);
		}
	
		setCourseData({...courseData});
		
		setAddTctOpen(false);
		setAddClassroomRestrictionOpen(false);
		setAddCourseHomeroomOpen(false);
		
		setTctResults([]);
		setClassroomRestrictionResults([]);
		setCourseHomeroomResults([]);

		setChangesMade(true);
	}

	const updateCourseGroup = (group_id, change_type, new_value, group_data_index) => {
		if(!group_data_index)
		{
			if(change_type === 'add')
			{
				const uniqueId = () => {
					const dateString = Date.now().toString(36);
					const randomness = Math.random().toString(36).substr(2);
					return dateString + randomness;
				};

				/// Update the FRONTEND ///
				let new_group_id = uniqueId();
				const new_group = {group_id:new_group_id, num_times_needed:'1', duration:'0', day_restrictions:[], strict_group:'0', max_group_size:null, student_list:[]};
				courseData.pullout_groups[new_group_id] = new_group;

				addedPulloutGroups.push(new_group);
				setAddedPulloutGroups([...addedPulloutGroups]);
			}
			else if(change_type === 'remove')
			{
				delete courseData.pullout_groups[group_id];

				removedPulloutGroups.push(group_id);
				setRemovedPulloutGroups([...removedPulloutGroups]);
			}
		}
		else if(group_data_index === 'student_list')
		{
			const spr_index = courseData.pullout_groups[group_id].student_list.findIndex(student_id => student_id === new_value);
			if(change_type === 'add' && spr_index === -1) 
			{
				courseData.pullout_groups[group_id].student_list.push(new_value);
			}
			else if(change_type ==='remove' && spr_index !== -1) 
			{
				courseData.pullout_groups[group_id].student_list.splice(spr_index, 1);
			}
		}
		else if(group_data_index === 'day_restrictions')
		{
			const dr_index = courseData.pullout_groups[group_id].day_restrictions.findIndex(day => day === new_value);
			if(change_type === 'add' && dr_index === -1) 
			{
				courseData.pullout_groups[group_id].day_restrictions.push(new_value);
			}
			else if(change_type ==='remove' && dr_index !== -1) 
			{
				courseData.pullout_groups[group_id].day_restrictions.splice(dr_index, 1);
			}
		}
		else if(change_type === 'change')
		{
			courseData.pullout_groups[group_id][group_data_index] = new_value;
		}
	
		setCourseData({...courseData});
		
		setAddTctOpen(false);
		setAddClassroomRestrictionOpen(false);
		setAddCourseHomeroomOpen(false);
		
		setTctResults([]);
		setClassroomRestrictionResults([]);
		setCourseHomeroomResults([]);

		setChangesMade(true);
	}
	
	const handleExitWithoutSaving = async () => {
		let exit_without_saving = true;
		
		if(changesMade)
		{
			const options =  {
				title: "Exit without saving?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you really want to exit without saving?</p>
					</div>
				)
			}
		
			exit_without_saving = await swal(options);
		}
		
		if(exit_without_saving) toggleEditData();
	}
	
	const updateCourse = () => {
		if(!courseData.name)
		{
			const options =  {
				title: "Missing a Course Name",
				icon:"warning",
				buttons: {
					cancel: {
						text: "Okay",
						value: false,
						visible: true,
						className: 'blue-btn'
					}
				},
				content: (
					<div>
						<p>You need to enter a course name before saving!</p>
					</div>
				)
			}
		
			swal(options);
		}
		else
		{
			const passed_data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'course', course_data:courseData, database:'elementary'};
			saveDataToDatabase(passed_data).then(response_data => {
				// change courseData course id to new database id
				courseData.course_id = response_data.new_course_id;
				
				if(dataID)
				{
					const index_of_specific_data = courses.findIndex(course => course.course_id === dataID);
					courses[index_of_specific_data] = courseData;

					const filter_results_index = filteredResults.findIndex(course => course.course_id === dataID);
					filteredResults[filter_results_index] = courseData;
				}
				else
				{
					courses.push(courseData);
					filteredResults.push(courseData);
				}

				// Update added pullouts with new database ID
				for(const group_id in response_data.new_group_ids)
				{
					const new_group_id = response_data.new_group_ids[group_id];

					if(group_id != new_group_id)
					{
						const pullout_group_index = addedPulloutGroups.findIndex(pullout_group => pullout_group.group_id === group_id);
						if(pullout_group_index !== -1) addedPulloutGroups[pullout_group_index].group_id = new_group_id;
					}
				}

				// Remove any deleted groups
				for(const deleted_group_id of removedPulloutGroups)
				{
					const pullout_group_index = pulloutGroups.findIndex(pullout_group => pullout_group.group_id === deleted_group_id);
					if(pullout_group_index !== -1) pulloutGroups.splice(pullout_group_index, 1);
				}

				// Update pullout groups
				setPulloutGroups([...pulloutGroups, ...addedPulloutGroups]);
				
				setDatabaseData([...courses]);
				setFilteredResults([...filteredResults]);
				setIsSaving(false);
				toggleEditData();
			});
		}
	}
	
	/////////////////////////////
	///// FOCUS ON INPUT ////////
	/////////////////////////////
	useEffect(() => {
		if(addTctOpen) document.getElementById("search-tct-input").focus();
	}, [addTctOpen]);
	
	useEffect(() => {
		if(addClassroomRestrictionOpen) document.getElementById("search-classroom-restriction-input").focus();
	}, [addClassroomRestrictionOpen]);

	useEffect(() => {
		const num_days_in_cycle = scheduleInfo.num_days_in_cycle;

		let new_days = [];

		for(let day = 1; day <= num_days_in_cycle; day++)
		{
			new_days.push(`${day}`);
		}

		setDays(new_days);
	}, [scheduleInfo]);
	
	/////////////////////////
	///// DO ON LOAD ////////
	/////////////////////////
	useEffect(() => {
		if(dataID)
		{
			loadSpecificData({data_type:'course', data_id:dataID, data_version_id:schoolInfo.current_data_version_id, schedule_version_id:schoolInfo.current_schedule_version_id, school_id:schoolInfo.school_id, database:'elementary'}).then(specific_data => {
				const index_of_specific_data = courses.findIndex(course => course.course_id === dataID);
				courses[index_of_specific_data] = specific_data;

				setDatabaseData(courses);
				setCourseData(specific_data);
				setIsLoading(false);
			});
		}
		else
		{
			setCourseData({new: true,  course_code: null, name: null, classroom_type_id: null, max_class_size:"30", num_times_needed:null, is_lunch:"0", is_special:"0", is_pullout:"0", classroom_restrictions: [], day_period_restrictions:[], teachers_can_teach:[], pullout_groups:{}, course_homerooms:[], pullout_allowed_courses:[]});
			setIsLoading(false);
		}
	}, []);
	
	//console.log(courseData);
	//console.log(departments);
	//console.log({sections});
	const num_cols = days.length;
	const day_period_grid = {gridTemplateColumns : `repeat(${num_cols}, minmax(90px, 1fr))`};
	
	return (
		<div className='modal-screen'>
			<div className='modal-screen-content'>
				{!isSaving && 
					<>
					<span className="data-modal-close" onClick={handleExitWithoutSaving}>&times;</span>
					<div className="modal-save-btn" onClick={updateCourse}><FontAwesomeIcon icon={faSave}/>Save Course</div>
					</>
				}
				<div className='full-width'>
					<div className='edit-data-content'>
						<FontAwesomeIcon className='edit-data-main-head-icon' icon={faEdit}/>
						<h1 className='edit-data-main-header'>{dataID ? 'Edit' : 'Add New'} Course</h1>
						{isSaving &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
								<div>Saving...</div>
							</div>
						}
						{isLoading &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						}
						{(!isLoading && !isSaving) &&
							<form className='edit-data-form'>
								<div className='edit-data-grid-25-75'>
									<div>
										<p className='modal-label'>Course Code:</p>
										<input type="text" className='edit-data-input' defaultValue={courseData.course_code} onChange={(e) => updateData('change', e.target.value, 'course_code')}/>
									</div>
									<div>
										<p className='modal-label'>Course Name:</p>
										<input type="text" className='edit-data-input' defaultValue={courseData.name} onChange={(e) => updateData('change', e.target.value, 'name')}/>
									</div>
								</div>
								<div>
									<div className='data-modal-heading'>Course Type</div>
									<div className='edit-data-grid-50 edit-data-other-options-container'>
										<div style={{width:'100%',textAlign:'left'}}>
											<div>Is Lunch?</div>
											<div className='dark-gray-text'>Answer "Yes" if this course is a lunch course</div>
										</div>
										<div className='toggle-with-text-btn-container edit-course-toggle'>
											<div className={`toggle-with-text-btn-optn ${courseData.is_lunch === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'is_lunch')}>No</div><div className={`toggle-with-text-btn-optn ${courseData.is_lunch === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'is_lunch')}>Yes</div>
										</div>
									</div>
									<div className='edit-data-grid-50 edit-data-other-options-container'>
										<div style={{width:'100%',textAlign:'left'}}>
											<div>Is Special</div>
											<div className='dark-gray-text'>Answer "Yes" if this course is a special</div>
										</div>
										<div className='toggle-with-text-btn-container edit-course-toggle'>
											<div className={`toggle-with-text-btn-optn ${courseData.is_special === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'is_special')}>No</div><div className={`toggle-with-text-btn-optn ${courseData.is_special === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'is_special')}>Yes</div>
										</div>
									</div>
									<div className='edit-data-grid-50 edit-data-other-options-container'>
										<div style={{width:'100%',textAlign:'left'}}>
											<div>Is Pullout?</div>
											<div className='dark-gray-text'>Answer "Yes" if this course is a pullout course (a course that requires a student get pulled out of their normal homeroom)</div>
										</div>
										<div className='toggle-with-text-btn-container edit-course-toggle'>
											<div className={`toggle-with-text-btn-optn ${courseData.is_pullout === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'is_pullout')}>No</div><div className={`toggle-with-text-btn-optn ${courseData.is_pullout === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'is_pullout')}>Yes</div>
										</div>
									</div>
								</div>
								<div className='edit-data-grid-50'>
									<div className='edit-data-dropdown-container'>
										<div className='data-modal-heading'>Semester</div>
										<Dropdown data={semesterOptions} currentValue={courseData.semester} handleChange={(new_value) => updateData('change', new_value, 'semester')} />
									</div>
									<div></div>
								</div>
								<div>
									<div className='data-modal-heading'>Teachers who teach this course</div>
									<div>
										{courseData.teachers_can_teach.length === 0 &&
											<div className='edit-data-no-data-container'>No teachers set yet!</div>
										}
										{courseData.teachers_can_teach.map((tct_info, index) => {
											const teacher_id = tct_info.teacher_id;
											const primary_secondary = tct_info.type;
											
											const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
											
											return (
												<div className='table-row-inv edit-data-teacher-can-teach-row' key={index}>
													<div>{teacher_info.name}, {teacher_info.first_name}</div>
													<div>
														<Dropdown data={primarySecondaryOptions} currentValue={primary_secondary} handleChange={(new_value) => updateData('change', index, 'teachers_can_teach', new_value)} />
													</div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'teachers_can_teach')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('tct')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Teacher Who Teaches</div>
									</div>
									{addTctOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'teachers')} placeholder='Search for teacher'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('tct')}/>
											</div>
											{tctResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{tctResults.map((teacher, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{teacher.name}, {teacher.first_name}</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', teacher.teacher_id, 'teachers_can_teach')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								<div>
									<div className='data-modal-heading'>Classroom(s) this course <span className='yellow-text'>NEEDS</span> to be in</div>
									<div>
										{courseData.classroom_restrictions.length > 0 &&
											<div className="edit-data-subheading edit-data-teacher-can-teach-row">
												<div><strong>Classroom</strong></div>
												<div>Classroom Type</div>
											</div>
										}
										{courseData.classroom_restrictions.length === 0 &&
											<div className='edit-data-no-data-container'>No classrooms set yet!</div>
										}
										{courseData.classroom_restrictions.map((classroom_id, index) => {
											const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
											
											return (
												<div className='table-row-inv edit-data-course-classroom-row' key={index}>
													<div>{classroom_info.classroom_name}</div>
													<div>{classroom_info.classroom_type}</div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'classroom_restrictions')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('classroom_restriction')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Classroom Restriction</div>
									</div>
									{addClassroomRestrictionOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-classroom-restriction-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'classrooms')} placeholder='Search for classroom or classroom type'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('classroom_restriction')}/>
											</div>
											{classroomRestrictionResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{classroomRestrictionResults.map((classroom, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{classroom.classroom_name} (<span className='orange-text'>Classroom Type</span>: {classroom.classroom_type})</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', classroom.classroom_id, 'classroom_restrictions')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								{courseData.is_special === '1' &&
									<>
									<div>
										<div className='data-modal-heading'>Homeroom teachers that need this special</div>
										<div>
											{courseData.course_homerooms.length === 0 &&
												<div className='edit-data-no-data-container'>No homeroom teachers set yet!</div>
											}
											{courseData.course_homerooms.map((teacher_id, index) => {
												const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);

												if(!teacher_info) return null;
												
												return (
													<div className='table-row-inv edit-data-teacher-can-teach-row' key={index}>
														<div>{teacher_info.name}, {teacher_info.first_name}</div>
														<div></div>
														<div className="remove-data" onClick={() => updateData('remove', index, 'course_homerooms')}><FontAwesomeIcon icon={faTimes}/></div>
													</div>
												)	
											})}
										</div>
										<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('course_homeroom')}>
											<FontAwesomeIcon icon={faPlusCircle}/>
											<div>Add Homeroom Teacher</div>
										</div>
										{addCourseHomeroomOpen &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'homeroom_teachers')} placeholder='Search for teacher'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('course_homeroom')}/>
												</div>
												{courseHomeroomResults.length !== 0 &&
													<div className='search-add-search-results'>
														<div className='search-add-did-you-mean'>Did you mean:</div>
														{courseHomeroomResults.map((teacher, i) => {
															return (
																<div className='search-add-search-result' key={i}>
																	<div>{teacher.name}, {teacher.first_name}</div>
																	<div>
																		<div className='search-add-add-btn' onClick={() => updateData('add', teacher.teacher_id, 'course_homerooms')}>Add</div>
																	</div>
																</div>
															)
														})}
													</div>
												}
											</div>
										}
									</div>
									<div>
										<div className='data-modal-heading'>How times a week is this special needed?</div>
										{numTimesNeededOptions.map((num_times_needed_option, index) => {
											const num_times_needed_value = num_times_needed_option.value;
											const num_times_needed_display = num_times_needed_option.display;

											return (
												<div className={`option-btn ${num_times_needed_value === courseData.num_times_needed ? 'option-btn-selected' : ''}`} key={index} onClick={(e) => updateData('change', num_times_needed_value, 'num_times_needed')}>{num_times_needed_display}</div>
											)	
										})}
									</div>
									<div>
										<div className='data-modal-heading'>Periods that this special can run</div>
										<div className='mb-elem-teacher-schedule-mini'>
											<div className='mb-elem-week-schedule-container-mini'>
												<div className='mb-elem-week-schedule-time-container-mini'>
													<div className='mb-elem-time-delimiter-mini'>
														<div>7:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>8:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>9:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>10:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>11:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>12:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>1:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>2:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>3:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>4:00PM</div>
													</div>
												</div>
												<div className='mb-elem-week-schedule-days-container-mini' style={day_period_grid}>
													{days.map(day_number => {
														const day_name = `Day ${day_number}`;

														const day_schedule = periods.filter(period => period.days.includes(day_number) && period.is_special === '1');

														///// Organize all the periods into overlapping groups /////
														// Any non overlapping periods get thrown into their own group
														const day_schedule_with_overlaps = day_schedule.reduce((results, period) => {
															const period_start_time = period.start_time;
															const period_end_time = period.end_time;

															const period_start_time_array = period_start_time.split(':');
															const period_end_time_array = period_end_time.split(':');

															const period_start_time_hour = parseInt(period_start_time_array[0]);
															const period_start_time_min = parseInt(period_start_time_array[1]);
															const period_start_num_hours = period_start_time_hour - 7;
															const period_row_start = Math.max(1, period_start_num_hours*60 + period_start_time_min);

															const period_end_time_hour = parseInt(period_end_time_array[0]);
															const period_end_time_min = parseInt(period_end_time_array[1]);
															const period_end_num_hours = period_end_time_hour - 7;
															const period_row_end = Math.max(1, period_end_num_hours*60 + period_end_time_min);

															period.row_start = period_row_start;
															period.row_end = period_row_end;
															period.period_length = period_row_end - period_row_start;

															results.push(period);
															return results;
														},[]);

														const sorted_day_schedule_with_overlaps = sortArrayOfObjects(day_schedule_with_overlaps, 'period_length', 'number', 'asc');

														const logged_positions = {};

														return (
															<div className='mb-elem-week-day-container-mini' key={`${day_number}`}>
																<h3>{day_name}</h3>
																<div className='mb-elem-week-day-block-parent-container-mini'>
																	<div className='mb-elem-week-day-grid-mini'>
																		{sorted_day_schedule_with_overlaps.map(period => {
																			const period_id = period.period_id;
																			const period_name = period.period_name;
																			const is_lunch = period.is_lunch;
																			const is_special = period.is_special;
																			const is_pullout = period.is_pullout;
																			
																			const start_time = period.start_time;
																			const end_time = period.end_time;
																			const start_time_formatted = formatTime(start_time);
																			const end_time_date_formatted = formatTime(end_time);

																			const row_start = period.row_start;
																			const row_end = period.row_end;

																			const period_color = (is_lunch === '1') ? 'pastel-yellow' : ((is_special === '1') ? 'green' :((is_pullout === '1') ? 'orange' : 'dark-purple'));

																			// Calculate the column start and end position on the grid
																			const period_position = calculatePeriodColumnStartEnd(period, day_schedule_with_overlaps, logged_positions, start_time, end_time);
																			
																			const position_index = period_position.position_index;
																			const col_start = period_position.col_start;
																			const col_end = period_position.col_end;

																			logged_positions[period_id] = position_index;

																			const day_period_index = courseData.day_period_restrictions.findIndex(day_period_restriction => day_period_restriction.day_number === day_number && day_period_restriction.period_id === period_id)
																			const day_is_restricted = day_period_index !== -1;
																			
																			return (
																				<div className='mb-elem-block-mini parent-div' style={{gridColumnStart:col_start, gridColumnEnd:col_end, gridRowStart:row_start, gridRowEnd:row_end}} key={period_id}>
																					<div className={`mb-elem-block-inner-mini left-border-${period_color}-thick`} >
																						<div>
																							{(!day_is_restricted) ? 
																								(
																									<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateData('add', {day_number:day_number, period_id:period_id}, 'day_period_restrictions')}/>
																								) : 
																								(
																									<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare} onClick={() => updateData('remove', day_period_index, 'day_period_restrictions')}/>
																								)
																							}
																						</div>
																						<div className='ellipsis'>
																							<div className={`mb-elem-block-header-mini`}>
																								<span className='tooltip' data-tooltip-text={period_name}>
																									<div className='mb-elem-block-name-mini ellipsis'>{period_name}</div>
																								</span>
																							</div>
																							<div className={`mb-elem-block-content-mini`}>
																								<div className='tooltip' data-tooltip-text={`${start_time_formatted} - ${end_time_date_formatted}`}>
																									<div className='mb-elem-block-time-mini ellipsis'>{start_time_formatted} - {end_time_date_formatted}</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			)
																		})}
																	</div>
																</div>
															</div>
														)
													})}
												</div>
											</div>
										</div>
									</div>
									</>
								}
								{(courseData.is_pullout === '1') &&
									<>
									<div className='pullouts-setup-question-container'>
										<div>
											<div className='data-modal-heading'>Create groups of students with similar needs</div>
											{Object.keys(courseData.pullout_groups).length === 0 &&
												<div className='edit-data-no-data-container'>No groups selected yet...</div>
											}
											{Object.keys(courseData.pullout_groups).map(group_id => {
												const group_info = courseData.pullout_groups[group_id];
												const student_list = group_info.student_list;
												const num_times_needed = group_info.num_times_needed;
												const duration = group_info.duration;
												const day_restrictions = group_info.day_restrictions;
												const strict_group = group_info.strict_group;
												const max_group_size = group_info.max_group_size;
												const student_search_open = ('student_search_results' in group_info);
												const student_search_results = (student_search_open) ? group_info.student_search_results : [];

												return (
													<div className='pullouts-setup-group-container' key={`group-${group_id}`}>
														<div className='pullouts-setup-group-delete'><FontAwesomeIcon icon={faTrashAlt} className='gray-to-red-link' onClick={() => updateCourseGroup(group_id, 'remove', null, null)} /></div>
														<div>
															<div className='pullouts-setup-group-container-main-heading'>Students</div>
															<div className='pullouts-setup-group-student-list'>
																{student_list.map(student_id => {
																	const student_index = students.findIndex(student => student.student_id === student_id);
																	const student_info = (student_index !== -1) ? students[student_index] : null;
																	if(!student_info) return;
						
																	const student_last_name = student_info.last_name;
																	const student_first_name = student_info.first_name;
																	const student_grade = student_info.grade;
						
																	return (
																		<div className='pullouts-setup-group-student' key={student_index}>
																			<div>{student_last_name}, {student_first_name} {student_grade && `- Grade ${student_grade}`}</div>
																			<div className='cursor-pointer' onClick={() => updateCourseGroup(group_id, 'remove', student_id, 'student_list')}><FontAwesomeIcon icon={faTimes}/></div>
																		</div>
																	)
																})}
															</div>
															<div className='blue-link medium-text pullouts-setup-add-link align-right' onClick={() => toggleGroupStudentSearch(group_id)}><FontAwesomeIcon icon={faCirclePlus}/> Add a student</div>
															{student_search_open &&
																<div className='search-add-row-container'>
																<div className='search-add-search-container'>
																	<div className='search-add-adding-input-container'>
																		<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => groupStudentSearch(e.target.value, group_id)} placeholder='Search for student'/>
																		<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
																	</div>
																	<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleGroupStudentSearch(group_id)}/>
																</div>
																{student_search_results.length > 0 &&
																	<div className='elem-suggestion-box-parent parent-div'>
																		<div className='elem-suggestion-box'>
																			{student_search_results.map((student, index) => {
																				const student_id = student.student_id;
																				const student_first_name = student.first_name;
																				const student_last_name = student.last_name;
																				const search_student_grade = student.grade;
																				const student_in_group = student_list.includes(student_id);
				
																				return (
																					<div className='search-add-search-result' key={index} onClick={student_in_group ? ()=>{} : () => updateCourseGroup(group_id, 'add', student_id, 'student_list')}>
																						<div>{student_last_name}, {student_first_name} {search_student_grade && `- Grade ${search_student_grade}`}</div>
																						{student_in_group ?
																						(
																							<div className='green-text'>In group</div>
																						):
																						(
																							<div className='search-add-add-btn'>Add</div>
																						)}
																					</div>
																				)
																			})}
																		</div>
																	</div>
																}
															</div>
															}
														</div>
														<div>
															<div className='pullouts-setup-group-container-main-heading'>Settings</div>
															<div className='pullouts-setup-group-label'># times needed per cycle</div>
															<Dropdown data={numTimesNeededOptions} currentValue={num_times_needed} handleChange={(new_value) => updateCourseGroup(group_id, 'change', new_value, 'num_times_needed')} />
															<div className='pullouts-setup-group-label'>Duration needed</div>
															<Dropdown data={durationOptions} currentValue={duration} handleChange={(new_value) => updateCourseGroup(group_id, 'change', new_value, 'duration')} />
															<div className='pullouts-setup-group-label'>What days can this run?</div>
															<div className='pullouts-setup-group-days'>
																{days.map(day => {
																	const day_selected = (day_restrictions.lengh === 0 || !day_restrictions.includes(day));
																	return (
																		<div className="option-btn option-btn-no-hover small-text" key={day} onClick={day_selected ? () => updateCourseGroup(group_id, 'add', day, 'day_restrictions') : () => updateCourseGroup(group_id, 'remove', day,'day_restrictions')}>
																			<div>
																			{(day_selected) ? 
																				(
																					<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare}/>
																				) : 
																				(
																					<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare}/>
																				)
																			}
																			</div>
																			<div>Day {day}</div>
																		</div>
																	)
																})}
															</div>
															<div className='pullouts-setup-group-label' style={{marginBottom:'10px'}}>Do all these students need to have this course together?</div>
															<div className='toggle-with-text-btn-container edit-course-toggle'>
																<div className={`toggle-with-text-btn-optn ${strict_group === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={() => updateCourseGroup(group_id, 'change', '1', 'strict_group')}>Yes</div><div className={`toggle-with-text-btn-optn ${strict_group === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={() => updateCourseGroup(group_id, 'change', '0', 'strict_group')}>No</div>
															</div>
															{strict_group === '0' &&
																<>
																<div className='pullouts-setup-group-label'>Max # students to group together</div>
																<Dropdown data={maxGroupSizeOptions} currentValue={max_group_size} handleChange={(new_value) => updateCourseGroup(group_id, 'change', new_value, 'max_group_size')} />
																</>
															}
														</div>
													</div>
												)
											})}
											<div className='blue-link edit-data-add-new-btn' onClick={() => updateCourseGroup(null, 'add', null, null)}>
												<FontAwesomeIcon icon={faCirclePlus}/> 
												<div>Create a group</div>
											</div>
										</div>
									</div>
									<div>
										<div className='data-modal-heading'>Courses to pull out from</div>
										<p>If courses are selected here, then the period restrictions (above) will get ignored. Pullouts will only pull out from these courses, wherever in the schedule they are placed.</p>
										<div>
											{courseData.pullout_allowed_courses.map((allowed_course_id, index) => {
												const course_info = courses.find(course => course.course_id === allowed_course_id);
												
												return (
													<div className='table-row-inv edit-data-teacher-can-teach-row' key={index}>
														<div>{course_info.name} {course_info.course_code && `(${course_info.course_code})`}</div>
														<div></div>
														<div className="remove-data" onClick={() => updateData('remove', index, 'pullout_allowed_courses')}><FontAwesomeIcon icon={faTimes}/></div>
													</div>
												)	
											})}
										</div>
										<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('pullout_allowed_course')}>
											<FontAwesomeIcon icon={faPlusCircle}/>
											<div>Add Course to Pull Out From</div>
										</div>
										{addPulloutAllowedCourseOpen &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'courses')} placeholder='Search for course'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('pullout_allowed_course')}/>
												</div>
												{pulloutAllowedCourseResults.length !== 0 &&
													<div className='search-add-search-results'>
														<div className='search-add-did-you-mean'>Did you mean:</div>
														{pulloutAllowedCourseResults.map((course, i) => {
															return (
																<div className='search-add-search-result' key={i}>
																	<div>{course.name} {course.course_code && `(${course.course_code})`}</div>
																	<div>
																		<div className='search-add-add-btn' onClick={() => updateData('add', course.course_id, 'pullout_allowed_courses')}>Add</div>
																	</div>
																</div>
															)
														})}
													</div>
												}
											</div>
										}
									</div>
									</>
								}
							</form>
						}
					</div>
				</div>
			</div>
		</div>
	);
}