import React from 'react';

import DocumentationHSMS from './hsms/DocumentationHSMS';

export default function Documentation({ match, isAuth, authComplete, schoolInfoLoading, userInfo, schoolType, setLoadScreen }) {
	return (
		<>
			{(() => {
				switch(schoolType) {
					case 'hsms':
						return <DocumentationHSMS match={match} isAuth={isAuth} authComplete={authComplete} schoolInfoLoading={schoolInfoLoading} userInfo={userInfo} schoolType={schoolType} setLoadScreen={setLoadScreen}/>;
					case 'elementary':
						return <DocumentationHSMS match={match} isAuth={isAuth} authComplete={authComplete} schoolInfoLoading={schoolInfoLoading} userInfo={userInfo} schoolType={schoolType} setLoadScreen={setLoadScreen}/>;
					default:
						return null;
				}
			})()}
		</>
	);
}