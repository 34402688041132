import React, { useState, useEffect } from 'react';
import {createPortal} from 'react-dom';

export default function Portal({ children, styleSheets, windowName, windowWidth, windowHeight, cleanUpFunction }) {
	const [externalWindow, setExternalWindow] = useState(null);
	const [containerElement, setContainerElement] = useState(null);

	useEffect(() => {
		const externalWindow = window.open("", "", `width=${windowWidth},height=${windowHeight}`);

        let containerElement = null;
        if(externalWindow) 
		{
            containerElement = externalWindow.document.createElement('div');
			containerElement.className = 'report-container';
            externalWindow.document.title = windowName;
            externalWindow.document.body.appendChild(containerElement);

			// Copy the app's styles into the new window
            const stylesheets = Array.from(document.styleSheets);

			stylesheets.forEach(stylesheet => {
				if (stylesheet.href) {
                    const newStyleElement = document.createElement('link');
                    newStyleElement.rel = 'stylesheet';
                    newStyleElement.href = stylesheet.href;
                    externalWindow.document.head.appendChild(newStyleElement);
                } else if (stylesheet && stylesheet.cssRules && stylesheet.cssRules.length > 0) {
                    const newStyleElement = document.createElement('style');
                    Array.from(stylesheet.cssRules).forEach(rule => {
                        newStyleElement.appendChild(document.createTextNode(rule.cssText));
                    });
                    externalWindow.document.head.appendChild(newStyleElement);
                }
            });

			externalWindow.addEventListener('beforeunload', () => {
				cleanUpFunction();
			});
		}

		setExternalWindow(externalWindow);
		setContainerElement(containerElement);
	}, []);

	return (
		<>{containerElement && createPortal(children, containerElement)}</>
	)
}