import React, { useState , useRef, useEffect } from 'react';
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import Fuse from 'fuse.js';

import DepartmentProgressScreen from '../department_progress_screen';
import EditTeacherData from '../../modals/edit_teacher';
import Button from '../../../Button';

import { useOutsideClick, getData } from '../../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUserCircle, faChalkboard, faHourglassHalf, faCheckCircle, faCheck, faSearch, faTimes, faExclamationCircle, faTrashAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function CleanTeacherClassrooms({isLoading, subscreenRestricted, subscreenLocked, subscreen, subscreenResponsibleRoleID, supervisors, openSupervisorsList, closeSupervisorsList, addSupervisorToDepartment, removeSupervisorFromDepartment, users, userIsSupervisor, userIsAdmin, departments, setDepartments, departmentID, sortedDepartments, changeDepartment, userInfo, schoolInfo, schoolType, scheduleInfo, classrooms, teachers, setTeachers, courses, students, scheduleStructureData, sections, addNewTeacherOpen, setAddNewTeacherOpen, subscreenSelect, submitDepartment, reviewing, toggleReviewDepartment, changeDepartmentResource, setRolePages, showNextButton, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo}) {
	const ref = useRef();
	
	const [addTeacherOpen, setAddTeacherOpen] = useState(false);
	const [teacherResults, setTeacherResults] = useState([...teachers]);
	
	const [changesSavedMessage, setChangesSavedMessage] = useState(false);
	
	/////////////////////////////////
	///// SUPERVISORS FUNCTIONS /////
	/////////////////////////////////
	useOutsideClick(ref, () => {
		closeSupervisorsList();
	});
	
	const addSearch = (e) => {
		const search_value = e.target.value;
		const fuse = new Fuse(teachers, {
			keys: ['name', 'first_name'],
			threshold: .6
		})
		
		const results = fuse.search(search_value);
		const teacher_results = results.map(result => result.item).splice(0,5);
		
		setTeacherResults(teacher_results);
	}
	
	////////////////////////////
	///// UPDATE FUNCTIONS /////
	////////////////////////////
	const updateTeacherClassrooms = async (teacher_id, update_type, classroom_id) => {
		const data = {school_id: schoolInfo.school_id, department_id:departmentID, schedule_version_id:schoolInfo.current_schedule_version_id, teacher_id:teacher_id, update_type:update_type, classroom_id:classroom_id};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/clean-data/update-teacher-classrooms';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
			
			///////////////////////
			///// UPDATE JSON /////
			///////////////////////
			const department_index = departments.findIndex(department => department.department_id === departmentID);

			if(userIsSupervisor) 
			{
				if(department_index !== -1)
				{
					departments[department_index].subscreens[subscreen].submitted = false;
					departments[department_index].subscreens[subscreen].confirmed = false;
				}

				// Reset overall progress to update the Progress screen
				getData('schoolProgress', '/get-school-progress', {school_id:schoolInfo.school_id, schedule_year_id:schoolInfo.current_schedule_year_id}, true).then(user_progress_data => {
					const role_pages = user_progress_data.role_pages;
					
					setRolePages(role_pages);
				});
			}

			if(userIsAdmin && departments[department_index].subscreens[subscreen].confirmed) setChangesSavedMessage(true);
			
			const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === teacher_id);
			const teacher_info = teachers[teacher_index];
			
			const classroom_restrictions = teacher_info.classroom_restrictions;
			
			if(update_type === 'remove')
			{
				const new_classroom_restrictions = classroom_restrictions.filter(classroom => classroom !== classroom_id);
				teacher_info.classroom_restrictions = new_classroom_restrictions;
			}
			else
			{
				// Check if teacher already has classroom attached
				// If no, add classroom
				const classroom_index = classroom_restrictions.findIndex(classroom => classroom === classroom_id);
				if(classroom_index === -1) classroom_restrictions.push(classroom_id);
			}
			
			delete teachers[teacher_index]['searching'];
			setTeachers([...teachers]);
			setDepartments([...departments]);
		} catch(e)
		{
			console.log(e.response);
		}
	}

	const updateTeacher = (changeType, dataType, data_id, old_department_id, new_department_id) => {
		changeDepartmentResource(changeType, dataType, data_id, old_department_id, new_department_id, () => {
			if(changeType === 'add' && userIsSupervisor) 
			{
				const department_index = departments.findIndex(department => department.department_id === departmentID);

				if(department_index !== -1)
				{
					departments[department_index].subscreens[subscreen].submitted = false;
					departments[department_index].subscreens[subscreen].confirmed = false;
				}
				
				// Update cleaning screen progress (for check marks)
				departments[department_index].subscreens['teacher_availability'].submitted = false;
				departments[department_index].subscreens['teacher_availability'].confirmed = false;

				departments[department_index].subscreens['course_teachers'].submitted = false;
				departments[department_index].subscreens['course_teachers'].confirmed = false;

				departments[department_index].subscreens['teacher_classrooms'].submitted = false;
				departments[department_index].subscreens['teacher_classrooms'].confirmed = false;

				setDepartments([...departments]);

				// Reset overall progress to update the Progress screen
				getData('schoolProgress', '/get-school-progress', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
					const role_pages = user_progress_data.role_pages;
					
					setRolePages(role_pages);
				});
			}
		});
	}
	
	const toggleAddNewData = (teacher_id) => {
		const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === teacher_id);
		const teacher_info = teachers[teacher_index];
		
		if('searching' in teacher_info) 
		{
			delete teachers[teacher_index]['searching'];
		}
		else
		{
			teachers[teacher_index]['searching'] = true;
		}
		
		delete teachers[teacher_index]['department_classroom_results'];
		delete teachers[teacher_index]['other_classroom_results'];
		
		setTeachers([...teachers]);
	}
	
	const fuzzySearch = (e, teacher_id) => {
		const search_value = e.target.value;
		
		const fuse = new Fuse(classrooms, {
			keys: ['classroom_name', 'classroom_type'],
			threshold: .6
		})
		
		const results = fuse.search(search_value);
		const classroom_results = results.map(result => result.item);
		
		const department_classroom_results = classroom_results.filter(classroom => classroom.departments.includes(departmentID)).splice(0, 5);
		const other_classroom_results = classroom_results.filter(classroom => !classroom.departments.includes(departmentID)).splice(0, 5);
		
		const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === teacher_id);
		const teacher_info = teachers[teacher_index];
		
		teacher_info['department_classroom_results'] = department_classroom_results;
		teacher_info['other_classroom_results'] = other_classroom_results;
		setTeachers([...teachers]);
	}
	
	//////////////////////////////
	//// USE EFFECT FUNCTIONS ////
	//////////////////////////////
	useEffect(() => {
		if(!departmentID || !userInfo || !users || !departments) return;

		const department_index = departments.findIndex(department => department.department_id === departmentID);
		const department_info = (department_index !== -1) ? departments[department_index] : null;
		const department_supervisors = (department_info) ? department_info.supervisors : [];

		const available_users = users.filter(user => (user.role_id === '1' || user.role_id === '2') || department_supervisors.findIndex(supervisor => supervisor.user_id === user.user_id) !== -1);

		setScreenAllowsUniversalSidebar(true);
		setUniversalSidebarLoadingInfo({sidebar_name:"Teacher Classroom Notes", sidebar_type:'notes', subscreen:'teacher_classrooms', department_id:departmentID, user_id:userInfo.user_id, available_users:available_users, help_link_path:'/app/hsms/documentation/clean_data/clean_department_data'});
	}, [departmentID, userInfo, users, departments]);
	
	const row_style = {gridTemplateColumns:'1fr 1fr auto', gridColumnGap:'15px'};
	
	const department_index = departments.findIndex(department => department.department_id === departmentID);
	const department_info = departments[department_index];
	const subscreen_info = (department_info) ? department_info.subscreens[subscreen] : null;
	
	const current_screen_submitted = (subscreen_info) ? subscreen_info.submitted : false;
	const current_screen_confirmed = (subscreen_info) ? subscreen_info.confirmed : false;
	const current_screen_unlocked= (subscreen_info) ? subscreen_info.unlocked : false;
	
	const show_current_screen = (current_screen_unlocked && (reviewing || userIsSupervisor || userIsAdmin));
	const screen_complete_for_user = ((current_screen_confirmed) || (userIsSupervisor && !userIsAdmin && current_screen_submitted));
	
	const department_teachers = teachers.filter(teacher => teacher.departments.includes(departmentID));
	
	return (
		<>
		<h1 className='progress-main-header'>Teacher Classrooms</h1>
		<p className='progress-subheader'>Set any constraints on what classrooms teachers prefer</p>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(!departmentID) ? 
		(
			<DepartmentProgressScreen subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} subscreenSelect={subscreenSelect} userInfo={userInfo} userIsAdmin={userIsAdmin} userIsSupervisor={userIsSupervisor} />
		):(!userIsSupervisor) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to change this department</div>	
			</div>
		):(departmentID) ?
		(
			<>
			<div id='clean-data-content'>
				<div>
					<FontAwesomeIcon icon={faChevronLeft} className='progress-back-to-departments-btn' onClick={()=>changeDepartment(null)}/>
				</div>
				<div className='clean-data-content-inner-content'>
					<div className='clean-data-submit-btn-container'>
						{!isLoading &&
							<>
							{(reviewing && screen_complete_for_user) &&
								<>
								{changesSavedMessage && 
									<div className='all-changes-saved-message' style={{margin:'15px 0px 0px 0px'}}>
										<FontAwesomeIcon className='green-text' icon={faCheckCircle}/>
										<div>
											<h5>Changes Saved</h5>
											<div className='small-text'>All your changes have been saved</div>
										</div>
										<FontAwesomeIcon className='x-remove' icon={faTimes} onClick={() => setChangesSavedMessage(false)}/>
									</div>
								}
								</>
							}
							{(!screen_complete_for_user && show_current_screen) &&
								<Button key={departmentID} classes={['mark-as-complete-btn']} selected={false} text={`${!current_screen_submitted ? 'Mark As Complete' : 'Confirm Department'}`} icon={faCheck} iconPosition='left' onClickFunction={() => submitDepartment(departmentID)} />
							}
							</>
						}
					</div>
					<div className='clean-data-content-header-container'>
						<h1 className='clean-data-content-header'>{department_info && department_info.department} Department</h1>
						{current_screen_confirmed ?
							(
								<div className='clean-data-status green-text'><div>Complete</div><FontAwesomeIcon icon={faCheckCircle}/></div>
							): (current_screen_submitted) ?
							(
								<div className='clean-data-status bright-yellow-text'><div>Pending Confirmation</div><FontAwesomeIcon icon={faCheckCircle}/></div>
							): (null)
						}
					</div>
					<div className='department-supervisor-parent-container'>
						<>
						{(department_info && department_info.supervisors.length === 0) &&
							<>
							<div className='department-supervisor-inner-container'>
								<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
								<div className='department-supervisor-name'>No Supervisor Assigned</div>
							</div>
							</>
						}
						{department_info && department_info.supervisors.map((supervisor, index) => {
							return (
								<div className='department-supervisor-outer-container' key={index}>
									<div className='department-supervisor-inner-container'>
										{supervisor.img_url &&
											<img className='department-supervisor-img' src={require(`../../../../images/users/${supervisor.img_url}`)} alt='user' />
										}
										{!supervisor.img_url &&
											<FontAwesomeIcon className='department-supervisor-user-icon gray-text' icon={faUserCircle}/>
										}
										<div className='department-supervisor-name'>{supervisor.last_name}, {supervisor.first_name}</div>
										{userIsAdmin &&
											<FontAwesomeIcon className='gray-text-hover' icon={faTimes} onClick={() => removeSupervisorFromDepartment(supervisor.user_id)}/>
										}
									</div>
								</div>
							)
						})}
						{userIsAdmin &&
							<>
							<div className={`clean-data-add-supervisor-link small-text click-restricted ${(department_info && department_info.supervisors.length === 0) ? 'blue-link' : 'gray-to-blue-link'}`} onClick={() => openSupervisorsList(departmentID)}>Add a Supervisor</div>
							<div className='supervisor-options-container'>
								{(department_info && department_info.supervisors_open) &&
									<div className='data-options-container' ref={ref}>
										{supervisors.map((supervisor_info, index) => {
											
											return (
												<div className='data-options-option assign-resourses-supervisor-option' key={index} onClick={() => addSupervisorToDepartment(supervisor_info)}>
													{supervisor_info.img_url &&
														<img className='department-supervisor-img' src={require(`../../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
													}
													{!supervisor_info.img_url &&
														<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
													}
													<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
												</div>
											)	
										})}
										<Link className='data-options-option blue-text' to={{pathname:`/app/${schoolType}/settings/users`, redirect:`/app/${schoolType}/progress/clean_data/teacher_classrooms`}} style={{borderTop:'1px solid #efefef'}} onClick={() => closeSupervisorsList(departmentID)}>Add new Supervisor</Link>
									</div>
								}
							</div>
							</>
						}
						</>
					</div>
					<div className='clean-data-container'>
						{isLoading ?
							(
								<div className="center-text">
									<img className='clean-data-loader' src={require(`../../../../images/balls.gif`)} alt='loading' />
								</div>
							):
							(
								<>
								{(!current_screen_unlocked) ?
									(
										<div className='general-screen-message'>
											<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
											<h1>Locked</h1>
											<div>This department has previous steps which have not been completed. Please review previous steps and make sure they are completed and saved. This page should open automatically once all previous steps have been finished.</div>
										</div>
									):(userIsAdmin && current_screen_unlocked && !show_current_screen && !current_screen_submitted && !userIsSupervisor) ?
									(
										<div className='general-screen-message'>
											<FontAwesomeIcon className='turquoise-text' style={{fontSize:'60px'}} icon={faHourglassHalf}/>
											<h1>Pending...</h1>
											<div>Waiting on confirmation from department supervisor</div>
											<p className='blue-link' onClick={toggleReviewDepartment}>Review teacher classrooms yourself</p>	
										</div>
									):(show_current_screen) ?
									(
										<>
										{department_teachers.length === 0 ?
											(
												<div className='clean-data-no-data-container'>
													<FontAwesomeIcon className='clean-data-no-data-icon dark-blue-text' icon={faExclamationCircle}/>
													<div>No teachers found</div>
													<p>Please confirm that this is correct by submitting the screen</p>
												</div>
											) :
											(
												<>
												{department_teachers.map((teacher_info, i) => {
													const teacher_id = teacher_info.teacher_id;
													const classroom_restrictions = teacher_info.classroom_restrictions.filter(classroom_id => classrooms.findIndex(classroom => classroom.classroom_id === classroom_id) !== -1);
													const dual_dept_bool = ([...new Set(teacher_info.departments)].length > 1) ? true : false;
													
													const is_searching = ('searching' in teacher_info) ? true : false;
													
													const department_classroom_results = ('department_classroom_results' in teacher_info) ? teacher_info.department_classroom_results : [];
													const other_classroom_results = ('other_classroom_results' in teacher_info) ? teacher_info.other_classroom_results : [];
													const any_search_results = department_classroom_results.length + other_classroom_results.length;
													
													return (
														<div className='clean-data-connection-main-container' key={i}>
															<h3 className='clean-data-connection-main-header'>
																<div>{teacher_info.name}, {teacher_info.first_name} {dual_dept_bool && <span className='orange-text small-text'>Dual Depts.</span>}</div>
																<FontAwesomeIcon className='gray-to-red-link' icon={faTrashAlt} onClick={() => updateTeacher('remove', 'teacher', teacher_id, departmentID, null)} style={{justifySelf:'center'}}/>	
															</h3>
															<div className='clean-data-connection-main-container-inner'>
																<div className='dark-blue-text medium-text' style={{marginBottom:'10px'}}>Adding a classroom preference is optional.</div>
																{classroom_restrictions.map((classroom_id, i) => {
																	const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
																	
																	return (
																		<div className='clean-data-connection-container' data-teacherid={teacher_id} data-classroomid={classroom_id} style={row_style} key={i}>
																			<div className='clean-data-connection-header'>
																				<div className='clean-data-connection-label'>Classroom</div>
																				<div className='dark-blue-text'><FontAwesomeIcon className='clean-data-connection-icon' icon={faChalkboard}/> {classroom_info.classroom_name}</div>
																			</div>
																			<div className='clean-data-connection-details'>
																				<div className='clean-data-connection-label'>Classroom Type</div>
																				{classroom_info.classroom_type}
																			</div>
																			<div className='clean-data-remove-connection-container' onClick={() => updateTeacherClassrooms(teacher_id, 'remove', classroom_id)}>
																				<FontAwesomeIcon className='x-remove' icon={faTimes}/>
																			</div>
																		</div>
																	)
																})}
																<div className='blue-link clean-data-add-new-connection' onClick={() => toggleAddNewData(teacher_id)}>Add Classroom Preference</div>
																{is_searching &&
																	<div className='search-add-row-container'>
																		<div className='search-add-search-container'>
																			<div className='search-add-adding-input-container'>
																				<input id={`search-input-teacher-${teacher_id}`} className='search-add-adding-input' onChange={(e) => fuzzySearch(e, teacher_id)} placeholder='Search for classroom or classroom type' autoFocus={true}/>
																				<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
																			</div>
																			<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewData(teacher_id)}/>
																		</div>
																		{any_search_results > 0 &&
																			<div className='search-add-search-results'>
																				{(department_classroom_results.length !== 0) && 
																					<>
																					<div className='search-add-did-you-mean'>Department Classrooms:</div>
														
																					{department_classroom_results.map((classroom, i) => {
																						return (
																							<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 1fr auto'}}>
																								<div>{classroom.classroom_name}</div>
																								<div className='dark-gray-text small-text'>{classroom.classroom_type}</div>
																								<div>
																									<div className='search-add-add-btn' onClick={() => updateTeacherClassrooms(teacher_id, 'add', classroom.classroom_id)}>Add</div>
																								</div>
																							</div>
																						)
																					})}
																					</>
																				}
																				{(other_classroom_results.length !== 0) &&
																					<>
																					<div className='search-add-did-you-mean'>Other Classrooms:</div>
																					{other_classroom_results.map((classroom, i) => {
																						return (
																							<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 1fr auto'}}>
																								<div>{classroom.classroom_name}</div>
																								<div className='dark-gray-text small-text'>{classroom.classroom_type}</div>
																								<div>
																									<div className='search-add-add-btn' onClick={() => updateTeacherClassrooms(teacher_id, 'add', classroom.classroom_id)}>Add</div>
																								</div>
																							</div>
																						)
																					})}
																					</>
																				}
																			</div>
																		}
																	</div>
																}
															</div>
														</div>
													)
												})}
												</>
											)
											
										}
										<div className='clean-data-add-new-data blue-link assign-resources-supervisor-confirm-add-new' onClick={() => setAddTeacherOpen(!addTeacherOpen)}>Add A Teacher</div>
										{addTeacherOpen &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='teacher-search-input' className='search-add-adding-input' onChange={(e) => addSearch(e)} placeholder='Search for teacher'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => setAddTeacherOpen(!addTeacherOpen)}/>
												</div>
												{teacherResults.length !== 0 &&
													<div className='search-add-search-results'>
														<div className='search-add-did-you-mean'>Did you mean:</div>
														{teacherResults.map((teacher, i) => {
															const teacher_departments = teacher.departments.reduce((result, department_id) => {
																const department_index = departments.findIndex(department => department.department_id === department_id);
																
																if(department_index !== -1) result.push(departments[department_index].department);
																
																return result;
															}, []);
															
															const department_text = teacher_departments.join(', ');
															
															return (
																<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 1fr auto'}}>
																	<div>{teacher.name}, {teacher.first_name}</div>
																	<div className='dark-gray-text small-text'>{department_text}</div>
																	<div>
																		<div className='search-add-add-btn' onClick={() => updateTeacher('add', 'teacher', teacher.teacher_id, null, departmentID)}>Add</div>
																	</div>
																</div>
															)
														})}
														<div className='clean-data-add-new-data-text dark-blue-text'>If you don&rsquo;t see the teacher you are looking for, you should first add them <span className='blue-link' onClick={() => setAddNewTeacherOpen(true)}>Add A New Teacher</span></div>
													</div>
												}
											</div>
										}
										{addNewTeacherOpen &&
											<EditTeacherData schoolInfo={schoolInfo} dataID={null} toggleEditData={() => setAddNewTeacherOpen(false)} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} teachers={teachers} students={students} courses={courses} classrooms={classrooms} sections={sections} departments={departments} setDatabaseData={setTeachers} defaultDepartmentID={departmentID}/>
										}
										</>
									):null
								}
								</>
							)
						}
					</div>
				</div>
			</div>
			</>
		):null}
		{showNextButton &&
			<div className='fixed-bottom-save-container'>
				<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Continue to Create Sections' onClickFunction={handleMoveToNextScreen} />
			</div>
		}
		</>
	);
}