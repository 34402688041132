import React, { useState, useRef } from 'react'
import { useDrop } from 'react-dnd';
import { API } from "aws-amplify";

import MagnetBoardSectionHSMS from './MagnetboardSectionHSMS';

import { useOutsideClick, } from '../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, } from '@fortawesome/free-solid-svg-icons';

export default function MagnetboardDropPeriodHSMS ({schoolInfo, scheduleInfo, scheduleStructureData, scheduleType, currentView, currentViewType, currentDay, currentScheduleVersionID, magnetboardOptions, teachers, setTeachers, courses, classrooms, students, setStudents, sections, setSections, subsections, labels, conflicts, setConflicts, setConflictsLoading, setMatchPercent, setMatchPercentLoading, labsOnlyFiltered, nonLabsOnlyFiltered, singletonsFiltered, doubletonsFiltered, noClassroomFiltered, hasClassroomFiltered, toggleEditSection, updateManualPlacement, updateSectionPlacement, isTeacherLunchPeriod, teacherID, classroomID, periodID, periodName, coursePeriodID, dataSections, handleSelectQuickViewDataID, selectedQuickViewSectionID}) {
	/////////////////////
	///// USE STATE /////
	/////////////////////
	const [addNewSectionIsOpen, setAddNewSectionIsOpen] = useState(false);
	const dataID = (currentView === 'classroom') ? classroomID : teacherID;

	/////////////////////////////
	///// USE DROP FUNCTION /////
	/////////////////////////////
    const [{ isOver }, dropRef] = useDrop({
        accept: ['section', 'manual-section', 'lunch'],
		drop: (item) => {
			if(item.type === 'section')
			{
				updateSectionPlacement({...item, droppedDataID: dataID, droppedCoursePeriodID: coursePeriodID});
			}
			else if(item.type === 'manual-section')
			{
				updateManualPlacement({ updateType:'add', sectionID:item.sectionID, droppedCoursePeriodID: coursePeriodID});
			}
			else if(item.type === 'lunch')
			{
				const new_schedule_period_id = scheduleStructureData.course_periods[item.draggedCoursePeriodID].days[currentDay];
				updateLunchSchedule(currentView, new_schedule_period_id, dataID);
			}
		},
    });

	///////////////////
	///// USE REF /////
	///////////////////
	const add_new_section_ref = useRef();

	useOutsideClick(add_new_section_ref, () => {
		setAddNewSectionIsOpen(false);
	});

	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const updateLunchSchedule = async (user_type, schedule_period_id, user_id) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, user_type:user_type, user_id:user_id, schedule_period_id:schedule_period_id};

		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/update-lunch-schedule';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
			
			if(user_type === 'teacher')
			{
				const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === user_id);
				if(teacher_index !== -1) teachers[teacher_index].lunch_schedule_period_id = schedule_period_id;

				setTeachers([...teachers]);
			}
			else if(user_type === 'student')
			{
				const student_index = students.findIndex(student => student.student_id === user_id);
				if(student_index !== -1) students[student_index].lunch_schedule_period_id = schedule_period_id;

				setStudents([...students]);
			}
		} catch(e)
		{
			console.log(e.response);
		}
	}

	/////////////////////
	///// USE EFFECT /////
	/////////////////////
	let logged_section_ids = [];

    return (
        <div className={`mb-inner-box mb-schedule-inner-box ${isOver && 'mb-schedule-inner-box-hovered'}`} ref={dropRef}>
			<div className='print-period-name'>{periodName}</div>
			{/*isTeacherLunchPeriod &&
				<div className='mb-section mb-lunch-section' ref={lunchDragRef}>
					<div className='mb-section-top-bar pastel-yellow'>
						<div>Lunch</div>
						<div className='mb-section-more-info opaque-link' onClick={() => updateLunchSchedule('teacher', null, teacherID)}><FontAwesomeIcon icon={faTrashAlt}/></div>
					</div>
					<div className='mb-section-content'>
						<div className='mb-section-name ellipsis'>Lunch</div>
					</div>
				</div>
			*/}
			{dataSections.map((section_info, index) => {
				const section_id = section_info.section_id;
				const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_id);
				const main_section_id = (subsection_index !== -1) ? subsections[subsection_index].section_id : section_id;

				if(logged_section_ids.includes(main_section_id)) return null;
				logged_section_ids.push(main_section_id);

				return (
					<MagnetBoardSectionHSMS key={index} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} scheduleType={scheduleType} currentView={currentView} currentViewType={currentViewType} currentDay={currentDay} currentScheduleVersionID={currentScheduleVersionID} teachers={teachers} courses={courses} classrooms={classrooms} sections={sections} setSections={setSections} subsections={subsections} labels={labels} conflicts={conflicts} setConflicts={setConflicts} setConflictsLoading={setConflictsLoading} setMatchPercent={setMatchPercent} setMatchPercentLoading={setMatchPercentLoading} labsOnlyFiltered={labsOnlyFiltered} nonLabsOnlyFiltered={nonLabsOnlyFiltered} singletonsFiltered={singletonsFiltered} doubletonsFiltered={doubletonsFiltered} noClassroomFiltered={noClassroomFiltered} hasClassroomFiltered={hasClassroomFiltered} toggleEditSection={toggleEditSection} dataID={dataID} coursePeriodID={coursePeriodID} sectionID={section_info.section_id} sectionType='section' handleSelectQuickViewDataID={handleSelectQuickViewDataID} selectedQuickViewSectionID={selectedQuickViewSectionID}/>
				)
			})}
			{magnetboardOptions.allow_new_sections &&
				<div className='mb-add-new-section' ref={add_new_section_ref}>
					<FontAwesomeIcon className='gray-to-blue-link' icon={faPlusCircle} onClick={magnetboardOptions.allow_placeholders ? () => setAddNewSectionIsOpen(!addNewSectionIsOpen) : () => toggleEditSection(null, coursePeriodID, dataID, '1')}/>
					{(dataID && addNewSectionIsOpen) &&
						<div className='dropdown-options-container' style={{top:'27px', zIndex:'999'}}>
							<div className='dropdown-option left-border-dark-blue-thick' onClick={() => toggleEditSection(null, coursePeriodID, dataID, '1')}>New Section</div>
							{(currentView === 'teacher') &&
								<>
								{currentViewType === 'day' &&
									<div className='dropdown-option left-border-pastel-yellow-thick' onClick={() => updateLunchSchedule('teacher', periodID, dataID)}>Lunch</div>
								}
								<div className='dropdown-option left-border-pastel-light-blue-thick' onClick={() => toggleEditSection(null, coursePeriodID, dataID, '2')}>Prep</div>
								<div className='dropdown-option left-border-pastel-brown-thick' onClick={() => toggleEditSection(null, coursePeriodID, dataID, '3')}>Duty</div>
								</>
							}
						</div>
					}
				</div>
			}
		</div>
    )
}