import React, { Component } from 'react';

import calendar_no_boxes from "../images/calendar-no-boxes.png";

export default class Loading extends Component {
	render() {
		return (
			<div id='cube-loader-screen'>
				<div id="cube-loader-container">
					<div id="cube-loader-inner-container">
						<img alt='calendar no days' id='cube-loading-calendar' src={calendar_no_boxes} />
						<div id='calendar-inside'>
							<div id='calendar-inside-inner'>
								<div className='calendar-block-container'>	
									<div className='calendar-block calendar-block-1 blue'></div>
								</div>
								<div className='calendar-block-container'>	
									<div className='calendar-block calendar-block-2 purple'></div>
								</div>
								<div className='calendar-block-container'>	
									<div className='calendar-block calendar-block-3 orange'></div>
								</div>
								<div className='calendar-block-container'>	
									<div className='calendar-block calendar-block-1 orange'></div>
								</div>
								<div className='calendar-block-container'>	
									<div className='calendar-block calendar-block-2 blue'></div>
								</div>
								<div className='calendar-block-container'>	
									<div className='calendar-block calendar-block-3 yellow'></div>
								</div>
								<div className='calendar-block-container'>	
									<div className='calendar-block calendar-block-1 yellow'></div>
								</div>
								<div className='calendar-block-container'>	
									<div className='calendar-block calendar-block-2 green'></div>
								</div>
								<div className='calendar-block-container'>	
									<div className='calendar-block calendar-block-3 purple'></div>
								</div>
							</div>
						</div>
						<div className="parallelogram"></div>
					</div>
				</div>
			</div>
		);
	}
}