import React, { useState , useEffect } from 'react';

import { saveLunchStructure } from '../../../js/SaveFunctions';
import { JSONexists, getJSONFromStorage, getData, recalculateMatchPercent, calculateConflicts } from '../../../js/Helpers';

import Button from '../../Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import '../../../css/hsms/structure_setup.css';
import '../../../css/hsms/lunch_structure.css';

export default function LunchStructure({ subscreenRestricted, subscreenLocked, schoolInfo, scheduleInfo, scheduleInfoLoading, setScheduleInfo, handleMoveToNextScreen }) {
	const [isLoading, setIsLoading] = useState(true);
	const [lunchInfoLoading, setLunchInfoLoading] = useState(true);
	
	const [lunchChangeMade, setLunchChangeMade] = useState(false);
	const [lunchTimes, setLunchTimes] = useState([]);
	const [errorMessage, setErrorMessage] = useState(null);
	
	////////////////////////////////////////////////
	/////////// UPDATE FUNCTIONS ///////////////////
	////////////////////////////////////////////////
	const removeErrors = () => {
		let selectInputs = document.getElementsByClassName('time-select-input');
				
		for(var i = 0; i < selectInputs.length; i++)
		{
		    selectInputs[i].classList.remove('error');
		}
		
		setErrorMessage(null);
	}
	
	const changeNumLunches = () => {
		if(!JSONexists('lunches', 'session')) return;
		
		let lunches = (lunchChangeMade) ? {} : getJSONFromStorage('lunches', true, 'session');
		
		const num_lunches = scheduleInfo.num_lunches;
		
		if(lunchChangeMade)
		{
			if(!scheduleInfo.num_courses_during_lunch_block)
			{	
				if(num_lunches === '1')
				{
					scheduleInfo.num_courses_during_lunch_block = '0';
					setScheduleInfo({...scheduleInfo});
				}
				else if(num_lunches === '2')
				{
					scheduleInfo.num_courses_during_lunch_block = '1';
					setScheduleInfo({...scheduleInfo});
				}
				else if(num_lunches === '6')
				{
					scheduleInfo.num_courses_during_lunch_block = '5';
					setScheduleInfo({...scheduleInfo});
				}
			}
			
			for(var i = 1; i <= num_lunches; i++)
			{
				lunches = Object.assign(lunches, {[i] : {
					end_time: '00:00',
					lunch_name: `Lunch ${i}`,
					lunch_path: [],
					lunch_period: null,
					start_time: '00:00'
				}});
			}
		}
		
		sessionStorage.setItem('lunches', JSON.stringify(lunches));
		createLunchTimesMatrix();
	}
	
	const selectNumLunches = (num_lunches) => {
		const num_courses_during_lunch_block = null;
		
		scheduleInfo.num_lunches = num_lunches;
		scheduleInfo.num_courses_during_lunch_block = num_courses_during_lunch_block;

		setLunchChangeMade(true);
		removeErrors();
		setScheduleInfo({...scheduleInfo});
	}
	
	const selectNumCoursesDuringLunchBlock = (num_courses_during_lunch_block) => {
		scheduleInfo.num_courses_during_lunch_block = num_courses_during_lunch_block;
		
		setLunchChangeMade(true);
		removeErrors();
		setScheduleInfo({...scheduleInfo});
	}
	
	const createLunchTimesMatrix = () => {
		const lunches = getJSONFromStorage('lunches', true, 'session');
		const defaultTimes = [];
		const num_lunches = Object.keys(lunches).length;
		
		const matrix_size = (num_lunches > 0) ? num_lunches : ((scheduleInfo.num_lunches) ? scheduleInfo.num_lunches : 0);
		var i = 0;
		
		for (const lunch_index in lunches)
		{
			let lunch_start_time = lunches[lunch_index]['start_time'];
			let lunch_end_time = lunches[lunch_index]['end_time'];
			
			// Get rid of seconds
			if(lunch_start_time.length === 8) lunch_start_time = lunch_start_time.slice(0, -3);
			if(lunch_end_time.length === 8) lunch_end_time = lunch_end_time.slice(0, -3);
			
			defaultTimes[i] = [];
			
			for(var j = 0; j < matrix_size; j++)
			{
				defaultTimes[i][j] = 0;
				
				if(i === j) defaultTimes[i][j] = {[lunch_index]: {start_time: lunch_start_time, end_time: lunch_end_time}};
			}
			
			i++;
		}
		
		setLunchTimes(defaultTimes);
		setIsLoading(false);
	}  
	
	const onTimeChange = (e) => {
		removeErrors();
		
		const lunches = getJSONFromStorage('lunches', true, 'session');
		
		const lunch_index = e.target.getAttribute('data-lunchindex');
		const time_type = e.target.getAttribute('data-type');
		const time = e.target.value;
		
		lunches[lunch_index][time_type] = time;
		
		sessionStorage.setItem('lunches', JSON.stringify(lunches));
		
		setLunchChangeMade(true);
		createLunchTimesMatrix();
	}
	
	const handleLunchStructureSubmit = async () => {
		const lunches = getJSONFromStorage('lunches', true, 'session');
		var error_message = null;
		setErrorMessage(null);
		
		for(const lunch_index in lunches)
		{
			const lunch_info = lunches[lunch_index];
			const start_time = lunch_info.start_time;
			const end_time = lunch_info.end_time;
			
			const start_time_date = new Date("1970-01-01 " + start_time + ":00 Z");
			const end_time_date = new Date("1970-01-01 " + end_time + ":00 Z");
			
			if(start_time === '00:00' || start_time === '00:00:00' || end_time === '00:00' || end_time === '00:00:00')
			{
				error_message = "Make sure to enter a start and end time for all lunches!";
				
			}
			else if (start_time_date > end_time_date || start_time_date.getTime() === end_time_date.getTime())
			{
				error_message = "Start times need to be before end times! Make sure to check them carefully.";
			}
			
			if(error_message)
			{
				let selectInputs = document.getElementsByClassName('time-select-input');
				
				for(var i = 0; i < selectInputs.length; i++)
				{
				    selectInputs[i].classList.add('error');
				}
				
				setErrorMessage(error_message);
				
				return false;
			}
		}
		
		if(lunchChangeMade)
		{
			let num_courses_during_lunch_block = scheduleInfo.num_courses_during_lunch_block;
			let data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, num_courses_during_lunch_block: num_courses_during_lunch_block, lunches: lunches};
			
			await saveLunchStructure(data);

			// Update conflicts
			const conflicts_to_check = ['course_period_restriction', 'teacher_period_restriction', 'teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_too_many_sections_in_a_row'];
			calculateConflicts({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, update_all:true})

			// Recalculate match percent
			recalculateMatchPercent({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});
			
			// Move to the next screen
			handleMoveToNextScreen();
		}
		else
		{
			handleMoveToNextScreen();
		}
	}
	
	////////////////////////////////////////////////
	//////////////////// ON LOAD ///////////////////
	////////////////////////////////////////////////
	const num_lunches_options = {
		1:{show_courses_during_lunch_block:false}, 
		2:{show_courses_during_lunch_block:false}, 
		3:{show_courses_during_lunch_block:true, possible_courses_during_lunch_block:['1','2']}, 
		4:{show_courses_during_lunch_block:true, possible_courses_during_lunch_block:['1','3']},
		5:{show_courses_during_lunch_block:true, possible_courses_during_lunch_block:['1','3', '4']}, 
		6:{show_courses_during_lunch_block:false}
	};
	const grid_layout = {
		'2': {
				'0':[[1,2], [2,3]], 
				'1':[[1,2], [2,3]],
				'2':null,
				'3': null
		}, 
		'3': {
				'0': [[1,2], [2,4], false], 
				'1': [[1,2], [2,3], [3,4]], 
				'2': [[1,3], false, [3,4]],
				'3': null
		}, 
		'4': {
				'0': [[1,2], [2,5], false, false], 
				'1': [[1,2], [2,3], [3,5], false], 
				'2': [[1,3], false, [3,4], [4,5]], 
				'3': [[1,4], false, false, [4,5]]
		}
	};
	
	/////////////////////////////
	//// DO ON DATE CHANGES /////
	/////////////////////////////
	useEffect(() => {
		if(!scheduleInfoLoading && !lunchInfoLoading) changeNumLunches();
	}, [scheduleInfo, lunchInfoLoading]);
	
	
	/////////////////////
	//// DO ON LOAD /////
	/////////////////////
	
	useEffect(() => {
		getData('lunchInfo', '/get-lunch-info', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(lunch_info_data => {
			sessionStorage.setItem('lunches', JSON.stringify(lunch_info_data.lunches));
			setLunchInfoLoading(false);
		});
	}, []);
	
	////////////////////////////////////////////////
	//////////////////// DISPLAY ///////////////////
	////////////////////////////////////////////////
	return (
		<div id="schedule-structure-options-container" className='schedule-structure-screen'>
			{isLoading ? 
				(
					<div className='center-text'>
						<img src={require("../../../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
					</div>
				):(subscreenRestricted) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>No Access</h1>
						<div>You do not have access to this screen</div>	
					</div>
				):(subscreenLocked) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>Temporarily Locked</h1>
						<div>This screen is locked until all other prior steps are completed</div>	
					</div>
				):
				(
					<>
					<h1 className='progress-main-header'>Lunch Structure</h1>
					<p className='progress-subheader'>Let's set up your lunches!</p>
					<div id='lunch-structure-options-container'>
						<h2 id='lunch-structure-num-lunches-text' style={{width:'100%', marginBottom:'8px'}}>How many lunch options do you have in your schedule?</h2>
						{Object.keys(num_lunches_options).map((num_lunches_option, i) => (
							<div className={`option-btn number-of-lunches ${scheduleInfo.num_lunches === num_lunches_option ? 'option-btn-selected' : ''}`} key={i} onClick={scheduleInfo.num_lunches !== num_lunches_option ? () => selectNumLunches(num_lunches_option) : null}>{num_lunches_option}</div>
						))}
						<div className="clear"></div>
						{scheduleInfo.num_lunches > 0 && num_lunches_options[scheduleInfo.num_lunches]['show_courses_during_lunch_block'] &&
							<div id="other-courses-besides-lunch-container">
								<h2 style={{width:'100%', marginBottom:'8px'}}>During the lunch block, how many courses does a student take?</h2>
								{num_lunches_options[scheduleInfo.num_lunches]['possible_courses_during_lunch_block'].map((num_courses_during_lunch_block, i) => (
									<div className={`option-btn number-courses-during-lunch-block ${scheduleInfo.num_courses_during_lunch_block === num_courses_during_lunch_block ? 'option-btn-selected' : ''}`} key={i} onClick={scheduleInfo.num_courses_during_lunch_block !== num_courses_during_lunch_block ? () => selectNumCoursesDuringLunchBlock(num_courses_during_lunch_block) : null}>{num_courses_during_lunch_block}</div>
								))}
							</div>
						}
					</div>
					{(scheduleInfo.num_lunches && scheduleInfo.num_courses_during_lunch_block != null) &&
						<div id='lunch_structure_wrapper'>
							<div id="lunch_structure_container" style={{gridTemplateColumns: `repeat(${scheduleInfo.num_lunches}, 220px`}}>
							{	
								lunchTimes.map((column, i) => {
									const index = parseInt(i) + 1;
									return (
										<div className="daily-lunch-optn-container" key={i}>
											<h2 className='daily-lunch-optn-header'>Lunch Option {index}</h2>
											<div className='daily-lunch-container' style={{gridTemplateRows: `repeat(${scheduleInfo.num_lunches}, minmax(100px, auto))`}}>
											{ 
												column.map((row, j) => {
													if(i === j)
													{
														const lunch_info = lunchTimes[i][j];
														const lunch_index = Object.keys(lunch_info)[0];
														const lunch_times = lunch_info[lunch_index];
														
														const start_time = lunch_times.start_time;
														const end_time = lunch_times.end_time;
														
														return (
															<div className='daily-lunch-lunchbox' key={`${i}${j}`}>
																<div className="daily-lunch-text white-text">Lunch {index}</div>
																<div className="time-select-container">
																	<div className='time-select'>
																		<div className='time-select-label'>Start Time</div>
																		<input className="time-select-input time-select-start-time" type="time" required='required' data-type='start_time' data-lunchindex={lunch_index} data-lunchid value={`${start_time}`} onChange={onTimeChange}/>
																	</div><div className='time-select'>
																		<div className='time-select-label'>End Time:</div>
																		<input className="time-select-input time-select-end-time" type="time" required='required' data-type='end_time'  data-lunchindex={lunch_index} value={`${end_time}`} onChange={onTimeChange} />
																	</div>
																</div>
															</div>
														)
													}
													else
													{
														const start = (scheduleInfo.num_courses_during_lunch_block > 1) ? j + 1 : grid_layout[scheduleInfo.num_lunches][i][j][0];
														const end = (scheduleInfo.num_courses_during_lunch_block > 1) ? start + 1 : grid_layout[scheduleInfo.num_lunches][i][j][1];
														
														if(start && end)
														{
															return (
																<div className='daily-lunch-coursebox' key={`${i}${j}`} style={{gridRow:`${start}/${end}`}}>
																	<div className='center-grid'>
																		<div>
																			<div className='number-of-other-courses-text'>{scheduleInfo.num_courses_during_lunch_block > 1 ? 1 : scheduleInfo.num_courses_during_lunch_block}</div>
																			<div style={{color: '#a9a9a9'}}>Class</div>
																			{(scheduleInfo.num_lunches > 2 && scheduleInfo.num_courses_during_lunch_block === '1' && j > i) &&
																				<div className='continued-div'>(continued)</div>
																			}
																		</div>
																	</div>
																</div>
															)
														}
													}
												})
											}
											</div>
										</div>
									)
								})
							}
							</div>
						</div>
					}
				    {errorMessage &&
						<div className="error-message-no-box" id='lunch-structure-error'>{errorMessage}</div>
					}
				    {(scheduleInfo.num_lunches && scheduleInfo.num_courses_during_lunch_block != null) &&
					    <div className='fixed-bottom-save-container'>
					    	<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Submit Lunch Structure' passedLoading={errorMessage ? false : true} onClickFunction={handleLunchStructureSubmit} />
						</div>
				    }
					</>
				)
				
			}
		</div>
	);
}