import React, { useState , useEffect } from 'react';
import { Redirect } from "react-router-dom";
import Loading from './Loading';

export default function LoadPage({ isAuth, authComplete, schoolType, loadScreen }) {
	const [isLoading, setIsLoading] = useState(true);
	
	useEffect(() => {
		if(authComplete && loadScreen)
		{
			setTimeout(() => {
	          setIsLoading(false);
			}, 1500);
			
		}
	}, [isAuth, authComplete, loadScreen]);

	//console.log({isAuth, authComplete, schoolType, loadScreen});
	
	if(isLoading)
	{
		return <Loading />;
	}
	else if(!isAuth)
	{
		return <Redirect to={`/app/login`} />;
	}
	else if(!schoolType)
	{
		return <Redirect to={`/app/select_school`} />;
	}
	else
	{
		return <Redirect to={`/app/${schoolType}/${loadScreen}`} />;
	}
}