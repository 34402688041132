import React, { useState , useEffect } from 'react';
import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';

import { loadSpecificData } from '../../../js/Helpers';
import { saveDataToDatabase } from '../../../js/SaveFunctions';

import '../../../css/hsms/edit_modals.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';

export default function EditClassroomData({ dataID, toggleEditData, schoolInfo, classrooms, courses, teachers, blocks, setDatabaseData, filteredResults, setFilteredResults = ()=>{} }) {
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState({});

	const [teacherOptions, setTeacherOptions] = useState([]);
	
	const [courseRestrictionResults, setCourseRestrictionResults] = useState([]);
	
	const [addCourseRestrictionOpen, setAddCourseRestrictionOpen] = useState(false);
	const [addHomeroomTeacherOpen, setAddHomeroomTeacherOpen] = useState(false);
	
	const [changesMade, setChangesMade] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	
	const toggleAddNewConnection = (connection_type) => {
		if(connection_type === 'course_restriction')
		{
			setAddCourseRestrictionOpen(!addCourseRestrictionOpen);
			setCourseRestrictionResults([]);
		}
	}
	
	const fuzzySearch = (e, dataType) => {
		const search_value = e.target.value;

		if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			});
			
			const results = fuse.search(search_value);
			const course_results = results.map(result => result.item);
			
			setCourseRestrictionResults(course_results);
		}
	}
	
	const updateData = (change_type, data_id, data_key) => {
		if(change_type === 'add')
		{
			data[data_key].push(data_id)
		}
		else if(change_type === 'change')
		{
			data[data_key] = data_id;
		}
		else if(change_type === 'remove')
		{
			data[data_key].splice(data_id, 1);
		}
		
		setAddCourseRestrictionOpen(false);
		setCourseRestrictionResults([]);
		
		setData({...data});
		setChangesMade(true);
	}
	
	const handleExitWithoutSaving = async () => {
		let exit_without_saving = true;
		
		if(changesMade)
		{
			const options =  {
				title: "Exit without saving?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you really want to exit without saving?</p>
					</div>
				)
			}
		
			exit_without_saving = await swal(options);
		}
		
		if(exit_without_saving) toggleEditData(null);
	}
	
	const updateClassroom = () => {
		const classroom_name = data.classroom_name;
		const classroom_type_id = data.classroom_type_id;
		
		if(!classroom_name)
		{
			const options =  {
				title: "Missing a Classroom Name",
				icon:"warning",
				buttons: {
					cancel: {
						text: "Okay",
						value: false,
						visible: true,
						className: 'blue-btn'
					}
				},
				content: (
					<div>
						<p>You need to enter a classroom name before saving!</p>
					</div>
				)
			}
		
			swal(options);
		}
		else
		{
			setIsSaving(true);
			
			saveDataToDatabase({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'classroom', data_to_save:data, database:'elementary'}).then(response_data => {
				// change data classroom id to new database id
				data.classroom_id = response_data.new_data_id;
				
				// Add it back to JSON
				if(dataID)
				{
					const index_of_specific_data = classrooms.findIndex(classroom => classroom.classroom_id === dataID);
					classrooms[index_of_specific_data] = data;

					const filter_results_index = filteredResults.findIndex(classroom => classroom.classroom_id === dataID);
					filteredResults[filter_results_index] = data;
				}
				else
				{
					classrooms.push(data);
					filteredResults.push(data);
				}
				
				setDatabaseData([...classrooms]);
				setFilteredResults([...filteredResults]);
				setIsSaving(false);
				toggleEditData(null);
			});
		}
	}
	
	/////////////////////////////
	///// FOCUS ON INPUT ////////
	/////////////////////////////
	useEffect(() => {
		if(addCourseRestrictionOpen) document.getElementById("search-course-restriction-input").focus();
	}, [addCourseRestrictionOpen]);
	
	useEffect(() => {
		if(addHomeroomTeacherOpen) document.getElementById("search-teacher-restriction-input").focus();
	}, [addHomeroomTeacherOpen]);

	useEffect(() => {
		const teacher_options = teachers.reduce((results, teacher) => {
			const teacher_id = teacher.teacher_id;
			const teacher_first_name = teacher.first_name;
			const teacher_last_name = teacher.name;
			const teacher_full_name = (teacher_first_name) ? `${teacher_last_name}, ${teacher_first_name}` : teacher_last_name; 

			const new_option = {value:teacher_id, display:teacher_full_name};
			results.push(new_option);
			return results;
		}, [{value:null, display:'No Homeroom Teacher'}]);

		setTeacherOptions(teacher_options);
	}, [teachers]);
	
	
	/////////////////////////
	///// DO ON LOAD ////////
	/////////////////////////
	useEffect(() => {
		if(dataID)
		{
			loadSpecificData({data_type:'classroom', data_id:dataID, data_version_id:schoolInfo.current_data_version_id, schedule_version_id:schoolInfo.current_schedule_version_id, school_id:schoolInfo.school_id, database:'elementary'}).then(specific_data => {
				const index_of_specific_data = classrooms.findIndex(classroom => classroom.classroom_id === dataID);
				classrooms[index_of_specific_data] = specific_data;

				setDatabaseData(classrooms);
				setData(specific_data);
				setIsLoading(false);
			});
		}
		else
		{
			setData({new: true, classroom_name: null, max_capacity: '30', classroom_type_id:null, multi_class:'0', course_restrictions: [], teacher_restrictions: []});
			setIsLoading(false);
		}
	}, []);
	
	//console.log(data);
	
	return (
		<div className='modal-screen'>
			<div className='modal-screen-content'>
				{!isSaving &&
					<>
					<span className="data-modal-close" onClick={handleExitWithoutSaving}>&times;</span>
					<div className="modal-save-btn" onClick={updateClassroom}><FontAwesomeIcon icon={faSave}/>Save Classroom</div>
					</>
				}
				<div className='full-width'>
					<div className='edit-data-content'>
						<FontAwesomeIcon className='edit-data-main-head-icon' icon={faEdit}/>
						<h1 className='edit-data-main-header'>{dataID ? 'Edit' : 'Add New'} Classroom</h1>
						{isSaving &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
								<div>Saving...</div>
							</div>
						}
						{isLoading &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						}
						{(!isLoading && !isSaving) &&
							<form className='edit-data-form'>
								<div className='edit-data-grid-50'>
									<div>
										<p className='modal-label'>Classroom Name:</p>
										<input type="text" className='edit-data-input' defaultValue={data.classroom_name} onChange={(e) => updateData('change', e.target.value, 'classroom_name')}/>
									</div>
									<div></div>
								</div>
								{/* <div>
									<div className='data-modal-heading'>Classroom Type</div>
									<div>
										{scheduleStructureData.classroom_types.map((classroom_type, index) => {
											return (
												<div className={`option-btn ${classroom_type.classroom_type_id === data.classroom_type_id ? 'option-btn-selected' : ''}`} key={index} onClick={() => updateData('change', classroom_type.classroom_type_id, 'classroom_type_id')}>{classroom_type.classroom_type}</div>
											)	
										})}
									</div>
								</div> */}
								{/* <div className='edit-data-grid-50'>
									<div>
										<div className='data-modal-heading'>Max Classroom Capacity</div>
										<input type="text" className='edit-data-input' defaultValue={data.max_capacity} onChange={(e) => updateData('change', e.target.value, 'max_capacity')}/>
									</div>
									<div></div>
								</div>
								<div className='edit-data-grid-50 edit-data-other-options-container'>
									<div style={{width:'100%',textAlign:'left'}}>
										<div>Allows multiple sections?</div>
										<div className='dark-gray-text'>Answer "Yes" if this classroom can hold multiple sections in the same period (e.g. Gym)</div>
									</div>
									<div className='toggle-with-text-btn-container edit-course-toggle'>
										<div className={`toggle-with-text-btn-optn ${data.multi_class === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'multi_class')}>No</div><div className={`toggle-with-text-btn-optn ${data.multi_class === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'multi_class')}>Yes</div>
									</div>
								</div> */}
								<div>
									<div className='data-modal-heading'>Course(s) that <span className='yellow-text'>NEED</span> to be in this classroom</div>
									<div className='dark-gray-text edit-data-explanation-text'>Add courses that absolutely need to be in this classroom</div>
									<div className='edit-data-list'>
										{data.course_restrictions.length === 0 &&
											<div className='edit-data-no-data-container'>No courses need to be in this classroom!</div>
										}
										{data.course_restrictions.map((course_id, index) => {
											const course_info = courses.find(course => course.course_id === course_id);
											
											return (
												<div className='table-row-inv edit-data-course-row' key={index}>
													<div>{course_info.name} {course_info.course_code && `(${course_info.course_code})`}</div>
													<div></div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'course_restrictions')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('course_restriction')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Course that Needs Classroom</div>
									</div>
									{addCourseRestrictionOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-course-restriction-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'courses')} placeholder='Search for course'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('course_restriction')}/>
											</div>
											{courseRestrictionResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{courseRestrictionResults.map((course, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{course.name} {course.course_code && `(${course.course_code})`}</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', course.course_id, 'course_restrictions')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								<div className='append-new-items-parent-container' id="student-teacher-container">
									<div className='data-modal-heading'>Homeroom Teacher for this Classroom</div>
									<div className='edit-data-dropdown-container'>
										<Dropdown data={teacherOptions} displayDefault='No Homeroom Teacher' currentValue={data.homeroom_teacher_id} handleChange={(new_value) => updateData('change', new_value, 'homeroom_teacher_id')} />
									</div>
								</div>
							</form>
						}
					</div>
				</div>
			</div>
		</div>
	);
}