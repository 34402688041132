import React from 'react';

import Loading from './Loading';

export default function SwitchingYearsModal() {
	return (
		<div className='modal-screen'>
			<div className='modal-screen-content'>
				<Loading />
			</div>
		</div>
	);
}