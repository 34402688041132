import React, { useState , useEffect, useRef } from 'react';
import swal from '@sweetalert/with-react';

import { filterArrayOfObjects, deleteData, sortArrayOfObjects } from '../../../js/Helpers';

import SortToggle from '../../SortToggle';
import ListFilter from '../../ListFilter';
import UploadModal from '../../UploadModal';
import EditClassroomData from '../modals/edit_classroom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faPlus, faUpload, faMinusSquare, faSortDown, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt, faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';

export default function DatabaseDataClassrooms({ schoolInfo, databaseData, setDatabaseData, scheduleStructureData, scheduleInfo, teachers, courses, sections, departments, dataIsLoading, handlePullNewData}) {
	const [isLoading, setIsLoading] = useState(true);
	const [handleChangeComplete, setHandleChangeComplete] = useState(false);

	const [filteredResults, setFilteredResults] = useState([]);
	const [displayResults, setDisplayResults] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	
	const [showEditDataModal, setShowEditDataModal] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [currentDataID, setCurrentDataID] = useState(null);
	
	const [selectedData, setSelectedData] = useState([]);
	const [dontShowDeletePopup, setDontShowDeletePopup] = useState(false);

	const [sortedBy, setSortedBy] = useState('classroom_name');
	const [sortAscDesc, setSortAscDesc] = useState('desc');
	const [dataSorted, setDataSorted] = useState(false);

	const filteredResultsRef = useRef(filteredResults);
	filteredResultsRef.current = filteredResults;
	
	let typingTimeout = null;
	
	const handleIsSearching = (e) => {
		if(e.keyCode !== 8)
		{
			setIsLoading(false);
			setIsSearching(true);
		}
		else
		{
			setIsLoading(true);
			setIsSearching(false);
		}
	}
	
	const handleChange = () => {
		if(filteredResultsRef.current.length === 0 && dataIsLoading) return;
		if(typingTimeout) clearTimeout(typingTimeout);
		
		const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
		
		typingTimeout = setTimeout(() => {
			if(search_value.length > 1)
			{
				const filtered_results = filterArrayOfObjects(filteredResultsRef.current, search_value, ['classroom_name']);
				setDisplayResults([...filtered_results]);
			}
			else
			{
				setDisplayResults([...filteredResultsRef.current]);
			}
			
			setIsLoading(false);
			setIsSearching(false);
			setHandleChangeComplete(true);
		}, 200);
	}

	const sortData = (sort_type = null) => {
		let sorted_data = sortArrayOfObjects(displayResults, sortedBy, sort_type, sortAscDesc);

		setDataSorted(true);
		setDisplayResults([...sorted_data]);
	}
	
	const handleSortClick = (sortedData, sorted_by, sort_asc_desc) => {
		setSortedBy(sorted_by);
		setSortAscDesc(sort_asc_desc);
		setDisplayResults([...sortedData]);
	}
	
	const handleFilterClick = (filteredData) => {
		setFilteredResults([...filteredData]);
	}
	
	const toggleEditData = (dataID) => {
		setDataSorted(false);
		setCurrentDataID(dataID);
		setShowEditDataModal(!showEditDataModal);
	}
	
	const toggleDontShow = () => {
		setDontShowDeletePopup(!dontShowDeletePopup);
	}

	const toggleSelectData = (data_id) => {
		const data_selected_index = selectedData.findIndex(data => data === data_id);

		if(data_selected_index !== -1)
		{
			selectedData.splice(data_selected_index, 1);
		}
		else
		{
			selectedData.push(data_id);
		}

		setSelectedData([...selectedData]);
	}

	const toggleSelectAll = () => {
		const any_data_selected = selectedData.length > 0;
		
		let new_selected_data = [];

		if(!any_data_selected) 
		{
			new_selected_data = displayResults.reduce((results, classroom) => {
				const classroom_id = classroom.classroom_id;
				results.push(classroom_id);
				return results;
			}, []);
		}

		setSelectedData(new_selected_data);
	}
	
	const handeDeleteData = async (data_ids) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'classroom', data_ids:data_ids};
		let delete_data_bool = true;
		
		if(!dontShowDeletePopup)
		{
			const options =  {
				title: "Are you sure?",
				icon: "warning",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'red-btn'
					},
				},
				content: (
					<div>
						<div>
							<p>Do you really want to delete the following classrooms:</p>
							<div style={{marginLeft:'50px', textAlign:'left'}}>
								{data_ids.map((data_id, i) => {
									const data_index = databaseData.findIndex(classroom => classroom.classroom_id === data_id);
									const data_info = databaseData[data_index];

									return (
										<div key={i}>{data_info.classroom_name}</div>
									)
								})}
							</div>
							<p className='red-text'>This will <strong>permanently</strong> remove them from the database.</p>
						</div>
						<div className='sweet-alert-dont-show-message' onClick={() => toggleDontShow('remove')}>
							<label className="checkmark_container">Please don&rsquo;t show me this message again
								<input type="checkbox" />
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
				)
			}
		
			delete_data_bool = await swal(options);
		}
		
		if(delete_data_bool)
		{
			const new_database_data = databaseData.filter(data => !data_ids.includes(data.classroom_id));
			const new_filtered_data = filteredResults.filter(filtered_data => !data_ids.includes(filtered_data.classroom_id));

			setDataSorted(false);
			setDatabaseData(new_database_data);
			setFilteredResults(new_filtered_data);
			
			deleteData(data);
		}
	}
	
	useEffect(() => {
		if(!showEditDataModal) setCurrentDataID(null);
		const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
		if(search_value.length > 0) handleChange();
	}, [showEditDataModal]);
	
	useEffect(() => {
		handleChange();
	}, [filteredResults]);

	useEffect(() => {
		if(!handleChangeComplete) setFilteredResults([...databaseData]);
	}, [databaseData, dataIsLoading]);

	useEffect(() => {
		if(dataSorted || displayResults.length <= 0) return;
		sortData();
	}, [displayResults]);
	
	//console.log(displayResults);
	const row_style = {gridTemplateColumns:'35px 2fr 2fr 1fr 70px'};
	
	return (
		<>
		{showEditDataModal &&
			<EditClassroomData schoolInfo={schoolInfo} dataID={currentDataID} toggleEditData={toggleEditData} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} classrooms={databaseData} courses={courses} teachers={teachers} sections={sections} departments={departments} setDatabaseData={setDatabaseData} filteredResults={filteredResults} setFilteredResults={setFilteredResults}/>
		}
		{showUploadModal &&
			<UploadModal schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} uploadTitle='Classrooms' uploadAPIName='classroomdata' closeModal={() => setShowUploadModal(false)} handlePullNewData={handlePullNewData}/>
		}
		<div className='school-data-content-screen'>
			<h1 className='school-data-main-heading capitalize'>Classrooms</h1>
			<div className='school-data-top-bar'>
				<div className='school-data-search-bar-container'>
					<input className='school-data-search-bar' placeholder="Search for classrooms..." onChange={handleChange} onKeyDown={(e) => handleIsSearching(e)} />
					<FontAwesomeIcon className='school-data-search-bar-icon' icon={faSearch}/>
				</div>
				{/* <div className='btn btn-icon-left turquoise-btn' onClick={() => setShowUploadModal(true)}>
					<FontAwesomeIcon icon={faUpload} />
					<div>Upload</div>
				</div> */}
			</div>
			<div className='school-data-database-display'>
				<div className='school-data-database-row school-data-database-display-header fixed-heading-on-scroll' style={row_style}>
					<div></div>
					<div className='school-data-database-header-col'>Classroom Name <SortToggle handleOnClick={handleSortClick} sortKey='classroom_name' passedData={displayResults}/></div>
					<div className='school-data-database-header-col'>Department(s) <ListFilter handleOnClick={handleFilterClick} passedData={databaseData} passedDataFilterKey='departments' filterOptions={departments} filterKey='department_id' filterDisplayKey ='department'/></div>
					<div className='school-data-database-header-col'>Classroom Type <SortToggle handleOnClick={handleSortClick} sortKey='classroom_type' passedData={displayResults}/></div>
					<div className='school-data-database-add-new-container' onClick={() => toggleEditData(null)}>
						<div className='school-data-database-add-new-btn'>
							<FontAwesomeIcon className='white-text' icon={faPlus}/>
						</div>
					</div>
				</div>
				{displayResults.length > 0 &&
					<div className='select-all-row'>
						<div className={`select-all-container ${selectedData.length > 0 && 'blue-text'}`} onClick={toggleSelectAll}>
							<FontAwesomeIcon icon={selectedData.length > 0 ? faMinusSquare : farSquare}/>
							<FontAwesomeIcon className='select-all-arrow' icon={faSortDown}/>
						</div>
						{selectedData.length > 0 &&
							<div className='small-text'>
								<div className='tooltip' data-tooltip-text='Delete students' onClick={() => handeDeleteData(selectedData)}>
									<div className='red-link'>Delete all selected</div>
								</div>
							</div>
						}
					</div>
				}
				{(!handleChangeComplete && isLoading) ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
					</div>
				): isSearching ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/searching.gif')} alt='searching...' style={{height:'80px'}}/>
					</div>
				):
				(
					<>
					{displayResults.length === 0 &&
						<div className='school-data-database-message-container'>
							<h4>Sorry, no results were found!</h4>
						</div>
					}
					{Object.keys(scheduleStructureData).length > 0 &&
						<>
						{displayResults.map((data_info, index) => {
							const classroom_id = data_info.classroom_id;
							const data_selected = selectedData.includes(classroom_id);

							return (
								<div className='school-data-database-row' style={row_style} key={classroom_id}>
									<FontAwesomeIcon className={data_selected ? 'blue-text' : 'dark-gray-text'} icon={data_selected ? faCheckSquare : farSquare} onClick={() => toggleSelectData(classroom_id)}/>
									<div>{data_info.classroom_name}</div>
									<div>
										{data_info.departments.length === 0 ?
											(
												<div className='school-data-department medium-text' key={index}>No department</div>
											):
											(
												<>
												{data_info.departments.map((department_id, index) => {
													const department_index = departments.findIndex(department => department.department_id === department_id);
													const department = (department_index !== -1) ? departments[department_index].department : 'No department';
													
													return (
														<div className='school-data-department medium-text' key={index}>{department}</div>
													)
												})}
												</>
											)
										}
									</div>
									<div className='medium-text'>{data_info.classroom_type}</div>
									<div className='school-data-database-row-btn-container'>
										<FontAwesomeIcon className='gray-to-dark-blue-link' icon={faEdit} onClick={() => toggleEditData(classroom_id)}/>
										<FontAwesomeIcon className='gray-to-red-link' icon={faTrashAlt} onClick={() => handeDeleteData([classroom_id])}/>
									</div>
								</div>
							)
						})}
						</>
					}
					</>
				)}
			</div>
		</div>
		</>
	);
}