import React, { useState , useEffect } from 'react';
import { Link } from "react-router-dom";
import swal from 'sweetalert';

import { getData, loadData, updateDepartment } from '../../../js/Helpers';
import { FileUpload } from '../../../js/upload';

import UploadModal from '../../UploadModal';
import DatabaseDataCourses from '../school_data/database_data_courses';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faQuestionCircle, faCheck, faCheckCircle, faEdit, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export default function UploadCourses({ subscreenRestricted, subscreenLocked, subscreen, schoolInfo, scheduleInfo, scheduleStructureData, departments, setDepartments, currentSIS, coursesLoading, setCoursesLoading, courses, students, teachers, classrooms, sections, labels, setCourses, setStudents, setRolePages, handleMoveToNextScreen }) {
	const [courseDataExists, setCourseDataExists] = useState(false);
	const [departmentDataExists, setDepartmentDataExists] = useState(false);

	const [alreadyUploadedChecked, setAlreadyUploadedChecked] = useState(false);
	const [alreadyUploaded, setAlreadyUploaded] = useState(false);
	
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showCourseData, setShowCourseData] = useState(false);
	const [showDepartmentData, setShowDepartmentData] = useState(false);
	const [showAddDepartment, setShowAddDepartment] = useState(false);

	const handleFocus = (event) => event.target.select();

	const setDataExists = (value) => {
		setCourseDataExists(value);
		setDepartmentDataExists(value);
	}

	const toggleShowCourseData = (display_value) => {
		if(display_value) setShowDepartmentData(false);
		setShowCourseData(display_value);
	}

	const toggleShowDepartmentData = (display_value) => {
		if(display_value) setShowCourseData(false);
		setShowDepartmentData(display_value);
	}

	const toggleEdit = (department_id) => {
		const department_index = departments.findIndex(department => department.department_id === department_id);
		const department_info = departments[department_index];
		
		if('editing' in department_info)
		{
			delete department_info.editing;
		}
		else
		{
			department_info.editing = true;
		}
		
		sessionStorage.setItem('departments', JSON.stringify(departments));
		setDepartments([...departments]);
	}
	
	const saveDepartmentUpdate = (e) => {
		const department_id = e.target.closest('.upload-courses-department-row').getAttribute('data-id');
		const department = e.target.closest('.edit-department-row-container').getElementsByClassName('edit-department-input')[0].value;
		
		updateDepartment({update_type:'update', school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, department_id:department_id, department:department}).then(data => {
			const department_index = departments.findIndex(department => department.department_id === department_id);
			const department_info = departments[department_index];
			
			if('editing' in department_info) delete department_info.editing;
			
			department_info.department = department;
			
			sessionStorage.setItem('departments', JSON.stringify(departments));
			setDepartments([...departments]);
		});
	}
	
	const addNewDepartment = (e) => {
		if(e.key !== 'Enter' && e.type !== 'click') return;
		
		const department = document.getElementById("new-department-name").value;
		
		if(!department)
		{
			document.getElementById("new-department-name").classList.add('error');	
			return false;
		}
		
		updateDepartment({update_type:'add', school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, department:department}).then(data => {
			departments.push({department_id:data.department_id, department:department, supervisors:[]});
		
			// Remove add info
			document.getElementById("new-department-name").value = "";
			setShowAddDepartment(false);
			
			sessionStorage.setItem('departments', JSON.stringify(departments));
			setDepartments([...departments]);
			
			// Since a new department was added that needs to go through all the steps, update all future screens as not being complete now
			getData('schoolProgress', '/get-school-progress', {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
				const role_pages = user_progress_data.role_pages;
				
				setRolePages({...role_pages});
			});
		});
	}
	
	const deleteDepartment = async (department_id) => {
		const options =  {
			title: "Are you sure?",
			text: "Do you really want to delete this department? Teachers, courses, and classrooms in this department will not be deleted, but will be removed from this department.",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			}
		}
		
		const delete_department_bool = await swal(options);
		
		if(delete_department_bool)
		{
			updateDepartment({update_type:'delete', school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, department_id:department_id}).then(data => {
				const department_index = departments.findIndex(department => department.department_id === department_id);
			
				//Remove department from object
				if(department_index !== -1) departments.splice(department_index, 1);
				
				//Save departments back to session storage
				sessionStorage.setItem('departments', JSON.stringify(departments));
				setDepartments([...departments]);
				
				const options =  {
					title: "Success!",
					text: "You have succcesfully removed this department",
					icon: "success",
					buttons: {
						cancel: {
							text: "Okay",
							value: false,
							visible: true,
							className: 'gray-btn'
						},
					}
				}
				
				swal(options);
			});
		}
	}

	const handlePullNewData = () => {
		loadData({data_types:['courses', 'departments'], schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id, school_id:schoolInfo.school_id}).then(database_data => {
			setDepartments(database_data.departments);
			setCourses(database_data.courses);
		});

		setDataExists(true);
		setAlreadyUploadedChecked(true);
		setAlreadyUploaded(true);
	}
	
	////////////////////////////////
	///// USE EFFECT FUNCTIONS /////
	////////////////////////////////
	useEffect(() => {
		if(schoolInfo.current_data_version_id && schoolInfo.school_id)
		{
			async function checkUploadAlreadyProcessed() {
				const file_upload = new FileUpload(schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, scheduleInfo.school_id);
			
				// Check whether courses have been uploaded
				const running_processes = await file_upload.getSchoolProcesses(schoolInfo.current_schedule_year_id);

				// Check if there are any running processes with the same data version id
				const dv_process_index = running_processes.findIndex(process => process.process_type === 'coursedata' && process.data_version_id == schoolInfo.current_data_version_id && process.is_done);
				const sv_process_index = running_processes.findIndex(process => process.process_type === 'coursedata' && process.schedule_version_id == schoolInfo.current_schedule_version_id && process.is_done);

				// If process has already finished running then set data existing
				if(sv_process_index !== -1 || dv_process_index !== -1) setAlreadyUploaded(true);

				setAlreadyUploadedChecked(true);
			}

			checkUploadAlreadyProcessed();
		}
	}, [scheduleInfo, schoolInfo, subscreen]);

	useEffect(() => {
		if(courses.length > 0) setCourseDataExists(true);
		if(departments.length > 0) setDepartmentDataExists(true);
	}, [courses, departments]);

	return (
		<>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):
		(
			<>
			{showUploadModal &&
				<UploadModal schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} uploadTitle='Courses' uploadAPIName='coursedata' closeModal={() => setShowUploadModal(false)} handlePullNewData={handlePullNewData}/>
			}
			<h1 className='progress-main-header'>Upload Courses</h1>
			<p className='progress-subheader'>Let's import your course list from your current SIS.</p>
			<div className="upload-screen-main-upload-screen">
				<div className="upload_screen">
					<div className='upload-screen-explanation-container'>
						<h2 className="upload-screen-heading-text"><span className="ms_login_system_name capitalize">{currentSIS}</span> Course Upload</h2>
						<p className='upload-screen-description'>Let&rsquo;s start by downloading the following data from <span className="ms_login_system_name capitalize"></span>:</p>
						<div className='upload-screen-data-container'>
							<img className='upload-info-image' src={require(`../../../images/textbook.png`)} alt='courses'/>
							<div className='upload-info-name'>Courses</div>
							{courseDataExists ?
								(
									<>
									<div>
										<FontAwesomeIcon icon={faCheckCircle} className='green-text upload-complete'/>
										<div className='upload-complete-text'>Complete</div>
									</div>
									<div className='btn btn-small blue-btn' onClick={()=>{toggleShowCourseData(true)}}>See Data</div>
									</>
								):
								(
									<>
									<div>
										<FontAwesomeIcon icon={faCheckCircle} className='gray-text upload-complete'/>
										<div className='upload-complete-text gray-text'>No Data</div>
									</div>
									<div className='white-text'>.</div>
									</>
								)
							}
							<img className='upload-info-image' src={require(`../../../images/microscope.png`)} alt='departments'/>
							<div className='upload-info-name'>Departments</div>
							{departmentDataExists ?
								(
									<>
									<div>
										<FontAwesomeIcon icon={faCheckCircle} className='green-text upload-complete'/>
										<div className='upload-complete-text'>Complete</div>
									</div>
									<div className='btn btn-small blue-btn' onClick={()=>{toggleShowDepartmentData(true)}}>See Data</div>
									</>
								):
								(
									<>
									<div>
										<FontAwesomeIcon icon={faCheckCircle} className='gray-text upload-complete'/>
										<div className='upload-complete-text gray-text'>No Data</div>
									</div>
									<div className='white-text'>.</div>
									</>
								)
							}
						</div>
						<div>
							{!alreadyUploadedChecked ?
								(
									<div className='center-text'>
										<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
									</div>
								): (alreadyUploaded) ? 
								(
									<div className='upload-screen-already-uploaded-container'>
										<div>
											<FontAwesomeIcon className='upload-screen-already-uploaded-check green-text' icon={faCheckCircle}/>
										</div>
										<div>
											<h3 className='upload-screen-already-uploaded-header'>You already uploaded Courses!</h3>
											<div>You can move on to the next step, or <span className='blue-link' onClick={() => setShowUploadModal(true)}>Reupload Courses</span>!</div>
										</div>
									</div>
								):
								(
									<div className='center-text'>
										<span className='btn turquoise-btn btn-round btn-large upload-btn' onClick={() => setShowUploadModal(true)}>Upload Courses</span>
									</div>
								)
							}
						</div>
						<p className='upload-how-to-line'><FontAwesomeIcon className='info-icon blue-text' icon={faQuestionCircle}/> <Link to={`/app/hsms/documentation/upload_data/upload_courses/${currentSIS}`} target="_blank" style={{color:'#3498db'}}>How to download courses from <span className="ms_login_system_name capitalize">{currentSIS}</span></Link></p>
					</div>
					{showCourseData ?
						(
							<div className='upload-parent-container'>
								<div className='upload-screen-close-data gray-to-dark-blue-link' onClick={() => {setShowCourseData(false)}}>
									<FontAwesomeIcon icon={faTimesCircle}/>
								</div>
								<DatabaseDataCourses schoolInfo={schoolInfo} databaseData={courses} setDatabaseData={setCourses} setStudents={setStudents} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} teachers={teachers} students={students} classrooms={classrooms} departments={departments} sections={sections} labels={labels} isLoading={coursesLoading} setIsLoading={setCoursesLoading}/>
							</div>
						): showDepartmentData ?
						(
							<div className='upload-parent-container'>
								<div className='upload-screen-close-data gray-to-dark-blue-link' onClick={() => {setShowDepartmentData(false)}}>
									<FontAwesomeIcon icon={faTimesCircle}/>
								</div>
								<h1>Departments</h1>
								<div id='assign-resources-department-list'>
									{departments.map((department_info, index) => {
										const department_id = department_info.department_id;
										const department = department_info.department;
										const editing = ('editing' in department_info) ? true : false;

										return (
											<div className='upload-courses-department-row' key={index} data-id={department_id}>
												{editing ?
													(
														<div className="edit-department-row-container">
															<div>
																<div className='assign-resources-adding-label'>Department Name</div>
																<input className='edit-department-input' defaultValue={department} autoFocus={true} onFocus={handleFocus} />
															</div>
															<FontAwesomeIcon className='x-cancel assign-resources-cancel-new-btn' icon={faTimes} onClick={() => toggleEdit(department_id)}/>
															<FontAwesomeIcon className='checkmark-submit assign-resources-add-new-btn' icon={faCheck}  onClick={saveDepartmentUpdate}/>
														</div>
													):
													(
														<>
														
														<div className='upload-courses-department-inner-row'>
															<div>{department}</div>
															<FontAwesomeIcon className='upload-courses-department-icon gray-to-dark-blue-link' icon={faEdit} onClick={() => toggleEdit(department_id)}/>
															<FontAwesomeIcon className='upload-courses-department-icon gray-to-red-link' icon={faTrashAlt} onClick={() => deleteDepartment(department_id)}/>
														</div>
														</>
													)
												}
											</div>
										)
									})}
								</div>
								{showAddDepartment ?
								(
									<div className="assign-resources-add-row-container">
										<div className="edit-department-row-container">
											<div>
												<div className='assign-resources-adding-label'>New Department Name</div>
												<input id='new-department-name' className='edit-department-input' onKeyDown={addNewDepartment} autoFocus={true}/>
											</div>
											<FontAwesomeIcon className='x-cancel assign-resources-cancel-new-btn parent-icon' icon={faTimes} onClick={() => {setShowAddDepartment(!showAddDepartment)}}/>
											<FontAwesomeIcon className='checkmark-submit assign-resources-add-new-btn parent-icon' icon={faCheck}  onClick={addNewDepartment}/>
										</div>
									</div>
								):
								(
									<div className='add-assign-resources-row' onClick={() => {setShowAddDepartment(!showAddDepartment)}}>Add New Department</div>
								)
								}
							</div>
						):null
					}
				</div>
				{(courseDataExists && departmentDataExists) &&
					<div className='fixed-bottom-save-container'>
						<div className="btn btn-extra-large green-btn align-right move-to-next-screen-btn move-to-next-screen-btn" onClick={handleMoveToNextScreen}>Go to Next Step: Upload Teachers</div>
					</div>
				}
			</div>
			</>
		)}
		</>
	);
}