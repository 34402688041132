import React, { useState } from 'react';

import { sortArrayOfObjects } from '../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

export default function SortToggle({handleOnClick, sortKey, sortType, passedData}) {
	const [sorted, setSorted] = useState(null);
	
	const toggleSorted = () => {
		setSorted(!sorted);
		
		let sorted_data;
		const sort_asc_desc = (sorted) ? 'desc' : 'asc';
		
		if(sorted)
		{
			sorted_data = sortArrayOfObjects(passedData, sortKey, sortType, sort_asc_desc);
		}
		else
		{
			sorted_data = sortArrayOfObjects(passedData, sortKey, sortType, sort_asc_desc);
		}
		
		handleOnClick(sorted_data, sortKey, sort_asc_desc);
	}
	
	return (
		<>
		{!sorted &&
			<FontAwesomeIcon className='sort-toggle' icon={faCaretDown} onClick={toggleSorted}/>
		}
		{sorted &&
			<FontAwesomeIcon className='sort-toggle' icon={faCaretUp} onClick={toggleSorted}/>
		}
		</>
	);
}