import React, { useState} from 'react';
import { API } from "aws-amplify";
import Fuse from 'fuse.js';

import { sortArrayOfObjects, recalculateMatchPercent, calculateConflicts } from '../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faSortDown, faPeopleArrows, faTrashAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';

export default function BulkEditCourseRequest({courseID, schoolInfo, courses, students, setStudents, sections, setSections, selectedStudents, setSelectedStudents}) {
	const [confirmBulkRemoveStudentsOpen, setConfirmBulkRemoveStudentsOpen] = useState(false);
	const [otherCoursesToBulkSendRequestsOpen, setOtherCoursesToBulkSendRequestsOpen] = useState(false);
	const [courseReceivingRequests, setCourseReceivingRequests] = useState(null);

	const toggleSelectAll = () => {
		const any_students_selected = selectedStudents.length > 0;
		
		if(any_students_selected)
		{
			setSelectedStudents([]);
		}
		else
		{
			const students_requesting_course = students.reduce((results, student) =>{
				const student_requested_course = student.student_requests.findIndex(request => request.course_id === courseID && request.is_deleted === '0') !== -1;
				if(student_requested_course) results.push(student.student_id);
				return results;
			}, []);

			setSelectedStudents([...students_requesting_course]);
		}
	}

	const bulkRemoveStudents = async () => {
		/// Update FRONTEND ///
		const affected_section_ids = [];
		selectedStudents.forEach(student_id => {
			const student_index = students.findIndex(student => student.student_id === student_id)
			const request_index = students[student_index].student_requests.findIndex(request => request.course_id === courseID);
			
			if(student_index !== -1 && request_index != -1) students[student_index].student_requests[request_index].is_deleted = '1';

			// Remove this student from any section of this course too
			const section_index = sections.findIndex(section => section.course_id === courseID && section.student_list.includes(student_id));

			if(section_index !== -1)
			{
				const section_info = sections[section_index];
				const section_id = section_info.section_id;
				const student_list = section_info.student_list;
				const student_index = student_list.findIndex(student => student === student_id);

				sections[section_index].student_list.splice(student_index, 1);

				affected_section_ids.push(section_id);
			}
		});

		setSections([...sections]);
		setStudents([...students]);
		setSelectedStudents([]);
		setConfirmBulkRemoveStudentsOpen(false);

		/// Update BACKEND ///
		const passed_data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, update_type:'delete', course_id:courseID, student_ids:selectedStudents};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-requests'
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(passed_data),
		};
		
		try {
			// Update the requests in the database
			await API.post(apiName, url, myInit);

			// Update conflicts
			const conflicts_to_check = ['student_double_booked', 'student_conflicting_periods', 'student_student_restriction', 'teacher_student_restriction', 'course_exceeds_capacity', 'inclusion_too_many_students', 'inclusion_over_half'];
			calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, affected_sections:affected_section_ids, update_all:true});

			// Recalculate match percent
			recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});
		} catch(e)
		{
			console.log(e);
		}
	}

	// Component for bulk sending students to other course request
	// Need to separate this out so it updates on state change in the modal (needed for search)
	const OtherCourseList = () => {
		const [otherCoursesSearchResults, setOtherCoursesSearchResults] = useState([...courses]);

		const otherCoursesSearch = (e) => {
			const search_value = e.target.value;
			
			let course_results = [];

			if(search_value.length === 0)
			{
				course_results = courses;
			}
			else
			{
				const fuse = new Fuse(courses, {
					keys: ['name', 'course_code'],
					threshold: .1
				});
				
				const results = fuse.search(search_value);
				course_results = results.map(result => result.item).slice(0, 6);
			}

			setOtherCoursesSearchResults([...course_results]);
		}

		return (
			<div className='text-align-left'>
				<h1 style={{margin:'0px'}}>Choose a Course</h1>
				<p style={{margin:'10px 0px 20px 0px'}}>What course should these course requests be transferred to?</p>
				<div className='search-add-adding-input-container' style={{marginBottom:'10px'}}>
					<input className='search-add-adding-input' onChange={(e) => otherCoursesSearch(e)} placeholder='Search for a course'/>
					<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
				</div>
				<div className='edit-course-move-student-request-row' style={{backgroundColor:'#fff'}}>
					<div><strong><u>Course Code</u></strong></div>
					<div><strong><u>Course</u></strong></div>
					<div className='white-text'>.</div>
				</div>
				{courses.filter(course => otherCoursesSearchResults.findIndex(other_course => other_course.course_id === course.course_id) !== -1).map((course, i) => {
					const course_id = course.course_id;
					const course_info = (course_id) ? courses.find(course => course.course_id === course_id) : null;
					const course_code = (course_info) ? course_info.course_code : null;
					const course_name = (course_info) ? course_info.name : null;

					const course_receiving_requests = (courseReceivingRequests === course_id);

					return (
						<div key={i} className='edit-course-move-student-request-row'>
							<div>{course_code}</div>
							<div>{course_name}</div>
							{(course_id === courseID) ?
								(
									<div className='small-text align-right'>Current Course</div>
								):
								(
									<div className={`btn-small btn-round blue-btn align-right ${courseReceivingRequests && 'opacity-75'}`} onClick={courseReceivingRequests ? ()=>{} : () => bulkSendStudentsToNewCourseRequest(course_id)}>{course_receiving_requests ? <div className='loader' style={{width:'20px'}}></div> : 'Transfer Requests'}</div>
								)
							}
						</div>
					)
				})}
			</div>
		)
	}

	const bulkSendStudentsToNewCourseRequest = async (new_course_id) => {
		setCourseReceivingRequests(new_course_id);

		// Move students from one course to another FRONTEND state
		// Remove students from any old section of current course
		const new_sections = sections.map(section => {
			const section_course_id = section.course_id;
			const section_student_list = section.student_list;
		
			if((section_course_id === courseID))
			{
				const selected_student_set = new Set(selectedStudents);
				const new_student_list = section_student_list.filter(student_id => !selected_student_set.has(student_id));
				section.student_list = new_student_list;
			}
			
			return section;
		});

		setSections([...new_sections]);

		// Add requests for the new course for all students
		selectedStudents.forEach(student_id => {
			const student_index = students.findIndex(student => student.student_id === student_id);
			const student_info = students[student_index];
			const student_requests = student_info.student_requests;

			const already_requested_index = student_requests.findIndex(request => request.course_id === new_course_id);

			if(already_requested_index === -1) 
			{
				students[student_index].student_requests.push({request_id: 'new', course_id:new_course_id, priority:'1', matched:'1', data_origin:'2', is_deleted:'0'});
			}
			else
			{
				students[student_index].student_requests[already_requested_index].is_deleted = '0';
			}
		});

		setStudents([...students]);

		// Remove these students from edit course screen
		bulkRemoveStudents();

		///////////////////////////
		///// SEND TO BACKEND /////
		///////////////////////////
		// Move students from one section to another in the BACK END
		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id,  student_list:selectedStudents, old_course_id:courseID, new_course_id:new_course_id};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/move-students-to-new-course-request';
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(data),
		};
		
		try {
			// MOVE STUDENTS TO NEW COURSE REQUEST
			await API.post(apiName, url, myInit);

			// Update conflicts
			const conflicts_to_check = ['student_double_booked', 'student_conflicting_periods', 'student_student_restriction', 'teacher_student_restriction', 'course_exceeds_capacity', 'inclusion_too_many_students', 'inclusion_over_half'];
			const affected_sections = sections.reduce((results, section) => {
				const section_course_id = section.course_id;
				if(section_course_id && section_course_id === courseID) results.push(section.section_id);
				return results;
			}, []);
			calculateConflicts({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:affected_sections, conflicts_to_check:conflicts_to_check, update_all:false});

			// Recalculate match percent
			recalculateMatchPercent({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});

			setCourseReceivingRequests(null);
			setOtherCoursesToBulkSendRequestsOpen(false);
		} catch(error)
		{
			console.log(error.response);
			setCourseReceivingRequests(null);
		}
	}

	const course_info = courses.find(course => course.course_id === courseID);
	const course_name = (course_info) ? course_info.name : null;
	const course_code = (course_info) ? course_info.course_code : null;

	const students_being_removed = students.filter(student => (selectedStudents.includes(student.student_id) && student.student_requests.findIndex(request => request.course_id === courseID && request.is_deleted === '0') !== -1));
	const sorted_students_being_removed = (students_being_removed.length > 0) ? sortArrayOfObjects(students_being_removed, 'last_name', 'text', 'desc') : [];

	return (
		<>
			<div className='select-all-row'>
				<div className={`select-all-container ${selectedStudents.length > 0 && 'blue-text'}`} onClick={toggleSelectAll}>
					<FontAwesomeIcon icon={selectedStudents.length > 0 ? faMinusSquare : farSquare}/>
					<FontAwesomeIcon className='select-all-arrow' icon={faSortDown}/>
				</div>
				{selectedStudents.length > 0 &&
					<div className='select-all-actions-container'>
						<div className='tooltip' data-tooltip-text='Transfer requests to another course' onClick={() => setOtherCoursesToBulkSendRequestsOpen(true)}>
							<FontAwesomeIcon className='select-all-action-btn tooltip' icon={faPeopleArrows}/>
						</div>
						<div className='tooltip' data-tooltip-text='Remove students' onClick={() => setConfirmBulkRemoveStudentsOpen(true)}>
							<FontAwesomeIcon className='select-all-action-btn tooltip' icon={faTrashAlt}/>
						</div>
					</div>
				}
			</div>
			{otherCoursesToBulkSendRequestsOpen &&
				<div className='modal'>
					<div className='modal-content' style={{width:'700px'}}>
						<span className="modal-close-right" onClick={() => setOtherCoursesToBulkSendRequestsOpen(false)}>&times;</span>
						<OtherCourseList />
					</div>
				</div>
			}
			{confirmBulkRemoveStudentsOpen &&
				<div className='modal'>
					<div className='modal-content'>
						<span className="modal-close-right" onClick={() => setConfirmBulkRemoveStudentsOpen(false)}>&times;</span>
						<h1>Are you sure?</h1>
						<p>Do you really want to remove the following {sorted_students_being_removed.length} students from {course_name} ({course_code})?</p>
						<p className='red-text'>This will also remove them from any section of {course_name} they are currently in.</p>
						<div className='text-align-left'>
							<div className='modal-example-list'>
								{sorted_students_being_removed.map((student_info, index) => {
									const student_id = (student_info) ? student_info.student_id : index;
									const student_first_name = (student_info) ? student_info.first_name : null;
									const student_last_name = (student_info) ? student_info.last_name : null;
									const student_full_name = (student_first_name && student_last_name) ? `${student_last_name}, ${student_first_name}` : null;

									return (
										<div key={student_id} className='modal-example-row'>{student_full_name}</div>
									)
								})}
							</div>
						</div>
						<div className='text-align-right'>
							<h3>Total: {sorted_students_being_removed.length} students</h3>
						</div>
						<div className='modal-btn-container'>
							<div className='btn gray-btn' onClick={() => setConfirmBulkRemoveStudentsOpen(false)}>Cancel</div>
							<div className='btn red-btn' onClick={bulkRemoveStudents}>Remove Requests</div>
						</div>
					</div>
				</div>
			}
		</>
		
	);
}