import React, { useEffect } from 'react';

import SettingsHSMS from './hsms/SettingsHSMS';

export default function Settings({ location, isAuth, authComplete, currentSchoolID, userInfo, setUserInfo, users, setUsers,  schoolInfo, schoolInfoLoading, setSchoolInfo, schoolType, setLoadScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo}) {
	useEffect(() => {
		setScreenAllowsUniversalSidebar(false);
		setUniversalSidebarLoadingInfo(null);
	}, []);

	return (
		<>
			{(() => {
				switch(schoolType) {
					case 'hsms':
						return <SettingsHSMS location={location} isAuth={isAuth} authComplete={authComplete} currentSchoolID={currentSchoolID} userInfo={userInfo} setUserInfo={setUserInfo} users={users} setUsers={setUsers} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} setSchoolInfo={setSchoolInfo} schoolType={schoolType} setLoadScreen={setLoadScreen}/>;
					case 'elementary':
						return <SettingsHSMS location={location} isAuth={isAuth} authComplete={authComplete} currentSchoolID={currentSchoolID} userInfo={userInfo} setUserInfo={setUserInfo} users={users} setUsers={setUsers} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} setSchoolInfo={setSchoolInfo} schoolType={schoolType} setLoadScreen={setLoadScreen}/>;
					default:
						return null;
				}
			})()}
		</>
	);
}