import React, { useState, useEffect } from 'react';

export default function Availability({availabilityDataType, availabilityViewID, availabilityDataPosition, scheduleType, currentDay, teachers, classrooms, students, courses, sections, scheduleStructureData }) {
	const [primaryName, setPrimaryName] = useState(null);
	const [primarySections, setPrimarySections] = useState([]);

	//////////////////////
	//// DO ON LOAD //////
	//////////////////////
	const periodsToShow = (scheduleType === 'daily') ? scheduleStructureData.schedule_periods : scheduleStructureData.course_periods;
	const num_cols = Object.keys(periodsToShow).length;
	const row_style = {gridTemplateColumns : `35px repeat(${num_cols}, 1fr)`};

	useEffect(() => {
		let primary_name = null;
		let primary_sections = [];

		if(availabilityDataType === 'teacher')
		{
			const teacher_info = teachers.find(teacher => teacher.teacher_id === availabilityViewID);
			primary_name = (teacher_info) ? `${teacher_info.first_name} ${teacher_info.name}` : null;

			primary_sections = sections.filter(section => section.teacher_id === availabilityViewID);
		}
		else if(availabilityDataType === 'classroom')
		{
			const classroom_info = classrooms.find(classroom => classroom.classroom_id === availabilityViewID);
			primary_name = (classroom_info) ? `${classroom_info.classroom_name}` : null;

			primary_sections = sections.filter(section => section.classroom_id === availabilityViewID);
		}
		else if(availabilityDataType === 'student')
		{
			const student_info = students.find(student => student.student_id === availabilityViewID);
			primary_name = (student_info) ? `${student_info.first_name} ${student_info.last_name}` : null;

			primary_sections = sections.filter(section => section.student_list.includes(availabilityViewID));
		}

		setPrimaryName(primary_name);
		setPrimarySections([...primary_sections]);
	}, [availabilityViewID, availabilityDataType]);

	//console.log({availabilityViewID, primaryName, primarySections});
	const quarters = ['1', '2', '3', '4'];

	return (
		<>
			<div className='edit-section-availability-hover-container' style={availabilityDataPosition}>
				<div className='dark-gray-text small-text'>Availability</div>
				<h4>{primaryName}</h4>
				<div style={row_style} className='availability-grid small-text'>
					<div></div>
					{Object.keys(periodsToShow).map((period_id, index) => {
						const period_name = periodsToShow[period_id].period_name;
						
						return (
							<div className='mb-inner-box' key={index}><div>{period_name}</div></div>
						)
					})}
					{quarters.map((quarter, q) => {
						return (
							<React.Fragment key={q}>
								<div>Q{quarter}:</div>
								{Object.keys(periodsToShow).map((period_id, index) => {
									const course_period_id = (scheduleType === 'daily') ? scheduleStructureData.schedule_structure[currentDay][period_id] : period_id;

									const quarter_sections = primarySections.filter(section => section.course_period_id === course_period_id && section.quarter_days.findIndex(quarter_day => quarter_day.quarter === quarter) !== -1);

									return (
										<div key={index}>
											<span className={`${quarter_sections.length > 0 ? 'red-text' : 'green-text'}`}>{quarter_sections.length > 0 ? 'Unavailable' : 'Available'}</span>
										</div>
									)
								})}
							</React.Fragment>
						)
					})}
				</div>
			</div>
		</>
	);
}