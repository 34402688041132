import axios from 'axios';
import { API } from "aws-amplify";

export class FileUpload {
  dataVersionId;
  scheduleVersionIds;
  schoolId;
  apiName;
  interval;
  constructor(dataVersionId, scheduleVersionIds, schoolId) {
    this.dataVersionId = dataVersionId;
    this.scheduleVersionIds = scheduleVersionIds;
    this.schoolId = schoolId;
    this.apiName = process.env.REACT_APP_ENDPOINT_NAME
  }
  cancelCheck() {
    if (this.interval === undefined) return;

    clearInterval(this.interval);
    this.interval = undefined;
  }

  async getSchoolProcesses(scheduleYearID) {
      const apiName = process.env.REACT_APP_ENDPOINT_NAME;
      const myInit = {
        response: true,
        queryStringParameters: { 
          school_id: this.schoolId,
          schedule_year_id: scheduleYearID
        }
      }
      const url = '/process/check-progress';
      
      const response = await API.get(apiName, url, myInit);
      const responseData = response.data;

      return responseData;
  }

  async checkUploading(schoolID, progressID, eventCB) {
    if (this.interval !== undefined) return;

    let lastPercent = 0;
    let progressUpdateInterval;
    const check = async (initialCheck) => {
      const apiName = process.env.REACT_APP_ENDPOINT_NAME;
      const myInit = {
        response: true,
        queryStringParameters: { 
          school_id:schoolID,
          progress_id: progressID
        }
      };
      const url = '/process/check-progress';
      
      const response = await API.get(apiName, url, myInit);
      const responseData = response.data[0];
      clearInterval(progressUpdateInterval);
      //TODO: get progress from here or implement websockets... If doing websockets, then remove setinvterval

      const progress = responseData.progress;
      const isDone = responseData.is_done;
      let progressPercent;
      if (isDone) {
        progressPercent = 100;
      } else {
        progressPercent = Math.floor(progress * 100);
      }
      if (initialCheck) {
        lastPercent = progressPercent;
      }
      const progressDif = progressPercent - lastPercent;
      const failure = responseData.failure;
      const has_feedback = responseData.has_feedback;
      if ((progressDif > 1 || (isDone && lastPercent > 0)) && !failure && !initialCheck) {
        let timeInterval;
        if (isDone) {
          // Simulate progress over time (1 sec since it is finished)
          timeInterval = Math.floor(1000 / progressDif);
        } else {
          // Simulate progress over time (5 sec as that is our interval)
          timeInterval = Math.floor(5000 / progressDif);
        }

        progressUpdateInterval = setInterval(() => {
          ++lastPercent;
          if (lastPercent == progressPercent) {
            if (isDone) {
              eventCB({
                has_started: true,
                done: true,
                failure: false,
                has_feedback: has_feedback,
                progress: 100
              });
            }
            clearInterval(progressUpdateInterval);
          }
          eventCB({
            has_started: true,
            done: false,
            failure: false,
            has_feedback: has_feedback,
            progress: lastPercent / 100
          });
        }, timeInterval);
      } else {
        eventCB({
          has_started: responseData.has_started,
          done: isDone,
          failure: failure,
          has_feedback: has_feedback,
          progress: progress ?? 0
        });	
      }
      if(responseData.has_feedback || responseData.is_done || responseData.failure) {
        this.cancelCheck();
        return false;
      }

      return true;
    };
    
    this.interval = setInterval(check, 5000, false);
  }

  async uploadFile(srcFile, uploadType, isReplace = false, eventCB) {
    const apiName = process.env.REACT_APP_ENDPOINT_NAME;
    const myInit = {
			response: true,
			queryStringParameters: {
				upload_type: uploadType,
				data_version_id: this.dataVersionId,
				schedule_version_ids: this.scheduleVersionIds,
				content_type: srcFile.type,
				school_id: this.schoolId,
        replace: isReplace
			}
		};

		const url = '/generate-upload-url';
    const response = await API.get(apiName, url, myInit);
    //TODO: Check repsonse here for success
    const responseData = response.data;
		const uploadToUrl = responseData.url;
    const uploadFields = responseData.fields;
    const formData = new FormData();
		for(let i = 0, keys = Object.keys(uploadFields); i < keys.length; ++i) {
			const key = keys[i];
			const value = uploadFields[key];
			formData.append(key, value);
		}
		formData.append('Content-Type', srcFile.type);
    formData.append('file', srcFile);
    const uploadResponse = await axios.request({
      method: 'post',
      url: uploadToUrl,
      data: formData,
      onUploadProgress: (event) => {
        eventCB({progress: event.loaded / event.total});
      }
    });
    if (uploadResponse.status === 204) {
      return true;
    } else {
      return false;
    }
  }
}