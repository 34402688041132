import React, { useState , useEffect, useRef } from 'react';
import Fuse from 'fuse.js';
import { API } from "aws-amplify";
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSquare, faCheckSquare, faTimes, faSearch, faPlusCircle, faLock, faMinusSquare, faSortDown} from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';
import { useOutsideClick, cloneObj, createUniqueObjectID, isObject } from '../../../js/Helpers';

import '../../../css/elementary/edit_modals.css';

export default function EditBlock({ schoolInfo, days, blocks, setBlocks, courses, setCourses, students, teachers, classrooms, blockData, overlapCheck,  removeBlock, toggleEditBlock, sidePanelClosing, setSidePanelClosing }) {
	///////////////////////////
	///// STATE VARIABLES /////
	///////////////////////////
	const [affectedBlocks, setAffectedBlocks] = useState([]);
	const [removedBlocks, setRemovedBlocks] = useState([]);
	const [addedBlocks, setAddedBlocks]= useState([]);

	const [blockDataImmutable, setBlockDataImmutable] = useState({});
	const [blockDataCopy, setBlockDataCopy] = useState(null);
	const [blockDataCopied, setBlockDatCopied] = useState(false);
	
	const [selectedStudents, setSelectedStudents] = useState([]);

	const [timeErrorMessage, setTimeErrorMessage] = useState(null);
	const [homeroomTeacherError, setHomeroomTeacherError] = useState(null);
	const [teachingTeacherError, setTeachingTeacherError] = useState(null);
	const [saveError, setSaveError] = useState(null);

	const [searchResults, setSearchResults] = useState({course_name:[], homeroom_teacher:[], teaching_teacher:[], suggested_students:[], students:[]});
	const [currentScreen, setCurrentScreen] = useState(1);
	const [showStudentSearch, setShowStudentSearch] = useState(false);
	const [changesMade, setChangesMade] = useState(false);

	const [teachingTeacherName, setTeachingTeacherName] = useState('');
	const [homeroomTeacherName, setHomeroomTeacherName] = useState('');

	const [classroomOptions, setClassroomOptions] = useState([{'value': null, display:'--'}]);

	///////////////////
	///// USE REF /////
	///////////////////
	const side_panel_ref = useRef();
	const course_search_ref = useRef();
	const homeroom_teachers_ref = useRef();
	const teaching_teachers_ref = useRef();

	useOutsideClick(side_panel_ref, () => {
		handleExitWithoutSaving(false);
	}, ['elem-suggestion-box-parent', 'mb-add-new-block-options-container', 'swal-button--cancel', 'swal-button--confirm', 'dropdown-option', 'cancel-student-search', 'elem-edit-block-student', 'select-all-row', 'select-all-container', 'select-all-actions-container']);

	useOutsideClick(course_search_ref, () => {
		setSearchResults({...searchResults, ...{course_name:[]}});
	}, []);

	useOutsideClick(teaching_teachers_ref, () => {
		setSearchResults({...searchResults, ...{teaching_teacher:[]}});
	}, []);

	useOutsideClick(homeroom_teachers_ref, () => {
		setSearchResults({...searchResults, ...{homeroom_teacher:[]}});
	}, []);
	
	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const closeSidePanel = () => {
		toggleEditBlock(null);

		if(blockData) setSidePanelClosing(true);
		setTimeErrorMessage(null);
		setHomeroomTeacherError(null);
		setTeachingTeacherError(null);
		setAffectedBlocks([]);
		setRemovedBlocks([]);
		setAddedBlocks([]);
		setSaveError(null);
		setChangesMade(false);
	}

	const fuzzySearch = (e, dataType, dataKey = dataType) => {
		const search_value = e.target.value;
		
		if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const course_results = results.map(result => result.item).slice(0, 20);
			
			searchResults[dataKey] = (course_results.length === 0 && search_value.length === 0) ? courses : course_results;
		}
		else if(dataType === 'teachers')
		{
			const fuse = new Fuse(teachers, {
				keys: ['first_name', 'name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const teacher_results = results.map(result => result.item).slice(0, 6);
			
			searchResults[dataKey] = (teacher_results.length === 0 && search_value.length === 0) ? teachers : teacher_results;
		}
		else if(dataType === 'classrooms')
		{
			const fuse = new Fuse(classrooms, {
				keys: ['name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const classroom_results = results.map(result => result.item).slice(0, 6);
			
			searchResults[dataKey] = (classroom_results.length === 0 && search_value.length === 0) ? classrooms : classroom_results;
		}
		else if(dataType === 'students')
		{
			// Search all students
			const fuse_students = new Fuse(students, {
				keys: ['first_name', 'last_name'],
				threshold: .1
			});
			const results = fuse_students.search(search_value);
			const student_results = results.map(result => result.item).slice(0, 30);
			
			searchResults['students'] = (student_results.length === 0 && search_value.length === 0) ? students : student_results;

			// Search through homeroom students
			const suggested_students = (blockDataCopy.homeroom_teacher_id) ? students.filter(student => {
				const student_homeroom_teacher_id = student.homeroom_teacher_id;
				if(student_homeroom_teacher_id && (student_homeroom_teacher_id === blockDataCopy.homeroom_teacher_id)) return true;
				return false;
			}) : [];
			const fuse_suggested_students = new Fuse(suggested_students, {
				keys: ['first_name', 'last_name'],
				threshold: .1
			});
			const suggested_results = fuse_suggested_students.search(search_value);
			const suggested_student_results = suggested_results.map(result => result.item).slice(0, 30);

			searchResults['suggested_students'] = (suggested_student_results.length === 0 && search_value.length === 0) ? suggested_students : suggested_student_results;
		}

		setSearchResults({...searchResults});
	}

	const validateTime = () => {
		const day_start_time = 700;
		const day_end_time = 1600;

		const start_time = document.getElementById("elem-edit-block-start-time").value;
		const end_time = document.getElementById("elem-edit-block-end-time").value;

		if(!start_time || !end_time) return {status:'error', error:'Missing start or end time'};

		const start_time_array = start_time.split(':');
		const end_time_array = end_time.split(':');

		const start_time_number = parseInt(start_time_array[0] + start_time_array[1]);
		const end_time_number = parseInt(end_time_array[0] + end_time_array[1]);

		if(start_time_number < day_start_time) return {status:'error', error:'Blocks cannot start before the start of the day'};
		if(start_time_number > day_end_time) return {status:'error', error:'Blocks cannot start after the end of the day'};
		if(end_time_number < day_start_time) return {status:'error', error:'Blocks cannot end before the start of the day'};
		if(end_time_number > day_end_time) return {status:'error', error:'Blocks cannot end after the end of the day'};
		if(start_time_number > end_time_number) return {status:'error', error:'Your block has a start time that is after the end time'};

		return {status:'valid'};
	}

	const createTeacherFullName = (teacher_id) => {
		const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === teacher_id);
		const teacher_info = (teacher_index !== -1) ? teachers[teacher_index] : null;
		const teacher_first_name = (teacher_info) ? teacher_info.first_name : null;
		const teacher_last_name = (teacher_info) ? teacher_info.name : null;
		const teacher_full_name = (teacher_info) ? `${teacher_last_name}, ${teacher_first_name}` : '';

		return teacher_full_name;
	}

	const handleExtraTeacherSearch = (e, teacher_type) => {
		fuzzySearch(e, 'teachers', teacher_type);

		if(teacher_type === 'teaching_teacher') 
		{
			setTeachingTeacherName(e.target.value);
			setBlockDataCopy({...blockDataCopy, ...{teacher_id:null}});
		}
		else if(teacher_type === 'homeroom_teacher') 
		{
			setHomeroomTeacherName(e.target.value);
			setBlockDataCopy({...blockDataCopy, ...{homeroom_teacher_id:null}});
		}
		
		setTeachingTeacherError('No valid teacher selected yet!');
		setChangesMade(true);
	} 

	const updateState = (e, key, _callback = ()=>{}) => {
		setChangesMade(true);
		setTimeErrorMessage(null);

		const value = (isObject(e) && 'target' in e) ? e.target.value : e;

		// If updated data is start or end time, make sure they are within the range
		// If not, return error
		let error_status;
		if(key === 'end_time' || key === 'start_time')
		{
			const validated_time = validateTime();
			error_status = validated_time.status;

			//if(error_status === 'empty') return;
			if(error_status === 'error')  setTimeErrorMessage(validated_time.error);
		}

		// Change the copied block data values
		blockDataCopy[key] = value;
		setBlockDataCopy({...blockDataCopy});

		// Update original block so you can see the changes made in real time
		if(blockDataCopy.block_id)
		{
			if(blockData.new)
			{
				affectedBlocks.forEach(affected_block => {
					const affected_block_id = affected_block.block_id;
					const block_index = blocks.findIndex(block => block.block_id === affected_block_id);
					if(block_index !== -1) blocks[block_index][key] = value;
				});
			}
			else
			{
				const block_index = blocks.findIndex(block => block.block_id === blockData.block_id);
				if(block_index !== -1) blocks[block_index][key] = value;
			}
				
			setBlocks([...blocks]);
		}
	

		_callback();
	}

	const updateBlockName = (course_id, block_name) => {
		const key1 = 'course_id';
		updateState(course_id, key1);
		
		const key2 = 'block_name';
		updateState(block_name, key2);

		// Close suggestion box
		searchResults.course_name = [];
		setSearchResults({...searchResults});
	}

	const updatePullOutTeacher = (teacher_id) => {
		const teacher_name = createTeacherFullName(teacher_id);

		blockDataCopy.teacher_id = teacher_id;
		
		setTeachingTeacherError(null);
		setTeachingTeacherName(teacher_name);
		setBlockDataCopy({...blockDataCopy});

		// Close suggestion box
		searchResults.teaching_teacher = [];
		setSearchResults({...searchResults});

		setChangesMade(true);
	}

	const updateExtraTeacher = (teacher_id, teacher_type) => {
		const teacher_name = createTeacherFullName(teacher_id);

		if(teacher_type === 'homeroom_teacher')
		{
			// Update student list to be for this homeroom teacher
			const student_list = students.reduce((results, student) => {
				if(student.homeroom_teacher_id && student.homeroom_teacher_id === teacher_id) results.push(student.student_id);
				return results;
			}, []);

			blockDataCopy.homeroom_teacher_id = teacher_id;
			blockDataCopy.student_list = student_list;
			
			setHomeroomTeacherError(null);
			setHomeroomTeacherName(teacher_name);
		}
		else if(teacher_type === 'teaching_teacher')
		{
			blockDataCopy.teacher_id = teacher_id;
			
			setTeachingTeacherError(null);
			setTeachingTeacherName(teacher_name);
		}

		setBlockDataCopy({...blockDataCopy});

		// Close suggestion box
		searchResults[teacher_type] = [];
		setSearchResults({...searchResults});
		setChangesMade(true);
	}

	const updateDays = (update_type, day) => {
		if(update_type === 'remove')
		{
			const block_index = blocks.findIndex(block => affectedBlocks.findIndex(affected_block => affected_block.block_id === block.block_id) !== -1 && block.day_number == day);
			const block_id_to_remove = (block_index !== -1) ? blocks[block_index].block_id : null;
			const block_info_removed = (block_index !== -1) ? cloneObj(blocks[block_index]) : null;

			if(block_id_to_remove)
			{
				const affected_block_index = affectedBlocks.findIndex(affected_block => affected_block.block_id === block_id_to_remove);
				const added_block_index = addedBlocks.findIndex(added_block => added_block.block_id === block_id_to_remove);

				// remove from blocks
				blocks.splice(block_index, 1);

				// remove from affected blocks
				affectedBlocks.splice(affected_block_index, 1);

				if(added_block_index !== -1)
				{
					// remove from added blocks
					addedBlocks.splice(added_block_index, 1);
				}
				else
				{
					// add to removed blocks
					removedBlocks.push(block_info_removed);
				}

				setBlocks([...blocks]);
				setAffectedBlocks([...affectedBlocks]);
				setRemovedBlocks([...removedBlocks]);
				setAddedBlocks([...addedBlocks]);
			}
		}
		else if(update_type === 'add')
		{
			/// Get all the current block data
			const course_id = blockDataCopy.course_id;
			const classroom_id = blockDataCopy.classroom_id;
			const end_time = blockDataCopy.end_time;
			const homeroom_teacher_id = blockDataCopy.homeroom_teacher_id;
			const is_lunch = blockDataCopy.is_lunch;
			const is_pullout = blockDataCopy.is_pullout;
			const is_special = blockDataCopy.is_special;
			const start_time = blockDataCopy.start_time;
			const teacher_id =blockDataCopy.teacher_id;
			const student_list =blockDataCopy.student_list;

			// Check to see if there is a removed block with same day
			const removed_block_index = removedBlocks.findIndex(removed_block => removed_block.day_number == day);

			if(removed_block_index !== -1)
			{
				const removed_block_info = removedBlocks[removed_block_index];

				removed_block_info.course_id = course_id;
				removed_block_info.classroom_id = classroom_id;
				removed_block_info.end_time = end_time;
				removed_block_info.homeroom_teacher_id = homeroom_teacher_id;
				removed_block_info.is_lunch = is_lunch;
				removed_block_info.is_pullout = is_pullout;
				removed_block_info.is_special = is_special;
				removed_block_info.start_time = start_time;
				removed_block_info.teacher_id = teacher_id;
				removed_block_info.student_list = student_list;

				// Add block back into the array
				blocks.push(removed_block_info);
				affectedBlocks.push(removed_block_info);

				// Take this block off the removed list
				removedBlocks.splice(removed_block_index, 1);
				
				setBlocks([...blocks]);
				setRemovedBlocks([...removedBlocks]);
			}
			else
			{
				const block_id = createUniqueObjectID(5);
				const new_block = {block_id:block_id, course_id:course_id, classroom_id:classroom_id, day_number:`${day}`, end_time:end_time, homeroom_teacher_id:homeroom_teacher_id, is_lunch:is_lunch, is_pullout:is_pullout, is_special:is_special, start_time:start_time, teacher_id:teacher_id, student_list:student_list};

				blocks.push(new_block);
				affectedBlocks.push(new_block);
				addedBlocks.push(new_block);

				setBlocks([...blocks]);
				setAffectedBlocks([...affectedBlocks]);
				setAddedBlocks([...addedBlocks]);
			}
		}

		setChangesMade(true);
	}

	const addStudent = (student_id) => {
		const student_index = blockDataCopy.student_list.findIndex(temp_student_id => temp_student_id === student_id);

		if(student_index === -1)
		{
			blockDataCopy.student_list.push(student_id);
			setBlockDataCopy({...blockDataCopy});

			setChangesMade(true);
		}
	}

	const removeStudent = (student_id) => {
		const student_index = blockDataCopy.student_list.findIndex(temp_student_id => temp_student_id === student_id);

		if(student_index !== -1)
		{
			blockDataCopy.student_list.splice(student_index, 1);
			setBlockDataCopy({...blockDataCopy});

			setChangesMade(true);
		}
	}

	const toggleSelectStudent = (student_id) => {
		const student_selected_index = selectedStudents.findIndex(student => student === student_id);

		if(student_selected_index !== -1)
		{
			selectedStudents.splice(student_selected_index, 1);
		}
		else
		{
			selectedStudents.push(student_id);
		}

		setSelectedStudents([...selectedStudents]);
	}

	const toggleSelectAll = () => {
		const student_list = blockDataCopy.student_list.filter(student_id => !blockDataCopy.locked_students.includes(student_id));
		const any_students_selected = selectedStudents.filter(student_id => student_list.includes(student_id)).length > 0;
		
		let new_selected_students = [];

		if(any_students_selected)
		{
			new_selected_students = selectedStudents.filter(student_id => !student_list.includes(student_id));
		}
		else
		{
			new_selected_students = [...selectedStudents, ...student_list];
		}

		setSelectedStudents([...new_selected_students]);
	}

	const toggleLockStudents = (lock_type, student_id = null) => {
		if(lock_type === 'lock')
		{
			if(student_id)
			{
				const lock_index = blockDataCopy.locked_students.findIndex(locked_student_id => locked_student_id === student_id);
				if(lock_index === -1) blockDataCopy.locked_students.push(student_id);
			}
			else
			{
				selectedStudents.forEach(student_id => {
					blockDataCopy.locked_students.push(student_id);
				});
	
				setSelectedStudents([]);
			}
		}
		else if(lock_type === 'unlock')
		{
			const lock_index = blockDataCopy.locked_students.findIndex(locked_student_id => locked_student_id === student_id);
			if(lock_index !== -1) blockDataCopy.locked_students.splice(lock_index, 1);
		}
		
		setBlockDataCopy({...blockDataCopy});
		setChangesMade(true);
	}

	const bulkRemoveStudents = async () => {
		const options =  {
			title: "Are you sure?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div>
					<p>Do you really want to remove the selected students from this block?</p>
				</div>
			)
		}

		if(await swal(options))
		{
			const selected_students_from_section = selectedStudents.filter(student_id => blockDataCopy.student_list.includes(student_id));
			const selected_students_not_from_section = selectedStudents.filter(student_id => !blockDataCopy.student_list.includes(student_id));
			
			selected_students_from_section.forEach(student_id => {
				removeStudent(student_id);
			});

			setSelectedStudents([...selected_students_not_from_section]);
			setChangesMade(true);
		}
	}

	const saveBlock = async () => {
		//////////////////////////
		// Validate the block data
		//////////////////////////
		const course_input = document.getElementById('elem-edit-block-name');
		const start_time_input = document.getElementById('elem-edit-block-start-time');
		const end_time_input = document.getElementById('elem-edit-block-end-time');
		
		if(!course_input.value || course_input.value === 'Untitled')
		{
			setSaveError("Missing a block name!");
			return false;
		}
		else if(!start_time_input.value || start_time_input.value === '00:00:00' || !end_time_input.value || end_time_input.value === '00:00:00')
		{
			setSaveError("Missing a start/end time!");
			return false;
		}
		else if(blockDataCopy.is_pullout === '1')
		{
			const pullout_input = document.getElementById('elem-edit-block-pullout-teacher');

			if(!pullout_input.value)
			{
				setSaveError("Missing a pull out teacher!");
				return false;
			}
		}
		else if(!blockDataCopy.teacher_id)
		{
			setSaveError("Missing a specials teacher!");
			return false;
		}
		else if(!blockDataCopy.homeroom_teacher_id)
		{
			setSaveError("Missing a homeroom teacher!");
			return false;
		}

		// If course is new, check if course name already exists
		// If yes, use the existing course for this block
		// If no, add/save the new course, then use for this block
		let course_id = blockDataCopy.course_id;
		const block_name = blockDataCopy.block_name;
		const course_index = (!course_id) ? courses.findIndex(temp_course => temp_course.name === block_name) : null;

		// If course id doesn't exist, but a course of the same name exists
		course_id = (!course_id && course_index !== -1) ? courses[course_index].course_id : course_id;
		
		if(course_index === -1)
		{
			const is_special = blockDataCopy.is_special;
			const is_lunch = blockDataCopy.is_lunch;
			const is_pullout = blockDataCopy.is_pullout;
			const course_data = {name:block_name, is_special:is_special, is_lunch:is_lunch, is_pullout:is_pullout};

			const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, course_data:course_data};
			const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
			const url = '/elementary/save-course';
			const myInit = { // OPTIONAL
				response: true,
				body: JSON.stringify(data),
			};
		
			try {
				const result = await API.post(apiName, url, myInit);
				const response_data = result.data;

				course_id = response_data.new_course_id;

				courses.push({course_id:course_id, name:block_name});
				setCourses([...courses]);
			} catch(e)
			{
				console.log(e.response);
			}
		}

		// If there are multiple affected sections, ask if they want other similar sections saved
		let save_other_affected_sections = true;
		if(!blockData.new && affectedBlocks.length > 0)
		{
			const options =  {
				title: "Other Similar Blocks Found!",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Yes, save to all similar blocks",
						value: true,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "No, only save changes to this block",
						value: false,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>We have found other similar sections. Do you want to save changes to all other sections too?</p>
					</div>
				)
			}
		
		 	save_other_affected_sections = await swal(options);
		}

		const classroom_id = blockDataCopy.classroom_id;
		const end_time = blockDataCopy.end_time;
		const homeroom_teacher_id = blockDataCopy.homeroom_teacher_id;
		const is_lunch = blockDataCopy.is_lunch;
		const is_pullout = blockDataCopy.is_pullout;
		const is_special = blockDataCopy.is_special;
		const start_time = blockDataCopy.start_time;
		const teacher_id =blockDataCopy.teacher_id;
		const student_list = blockDataCopy.student_list;
		const locked_students = blockDataCopy.locked_students;

		// Save the current blocks with the new updated copied data
		let affected_blocks = [];
		affectedBlocks.forEach(affected_block => {
			const affected_block_id = affected_block.block_id;

			// If new block, save all original affected blocks
			// If not new, only save what the user says (either all blocks or just the current one)
			if(!save_other_affected_sections && affected_block_id !== blockData.block_id) return;

			const block_index = blocks.findIndex(block => block.block_id === affected_block_id);
			if(block_index !== -1) 
			{
				blocks[block_index].course_id = course_id;
				blocks[block_index].classroom_id = classroom_id;
				blocks[block_index].end_time = end_time;
				blocks[block_index].homeroom_teacher_id = homeroom_teacher_id;
				blocks[block_index].is_lunch = is_lunch;
				blocks[block_index].is_pullout = is_pullout;
				blocks[block_index].is_special = is_special;
				blocks[block_index].start_time = start_time;
				blocks[block_index].teacher_id = teacher_id;
				blocks[block_index].student_list = student_list;

				// Update locked blocks for students
				student_list.forEach(student_id => {
					const student_is_locked = locked_students.includes(student_id);

					const student_index = students.findIndex(student => student.student_id === student_id);
					if(student_index !== -1)
					{
						const student_locked_block_index = students[student_index].student_locked_blocks.findIndex(locked_block_id => locked_block_id === affected_block_id);
						if(student_is_locked && student_locked_block_index === -1)
						{
							students[student_index].student_locked_blocks.push(affected_block_id);
						}
						else if(!student_is_locked && student_locked_block_index !== -1)
						{
							students[student_index].student_locked_blocks.splice(student_locked_block_index, 1);
						}
					}
				});

				affected_blocks.push(blocks[block_index]);
			}
		});

		setBlocks([...blocks]);

		///////////////////////////////
		///// SAVE TO BACKEND //////
		////////////////////////////
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_blocks:affected_blocks, removed_blocks:removedBlocks, block_data:blockDataCopy};
		const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
		const url = '/elementary/save-block';
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(data),
		};
	
		try {
			const result = await API.post(apiName, url, myInit);
			const response_data = result.data;

			const new_block_ids = response_data.new_block_ids;

			// Change any updated block ids
			// Collect affected blocks for conflict updates
			let affected_blocks = [];
			new_block_ids.forEach(new_block_id_combo => {
				const block_index = blocks.findIndex(block => block.block_id === new_block_id_combo.old_block_id);
				if(block_index !== -1) blocks[block_index].block_id = new_block_id_combo.new_block_id;

				affected_blocks.push(new_block_id_combo.old_block_id);
				affected_blocks.push(new_block_id_combo.new_block_id);
			});
			setBlocks([...blocks]);
		} catch(e)
		{
			console.log(e.response);
		}

		closeSidePanel();
	}

	const handleExitWithoutSaving = async (save_before_exiting = false) => {
		if(changesMade)
		{
			save_before_exiting = true;

			const options =  {
				title: "Would you like to save your changes?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Exit without saving",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Continue Working",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you want to save them now?</p>
					</div>
				)
			}
		
			save_before_exiting = await swal(options);
		}
		
		if(!save_before_exiting) 
		{
			// Remove any added blocks
			addedBlocks.forEach(added_block => {
				const added_block_id = added_block.block_id;
				const block_index = blocks.findIndex(block => block.block_id === added_block_id);
				if(block_index !== -1) blocks.splice(block_index, 1);
			});

			// Add any removed blocks
			removedBlocks.forEach(removed_block => {
				blocks.push(removed_block);
			});

			if(!changesMade)
			{
				setBlocks([...blocks]);
				closeSidePanel();
				return false;
			}

			// If changes were made but user is not saving, revert the current board to have previous data
			if(blockDataImmutable) 
			{
				if(blockDataImmutable.block_id)
				{
					const course_id = blockDataImmutable.course_id;
					const classroom_id = blockDataImmutable.classroom_id;
					const end_time = blockDataImmutable.end_time;
					const homeroom_teacher_id = blockDataImmutable.homeroom_teacher_id;
					const is_lunch = blockDataImmutable.is_lunch;
					const is_pullout = blockDataImmutable.is_pullout;
					const is_special = blockDataImmutable.is_special;
					const start_time = blockDataImmutable.start_time;
					const teacher_id =blockDataImmutable.teacher_id;
					const student_list =blockDataImmutable.student_list;

					// Change all affected blocks settings back to the original settings
					affectedBlocks.forEach(affected_block => {
						const affected_block_id = affected_block.block_id;
						const block_index = blocks.findIndex(block => block.block_id === affected_block_id);
						if(block_index !== -1) 
						{
							blocks[block_index].course_id = course_id;
							blocks[block_index].classroom_id = classroom_id;
							blocks[block_index].end_time = end_time;
							blocks[block_index].homeroom_teacher_id = homeroom_teacher_id;
							blocks[block_index].is_lunch = is_lunch;
							blocks[block_index].is_pullout = is_pullout;
							blocks[block_index].is_special = is_special;
							blocks[block_index].start_time = start_time;
							blocks[block_index].teacher_id = teacher_id;
							blocks[block_index].student_list = student_list;
						}
					});
				}
			}

			setBlocks([...blocks]);
			closeSidePanel();
		}
		else if(blockData)
		{
			swal.close();
		}
	}

	const submitCheck = (e) => {
		const key_code = e.keyCode;
		if (key_code === 13)
		{
			e.preventDefault();
			saveBlock();
		}
	}

	//////////////////////
	///// USE EFFECT /////
	//////////////////////
	useEffect(() => {
		const classroom_options = classrooms.reduce((results, classroom) => {
				const classroom_id = classroom.classroom_id;
				const classroom_name = classroom.classroom_name;

				const new_option = {'value': classroom_id, 'display': classroom_name};
				results.push(new_option);
				return results;
		}, [{'value': null, display:'--'}]);

		setClassroomOptions([...classroom_options]);
	}, [classrooms]);

	useEffect(() => {
		if(showStudentSearch) 
		{
			const suggested_students = (blockDataCopy.homeroom_teacher_id) ? students.filter(student => {
				const student_homeroom_teacher_id = student.homeroom_teacher_id;
				if(student_homeroom_teacher_id && (student_homeroom_teacher_id === blockDataCopy.homeroom_teacher_id)) return true;
				return false;
			}) : [];
			setSearchResults({...searchResults, ...{suggested_students:suggested_students}, ...{students:students}});
		}
	}, [showStudentSearch, students])

	useEffect(() => {
		if(blockData) 
		{
			if(!blockDataCopied || (blockData.block_id !== blockDataCopied.block_id))
			{
				/// Get all other blocks with same data, and set them as affected blocks
				const course_id = blockData.course_id;
				const classroom_id = blockData.classroom_id;
				const end_time = blockData.end_time;
				const homeroom_teacher_id = blockData.homeroom_teacher_id;
				const is_lunch = blockData.is_lunch;
				const is_pullout = blockData.is_pullout;
				const is_special = blockData.is_special;
				const start_time = blockData.start_time;
				const teacher_id =blockData.teacher_id;

				const affected_blocks = (blockData.block_id) ? blocks.reduce((results, block) => {
					const temp_course_id = block.course_id;
					const temp_classroom_id = block.classroom_id;
					const temp_end_time = block.end_time;
					const temp_homeroom_teacher_id = block.homeroom_teacher_id;
					const temp_is_lunch = block.is_lunch;
					const temp_is_pullout = block.is_pullout;
					const temp_is_special = block.is_special;
					const temp_start_time = block.start_time;
					const temp_teacher_id =block.teacher_id;

					if(course_id === temp_course_id && classroom_id === temp_classroom_id && end_time === temp_end_time && homeroom_teacher_id === temp_homeroom_teacher_id && is_lunch === temp_is_lunch && is_pullout === temp_is_pullout && is_special === temp_is_special && start_time === temp_start_time && teacher_id === temp_teacher_id) results.push(block);

					return results;
				}, []) : [];

				// Set/Create a block id
				const block_id = (blockData.block_id) ? blockData.block_id : createUniqueObjectID(5);
				blockData.block_id = block_id;

				// If this is a brand new block, add it to affectedBlocks and blocks (to display on board)
				if(affected_blocks.findIndex(affected_block => affected_block.block_id === block_id) === -1) 
				{
					affected_blocks.push(blockData);
					addedBlocks.push(blockData);
					blocks.push(blockData);

					setBlocks([...blocks]);
					setAddedBlocks([...addedBlocks]);
				}

				// Set block name
				const course_info = (course_id) ? courses.find(course => course.course_id === course_id) : null;
				const course_name = (course_info) ? course_info.name : null;
				const course_code = (course_info) ? course_info.course_code : null;

				const block_name = (course_info) ? `${course_name} ${course_code && `(${course_code})`}` : ((blockData.is_lunch === '1') ? 'Lunch' : 'Untitled');
				blockData.block_name = block_name;

				// Set locked students
				const locked_students = blockData.student_list.reduce((results, student_id) => {
					const student_info = students.find(student => student.student_id === student_id);
					const student_locked_blocks = (student_info) ? student_info.student_locked_blocks : [];
					const student_locked = student_locked_blocks.includes(blockData.block_id);
					if(student_locked) results.push(student_id);
					return results;
				}, []);
				blockData.locked_students = locked_students;

				const copy_of_block_data = cloneObj(blockData);
				const block_data_immutable = cloneObj(blockData);

				setAffectedBlocks(affected_blocks);
				setBlockDataCopy(copy_of_block_data);
				setBlockDataImmutable(block_data_immutable);
				setBlockDatCopied(true);

				if(copy_of_block_data.is_pullout === '1' || copy_of_block_data.is_special === '1')
				{
					const homeroom_teacher_full_name = createTeacherFullName(copy_of_block_data.homeroom_teacher_id);
					const teaching_teacher_full_name = createTeacherFullName(copy_of_block_data.teacher_id);

					setHomeroomTeacherName(homeroom_teacher_full_name);
					setTeachingTeacherName(teaching_teacher_full_name);
				}
			}

			setCurrentScreen(1);
			setShowStudentSearch(false);
			setSidePanelClosing(false);
		}
	}, [blockData]);

	//console.log({blockData, blockDataCopy, affectedBlocks, removedBlocks, addedBlocks, students});
	//console.log({blocks});
	//console.log({searchResults});
	//console.log({sidePanelClosing});

	const screen_options = [{value:1, display:'Block Info'}, {value:2, display:'Students'}];
	const block_color = (!blockDataCopy) ? 'gray' : ((blockDataCopy.is_lunch === '1') ? 'pastel-yellow' : ((blockDataCopy.is_special === '1') ? 'green' :((blockDataCopy.is_pullout === '1') ? 'orange' : 'dark-purple')));

	return (
		<div className={`elem-modal-screen parent-div ${blockData ? 'open' :(sidePanelClosing ? 'close' : '')}`} ref={side_panel_ref}>
			<div id='elem-top-bar' className={block_color}>
				<div className='elem-modal-close' onClick={() => handleExitWithoutSaving(false)}>&times;</div>
				<div id='elem-top-bar-btns'>
					{(blockData && blockData.block_id && blockData.locked === '0') &&
						<div className='tooltip-left align-right' data-tooltip-text='Delete Block' style={{width:'fit-content'}} onClick={() => removeBlock(blockData.block_id)}>
							<FontAwesomeIcon icon={faTrashAlt} className='opaque-link'/>
						</div>
					}
				</div>
			</div>
			<div className='elem-modal-screen-content'>
				<div className='full-width'>
					{(!blockDataCopy || !blockData) ?
						(
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						):
						(
							<div>
								<div id='elem-edit-block-main-info'>
									<div className='elem-edit-block-label'>Course</div>
									<input id='elem-edit-block-name' value={blockDataCopy.block_name} onChange={(e) => updateState(e, 'block_name', fuzzySearch(e, 'courses', 'course_name'))} onKeyDown={submitCheck} disabled={blockData.locked === '1'}/>
									{(searchResults['course_name'].length > 0) &&
										<div className='elem-suggestion-box-parent parent-div' ref={course_search_ref}>
											<div className='elem-suggestion-box'>
												{searchResults['course_name'].map((search_result, index) => {
													const course_id = search_result.course_id;
													const course_name = search_result.name;
													const course_code = search_result.course_code;
													return (
														<div className='search-add-search-result' key={index}>
															<div>{course_code && `(${course_code}) `}{course_name}</div>
															<div className='search-add-add-btn' onClick={() => updateBlockName(course_id, course_name)}>Add</div>
														</div>
													)
												})}
											</div>
										</div>
									}
									<div className='elem-edit-block-screen-options'>
										{screen_options.map(screen_info => {
											return (
												<div className={`${currentScreen === screen_info.value && 'elem-edit-block-screen-option-selected'} elem-edit-block-screen-option`} onClick={() => setCurrentScreen(screen_info.value)} key={screen_info.value}>{screen_info.display}</div>
											)
										})}
									</div>
									<div className={currentScreen !== 1 ? 'hide' : 'elem-edit-block-screen'}>
										<div id='elem-edit-block-time-container'>
											<input id='elem-edit-block-start-time' type='time' min='07:00' max='16:00' value={blockDataCopy.start_time} onChange={(e) => updateState(e, 'start_time')} onKeyDown={submitCheck} disabled={blockData.locked === '1'}/>
											<div>to</div>
											<input id='elem-edit-block-end-time' type='time' min='07:00' max='16:00' value={blockDataCopy.end_time} onChange={(e) => updateState(e, 'end_time')} onKeyDown={submitCheck} disabled={blockData.locked === '1'}/>
										</div>
										<div id='elem-edit-block-days-container'>
											{days.map((day, index) => {
												const day_included_in_affected_blocks = affectedBlocks.reduce((results, affected_block) => {
													if(affected_block.day_number == day) results = true;
													return results;
												}, false);

												return (
													<div key={index}>
														<div>Day {day}</div>
														{(day_included_in_affected_blocks) ? 
															(
																<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare} onClick={blockData.locked === '0' ? () => updateDays('remove', day) : null}/>
															) : 
															(
																<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare} onClick={blockData.locked === '0' ? () => updateDays('add', day) : null}/>
															)
														}
													</div>
												)
											})}
										</div>
										{timeErrorMessage && <div className='error-message-no-box'>{timeErrorMessage}</div>}
										{(blockDataCopy.is_pullout === '1') &&
											<div id='elem-edit-block-pull-out-teacher-container'>
												<div className='elem-edit-block-label'>Pull Out Teacher</div>
												<input id='elem-edit-block-pullout-teacher' value={teachingTeacherName} onChange={(e) => handleExtraTeacherSearch(e, 'teaching_teacher')} disabled={blockData.locked === '1'}/>
												{teachingTeacherError && <div className='error-message-no-box'>{teachingTeacherError}</div>}
												{(searchResults['teaching_teacher'].length > 0) &&
													<div className='elem-suggestion-box-parent parent-div' ref={teaching_teachers_ref}>
														<div className='elem-suggestion-box'>
															{searchResults['teaching_teacher'].map((search_result, index) => {
																const search_teacher_id = search_result.teacher_id;
																const search_teacher_first_name = search_result.first_name;
																const search_teacher_last_name = search_result.name;
																const search_teacher_full_name =  `${search_teacher_last_name}, ${search_teacher_first_name}`;

																return (
																	<div className='search-add-search-result' key={index}>
																		<div>{search_teacher_full_name}</div>
																		<div className='search-add-add-btn' onClick={() => updatePullOutTeacher(search_teacher_id)}>Add</div>
																	</div>
																)
															})}
														</div>
													</div>
												}
											</div>
										}
										{(blockDataCopy.is_special === '1') &&
											<div id='elem-edit-block-special-teacher-container'>
												<div className='elem-edit-block-label'>Special Teacher</div>
												<input id='elem-edit-block-specials-teacher' value={teachingTeacherName} onChange={(e) => handleExtraTeacherSearch(e, 'teaching_teacher')} disabled={blockData.locked === '1'}/>
												{teachingTeacherError && <div className='error-message-no-box'>{teachingTeacherError}</div>}
												{(searchResults['teaching_teacher'].length > 0) &&
													<div className='elem-suggestion-box-parent parent-div' ref={teaching_teachers_ref}>
														<div className='elem-suggestion-box'>
															{searchResults['teaching_teacher'].map((search_result, index) => {
																const search_teacher_id = search_result.teacher_id;
																const search_teacher_first_name = search_result.first_name;
																const search_teacher_last_name = search_result.name;
																const search_teacher_full_name =  `${search_teacher_last_name}, ${search_teacher_first_name}`;

																return (
																	<div className='search-add-search-result' key={index}>
																		<div>{search_teacher_full_name}</div>
																		<div className='search-add-add-btn' onClick={() => updateExtraTeacher(search_teacher_id, 'teaching_teacher')}>Add</div>
																	</div>
																)
															})}
														</div>
													</div>
												}
												<div className='elem-edit-block-label'>Homeroom Teacher</div>
												<input id='elem-edit-block-homeroom-teacher' value={homeroomTeacherName} onChange={(e) => handleExtraTeacherSearch(e, 'homeroom_teacher')} disabled={blockData.locked === '1'}/>
												{homeroomTeacherError && <div className='error-message-no-box'>{homeroomTeacherError}</div>}
												{(searchResults['homeroom_teacher'].length > 0) &&
													<div className='elem-suggestion-box-parent parent-div' ref={homeroom_teachers_ref}>
														<div className='elem-suggestion-box'>
															{searchResults['homeroom_teacher'].map((search_result, index) => {
																const search_teacher_id = search_result.teacher_id;
																const search_teacher_first_name = search_result.first_name;
																const search_teacher_last_name = search_result.name;
																const search_teacher_full_name =  `${search_teacher_last_name}, ${search_teacher_first_name}`;

																return (
																	<div className='search-add-search-result' key={index}>
																		<div>{search_teacher_full_name}</div>
																		<div className='search-add-add-btn' onClick={() => updateExtraTeacher(search_teacher_id, 'homeroom_teacher')}>Add</div>
																	</div>
																)
															})}
														</div>
													</div>
												}
											</div>
										}
										<div className='elem-edit-block-label'>Classroom</div>
										<Dropdown data={classroomOptions} currentValue={blockDataCopy.classroom_id} handleChange={(new_value) => updateState(new_value, 'classroom_id')} disabled={blockData.locked === '1'}/>
										</div>
										<div className={currentScreen !== 2 ? 'hide' : 'elem-edit-block-screen'}>
											{(blockData.locked === '0' && blockDataCopy.student_list.length > 0) &&
												<div className='select-all-row parent-div'>
													<div className={`select-all-container parent-div ${(selectedStudents.length > 0) && 'blue-text'}`} onClick={toggleSelectAll}>
														<FontAwesomeIcon icon={(selectedStudents.length > 0) ? faMinusSquare : farSquare}/>
														<FontAwesomeIcon className='select-all-arrow' icon={faSortDown}/>
													</div>
													{(selectedStudents.length > 0) &&
														<div className='select-all-actions-container parent-div'>
															<div className='tooltip' data-tooltip-text='Lock students into section' onClick={() => toggleLockStudents('lock')}>
																<FontAwesomeIcon className='select-all-action-btn tooltip' icon={faLock}/>
															</div>
															<div className='tooltip' data-tooltip-text='Remove students' onClick={bulkRemoveStudents}>
																<FontAwesomeIcon className='select-all-action-btn tooltip' icon={faTrashAlt}/>
															</div>
														</div>
													}
												</div>
											}
											<div className='elem-edit-block-student-list'>
												{blockDataCopy.student_list.map(student_id => {
													const student_info = students.find(student => student.student_id === student_id);
													const student_first_name = (student_info) ? student_info.first_name : null;
													const student_last_name = (student_info) ? student_info.last_name : null;
													const student_grade = (student_info) ? student_info.grade : null;
													const student_code = (student_info) ? student_info.student_code : null;
													const student_locked = (blockDataCopy.locked_students.includes(student_id) || blockData.locked === '1');
													const opposite_locked_type = student_locked ? 'unlock' : 'lock';
													const student_selected = selectedStudents.includes(student_id);

													const student_is_pulled_out = blocks.reduce((results, other_block) => {
														const other_block_id = other_block.block_id;
														const other_block_is_pullout = other_block.is_pullout === '1';
														const other_block_student_list = other_block.student_list;

														// If same block, skip it
														if(blockData.block_id === other_block_id) return results;
														// If student not in other block, skip it
														if(!other_block_student_list.includes(student_id)) return results;

														// Check if the two blocks overlap
														const block_data_time = {start_time:blockDataCopy.start_time, end_time:blockDataCopy.end_time};
														const other_block_time = {start_time:other_block.start_time, end_time:other_block.end_time};
														
														// If other block is pull out and the blocks overlap, show that student is pulled out
														if(other_block_is_pullout && overlapCheck(block_data_time, other_block_time)) results = true;
														return results;
													}, false);

													return (
														<div className='elem-edit-block-student parent-div' key={student_id}>
															<FontAwesomeIcon className={student_locked ? 'light-gray-text dropdown_disabled' : (student_selected ? 'blue-text' : 'dark-gray-text')} icon={student_locked ? faSquare : (student_selected ? faCheckSquare : farSquare)} onClick={student_locked ? ()=>{} : () => toggleSelectStudent(student_id)}/>
															<div>
																<div className='elem-edit-block-student-name'>{student_last_name}, {student_first_name} {student_grade && student_grade} {student_code && `(${student_code})`} {student_is_pulled_out && '(Pulled Out)'}</div>
																<FontAwesomeIcon icon={faLock} className={`edit-section-lock-student-icon cursor-pointer ${student_locked ? 'bright-yellow-text' : 'gray-text'}`} onClick={() => toggleLockStudents(opposite_locked_type, student_id)}/>
															</div>
															{(!student_locked) &&
																<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => removeStudent(student_id)}/>
															}
														</div>
													)
												})}
											</div>
											{(blockData.locked === '0') &&
												<div className='edit-section-add-new-btn blue-link' onClick={() => setShowStudentSearch(!showStudentSearch)}>
													<FontAwesomeIcon icon={faPlusCircle}/>
													<div>Add Student</div>
												</div>
											}
											{showStudentSearch && 
												<div className='search-add-row-container'>
													<div className='search-add-search-container'>
														<div className='search-add-adding-input-container'>
															<input className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'students')} placeholder='Search for students' autoFocus={true}/>
															<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
														</div>
														<div className='cancel-student-search parent-div' onClick={() => setShowStudentSearch(false)}><FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes}/></div>
														
													</div>
													{searchResults.students.length !== 0 &&
														<>
														<div className='search-add-search-results' style={{marginTop:'20px', padding:'0px'}}>
															{(searchResults.suggested_students.length > 0) &&
																<div className='search-add-did-you-mean'>Students in this Homeroom:</div>
															}
															{searchResults.suggested_students.map((search_student, i) => {
																const student_in_section_index = blockDataCopy.student_list.findIndex(student_id => student_id === search_student.student_id);
																const student_in_section = (student_in_section_index !== -1) ? true : false;
																const student_locked = blockDataCopy.locked_students.includes(search_student.student_id);

																return (
																	<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr auto', textAlign:'left'}}>
																		<div>{search_student.last_name}, {search_student.first_name}</div>
																		<div>
																			{(student_locked) ? 
																				(
																					<span className='bright-yellow-text' >Locked <FontAwesomeIcon icon={faLock} style={{margin:'0px 5px'}}/></span>
																				):(student_in_section) ? 
																				(
																					<div className='search-add-add-btn student-search-btn red' onClick={() => removeStudent(search_student.student_id)}>Remove</div>
																				): 
																				(
																					<div className='search-add-add-btn student-search-btn' onClick={() => addStudent(search_student.student_id)}>Add</div>
																				)
																			}
																		</div>
																	</div>
																)
															})}
															{(searchResults.students.length > 0) &&
																<div className='search-add-did-you-mean'>Other Students:</div>
															}
															{searchResults.students.map((search_student, i) => {
																const search_student_first_name = search_student.first_name;
																const search_student_last_name = search_student.last_name;
																const search_student_grade = search_student.grade;
																const search_student_code = search_student.student_code;
																const student_in_section_index = blockDataCopy.student_list.findIndex(student_id => student_id === search_student.student_id);
																const student_in_section = (student_in_section_index !== -1) ? true : false;
																const student_locked = blockDataCopy.locked_students.includes(search_student.student_id);

																return (
																	<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr auto', textAlign:'left'}}>
																		<div>{search_student_last_name}, {search_student_first_name} {search_student_grade && search_student_grade} {search_student_code && `(${search_student_code})`}</div>
																		<div>
																			{(student_locked) ? 
																				(
																					<span className='bright-yellow-text' >Locked <FontAwesomeIcon icon={faLock} style={{margin:'0px 5px'}}/></span>
																				):(student_in_section) ? 
																				(
																					<div className='search-add-add-btn student-search-btn red' onClick={() => removeStudent(search_student.student_id)}>Remove</div>
																				): 
																				(
																					<div className='search-add-add-btn student-search-btn' onClick={() => addStudent(search_student.student_id)}>Add</div>
																				)
																			}
																		</div>
																	</div>
																)
															})}
														</div>
														</>
													}
												</div>
											}
										</div>
								</div>
							</div>
						)
					}
				</div>
			</div>
			<div id='elem-edit-block-save-row'>
				{(blockData && blockDataCopy) &&
					<>
					{(blockData.locked === '1') ? 
						(
							<div><FontAwesomeIcon className='bright-yellow-text' icon={faLock} style={{marginRight:'10px'}}/> This block is current locked</div>
						):
						(
							<>
							{saveError && <div className='error-message-no-box' style={{marginBottom:'10px'}}>{saveError}</div>}
							<div className={`btn btn-large ${block_color}-btn`} onClick={saveBlock}>Save</div>
							</>
						)
					}
					</>
				}
			</div>
		</div>
	);
}