import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import Fuse from 'fuse.js';

import Dropdown from '../../Dropdown';
import EditTeacherData from '../modals/edit_teacher';

import { formatTime, sortArrayOfObjects, getData, getBlockConnections } from '../../../js/Helpers';
import { saveDataToDatabase } from '../../../js/SaveFunctions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faLock, faCheckSquare, faSquare, faTimes, faSearch, faCalendarDays } from '@fortawesome/free-solid-svg-icons';

import '../../../css/elementary/auto_scheduling_setup.css';

export default function SpecialsCourses({ isLoading, schoolSubscribed, subscreenLocked, subscreenRestricted, schoolInfo, scheduleInfo, periods, courses, setCourses, teachers, setTeachers, students, setStudents, classrooms, blocks, setBlocks, handleMoveToNextScreen }) {
	const [selectedCourseID, setSelectedCourseID] = useState(null);
	const [openSearches, setOpenSearches] = useState({teachers:false, classrooms:false, homeroom_teachers: false})
	const [searchResults, setSearchResults] = useState({courses:[], teachers:[], classrooms:[], homeroom_teachers:[]});
	const [days, setDays] = useState([]);
	const [autoGeneratingSpecialsInProgress, setAutoGeneratingSpecialsInProgress] = useState(false);


	const [showEditDataModal, setShowEditDataModal] = useState(false);
	const [currentDataID, setCurrentDataID] = useState(null);

	const group_positions = {
		'1':{'0':{start:'1', end:'120'}}, 
		'2':{'0':{start:'1', end:'58'},'1':{start:'62', end:'120'}}, 
		'3':{'0':{start:'1', end:'38'},'1':{start:'41', end:'78'},'2':{start:'82', end:'120'}}, 
		'4':{'0':{start:'1', end:'27'},'1':{start:'32', end:'58'},'2':{start:'63', end:'89'},'3':{start:'94', end:'120'}}
	};
	
	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const updateCourse = (course_id, data_index, new_value, change_type = 'change') => {
		const course_index = courses.findIndex(course => course.course_id === course_id);

		if(course_index === -1) return;

		if(data_index === 'teachers_can_teach')
		{
			const tct_index = courses[course_index]['teachers_can_teach'].findIndex(tct => tct.teacher_id === new_value);
			if(change_type ==='add' && tct_index === -1) 
			{
				courses[course_index]['teachers_can_teach'].push({teacher_id:new_value, type:'1'});
			}
			else if(change_type ==='remove' && tct_index !== -1) 
			{
				courses[course_index]['teachers_can_teach'].splice(tct_index, 1);
			}

			openSearches['teachers'] = false;
		}
		else if(data_index === 'classroom_restrictions')
		{
			const cr_index = courses[course_index]['classroom_restrictions'].findIndex(classroom_id => classroom_id === new_value);
			if(change_type === 'add' && cr_index === -1) 
			{
				courses[course_index]['classroom_restrictions'].push(new_value);
			}
			else if(change_type ==='remove' && cr_index !== -1) 
			{
				courses[course_index]['classroom_restrictions'].splice(cr_index, 1);
			}

			openSearches['classrooms'] = false;
		}
		else if(data_index === 'course_homerooms')
		{
			const hwns_index = courses[course_index]['course_homerooms'].findIndex(teacher_id => teacher_id === new_value);
			if(change_type === 'add' && hwns_index === -1) 
			{
				courses[course_index]['course_homerooms'].push(new_value);
			}
			else if(change_type ==='remove' && hwns_index !== -1) 
			{
				courses[course_index]['course_homerooms'].splice(hwns_index, 1);
			}

			openSearches['homeroom_teachers'] = false;
		}
		else if(data_index === 'day_period_restrictions')
		{
			const dpr_index = courses[course_index]['day_period_restrictions'].findIndex(day_period_restriction => day_period_restriction.day_number === new_value.day_number && day_period_restriction.period_id === new_value.period_id);
			if(change_type === 'add' && dpr_index === -1) 
			{
				courses[course_index]['day_period_restrictions'].push(new_value);
			}
			else if(change_type ==='remove' && dpr_index !== -1) 
			{
				courses[course_index]['day_period_restrictions'].splice(dpr_index, 1);
			}
		}
		else 
		{
			courses[course_index][data_index] = new_value;
		}

		setCourses([...courses]);
		setOpenSearches({...openSearches});
		
		saveDataToDatabase({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'course', course_data:courses[course_index], database:'elementary'});
	}

	const calculatePeriodColumnStartEnd = (period_data, day_schedule, logged_positions, start_time, end_time) => {
		const overlapCheck = (time1, time2) => {
			const start_time1 = time1.start_time;
			const end_time1 = time1.end_time;
			const start_time1_array = start_time1.split(':');
			const end_time1_array = end_time1.split(':');

			const start_time1_number = parseInt(start_time1_array[0] + start_time1_array[1]);
			const end_time1_number = parseInt(end_time1_array[0] + end_time1_array[1]);

			const start_time2 = time2.start_time;
			const end_time2 = time2.end_time;
			const start_time2_array = start_time2.split(':');
			const end_time2_array = end_time2.split(':');

			const start_time2_number = parseInt(start_time2_array[0] + start_time2_array[1]);
			const end_time2_number = parseInt(end_time2_array[0] + end_time2_array[1]);

			if(end_time1_number > start_time2_number && end_time2_number > start_time1_number) return true;

			return false;
		}

		const period_id = period_data.period_id;

		///// Calculate left and right position
		// Get max number of overlaps
		const period_time = {period_id:period_id, start_time:start_time, end_time:end_time};

		let overlapping_time_groups = [[period_time]];
		for(var i = 0; i < day_schedule.length; i++)
		{
			const new_period = day_schedule[i];
			const new_period_id = new_period.period_id;

			if(period_id === new_period_id) continue; //ignore the original period itself

			const new_time = {period_id:new_period_id, start_time:new_period.start_time, end_time:new_period.end_time};
			const new_time_overlaps_original = overlapCheck(period_time, new_time);

			if(!new_time_overlaps_original) continue; //ignore periods that don't even overlap original

			// Go through all overlap groups and see if new period overlaps all of the times in the current groups
			// If yes, add to that group
			// If no, create a new group
			for(var j = 0; j < overlapping_time_groups.length; j++)
			{
				const overlapping_time_group = overlapping_time_groups[j];

				// Ignore a period that has already been added to this group
				const new_time_index = overlapping_time_group.findIndex(overlapping_time => overlapping_time.period_id === new_period_id);
				if(new_time_index !== -1) continue;

				let new_time_overlaps_entire_group = true;
				
				for(var k = 0; k < overlapping_time_group.length; k++)
				{
					const time_to_check = overlapping_time_group[k];
					const time_overlaps = overlapCheck(time_to_check, new_time);

					if(!time_overlaps) new_time_overlaps_entire_group = false;
				}

				if(new_time_overlaps_entire_group)
				{
					overlapping_time_groups[j].push(new_time);
				}
				else
				{
					overlapping_time_groups.push([period_time, new_time]);
				}
			}
		}

		const overall_max_stats = overlapping_time_groups.reduce((results, overlapping_time_group) => {
			const overlapping_time_group_length = overlapping_time_group.length;
			if(overlapping_time_group_length > results.max_overlaps) results = {max_overlaps: overlapping_time_group_length, overlapping_periods:overlapping_time_group};
			return results;
		}, {max_overlaps:0, overlapping_periods:[]});

		/// Use max overlaps to get the possible positions (preset depending on number of overlaps)
		const max_overlaps = overall_max_stats.max_overlaps;
		const overlapping_periods = overall_max_stats.overlapping_periods;

		const possible_positions = group_positions[max_overlaps];
		const possible_positions_indexes = Object.keys(possible_positions);
		const used_positions = overlapping_periods.reduce((results, period_times) => {
			const overlapping_period_id = period_times.period_id;
			const used_position = (overlapping_period_id in logged_positions) ? logged_positions[overlapping_period_id] : null;
			if(used_position) results.push(used_position);
			return results;
		},[]);
		const unused_positions = possible_positions_indexes.filter(x => used_positions.indexOf(x) === -1);
		const position_index = unused_positions[0];
		const start_position = possible_positions[position_index].start;
		const end_position = possible_positions[position_index].end;

		return {position_index:position_index, col_start:start_position, col_end:end_position};
	}

	const toggleAddNewConnection = (searchType) => {
		const new_value = !openSearches[searchType];

		if(new_value) searchResults[searchType] = (searchType === 'teachers' || searchType === 'homeroom_teachers') ? teachers : classrooms;

		openSearches[searchType] = new_value;

		setSearchResults({...searchResults});
		setOpenSearches({...openSearches});
	}
	
	const toggleEditData = (dataID) => {
		setCurrentDataID(dataID);
		setShowEditDataModal(!showEditDataModal);
	}

	const fuzzySearch = (e, dataType, dataKey = dataType) => {
		const search_value = e.target.value;
		
		if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const course_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : courses;
			
			searchResults[dataKey] = course_results;
		}
		else if(dataType === 'teachers')
		{
			const fuse = new Fuse(teachers, {
				keys: ['first_name', 'name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const teacher_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : teachers;
			
			searchResults[dataKey] = teacher_results;
		}
		else if(dataType === 'classrooms')
		{
			const fuse = new Fuse(classrooms, {
				keys: ['name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const classroom_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : classrooms;
			
			searchResults[dataKey] = classroom_results;
		}
		else if(dataType === 'homeroom_teachers')
		{
			const fuse = new Fuse(teachers, {
				keys: ['first_name', 'name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const teacher_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : teachers;
			
			searchResults[dataKey] = teacher_results;
		}

		setSearchResults({...searchResults});
	}

	const pullNewBlocks = (_callback = ()=>{}) => {
		getData('blocks', '/elementary/get-blocks', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true, 'elementary').then(blocks_without_connections => {
			getBlockConnections(schoolInfo.school_id, schoolInfo.current_schedule_version_id).then(connections => {
				let blocks_with_connections = [];

				blocks_without_connections.forEach((specific_data, index) => {
					const block_id = specific_data.block_id;

					const student_list = (block_id in connections.students_in_blocks) ? connections.students_in_blocks[block_id] : [];
					specific_data.student_list = student_list;

					blocks_with_connections[index] = specific_data;
				});

				setBlocks(blocks_with_connections);
				_callback();
			});
		});
	}

	const autoPlaceSpecials = async () => {

		if(!schoolSubscribed)
		{
			alert("This feature is only available to schools with a paid subscription! Please contact us at contact@edario.com to activate auto-placing specials.");
			return false;
		}

		// Set auto generating in progress
		setAutoGeneratingSpecialsInProgress(true);

		/// Update the BACKEND ///
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id};
		const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
		const url = '/elementary/auto-place-specials';
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(data),
		};
	
		try {
			await API.post(apiName, url, myInit);

			// Pull new blocks and then move to next screen
			pullNewBlocks(() => {
				// Move on to the next screen
				handleMoveToNextScreen();
			});
		} catch(e)
		{
			console.log(e.response);
		}
	}
	
	////////////////////////////////
	///// USE EFFECT /////
	////////////////////////////////
	// Set the first special as the selected course id
	useEffect(() => {
		if(selectedCourseID) return;
		const specials = courses.filter(course => course.is_special === '1');

		if(specials.length > 0)
		{
			const selected_course_id = specials[0].course_id;
			setSelectedCourseID(selected_course_id);
		}
	}, [courses]);

	useEffect(() => {
		const num_days_in_cycle = scheduleInfo.num_days_in_cycle;

		let new_days = [];

		for(let day = 1; day <= num_days_in_cycle; day++)
		{
			new_days.push(`${day}`);
		}

		setDays(new_days);
	}, [scheduleInfo]);

	////////////////////////////////
	///// INITIALIZE VARIABLES /////
	////////////////////////////////
	const numTimesNeededOptions = [
		{value:null, display: 'No Limit'},
		{value:'1', display: '1'},
		{value:'2', display: '2'},
		{value:'3', display: '3'},
		{value:'4', display: '4'},
		{value:'5', display: '5'},
		{value:'6', display: '6'},
		{value:'7', display: '7'},
		{value:'8', display: '8'},
		{value:'9', display: '9'},
		{value:'10', display: '10'},
		{value:'11', display: '11'},
		{value:'12', display: '12'},
	];

	const courseInfo = (selectedCourseID) ? courses.find(course => course.course_id === selectedCourseID) : null;
	const specialName = (courseInfo) ? courseInfo.name : null;
	const numTimesNeeded = (courseInfo) ? courseInfo.num_times_needed : null;
	const classroomRestrictions = (courseInfo) ? courseInfo.classroom_restrictions : [];
	const teachersCanTeach = (courseInfo) ? courseInfo.teachers_can_teach : [];
	const courseHomerooms = (courseInfo) ? courseInfo.course_homerooms : [];

	const num_cols = days.length;
	const day_period_grid = {gridTemplateColumns : `repeat(${num_cols}, 120px)`};
	const specialsCourses = courses.filter(course => course.is_special === '1');

	//console.log({courseInfo});

	return (
		<>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(isLoading) ?
		(
			<div className='center-text'>
				<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'25px'}}/>
			</div>
		):
		(
			<>
			{showEditDataModal &&
				<EditTeacherData schoolInfo={schoolInfo} dataID={currentDataID} toggleEditData={toggleEditData} scheduleInfo={scheduleInfo} periods={periods} teachers={teachers} courses={courses} classrooms={classrooms} students={students} setStudents={setStudents} setDatabaseData={setTeachers} filteredResults={teachers}/>
			}
			<div className='mb-elem-container'>
				<h1 className='progress-main-header'>Specials Setup</h1>
				{autoGeneratingSpecialsInProgress ?
					(
						<div className='section-review-missing-requests-container'>
							<div id='custom-section-creation-loader-container'>
								<div className='custom-section-creation-loader-column'>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top pastel-orange'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top pastel-orange'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
								</div>
								<div className='custom-section-creation-loader-column'>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top yellow'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top yellow'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top yellow'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
								</div>
								<div className='custom-section-creation-loader-column'>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top pastel-green'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
								</div>
								<div className='custom-section-creation-loader-column'>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top pastel-light-blue'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top pastel-light-blue'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
								</div>
							</div>
							<h2>Specials are being placed...</h2>
							<div>We are currently placing specials. Please do not refresh the page while this processes.</div>
						</div>
					) :
					(
						<>
						{specialsCourses.length === 0 &&
							<div className='specials-setup-empty-courses'>You have no specials courses to display.</div>
						}
						<div className='specials-setup-option-container'>
							{specialsCourses.map((course, course_index) => {
								const course_id = course.course_id;
								const course_name = course.name;
								const course_code = course.course_code;

								return (
									<div className={`specials-setup-course-option ${course_id === selectedCourseID && 'specials-setup-course-option-selected'}`} key={course_index} onClick={() => setSelectedCourseID(course_id)}>{course_name} {course_code && `(${course_code}) `} </div>
								)
							})}
						</div>
						<div style={{marginTop:'16px'}}>Missing Specials? <Link className='blue-text' to={{pathname:`/app/elementary/school_data/courses/`, redirect:`/app/elementary/progress/auto_scheduling_setup/specials_setup`}}>Review your Courses</Link></div>
						{selectedCourseID &&
							<div className='specials-setup-specials-container'>
								<div className='specials-setup-question-container'>
									<div>
										<div className='specials-setup-label specials-setup-name'>Special Name</div>
										<div className='specials-setup-special-name'>{specialName}</div>
									</div>
								</div>
								<div className='specials-setup-question-container'>
									<div>
										<div className='specials-setup-label'>Who can teach this course?</div>
										{teachersCanTeach.length === 0 &&
											<div className='specials-setup-display'>No teachers selected yet...</div>
										}
										{teachersCanTeach.map((teacher_can_teach, tct_index) => {
											const teacher_id = teacher_can_teach.teacher_id;
											const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === teacher_id);
											const teacher_info = (teacher_index !== -1) ? teachers[teacher_index] : null;

											if(!teacher_info) return;

											const teacher_last_name = teacher_info.name;
											const teacher_first_name = teacher_info.first_name;

											return (
												<div className='specials-setup-tct' key={tct_index}>
													<div className='specials-setup-display'>
														<div>{teacher_last_name}, {teacher_first_name}</div>
														<div className='cursor-pointer' onClick={() => updateCourse(selectedCourseID, 'teachers_can_teach', teacher_id, 'remove')}><FontAwesomeIcon icon={faTimes}/></div>
													</div>
													<FontAwesomeIcon className='blue-link' icon={faCalendarDays} onClick={() => toggleEditData(teacher_id)}/>
												</div>
												
											)
										})}
										{(openSearches.teachers) &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'teachers')} placeholder='Search for teacher'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('teachers')}/>
												</div>
												{searchResults['teachers'].length > 0 &&
													<div className='elem-suggestion-box-parent parent-div'>
														<div className='elem-suggestion-box'>
															{searchResults['teachers'].map((search_result, index) => {
																const search_teacher_id = search_result.teacher_id;
																const search_teacher_first_name = search_result.first_name;
																const search_teacher_last_name = search_result.name;
																const search_teacher_full_name =  `${search_teacher_last_name}, ${search_teacher_first_name}`;

																return (
																	<div className='search-add-search-result' key={index}>
																		<div>{search_teacher_full_name}</div>
																		<div className='search-add-add-btn' onClick={() => updateCourse(selectedCourseID, 'teachers_can_teach', search_teacher_id, 'add')}>Add</div>
																	</div>
																)
															})}
														</div>
													</div>
												}
											</div>
										}
										<div className='blue-link medium-text specials-setup-add-link' onClick={() => toggleAddNewConnection('teachers')}><FontAwesomeIcon icon={faCirclePlus}/> Add a Teacher</div>
									</div>
								</div>
								<div className='specials-setup-question-container'>
									<div>
										<div className='specials-setup-label'>Which classrooms does this course use?</div>
										{classroomRestrictions.length === 0 &&
											<div className='specials-setup-display'>No classrooms selected yet...</div>
										}
										{classroomRestrictions.map((classroom_id, cr_index) => {
											const classroom_index = classrooms.findIndex(classroom => classroom.classroom_id === classroom_id);
											const classroom_info = (classroom_index !== -1) ? classrooms[classroom_index] : null;

											if(!classroom_info) return;

											const classroom_name = classroom_info.classroom_name;

											return (
												<div className='specials-setup-display' key={cr_index}>
													<div>{classroom_name}</div>
													<div className='cursor-pointer' onClick={() => updateCourse(selectedCourseID, 'classroom_restrictions', classroom_id, 'remove')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)
										})}
										{(openSearches.classrooms) &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'classrooms')} placeholder='Search for classroom'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('classrooms')}/>
												</div>
												{searchResults['classrooms'].length > 0 &&
													<div className='elem-suggestion-box-parent parent-div'>
														<div className='elem-suggestion-box'>
															{searchResults['classrooms'].map((search_result, index) => {
																const search_classroom_id = search_result.classroom_id;
																const search_classroom_name = search_result.classroom_name;

																return (
																	<div className='search-add-search-result' key={index}>
																		<div>{search_classroom_name}</div>
																		<div className='search-add-add-btn' onClick={() => updateCourse(selectedCourseID, 'classroom_restrictions', search_classroom_id, 'add')}>Add</div>
																	</div>
																)
															})}
														</div>
													</div>
												}
											</div>
										}
										<div className='blue-link specials-setup-add-link' onClick={() => toggleAddNewConnection('classrooms')}><FontAwesomeIcon icon={faCirclePlus}/> Add a Classroom</div>
									</div>
								</div>
								<div className='specials-setup-question-container'>
									<div>
										<div className='specials-setup-label'>How many times does a homeroom need this per cycle?</div>
										<Dropdown data={numTimesNeededOptions} currentValue={numTimesNeeded} handleChange={(new_value) => updateCourse(selectedCourseID, 'num_times_needed', new_value)} />
									</div>
								</div>
								<div className='specials-setup-question-container'>
									<div>
										<div className='specials-setup-label'>Which homerooms need this special?</div>
										{courseHomerooms.length === 0 &&
											<div className='specials-setup-display'>No homerooms selected yet...</div>
										}
										{courseHomerooms.map((homeroom_teacher_id, homeroom_index) => {
											const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === homeroom_teacher_id);
											const teacher_info = (teacher_index !== -1) ? teachers[teacher_index] : null;

											if(!teacher_info) return;

											const teacher_last_name = teacher_info.name;
											const teacher_first_name = teacher_info.first_name;

											return (
												<div className='specials-setup-display' key={homeroom_index}>
													<div>{teacher_last_name}, {teacher_first_name}</div>
													<div className='cursor-pointer' onClick={() => updateCourse(selectedCourseID, 'course_homerooms', homeroom_teacher_id, 'remove')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)
										})}
										{(openSearches.homeroom_teachers) &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'homeroom_teachers')} placeholder='Search for homeroom teacher'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('homeroom_teachers')}/>
												</div>
												{searchResults['homeroom_teachers'].length > 0 &&
													<div className='elem-suggestion-box-parent parent-div'>
														<div className='elem-suggestion-box'>
															{searchResults['homeroom_teachers'].map((search_result, index) => {
																const search_teacher_id = search_result.teacher_id;
																const search_teacher_first_name = search_result.first_name;
																const search_teacher_last_name = search_result.name;
																const search_teacher_full_name =  `${search_teacher_last_name}, ${search_teacher_first_name}`;

																return (
																	<div className='search-add-search-result' key={index}>
																		<div>{search_teacher_full_name}</div>
																		<div className='search-add-add-btn' onClick={() => updateCourse(selectedCourseID, 'course_homerooms', search_teacher_id, 'add')}>Add</div>
																	</div>
																)
															})}
														</div>
													</div>
												}
											</div>
										}
										<div className='blue-link medium-text specials-setup-add-link' onClick={() => toggleAddNewConnection('homeroom_teachers')}><FontAwesomeIcon icon={faCirclePlus}/> Add a Homeroom Teacher</div>
									</div>
								</div>
								<div className='specials-setup-question-container'>
									<div>
										<div className='specials-setup-label'>When can this special run?</div>
										<div className='mb-elem-teacher-schedule-mini'>
											<div className='mb-elem-week-schedule-container-mini'>
												<div className='mb-elem-week-schedule-time-container-mini'>
													<div className='mb-elem-time-delimiter-mini'>
														<div>7:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>8:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>9:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>10:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>11:00AM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>12:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>1:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>2:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>3:00PM</div>
													</div>
													<div className='mb-elem-time-delimiter-mini'>
														<div>4:00PM</div>
													</div>
												</div>
												<div className='mb-elem-week-schedule-days-container-mini' style={day_period_grid}>
													{days.map(day_number => {
														const day_name = `Day ${day_number}`;

														const day_schedule = periods.filter(period => period.days.includes(day_number) && period.is_special === '1');

														///// Organize all the periods into overlapping groups /////
														// Any non overlapping periods get thrown into their own group
														const day_schedule_with_overlaps = day_schedule.reduce((results, period) => {
															const period_start_time = period.start_time;
															const period_end_time = period.end_time;

															const period_start_time_array = period_start_time.split(':');
															const period_end_time_array = period_end_time.split(':');

															const period_start_time_hour = parseInt(period_start_time_array[0]);
															const period_start_time_min = parseInt(period_start_time_array[1]);
															const period_start_num_hours = period_start_time_hour - 7;
															const period_row_start = Math.max(1, period_start_num_hours*60 + period_start_time_min);

															const period_end_time_hour = parseInt(period_end_time_array[0]);
															const period_end_time_min = parseInt(period_end_time_array[1]);
															const period_end_num_hours = period_end_time_hour - 7;
															const period_row_end = Math.max(1, period_end_num_hours*60 + period_end_time_min);

															period.row_start = period_row_start;
															period.row_end = period_row_end;
															period.period_length = period_row_end - period_row_start;

															results.push(period);
															return results;
														},[]);

														const sorted_day_schedule_with_overlaps = sortArrayOfObjects(day_schedule_with_overlaps, 'period_length', 'number', 'asc');

														const logged_positions = {};

														return (
															<div className='mb-elem-week-day-container-mini' key={`${day_number}`}>
																<h3>{day_name}</h3>
																<div className='mb-elem-week-day-block-parent-container-mini'>
																	<div className='mb-elem-week-day-grid-mini'>
																		{sorted_day_schedule_with_overlaps.map(period => {
																			const period_id = period.period_id;
																			const period_name = period.period_name;
																			const is_lunch = period.is_lunch;
																			const is_special = period.is_special;
																			
																			const start_time = period.start_time;
																			const end_time = period.end_time;
																			const start_time_formatted = formatTime(start_time);
																			const end_time_date_formatted = formatTime(end_time);

																			const row_start = period.row_start;
																			const row_end = period.row_end;

																			const period_color = (is_lunch === '1') ? 'pastel-yellow' : ((is_special === '1') ? 'green' :'dark-purple');

																			// Calculate the column start and end position on the grid
																			const period_position = calculatePeriodColumnStartEnd(period, day_schedule_with_overlaps, logged_positions, start_time, end_time);
																			
																			const position_index = period_position.position_index;
																			const col_start = period_position.col_start;
																			const col_end = period_position.col_end;

																			logged_positions[period_id] = position_index;

																			const day_is_restricted = courseInfo.day_period_restrictions.findIndex(day_period_restriction => day_period_restriction.day_number === day_number && day_period_restriction.period_id === period_id) !== -1;
																			
																			return (
																				<div className='mb-elem-block-mini parent-div' style={{gridColumnStart:col_start, gridColumnEnd:col_end, gridRowStart:row_start, gridRowEnd:row_end}} key={period_id}>
																					<div className={`mb-elem-block-inner-mini left-border-green-thick`} >
																						<div>
																							{(!day_is_restricted) ? 
																								(
																									<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateCourse(selectedCourseID, 'day_period_restrictions', {day_number:day_number, period_id:period_id}, 'add')}/>
																								) : 
																								(
																									<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare} onClick={() => updateCourse(selectedCourseID, 'day_period_restrictions', {day_number:day_number, period_id:period_id}, 'remove')}/>
																								)
																							}
																						</div>
																						<div className='ellipsis'>
																							<div className={`mb-elem-block-header-mini`}>
																								<span className='tooltip' data-tooltip-text={period_name}>
																									<div className='mb-elem-block-name-mini ellipsis'>{period_name}</div>
																								</span>
																							</div>
																							<div className={`mb-elem-block-content-mini`}>
																								<div className='tooltip' data-tooltip-text={`${start_time_formatted} - ${end_time_date_formatted}`}>
																									<div className='mb-elem-block-time-mini ellipsis'>{start_time_formatted} - {end_time_date_formatted}</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			)
																		})}
																	</div>
																</div>
															</div>
														)
													})}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						<div className='fixed-bottom-save-container'>
							<div className="btn btn-extra-large green-btn align-right move-to-next-screen-btn move-to-next-screen-btn" onClick={autoPlaceSpecials}>Auto Generate Specials!</div>
						</div>
						</>
					)
				}
			</div>
			</>
		)
		}
		</>
	)
}