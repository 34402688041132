import React, { useState , useEffect } from 'react';
import { API } from "aws-amplify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { createCommaSeparatedListFromArray, sortArrayOfObjects } from '../../../js/Helpers';
import { saveDataToDatabase } from '../../../js/SaveFunctions';

export default function ExportSchedule({schoolInfo, scheduleInfo, scheduleStructureData, lunchInfo, classrooms, courses, setCourses, teachers, setTeachers, students, setStudents, sections, setSections, subsections, labels, conflicts, openConflicts, closeModal }) {
	/////////////////
	///// STATE /////
	/////////////////
	const [currentScreen, setCurrentScreen] = useState(null);
	const [screensToShow, setScreensToShow] = useState([]);
	const [exportSettings, setExportSettings] = useState(schoolInfo.type === '2' ? null :
		{
			day_type: {
				display:'Day Cycle Label', 
				display_question:'What is the label you use for the days in your cycle?', 
				explanation:'We want to match this up to the cycles in your SIS so there is no issue matching up rotation days in the export file.', 
				options:[
					{display:'MTWRF', value:'1'}, 
					{display:'A to Z (ABCD)', value:'2'}, 
					{display:'1 to 9', value:'3'}
				],
				prereqs:[], 
				value: '1'
			},
			split_subsections : {
				display:'Separate ICR sections', 
				display_question:'Do you want ICR sections to have their own sections? (This will keep the ICR section as a subsection of the general ed section, but also create a separate section for the ICR)', 
				explanation:'Putting YES would create a COPY of the ICR subsection as its own unique section. The original ICR subsection will still remain under the general section.', 
				options:[{display:'YES', value:true}, {display:'NO', value:false}], 
				prereqs:[], 
				value:false
			}
		}
	);
	const [constraintExplanationOpen, setConstraintExplanationOpen] = useState([]);
	const [teachersMissingCode, setTeachersMissingCode] = useState([]);
	const [studentsMissingCode, setStudentsMissingCode] = useState([]);
	const [periodMappings, setPeriodMappings] = useState([]);
	const [errorMessage, setErrorMessage] = useState(null);

	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const handleCloseModal = () => {
		closeModal();
	}

	const handleMoveToNextPage = () => {
		setErrorMessage(null);
		// Collect all the necessary checks
		// Check if, user wants sections rolled over
		// Check if, any constraints are set
		let next_screen_to_show = currentScreen;
		const teachers_missing_code = teachersMissingCode.length > 0;
		const students_missing_code = studentsMissingCode.length > 0;

		if(currentScreen === 1)
		{
			next_screen_to_show = 4;
			if(teachers_missing_code)
			{
				next_screen_to_show = 2;
			}
			else if(students_missing_code)
			{
				next_screen_to_show = 3;
			}
		} 
		else if(currentScreen === 2)
		{
			// Validate form
			const teachers_still_missing_codes = teachersMissingCode.filter(teacher => !teacher.teacher_code).length > 0;
			if(teachers_still_missing_codes)
			{
				setErrorMessage('There are still teachers missing codes');
				return false;
			}

			// Save all teacher IDs to backend
			teachersMissingCode.forEach(teacher_missing_code => {
				const teacher_id = teacher_missing_code.teacher_id;

				saveDataToDatabase({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'teacher', data_to_save:teacher_missing_code}).then(response_data => {
					// change data teacher to new database id
					teacher_missing_code.teacher_id = response_data.new_data_id;
					
					if(teacher_id)
					{
						const index_of_specific_data = teachers.findIndex(teacher => teacher.teacher_id === teacher_id);
						teachers[index_of_specific_data] = teacher_missing_code;
					}
				})
			});

			// If all inputs have values, then move on
			next_screen_to_show = 4;
			if(students_missing_code) next_screen_to_show = 3;
		}
		else if(currentScreen === 3)
		{
			// Validate form
			const students_still_missing_codes = studentsMissingCode.filter(student => !student.student_code).length > 0;
			if(students_still_missing_codes)
			{
				setErrorMessage('There are still students missing codes');
				return false;
			}

			// If all inputs have values, then move on
			next_screen_to_show = 4;
		}
		else if(currentScreen === 4)
		{
			// Validate form
			const periods_still_missing_mappings = periodMappings.filter(period => !period.display_name || !period.sis_start_period || !period.sis_end_period).length > 0;
			if(periods_still_missing_mappings)
			{
				setErrorMessage('There are still periods missing SIS settings');
				return false;
			}

			savePeriodMappings();

			// If all inputs have values, then move on
			next_screen_to_show = 5;
		}
		else if(currentScreen === 5)
		{
			// If all inputs have values, then move on
			next_screen_to_show = 6;
		}

		setCurrentScreen(next_screen_to_show);
	}

	const handleMoveToPrevPage = () => {
		setErrorMessage(null);
		// Collect all the necessary checks
		// Check if, user wants sections rolled over
		// Check if, any constraints are set
		let next_screen_to_show = currentScreen -1;
		const conflicts_exist = conflicts.length > 0;
		const teachers_missing_code = teachersMissingCode.length > 0;
		const students_missing_code = studentsMissingCode.length > 0;

		if(currentScreen === 4)
		{
			next_screen_to_show = 4;
			if(students_missing_code)
			{
				next_screen_to_show = 3;
			}
			else if(teachers_missing_code)
			{
				next_screen_to_show = 2;
			}
			else if(conflicts_exist)
			{
				next_screen_to_show = 1;
			}
		} 
		else if(currentScreen === 3)
		{
			next_screen_to_show = 3;
			if(teachers_missing_code)
			{
				next_screen_to_show = 2;
			}
			else if(conflicts_exist)
			{
				next_screen_to_show = 1;
			}
		}

		setCurrentScreen(next_screen_to_show);
	}

	const updateTeacherCode = (e, i) => {
		const teacher_code = e.target.value;
		teachersMissingCode[i].teacher_code = teacher_code;
		setTeachersMissingCode([...teachersMissingCode]);
	}

	const updateStudentCode = (e, i) => {
		const student_code = e.target.value;
		studentsMissingCode[i].student_code = student_code;
		setStudentsMissingCode([...studentsMissingCode]);
	}

	const updatePeriodMapping = (e, i, input_type) => {
		const value = e.target.value;
		periodMappings[i][input_type] = value;
		setPeriodMappings([...periodMappings]);
	}

	const toggleSettings = (setting_index, setting_value) => {
		// Change the settings
		exportSettings[setting_index].value = setting_value;

		// Check if prereqs are met for every setting
		// If not set as false in settings
		Object.keys(exportSettings).forEach((setting_index, index) => {
			const export_setting = exportSettings[setting_index];
			const setting_prereqs = export_setting.prereqs;
			const all_prereqs_met = (!setting_prereqs) ? true : setting_prereqs.reduce((result, prereq) => {
				const prereq_index = exportSettings.findIndex(temp_setting => temp_setting.index === prereq);
				const prereq_info = (prereq_index !== -1) ? exportSettings[prereq_index] : null;
				const prereq_value = (prereq_info) ? prereq_info.value : false;
				if(!prereq_value) result = false;
				return result;
			}, true);

			if(!all_prereqs_met) exportSettings[setting_index].value = false;
		});

		setExportSettings({...exportSettings});
	}

	const toggleConstraintExplanation = (constraint) => {
		const constraint_index = constraintExplanationOpen.findIndex(temp_constraint => temp_constraint === constraint);

		if(constraint_index !== -1)
		{
			constraintExplanationOpen.splice(constraint_index, 1);
		}
		else
		{
			constraintExplanationOpen.push(constraint);
		}

		setConstraintExplanationOpen([...constraintExplanationOpen]);
	}

	const savePeriodMappings = async () => {
		const passed_data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, period_mappings:periodMappings};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/save-period-mappings'
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(passed_data),
		};
		
	
		try {
			// Update database
			await API.post(apiName, url, myInit);

			// Update course periods
			periodMappings.forEach(period_mapping => {
				const course_period_id = period_mapping.course_period_id;

				scheduleStructureData.course_periods[course_period_id]['sis_mapping'] = period_mapping;
			});
		} catch(error)
		{
			console.log(error.response);
		}
	}

	const startExport = async () => {
		setCurrentScreen(6);

		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, split_subsections:exportSettings.split_subsections.value, day_type:exportSettings.day_type.value};
		console.log({data});
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/sis/export';
		const myInit = { // OPTIONAL
			response: true,
			headers: {'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
			responseType: 'text',
			queryStringParameters: data,
		};
	
		try {
			const response = await API.get(apiName, url, myInit);
			const response_data = response.data;

			// Create a ghost link
			const downloadURL = response_data.download_url;
			const anchorElement = document.createElement('a');
			document.body.appendChild(anchorElement);
			anchorElement.style.display = 'none';

			// Set up schedule year for default file name
			const current_schedule_year_id = schoolInfo.current_schedule_year_id;
			const schedule_year_info = scheduleInfo.schedule_years.find(schedule_year => schedule_year.schedule_year_id === current_schedule_year_id);
			const schedule_year = ('schedule_year' in schedule_year_info) ? schedule_year_info.schedule_year : '';
			const schedule_year_text = schedule_year.replace("-", "_");

			// Set filename and mimic clicking the link to start the download
			anchorElement.href = downloadURL;
			anchorElement.target = '_self';
			anchorElement.download = `${schoolInfo.school_code}_export_files_${schedule_year_text}`;
			anchorElement.click();
			anchorElement.remove();

			closeModal();
		} catch(error)
		{
			console.log(error);
			//console.log(error.response);
		}
	}

	///////////////////////
	///// USE EFFECT //////
	///////////////////////
	useEffect(() => {
		let current_screen = null;
		let screens_to_show = [];

		// Figure out if you need to show conflicts warning before export
		if(conflicts.length > 0) 
		{
			screens_to_show.push('conflicts');
			current_screen = 1;
		}

		// Figure out if you need to show teachers missing codes screen before export
		// Only care about teachers who are actually teaching a section that is placed in the schedule
		const teachers_missing_code = teachers.filter(teacher => !teacher.teacher_code && sections.findIndex(section => section.course_period_id && section.teacher_id === teacher.teacher_id) !== -1);
		if(teachers_missing_code.length > 0) 
		{
			screens_to_show.push('missing_teacher_codes');
			if(!current_screen) current_screen = 2;
		}

		// Figure out if you need to show students missing codes screen before export
		// Only care about students who are actually in a section that is placed in the schedule
		const students_missing_code = students.filter(student => !student.student_code && sections.findIndex(section => section.course_period_id && section.student_list.includes(student.student_id)) !== -1);
		if(students_missing_code.length > 0) 
		{
			screens_to_show.push('missing_student_codes');
			if(!current_screen) current_screen = 3;
		}

		if(!current_screen) current_screen = 4;

		setCurrentScreen(current_screen);
		setScreensToShow(screens_to_show);
		setTeachersMissingCode(teachers_missing_code);
		setStudentsMissingCode(students_missing_code);
	}, [conflicts, teachers, students]);

	useEffect(() => {
		const periods_to_show = (scheduleInfo.schedule_type === 'daily') ? scheduleStructureData.schedule_periods : scheduleStructureData.course_periods;

		let sortable = [];
		for (const period in periods_to_show) {
			const period_info = periods_to_show[period];
			const period_id = period_info.period_id;
			const period_order = (scheduleInfo.schedule_type === 'daily') ? period_info.period_order : period_info.period_name;
			const period_name = period_info.period_name;

			sortable.push({period_id: period_id, period_order: parseInt(period_order), period_name: period_name});
		}

		const sorted_periods = sortArrayOfObjects(sortable, 'period_order', 'number', 'desc');
		const period_mappings = sorted_periods.reduce((results, period_info) => {
			const period_id = period_info.period_id;
			const period_name = period_info.period_name;
			const course_period_id = (scheduleInfo.schedule_type === 'daily') ? scheduleStructureData.schedule_structure[1][period_id] : period_id;
			const sis_mapping = scheduleStructureData.course_periods[course_period_id].sis_mapping;

			results.push({period_name:period_name, course_period_id:course_period_id, ...sis_mapping});
			return results;
		}, []);
		setPeriodMappings(period_mappings);
	}, [scheduleInfo, scheduleStructureData]);

	////////////////////////////////
	///// INITIALIZE VARIABLES /////
	////////////////////////////////
	const conflicts_exist = conflicts.length > 0;
	const teachers_missing_code = teachersMissingCode.length > 0;
	const students_missing_code = studentsMissingCode.length > 0;

	const screens_before_missing_teacher_codes_exist = (conflicts_exist);
	const screens_before_missing_student_codes_exist = (conflicts_exist || teachers_missing_code);
	const screens_before_period_mappings_exist = (conflicts_exist || teachers_missing_code || students_missing_code);

	return (
		<div className='modal'>
			<div id='export-modal-content' className='modal-content'>
				{currentScreen !== 6 &&
					<span className="modal-close-right" onClick={handleCloseModal}>×</span>
				}
				<h1 id='export-modal-title'>Export Your Schedule</h1>
				<div className='export-page'>
					{
						(currentScreen === 1 && screensToShow.includes('conflicts')) ? 
						(
							<>
							<div className='export-schedule-warning'>
								<FontAwesomeIcon className='export-warning-icon dark-orange-text' icon={faExclamationTriangle} />
								<div className='medium-text'>
									<div style={{margin:'0px 0px 10px 0px'}}>You have conflicts/warnings on this schedule.</div>
									<div>You may want to review these conflicts before exporting your schedule to <span className='capitalize'>{scheduleInfo.sis}</span>.</div>
								</div>
							</div>
							<div className='export-btn-container' style={{gridTemplateColumns:'3fr 2fr'}}>
								<div className='btn dark-orange-btn medium-text' onClick={openConflicts}>Check Conflicts</div>
								<div className='full-width text-align-center blue-link medium-text' onClick={handleMoveToNextPage}>Continue Export</div>
							</div>
							</>
						):(currentScreen === 2 && screensToShow.includes('missing_teacher_codes')) ? 
						(
							<>
							<div className='medium-text' style={{margin:'0px 0px 20px 0px'}}>We’ve found a few teachers without teacher codes.</div>
							<div className='medium-text' style={{margin:'0px 0px 30px 0px'}}>These codes should match what is in <span className='capitalize'>{scheduleInfo.sis}</span>.</div>
							<div className='missing-codes-container medium-text'>
								<div className='bold-text'>Teacher</div>
								<div className='bold-text'>Teacher Code in <span className='capitalize'>{scheduleInfo.sis}</span></div>
								{teachersMissingCode.map((teacher_info, i) => {
									const teacher_first_name = teacher_info.first_name;
									const teacher_last_name = teacher_info.name;
									const teacher_code = teacher_info.teacher_code;
									
									return (
										<React.Fragment key={i}>
										<div>{teacher_last_name}, {teacher_first_name}</div>
										<input value={teacher_code ? teacher_code : ''} onChange={(e) => updateTeacherCode(e, i)}/>
										</React.Fragment>
									)
								})}
							</div>
							{errorMessage &&
								<div className='error-message-no-box' style={{margin:'30px 0px -30px 0px'}}>{errorMessage}</div>
							}
							<div className='export-btn-container' style={{gridTemplateColumns:'1fr 2fr'}}>
								{screens_before_missing_teacher_codes_exist ? 
								(
									<div className='btn gray-btn btn-large medium-text' onClick={handleMoveToPrevPage}>Back</div>
								):
								(
									<div></div>
								)}
								<div className='btn blue-btn btn-large medium-text' onClick={handleMoveToNextPage}>Next</div>
							</div>
							</>
						):(currentScreen === 3 && screensToShow.includes('missing_student_codes')) ? 
						(
							<>
							<div className='medium-text' style={{margin:'0px 0px 20px 0px'}}>We’ve found a few students without student codes. </div>
							<div className='medium-text' style={{margin:'0px 0px 30px 0px'}}>These codes should match what is in <span className='capitalize'>{scheduleInfo.sis}</span>.</div>
							<div className='missing-codes-container medium-text'>
								<div className='bold-text'>Student</div>
								<div className='bold-text'>Student Code in <span className='capitalize'>{scheduleInfo.sis}</span></div>
								{studentsMissingCode.map((student_info, i) => {
									const student_first_name = student_info.first_name;
									const student_last_name = student_info.last_name;
									const student_code = student_info.student_code;
									const student_grade = student_info.grade;
									
									return (
										<React.Fragment key={i}>
										<div>{student_last_name}, {student_first_name} (Grade {student_grade})</div>
										<input value={student_code ? student_code : ''} onChange={(e) => updateStudentCode(e, i)}/>
										</React.Fragment>
									)
								})}
							</div>
							{errorMessage &&
								<div className='error-message-no-box' style={{margin:'30px 0px -30px 0px'}}>{errorMessage}</div>
							}
							<div className='export-btn-container' style={{gridTemplateColumns:'1fr 2fr'}}>
								{screens_before_missing_student_codes_exist ? 
								(
									<div className='btn gray-btn btn-large medium-text' onClick={handleMoveToPrevPage}>Back</div>
								):
								(
									<div></div>
								)}
								<div className='btn blue-btn btn-large medium-text' onClick={handleMoveToNextPage}>Next</div>
							</div>
							</>
						):(currentScreen === 4) ? 
						(
							<>
							<div className='medium-text' style={{margin:'0px 0px 20px 0px'}}>Let us know how the periods in Edario are set up in <span className='capitalize'>{scheduleInfo.sis}</span>. </div>
							<div className='medium-text' style={{margin:'0px 0px 30px 0px'}}>We will use this to make sure your section periods are exported correctly.</div>
							<div className='export-period-mapping-container medium-text'>
								<div></div>
								<div className='dark-blue text-align-center' style={{padding:'5px'}}><span className='capitalize'>{scheduleInfo.sis}</span> Settings</div>
								<div className='bold-text'>Period in Edario</div>
								<div className='export-period-mapping-sis-container'>
									<div className='bold-text'>Display Name</div>
									<div className='bold-text'>Start Period</div>
									<div className='bold-text'>End Period</div>
								</div>
								{periodMappings.map((period_info, i) => {
									const period_name = period_info.period_name;
									const display_name = period_info.display_name;
									const sis_start_period = period_info.sis_start_period;
									const sis_end_period = period_info.sis_end_period;
									
									return (
										<React.Fragment key={i}>
										<div>{period_name}</div>
										<div className='export-period-mapping-sis-container'>
											<input value={display_name ? display_name : ''} onChange={(e) => updatePeriodMapping(e, i, 'display_name')}/>
											<input value={sis_start_period ? sis_start_period : ''} onChange={(e) => updatePeriodMapping(e, i, 'sis_start_period')}/>
											<input value={sis_end_period ? sis_end_period : ''} onChange={(e) => updatePeriodMapping(e, i, 'sis_end_period')}/>
										</div>
										</React.Fragment>
									)
								})}
							</div>
							{errorMessage &&
								<div className='error-message-no-box' style={{margin:'30px 0px -30px 0px'}}>{errorMessage}</div>
							}
							<div className='export-btn-container' style={{gridTemplateColumns:'1fr 2fr'}}>
								{screens_before_period_mappings_exist ? 
								(
									<div className='btn gray-btn btn-large medium-text' onClick={handleMoveToPrevPage}>Back</div>
								):
								(
									<div></div>
								)}
								<div className='btn blue-btn btn-large medium-text' onClick={handleMoveToNextPage}>Next</div>
							</div>
							</>
						):(currentScreen === 5) ?
						(
							<>
							<div className='medium-text' style={{margin:'0px 0px 30px 0px'}}>Last step! We just need some set up options to make sure the export files are created exactly as you expect them.</div>
							{Object.keys(exportSettings).map((setting_index, i) => {
								const export_setting = exportSettings[setting_index];
								const export_setting_question = export_setting.display_question;
								const export_setting_value = export_setting.value;
								const explanation_open = constraintExplanationOpen.includes(setting_index);
								const export_options = export_setting.options;

								let missing_prereqs = [];
								const setting_prereqs = export_setting.prereqs;
								const all_prereqs_met = (!setting_prereqs) ? true : setting_prereqs.reduce((result, prereq) => {
									const prereq_index = exportSettings.findIndex(temp_setting => temp_setting.index === prereq);
									const prereq_info = (prereq_index !== -1) ? exportSettings[prereq_index] : null;
									const prereq_value = (prereq_info) ? prereq_info.value : false;
									if(!prereq_value)
									{
										result = false;
										
										// Find prerequisite display so you can show a list of missing prereqs
										const setting_display = (prereq_info) ? prereq_info.display : null;
										if(setting_display) missing_prereqs.push(setting_display);
									}
									return result;
								}, true);
								const missing_prereqs_string = createCommaSeparatedListFromArray(missing_prereqs);

								return (
									<React.Fragment key={i}>
										<div className={`export-select-container ${!all_prereqs_met && 'export-select-container-disabled'}`}>
											<div>
												{export_setting_question}
												{'explanation' in export_setting && <FontAwesomeIcon className='export-info-btn' icon={faInfoCircle} onClick={()=>toggleConstraintExplanation(setting_index)}/>}
											</div>
											<div className='export-radio-btn-parent-container'>
												{export_options.map((export_option, j) => {
													const export_option_display = export_option.display;
													const export_option_value = export_option.value;

													return (
														<div className='export-radio-btn-container' onClick={all_prereqs_met ? ()=>toggleSettings(setting_index, export_option_value): null} key={j}>
															<div className={`radio-btn ${export_setting_value === export_option_value && 'radio-btn-selected'}`}></div>
															<div>{export_option_display}</div>
														</div>
													)
												})}
											</div>
										</div>
										{!all_prereqs_met &&
											<div className='export-setting-explanation red-text'>This option is not available because you are not {missing_prereqs_string}.</div>
										}
										{explanation_open &&
											<div className='export-setting-explanation'>{export_setting.explanation}</div>
										}
									</React.Fragment>
								)
							})}
							{errorMessage &&
								<div className='error-message-no-box' style={{margin:'30px 0px -30px 0px'}}>{errorMessage}</div>
							}
							<div className='export-btn-container' style={{gridTemplateColumns:'1fr 2fr'}}>
								<div className='btn gray-btn btn-large medium-text' onClick={handleMoveToPrevPage}>Back</div>
								<div className='btn green-btn btn-large medium-text' onClick={startExport}>Create Export File</div>
							</div>
							</>
						):(currentScreen === 6) ?
						(
							<div className='text-align-center'>
								<img src={require("../../../images/copying.png")} alt='loading...' style={{width:'200px', margin:'50px 0px 25px 0px'}}/>
								<div style={{marginBottom:'30px'}}>Creating your export files.</div>
							</div>
						):null
					}
				</div>
			</div>
		</div>
	)
}