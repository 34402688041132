import React from 'react';

import FillSections from './fill_sections';

import '../../../css/hsms/fill_sections.css';

export default function StudentScheduling({ schoolSubscribed, subscreenRestricted, subscreenLocked, isLoading, setIsLoading, subscreen, schoolInfo, scheduleInfo, userInfo, showNextButton, handleMoveToNextScreen, setLoadScreen }) {
	return (
		<div id='section-placement-main-container'>
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				) :
				(
					<>
					{(() => {
						switch(subscreen) {
							case 'fill_sections':
								return <FillSections schoolSubscribed={schoolSubscribed} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} isLoading={isLoading} setIsLoading={setIsLoading} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} userInfo={userInfo} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setLoadScreen={setLoadScreen}/>;
							case 'dashboard':
								return null;
							default:
								return null;
						}
					})()}
					</>
				)
			}
		</div>
	);
}