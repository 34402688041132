import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";

import HomePage from './components/HomePage';
import SchedulingApp from './components/SchedulingApp';

export default function App() {
	return (
		<div className="App">
			<BrowserRouter>
					<Switch>
						<Route exact path='/' render={() => ( <HomePage/>)}/>
						<Route path='/app/:school_type?/:screen?' render={(props) => (<SchedulingApp {...props}/>)} />
					</Switch>
			</BrowserRouter>
		</div>
	)
}