import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import Fuse from 'fuse.js';

import Dropdown from '../../Dropdown';
import EditTeacherData from '../modals/edit_teacher';

import { getData, getBlockConnections } from '../../../js/Helpers';
import { saveDataToDatabase } from '../../../js/SaveFunctions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faLock, faTimes, faSearch, faCalendarDays, faCheckSquare, faSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import '../../../css/elementary/auto_scheduling_setup.css';

const numTimesNeededOptions = [
	{value:null, display: 'No Limit'},
	{value:'1', display: '1'},
	{value:'2', display: '2'},
	{value:'3', display: '3'},
	{value:'4', display: '4'},
	{value:'5', display: '5'},
	{value:'6', display: '6'},
	{value:'7', display: '7'},
	{value:'8', display: '8'},
	{value:'9', display: '9'},
	{value:'10', display: '10'},
	{value:'11', display: '11'},
	{value:'12', display: '12'},
];

const temp_durations = [...Array(200).keys()];
const durationOptions = temp_durations.reduce((results, value) => {
	results.push({value:`${value}`, display:`${value} min(s)`});
	return results;
},[]);

const temp_max_group_sizes = [...Array(40).keys()];
const maxGroupSizeOptions = temp_max_group_sizes.reduce((results, value) => {
	if(value === 0) return results;
	results.push({value:`${value}`, display:`${value} student(s)`});
	return results;
},[{value:null, display: 'No Limit'}]);

export default function PulloutsCourses({ isLoading, schoolSubscribed, subscreenLocked, subscreenRestricted, schoolInfo, scheduleInfo, periods, courses, setCourses, teachers, setTeachers, students, setStudents, classrooms, blocks, setBlocks, pulloutGroups, setPulloutGroups, handleMoveToNextScreen }) {
	const [selectedCourseID, setSelectedCourseID] = useState(null);
	const [openSearches, setOpenSearches] = useState({teachers:false, classrooms:false, homeroom_teachers:false, groups:[], pullout_allowed_courses:false})
	const [searchResults, setSearchResults] = useState({courses:[], teachers:[], classrooms:[], homeroom_teachers:[], students:[], pullout_allowed_courses:[]});const [days, setDays] = useState([]);
	const [autoGeneratingPulloutsInProgress, setAutoGeneratingPulloutsInProgress] = useState(false);

	const [showEditDataModal, setShowEditDataModal] = useState(false);
	const [currentDataID, setCurrentDataID] = useState(null);
	
	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const updateCourse = (course_id, data_index, new_value, change_type = 'change', group_id = null, group_data_index = null) => {
		const course_index = courses.findIndex(course => course.course_id === course_id);

		if(course_index === -1) return;

		if(data_index === 'teachers_can_teach')
		{
			const tct_index = courses[course_index]['teachers_can_teach'].findIndex(tct => tct.teacher_id === new_value);
			if(change_type ==='add' && tct_index === -1) 
			{
				courses[course_index]['teachers_can_teach'].push({teacher_id:new_value, type:'1'});
			}
			else if(change_type ==='remove' && tct_index !== -1) 
			{
				courses[course_index]['teachers_can_teach'].splice(tct_index, 1);
			}

			openSearches['teachers'] = false;
		}
		else if(data_index === 'classroom_restrictions')
		{
			const cr_index = courses[course_index]['classroom_restrictions'].findIndex(classroom_id => classroom_id === new_value);
			if(change_type === 'add' && cr_index === -1) 
			{
				courses[course_index]['classroom_restrictions'].push(new_value);
			}
			else if(change_type ==='remove' && cr_index !== -1) 
			{
				courses[course_index]['classroom_restrictions'].splice(cr_index, 1);
			}

			openSearches['classrooms'] = false;
		}
		else if(data_index === 'pullout_groups')
		{
			const group_index = pulloutGroups.findIndex(pullout_group => pullout_group.group_id === group_id);

			if(!group_data_index)
			{
				if(change_type === 'add')
				{
					const uniqueId = () => {
						const dateString = Date.now().toString(36);
						const randomness = Math.random().toString(36).substr(2);
						return dateString + randomness;
					};
	
					/// Update the FRONTEND ///
					let new_group_id = uniqueId();
					pulloutGroups.push({group_id:new_group_id, course_id:course_id, num_times_needed:'1', duration:'0', day_restrictions:[], strict_group:'1', max_group_size:null, student_list:[]});
				}
				else if(change_type === 'remove')
				{
					if(group_index !== -1) pulloutGroups.splice(group_index, 1);
				}
			}
			else if(group_data_index === 'student_list')
			{
				const spr_index = pulloutGroups[group_index].student_list.findIndex(student_id => student_id === new_value);
				if(change_type === 'add' && spr_index === -1) 
				{
					pulloutGroups[group_index].student_list.push(new_value);
				}
				else if(change_type ==='remove' && spr_index !== -1) 
				{
					pulloutGroups[group_index].student_list.splice(spr_index, 1);
				}
			}
			else if(group_data_index === 'day_restrictions')
			{
				const dr_index = pulloutGroups[group_index].day_restrictions.findIndex(day => day === new_value);
				if(change_type === 'add' && dr_index === -1) 
				{
					pulloutGroups[group_index].day_restrictions.push(new_value);
				}
				else if(change_type ==='remove' && dr_index !== -1) 
				{
					pulloutGroups[group_index].day_restrictions.splice(dr_index, 1);
				}
			}
			else if(change_type === 'change')
			{
				pulloutGroups[group_index][group_data_index] = new_value;
			}

			const course_pullout_groups = (courseInfo) ? pulloutGroups.filter(pullout_group => pullout_group.course_id === courseInfo.course_id) : [];
			courses[course_index].pullout_groups = course_pullout_groups;

			setPulloutGroups([...pulloutGroups]);
			openSearches['groups'] = false;
		}
		else if(data_index === 'pullout_allowed_courses')
		{
			const pac_index = courses[course_index]['pullout_allowed_courses'].findIndex(pullout_allowed_course => pullout_allowed_course === new_value);
			if(change_type === 'add' && pac_index === -1) 
			{
				courses[course_index]['pullout_allowed_courses'].push(new_value);
			}
			else if(change_type ==='remove' && pac_index !== -1) 
			{
				courses[course_index]['pullout_allowed_courses'].splice(pac_index, 1);
			}

			openSearches['pullout_allowed_courses'] = false;
		}
		else 
		{
			courses[course_index][data_index] = new_value;
		}

		setCourses([...courses]);
		setOpenSearches({...openSearches});

		//console.log({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'course', course_data:courses[course_index], database:'elementary'});
		
		saveDataToDatabase({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'course', course_data:courses[course_index], database:'elementary'}).then(returned_data => {
			/// Update any new groups with their database group ids
			for(const group_id in returned_data.new_group_ids)
			{
				const new_group_id = returned_data.new_group_ids[group_id];

				if(group_id != new_group_id)
				{
					const pullout_group_index = pulloutGroups.findIndex(pullout_group => pullout_group.group_id === group_id);
					if(pullout_group_index !== -1) pulloutGroups[pullout_group_index].group_id = new_group_id;
					setPulloutGroups([...pulloutGroups])
				}
			}
		});
	}

	console.log({pulloutGroups});

	const toggleAddNewConnection = (searchType) => {
		const new_value = !openSearches[searchType];

		if(new_value) searchResults[searchType] = (searchType === 'teachers' || searchType === 'homeroom_teachers') ? teachers : classrooms;

		openSearches[searchType] = new_value;

		setSearchResults({...searchResults});
		setOpenSearches({...openSearches});
	}

	const toggleGroupStudentSearch = (group_id) => {
		const group_index = pulloutGroups.findIndex(pullout_group => pullout_group.group_id === group_id);
		if(group_index === -1) return;

		if('student_search_results' in pulloutGroups[group_index])
		{
			delete pulloutGroups[group_index].student_search_results;
		}
		else
		{
			pulloutGroups[group_index].student_search_results = students;
		}

		setPulloutGroups([...pulloutGroups]);
	}
	
	const toggleEditData = (dataID) => {
		setCurrentDataID(dataID);
		setShowEditDataModal(!showEditDataModal);
	}

	const groupStudentSearch = (search_value, group_id) => {
		const group_index = pulloutGroups.findIndex(pullout_group => pullout_group.group_id === group_id);
		if(group_index === -1) return;

		const fuse = new Fuse(students, {
			keys: ['first_name', 'last_name'],
			threshold: .1
		})
		
		const results = fuse.search(search_value);
		const student_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : students;
		
		pulloutGroups[group_index].student_search_results = student_results;

		setPulloutGroups([...pulloutGroups]);
	}

	const fuzzySearch = (e, dataType, dataKey = dataType) => {
		const search_value = e.target.value;
		
		if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const course_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : courses;
			
			searchResults[dataKey] = course_results;
		}
		else if(dataType === 'teachers')
		{
			const fuse = new Fuse(teachers, {
				keys: ['first_name', 'name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const teacher_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : teachers;
			
			searchResults[dataKey] = teacher_results;
		}
		else if(dataType === 'classrooms')
		{
			const fuse = new Fuse(classrooms, {
				keys: ['name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const classroom_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : classrooms;
			
			searchResults[dataKey] = classroom_results;
		}
		else if(dataType === 'homeroom_teachers')
		{
			const fuse = new Fuse(teachers, {
				keys: ['first_name', 'name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const teacher_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : teachers;
			
			searchResults[dataKey] = teacher_results;
		}
		else if(dataType === 'students')
		{
			const fuse = new Fuse(students, {
				keys: ['last_name', 'first_name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const student_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : courses;
			
			searchResults[dataKey] = student_results;
		}
		else if(dataType === 'pullout_allowed_courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const course_results = (search_value.length > 0) ? results.map(result => result.item).slice(0, 6) : courses;
			
			searchResults[dataKey] = course_results;
		}

		setSearchResults({...searchResults});
	}

	const pullNewBlocks = (_callback = ()=>{}) => {
		getData('blocks', '/elementary/get-blocks', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true, 'elementary').then(blocks_without_connections => {
			getBlockConnections(schoolInfo.school_id, schoolInfo.current_schedule_version_id).then(connections => {
				let blocks_with_connections = [];

				blocks_without_connections.forEach((specific_data, index) => {
					const block_id = specific_data.block_id;

					const student_list = (block_id in connections.student_list) ? connections.student_list[block_id] : [];
					specific_data.student_list = student_list;

					blocks_with_connections[index] = specific_data;
				});

				setBlocks(blocks_with_connections);
				_callback();
			});
		});
	}

	const autoPlacePullouts = async () => {
		if(!schoolSubscribed)
		{
			alert("This feature is only available to schools with a paid subscription! Please contact us at contact@edario.com to activate auto-placing pullouts.");
			return false;
		}

		// Set auto generating in progress
		setAutoGeneratingPulloutsInProgress(true);

		/// Update the BACKEND ///
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id};
		const apiName = process.env.REACT_APP_ELEM_ENDPOINT_NAME;
		const url = '/elementary/auto-place-pullouts';
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(data),
		};
	
		try {
			await API.post(apiName, url, myInit);

			// Pull new blocks and then move to next screen
			pullNewBlocks(() => {
				// Move on to the next screen
				handleMoveToNextScreen();
			});
		} catch(e)
		{
			console.log(e.response);
		}
	}
	
	////////////////////////////////
	///// USE EFFECT /////
	////////////////////////////////
	// Set the first pullout as the selected course id
	useEffect(() => {
		if(selectedCourseID) return;
		const pullouts = courses.filter(course => course.is_pullout === '1');

		if(pullouts.length > 0)
		{
			const selected_course_id = pullouts[0].course_id;
			setSelectedCourseID(selected_course_id);
		}
	}, [courses]);

	useEffect(() => {
		const num_days_in_cycle = scheduleInfo.num_days_in_cycle;

		let new_days = [];

		for(let day = 1; day <= num_days_in_cycle; day++)
		{
			new_days.push(`${day}`);
		}

		setDays(new_days);
	}, [scheduleInfo]);

	////////////////////////////////
	///// INITIALIZE VARIABLES /////
	////////////////////////////////
	const courseInfo = (selectedCourseID) ? courses.find(course => course.course_id === selectedCourseID) : null;
	const pulloutName = (courseInfo) ? courseInfo.name : null;
	const classroomRestrictions = (courseInfo) ? courseInfo.classroom_restrictions : [];
	const teachersCanTeach = (courseInfo) ? courseInfo.teachers_can_teach : [];
	const coursePulloutGroups = (courseInfo) ? pulloutGroups.filter(pullout_group => pullout_group.course_id === courseInfo.course_id) : [];
	const pulloutAllowedCourses = (courseInfo) ? courseInfo.pullout_allowed_courses : [];

	const pulloutCourses = courses.filter(course => course.is_pullout === '1');

	return (
		<>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(isLoading) ?
		(
			<div className='center-text'>
				<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'25px'}}/>
			</div>
		):
		(
			<>
			{showEditDataModal &&
				<EditTeacherData schoolInfo={schoolInfo} dataID={currentDataID} toggleEditData={toggleEditData} scheduleInfo={scheduleInfo} periods={periods} teachers={teachers} courses={courses} classrooms={classrooms} students={students} setStudents={setStudents} setDatabaseData={setTeachers} filteredResults={teachers}/>
			}
			<div className='mb-elem-container'>
				<h1 className='progress-main-header'>Pullouts Setup</h1>
				{autoGeneratingPulloutsInProgress ?
					(
						<div className='section-review-missing-requests-container'>
							<div id='custom-section-creation-loader-container'>
								<div className='custom-section-creation-loader-column'>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top pastel-orange'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top pastel-orange'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
								</div>
								<div className='custom-section-creation-loader-column'>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top yellow'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top yellow'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top yellow'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
								</div>
								<div className='custom-section-creation-loader-column'>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top pastel-green'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
								</div>
								<div className='custom-section-creation-loader-column'>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top pastel-light-blue'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
									<div className='custom-section-creation-loader-row'>
										<div className='custom-section-creation-loader-row-top pastel-light-blue'></div>
										<div className='custom-section-creation-loader-row-bottom'></div>
									</div>
								</div>
							</div>
							<h2>Pullouts are being placed...</h2>
							<div>We are currently placing pullouts. Please do not refresh the page while this processes.</div>
						</div>
					) :
					(
						<>
						{pulloutCourses.length === 0 &&
							<div className='pullouts-setup-empty-courses'>You have no pullouts courses to display.</div>
						}
						<div className='pullouts-setup-option-container'>
							{pulloutCourses.map((course, course_index) => {
								const course_id = course.course_id;
								const course_name = course.name;
								const course_code = course.course_code;

								return (
									<div className={`pullouts-setup-course-option ${course_id === selectedCourseID && 'pullouts-setup-course-option-selected'}`} key={course_index} onClick={() => setSelectedCourseID(course_id)}>{course_name} {course_code && `(${course_code})`} </div>
								)
							})}
						</div>
						<div style={{marginTop:'16px'}}>Missing Pullouts? <Link className='blue-text' to={{pathname:`/app/elementary/school_data/courses/`, redirect:`/app/elementary/progress/auto_scheduling_setup/pullouts_setup`}}>Review your Courses</Link></div>
						{selectedCourseID &&
							<div className='pullouts-setup-pullouts-container'>
								<div className='pullouts-setup-question-container'>
									<div>
										<div className='pullouts-setup-label pullouts-setup-name'>Pullout Name</div>
										<div className='pullouts-setup-pullout-name'>{pulloutName}</div>
									</div>
								</div>
								<div className='pullouts-setup-question-container'>
									<div>
										<div className='pullouts-setup-label'>Who can teach this course?</div>
										{teachersCanTeach.length === 0 &&
											<div className='pullouts-setup-display'>No teachers selected yet...</div>
										}
										{teachersCanTeach.map((teacher_can_teach, tct_index) => {
											const teacher_id = teacher_can_teach.teacher_id;
											const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === teacher_id);
											const teacher_info = (teacher_index !== -1) ? teachers[teacher_index] : null;

											if(!teacher_info) return;

											const teacher_last_name = teacher_info.name;
											const teacher_first_name = teacher_info.first_name;

											return (
												<div className='pullouts-setup-tct' key={tct_index}>
													<div className='pullouts-setup-display'>
														<div>{teacher_last_name}, {teacher_first_name}</div>
														<div className='cursor-pointer' onClick={() => updateCourse(selectedCourseID, 'teachers_can_teach', teacher_id, 'remove')}><FontAwesomeIcon icon={faTimes}/></div>
													</div>
													<FontAwesomeIcon className='blue-link' icon={faCalendarDays} onClick={() => toggleEditData(teacher_id)}/>
												</div>
												
											)
										})}
										{(openSearches.teachers) &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'teachers')} placeholder='Search for teacher'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('teachers')}/>
												</div>
												{searchResults['teachers'].length > 0 &&
													<div className='elem-suggestion-box-parent parent-div'>
														<div className='elem-suggestion-box'>
															{searchResults['teachers'].map((search_result, index) => {
																const search_teacher_id = search_result.teacher_id;
																const search_teacher_first_name = search_result.first_name;
																const search_teacher_last_name = search_result.name;
																const search_teacher_full_name =  `${search_teacher_last_name}, ${search_teacher_first_name}`;

																return (
																	<div className='search-add-search-result' key={index}>
																		<div>{search_teacher_full_name}</div>
																		<div className='search-add-add-btn' onClick={() => updateCourse(selectedCourseID, 'teachers_can_teach', search_teacher_id, 'add')}>Add</div>
																	</div>
																)
															})}
														</div>
													</div>
												}
											</div>
										}
										<div className='blue-link medium-text pullouts-setup-add-link' onClick={() => toggleAddNewConnection('teachers')}><FontAwesomeIcon icon={faCirclePlus}/> Add a Teacher</div>
									</div>
								</div>
								<div className='pullouts-setup-question-container'>
									<div>
										<div className='pullouts-setup-label'>Which classrooms does this course use?</div>
										{classroomRestrictions.length === 0 &&
											<div className='pullouts-setup-display'>No classrooms selected yet...</div>
										}
										{classroomRestrictions.map((classroom_id, cr_index) => {
											const classroom_index = classrooms.findIndex(classroom => classroom.classroom_id === classroom_id);
											const classroom_info = (classroom_index !== -1) ? classrooms[classroom_index] : null;

											if(!classroom_info) return;

											const classroom_name = classroom_info.classroom_name;

											return (
												<div className='pullouts-setup-display' key={cr_index}>
													<div>{classroom_name}</div>
													<div className='cursor-pointer' onClick={() => updateCourse(selectedCourseID, 'classroom_restrictions', classroom_id, 'remove')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)
										})}
										{(openSearches.classrooms) &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'classrooms')} placeholder='Search for classroom'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('classrooms')}/>
												</div>
												{searchResults['classrooms'].length > 0 &&
													<div className='elem-suggestion-box-parent parent-div'>
														<div className='elem-suggestion-box'>
															{searchResults['classrooms'].map((search_result, index) => {
																const search_classroom_id = search_result.classroom_id;
																const search_classroom_name = search_result.classroom_name;

																return (
																	<div className='search-add-search-result' key={index}>
																		<div>{search_classroom_name}</div>
																		<div className='search-add-add-btn' onClick={() => updateCourse(selectedCourseID, 'classroom_restrictions', search_classroom_id, 'add')}>Add</div>
																	</div>
																)
															})}
														</div>
													</div>
												}
											</div>
										}
										<div className='blue-link medium-text pullouts-setup-add-link' onClick={() => toggleAddNewConnection('classrooms')}><FontAwesomeIcon icon={faCirclePlus}/> Add a Classroom</div>
									</div>
								</div>
								<div className='pullouts-setup-question-container'>
									<div>
										<div className='pullouts-setup-label'>Create groups of students with similar needs</div>
										{coursePulloutGroups.length === 0 &&
											<div className='pullouts-setup-display'>No groups selected yet...</div>
										}
										{coursePulloutGroups.map(group_info => {
											const group_id = group_info.group_id;
											const student_list = group_info.student_list;
											const num_times_needed = group_info.num_times_needed;
											const duration = group_info.duration;
											const day_restrictions = group_info.day_restrictions;
											const strict_group = group_info.strict_group;
											const max_group_size = group_info.max_group_size;
											const student_search_open = ('student_search_results' in group_info);
											const student_search_results = (student_search_open) ? group_info.student_search_results : [];

											return (
												<div className='pullouts-setup-group-container' key={`${selectedCourseID}-${group_id}`}>
													<div className='pullouts-setup-group-delete'><FontAwesomeIcon icon={faTrashAlt} className='gray-to-red-link' onClick={() => updateCourse(selectedCourseID, 'pullout_groups', null, 'remove', group_id, null)} /></div>
													<div>
														<div className='pullouts-setup-group-container-main-heading'>Students</div>
														<div className='pullouts-setup-group-student-list'>
															{student_list.map(student_id => {
																const student_index = students.findIndex(student => student.student_id === student_id);
																const student_info = (student_index !== -1) ? students[student_index] : null;
																if(!student_info) return;
					
																const student_last_name = student_info.last_name;
																const student_first_name = student_info.first_name;
																const student_grade = student_info.grade;
					
																return (
																	<div className='pullouts-setup-group-student' key={student_index}>
																		<div>{student_last_name}, {student_first_name} {student_grade && `- Grade ${student_grade}`}</div>
																		<div className='cursor-pointer' onClick={() => updateCourse(selectedCourseID, 'pullout_groups', student_id, 'remove', group_id, 'student_list')}><FontAwesomeIcon icon={faTimes}/></div>
																	</div>
																)
															})}
														</div>
														<div className='pullouts-setup-student-add-container'>
															<div className='pullouts-setup-student-total'>Total: {student_list.length}</div>
															<div className='blue-link medium-text pullouts-setup-add-link align-right' onClick={() => toggleGroupStudentSearch( group_id)}><FontAwesomeIcon icon={faCirclePlus}/> Add a student</div>
														</div>
														{student_search_open &&
															<div className='search-add-row-container'>
															<div className='search-add-search-container'>
																<div className='search-add-adding-input-container'>
																	<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => groupStudentSearch(e.target.value, group_id)} placeholder='Search for student'/>
																	<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
																</div>
																<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleGroupStudentSearch(group_id)}/>
															</div>
															{student_search_results.length > 0 &&
																<div className='elem-suggestion-box-parent parent-div'>
																	<div className='elem-suggestion-box'>
																		{student_search_results.map((student, index) => {
																			const student_id = student.student_id;
																			const student_first_name = student.first_name;
																			const student_last_name = student.last_name;
																			const search_student_grade = student.grade;
																			const student_in_group = student_list.includes(student_id);
			
																			return (
																				<div className='search-add-search-result' key={index} onClick={student_in_group ? ()=>{} : () => updateCourse(selectedCourseID, 'pullout_groups', student_id, 'add', group_id, 'student_list')}>
																					<div>{student_last_name}, {student_first_name} {search_student_grade && `- Grade ${search_student_grade}`}</div>
																					{student_in_group ?
																					(
																						<div className='green-text'>In group</div>
																					):
																					(
																						<div className='search-add-add-btn'>Add</div>
																					)}
																				</div>
																			)
																		})}
																	</div>
																</div>
															}
														</div>
														}
													</div>
													<div>
														<div className='pullouts-setup-group-container-main-heading'>Settings</div>
														<div className='pullouts-setup-group-label'># times needed per cycle</div>
														<Dropdown data={numTimesNeededOptions} currentValue={num_times_needed} handleChange={(new_value) => updateCourse(selectedCourseID, 'pullout_groups', new_value, 'change', group_id, 'num_times_needed')} />
														<div className='pullouts-setup-group-label'>Duration needed</div>
														<Dropdown data={durationOptions} currentValue={duration} handleChange={(new_value) => updateCourse(selectedCourseID, 'pullout_groups', new_value, 'change', group_id, 'duration')} />
														<div className='pullouts-setup-group-label'>What days can this run?</div>
														<div className='pullouts-setup-group-days'>
															{days.map(day => {
																const day_selected = (day_restrictions.lengh === 0 || !day_restrictions.includes(day));
																return (
																	<div className="option-btn option-btn-no-hover small-text" key={day} onClick={day_selected ? () => updateCourse(selectedCourseID, 'pullout_groups', day, 'add', group_id, 'day_restrictions') : () => updateCourse(selectedCourseID, 'pullout_groups', day, 'remove', group_id,'day_restrictions')}>
																		<div>
																		{(day_selected) ? 
																			(
																				<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare}/>
																			) : 
																			(
																				<FontAwesomeIcon className='fas-checkbox-unchecked' icon={faSquare}/>
																			)
																		}
																		</div>
																		<div>Day {day}</div>
																	</div>
																)
															})}
														</div>
														{/* <div className='pullouts-setup-group-label' style={{marginBottom:'10px'}}>Do all these students need to have this course together?</div>
														<div className='toggle-with-text-btn-container edit-course-toggle'>
															<div className={`toggle-with-text-btn-optn ${strict_group === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={() => updateCourse(selectedCourseID, 'pullout_groups', '1', 'change', group_id, 'strict_group')}>Yes</div><div className={`toggle-with-text-btn-optn ${strict_group === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={() => updateCourse(selectedCourseID, 'pullout_groups', '0', 'change', group_id, 'strict_group')}>No</div>
														</div>
														{strict_group === '0' &&
															<>
															<div className='pullouts-setup-group-label'>Max # students to group together</div>
															<Dropdown data={maxGroupSizeOptions} currentValue={max_group_size} handleChange={(new_value) => updateCourse(selectedCourseID, 'pullout_groups', new_value, 'change', group_id, 'max_group_size')} />
															</>
														} */}
													</div>
												</div>
											)
										})}
										<div className='blue-link medium-text pullouts-setup-add-link' onClick={() => updateCourse(selectedCourseID, 'pullout_groups', null, 'add', null, null)}><FontAwesomeIcon icon={faCirclePlus}/> Create a group</div>
									</div>
								</div>
								<div className='pullouts-setup-question-container'>
									<div>
										<div className='pullouts-setup-label'>Courses to pull out from</div>
										{pulloutAllowedCourses.length === 0 &&
											<div className='pullouts-setup-display'>No courses selected yet...</div>
										}
										{pulloutAllowedCourses.map((allowed_course_id, allowed_course_index) => {
											const course_index = courses.findIndex(course => course.course_id === allowed_course_id);
											const course_info = (course_index !== -1) ? courses[course_index] : null;

											if(!course_info) return;

											const course_name = course_info.name;
											const course_code = course_info.course_code;

											return (
												<div className='pullouts-setup-display' key={allowed_course_index}>
													<div>{course_name} {course_code && `(${course_code})`}</div>
													<div className='cursor-pointer' onClick={() => updateCourse(selectedCourseID, 'pullout_allowed_courses', allowed_course_id, 'remove')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)
										})}
										{(openSearches.pullout_allowed_courses) &&
											<div className='search-add-row-container'>
												<div className='search-add-search-container'>
													<div className='search-add-adding-input-container'>
														<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'pullout_allowed_courses')} placeholder='Search for course'/>
														<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
													</div>
													<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('pullout_allowed_courses')}/>
												</div>
												{searchResults['pullout_allowed_courses'].length > 0 &&
													<div className='elem-suggestion-box-parent parent-div'>
														<div className='elem-suggestion-box'>
															{searchResults['pullout_allowed_courses'].map((course, index) => {
																const course_id = course.course_id;
																const course_name = course.name;
																const course_code = course.course_code;

																return (
																	<div className='search-add-search-result' key={index}>
																		<div>{course_name} {course_code && `(${course_code})`}</div>
																		<div className='search-add-add-btn' onClick={() => updateCourse(selectedCourseID, 'pullout_allowed_courses', course_id, 'add')}>Add</div>
																	</div>
																)
															})}
														</div>
													</div>
												}
											</div>
										}
										<div className='blue-link medium-text pullouts-setup-add-link' onClick={() => toggleAddNewConnection('pullout_allowed_courses')}><FontAwesomeIcon icon={faCirclePlus}/> Add a Course</div>
									</div>
								</div>
							</div>
						}
						<div className='fixed-bottom-save-container'>
							<div className="btn btn-extra-large green-btn align-right move-to-next-screen-btn" onClick={autoPlacePullouts}>Auto Generate Pullouts!</div>
						</div>
						</>
					)
				}
			</div>
			</>
		)
		}
		</>
	)
}