import React from 'react';

import AssignDepartments from './assign_departments';

import '../../../css/hsms/assign_supervisors.css';

export default function AssignSupervisors({ isLoading, subscreenRestricted, subscreenLocked, subscreen, addSupervisorToDepartment, removeSupervisorFromDepartment, openSupervisorsList, closeSupervisorsList, scheduleStructureDataLoading, departments, setDepartments, supervisors, userInfo, schoolInfo, rolePages, setRolePages, handleMoveToNextScreen }) {
	return (
		<div className="school-setup-main-container">
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				) :
				(
					<AssignDepartments isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreen={subscreen} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} openSupervisorsList={openSupervisorsList} closeSupervisorsList={closeSupervisorsList} scheduleStructureDataLoading={scheduleStructureDataLoading} departments={departments} setDepartments={setDepartments} supervisors={supervisors} userInfo={userInfo} schoolInfo={schoolInfo} rolePages={rolePages} setRolePages={setRolePages} handleMoveToNextScreen={handleMoveToNextScreen}/>
				)
			}
		</div>
	);
}