import React, { useState, useEffect }  from 'react';
import { Link } from "react-router-dom";

import Button from '../../Button';
import Toggle from '../../Toggle';

import { updateDepartment, isEmpty } from '../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock, faUserCircle } from '@fortawesome/free-solid-svg-icons';

export default function PlacementPermissions({ isLoading, subscreenRestricted, subscreenLocked, departments, setDepartments, userInfo, schoolInfo, rolePages, handleMoveToNextScreen }) {
	const [subscreenIsComplete, setSubscreenIsComplete] = useState(false);

	const toggleAllowManualPlacements = (department_id, supervisor_id, toggle_state) => {
		const department_index = departments.findIndex(department => department.department_id === department_id);
		const supervisor_index = departments[department_index].supervisors.findIndex(supervisor => supervisor.user_id === supervisor_id);

		const allow_manual_placements = (toggle_state) ? '1' : '0';

		updateDepartment({update_type:'allow_manual_placements', school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, department_id:department_id, user_id:supervisor_id}).then(data => {
			departments[department_index].supervisors[supervisor_index].allow_manual_placements = allow_manual_placements;
			setDepartments([...departments]);
		});
	}

	useEffect(() => {
		if(rolePages)
		{
			const role_page_subscreens = rolePages['clean_data'].subscreens;
			const subscreen_index = role_page_subscreens.findIndex(temp_subscreen => temp_subscreen.subscreen === 'clean_duplicates');

			if(subscreen_index !== -1)
			{
				const subscreen_is_complete = role_page_subscreens[subscreen_index].is_complete;
				setSubscreenIsComplete(subscreen_is_complete);
			}
		}
	}, [rolePages]);

	const current_user_is_admin = (userInfo.role_id === '1'|| userInfo.role_id === '2' || userInfo.role_id === '5');
	
	return (
		<>
		<h1 className='progress-main-header'>Placement Permissions</h1>
		<p className='progress-subheader'>Set which department supervisors (if any) are allowed to place sections manually. Any supervisor given this permission, will get a chance to place sections before Edario auto-places the rest of the sections.</p>
		{(isLoading) ?
		(
			<div className='center-text'>
				<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
			</div>
		):(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(current_user_is_admin) ?
		(
			<>
			<div className='section-placement-container'>
				{isLoading ?
					(
						<div className='center-text'>
							<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
						</div>
					):
					(
						<div id='section-placement-department-list'>
							<div className='placement-permissions-department-row medium-text'>
								<div style={{fontWeight:'bold', textDecoration:'underline'}}>Department</div>
								<div className='placement-permissions-supervisor-container'>
									<div style={{fontWeight:'bold', textDecoration:'underline'}}>Supervisor(s)</div>
									<div style={{fontWeight:'bold', textAlign:'center', textDecoration:'underline'}}>Allow to manually place their sections?</div>
								</div>
							</div>
							{departments.map((department_info, index) => {
								const department_id = department_info.department_id;
								const department = department_info.department;
								const department_supervisors = department_info.supervisors;

								return (
									<div className='placement-permissions-department-row' key={index} data-id={department_id}>
										<div className='section-placement-department'>{department}</div>
										<div className='supervisors-container'>
											{isEmpty(department_supervisors) &&
												<div className='placement-permissions-supervisor-container'>
													<div className='department-supervisor-inner-container' style={{gridTemplateColumns:'auto 1fr', width:'100%', margin:'0px'}}>
														<FontAwesomeIcon className='department-supervisor-user-icon gray-text' icon={faUserCircle}/>
														<div className='department-supervisor-name gray-text'>No Supervisor</div>
													</div>
													<div></div>
												</div>
											}
											{department_supervisors.map((supervisor_info, index) => {
												const supervisor_id = supervisor_info.user_id;
												const allow_manual_placements = (supervisor_info.allow_manual_placements === '1');

												return (
													<div className='placement-permissions-supervisor-container' key={index} >
														<div className='department-supervisor-inner-container' style={{gridTemplateColumns:'auto 1fr', width:'100%'}}>
															{supervisor_info.img_url &&
																<img className='department-supervisor-img' src={require(`../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
															}
															{!supervisor_info.img_url &&
																<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
															}
															<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
														</div>
														<div  className='placement-permissions-toggle-container'>
															<Toggle toggleOn={allow_manual_placements} handleChange={(new_state) => toggleAllowManualPlacements(department_id, supervisor_id, new_state)}/>
														</div>
													</div>
												
												)
											})}
										</div>
									</div>
								)
							})}
							<div className='medium-text' style={{marginTop:'40px'}}>Missing supervisors? Go back to the <Link to={`/app/hsms/progress/assign_supervisors/`} className='blue-link'>Assign Supervisors</Link> and then come back here!</div>
						</div>
					)
				}
			</div>
			<div className="clear"></div>
			<div className='fixed-bottom-save-container'>
				<Button classes={['next_assign_step']} selected={false} text='Save & Continue' onClickFunction={handleMoveToNextScreen} />
			</div>
			</>
		):(userInfo && !current_user_is_admin && !subscreenIsComplete) ? 
		(
			<div className='locked-screen-notification-container'>
				<FontAwesomeIcon className='dark-blue-text' icon={faLock} style={{fontSize:'50px'}}/>
				<h1>Locked</h1>
				<p>This step is only accessible to the master scheduler. You should be notified when future steps are unlocked!</p>
			</div>
		):(userInfo && !current_user_is_admin && subscreenIsComplete) ?
		(
			<div className='locked-screen-notification-container'>
				<FontAwesomeIcon className='green-text' icon={faUnlock} style={{fontSize:'50px'}}/>
				<h1>Step Unlocked!</h1>
				<p>The scheduling admin has finished assigning department supervisors, and you can now move on to the next step!</p>
			</div>
		) : null}
		</>
	);
}