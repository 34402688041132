import React, { useState , useEffect } from 'react';

import { loadData } from '../../../js/Helpers';
import { FileUpload } from '../../../js/upload';

import UploadModal from '../../UploadModal';
import DatabaseDataTeachers from '../school_data/database_data_teachers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export default function UploadTeachers({ subscreenRestricted, subscreenLocked, subscreen, currentSIS, schoolInfo, scheduleInfo, teachersLoading, setTeachersLoading, courses, students, teachers, classrooms, setTeachers, departments, handleMoveToNextScreen }) {
	const [teacherDataExists, setTeacherDataExists] = useState(false);
	
	const [alreadyUploadedChecked, setAlreadyUploadedChecked] = useState(false);
	const [alreadyUploaded, setAlreadyUploaded] = useState(false);

	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showTeacherData, setShowTeacherData] = useState(false);

	const setDataExists = (value) => {
		setTeacherDataExists(value);
	}

	const handlePullNewData = () => {
		loadData({data_types:['teachers'], schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id, school_id:schoolInfo.school_id}).then(database_data => {
			setTeachers(database_data);
		});
		
		setDataExists(true);
		setAlreadyUploadedChecked(true);
		setAlreadyUploaded(true);
	}

	////////////////////////////////
	///// USE EFFECT FUNCTIONS /////
	////////////////////////////////
	useEffect(() => {
		if(schoolInfo.current_data_version_id && schoolInfo.school_id)
		{
			async function checkUploadAlreadyProcessed() {
				const file_upload = new FileUpload(schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, scheduleInfo.school_id);
			
				// Check whether courses have been uploaded
				const running_processes = await file_upload.getSchoolProcesses(schoolInfo.current_schedule_year_id);

				// Check if there are any running processes with the same data version id
				const dv_process_index = running_processes.findIndex(process => process.process_type === 'teacherdata' && process.data_version_id == schoolInfo.current_data_version_id && process.is_done);
				const sv_process_index = running_processes.findIndex(process => process.process_type === 'teacherdata' && process.schedule_version_id == schoolInfo.current_schedule_version_id && process.is_done);

				// If process has already finished running then set data existing
				if(sv_process_index !== -1 || dv_process_index !== -1) setAlreadyUploaded(true);

				setAlreadyUploadedChecked(true);
			}

			checkUploadAlreadyProcessed();
		}
	}, [scheduleInfo, schoolInfo, subscreen]);

	useEffect(() => {
		if(teachers.length > 0) setTeacherDataExists(true);
	}, [teachers]);

	return (
		<>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):
		(
			<>
			{showUploadModal &&
				<UploadModal schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} uploadTitle='Teachers' uploadAPIName='teacherdata' closeModal={() => setShowUploadModal(false)} handlePullNewData={handlePullNewData}/>
			}
			<h1 className='progress-main-header'>Upload Teachers</h1>
			<p className='progress-subheader'>Let's import your teacher list from your current SIS.</p>
			<div className="upload_screen">
				<div className='upload-screen-explanation-container'>
					<h2 className="upload-screen-heading-text"><span className="ms_login_system_name capitalize">{currentSIS}</span> Teacher Upload</h2>
					<p className='upload-screen-description'>Let&rsquo;s start by downloading the following data from <span className="ms_login_system_name capitalize">{currentSIS}</span>:</p>
					<div className='upload-screen-data-container'>
						<img className='upload-info-image' src={require(`../../../images/teacher.png`)} alt='teachers'/>
						<div className='upload-info-name'>Teachers</div>
						{teacherDataExists ?
							(
								<>
								<div>
									<FontAwesomeIcon icon={faCheckCircle} className='green-text upload-complete'/>
									<div className='upload-complete-text'>Complete</div>
								</div>
								<div className='btn btn-small blue-btn' onClick={() => setShowTeacherData(!showTeacherData)}>See Data</div>
								</>
							):
							(
								<>
								<div>
									<FontAwesomeIcon icon={faCheckCircle} className='gray-text upload-complete'/>
									<div className='upload-complete-text gray-text'>No Data</div>
								</div>
								<div className='white-text'>.</div>
								</>
							)
						}
					</div>
					<div>
						{!alreadyUploadedChecked ?
							(
								<div className='center-text'>
									<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
								</div>
							): (alreadyUploaded)  ? 
							(
								<div className='upload-screen-already-uploaded-container'>
										<div>
											<FontAwesomeIcon className='upload-screen-already-uploaded-check green-text' icon={faCheckCircle}/>
										</div>
										<div>
											<h3 className='upload-screen-already-uploaded-header'>You already uploaded Teachers!</h3>
											<div>You can move on to the next step, or <span className='blue-link' onClick={() => setShowUploadModal(true)}>Reupload Teachers</span>!</div>
										</div>
									</div>
							):
							(
								<div className='center-text'>
									<span className='btn turquoise-btn btn-round btn-large upload-btn' onClick={() => setShowUploadModal(true)}>Upload Teachers</span>
								</div>
							)
						}
					</div>
					{/*<p className='upload-how-to-line'><FontAwesomeIcon className='info-icon blue-text' icon={faQuestionCircle}/> <Link to={`/app/documentation/upload_data/upload_teachers/${currentSIS}`} target="_blank" style={{color:'#3498db'}}>How to download teachers from <span className="ms_login_system_name capitalize">{currentSIS}</span></Link></p>*/}
				</div>
				{showTeacherData &&
					<div className='upload-parent-container'>
						<div className='upload-screen-close-data gray-to-dark-blue-link' onClick={() => {setShowTeacherData(false)}}>
							<FontAwesomeIcon icon={faTimesCircle}/>
						</div>
						<DatabaseDataTeachers schoolInfo={schoolInfo} databaseData={teachers}  setDatabaseData={setTeachers} scheduleInfo={scheduleInfo} courses={courses} classrooms={classrooms} students={students} departments={departments} isLoading={teachersLoading} setIsLoading={setTeachersLoading}/>
					</div>
				}
			</div>
			{(teacherDataExists) &&
				<div className='fixed-bottom-save-container'>
					<div className="btn btn-extra-large green-btn align-right move-to-next-screen-btn move-to-next-screen-btn" onClick={handleMoveToNextScreen}>Go to Next Step: Upload Classrooms</div>
				</div>
			}
			</>
		)}
		</>
	);
}