import React, { useState , useEffect, useRef } from 'react';
import swal from '@sweetalert/with-react';

import { filterArrayOfObjects, deleteData } from '../../../js/Helpers';

import SortToggle from '../../SortToggle';
import UploadModal from '../../UploadModal';
import EditStudentData from '../modals/edit_student';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

export default function DatabaseDataStudents({ schoolInfo, databaseData, setDatabaseData, scheduleInfo, courses, teachers, dataIsLoading, blocks, pulloutGroups, setPulloutGroups, handlePullNewData }) {
	const [isLoading, setIsLoading] = useState(true);
	const [handleChangeComplete, setHandleChangeComplete] = useState(false);

	const [displayResults, setDisplayResults] = useState(databaseData);
	const [isSearching, setIsSearching] = useState(false);
	
	const [showEditDataModal, setShowEditDataModal] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [currentDataID, setCurrentDataID] = useState(null);
	
	const [dontShowDeletePopup, setDontShowDeletePopup] = useState(false);
	
	const [maxStudentsToShow, setMaxStudentsToShow] = useState(50);

	const databaseDataRef = useRef(databaseData);
	databaseDataRef.current = databaseData;
	
	let typingTimeout = null;
	
	const handleIsSearching = (e) => {
		const search_value = e.target.value;
		
		if(search_value.length > 1)
		{
			if(e.keyCode !== 8)
			{
				setIsLoading(false);
				setIsSearching(true);
			}
			else
			{
				setIsLoading(true);
				setIsSearching(false);
			}
		}
		else
		{
			setIsLoading(false);
			setIsSearching(false);
		}
	}
	
	const handleChange = () => {
		setMaxStudentsToShow(50);
		
		if(databaseDataRef.current.length === 0 && dataIsLoading) return;
		if(typingTimeout) clearTimeout(typingTimeout);
		
		const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
		
		typingTimeout = setTimeout(() => {
			if(search_value.length > 1)
			{
					const filtered_results = filterArrayOfObjects(databaseDataRef.current, search_value, ['last_name', 'first_name', 'student_code']);
					setDisplayResults([...filtered_results]);
			}
			else
			{
				setDisplayResults([...databaseDataRef.current]);
			}

			setIsLoading(false);
			setIsSearching(false);
			setHandleChangeComplete(true);
		}, 300);
	}
	
	const showMoreStudents = () => {
		const num_students_to_show = maxStudentsToShow + 50;
		setMaxStudentsToShow(num_students_to_show);
	}
	
	const handleSortClick = (sortedData) => {
		setDisplayResults([...sortedData]);
	}
	
	const toggleEditData = (dataID) => {
		setCurrentDataID(dataID);
		setShowEditDataModal(!showEditDataModal);
	}
	
	const toggleDontShow = () => {
		setDontShowDeletePopup(!dontShowDeletePopup);
	}
	
	const handeDeleteData = async (data_id) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'student', data_ids:[data_id], database:'elementary'};
		let delete_data_bool = true;
		
		const data_index = databaseData.findIndex(student => student.student_id === data_id);
		const data_info = databaseData[data_index];
		
		if(!dontShowDeletePopup)
		{
			const gender_pronoun = (data_info.gender === "M") ? "him" : ((data_info.gender === "F") ? "her" : "them");
			const options =  {
				title: "Are you sure?",
				icon: "warning",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'red-btn'
					},
				},
				content: (
					<div>
						<div>
							<p>Do you really want to delete {data_info.first_name} {data_info.last_name}?</p>
							<p className='red-text'>This will <strong>permanently</strong> remove {gender_pronoun} from the database.</p>
						</div>
						<div className='sweet-alert-dont-show-message' onClick={() => toggleDontShow('remove')}>
							<label className="checkmark_container">Please don&rsquo;t show me this message again
								<input type="checkbox" />
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
				)
			}
		
			delete_data_bool = await swal(options);
		}
		
		if(delete_data_bool)
		{
			databaseData.splice(data_index, 1);
			setDatabaseData([...databaseData]);
			
			deleteData(data);
		}
	}
	
	useEffect(() => {
		if(!showEditDataModal) setCurrentDataID(null);

		const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
		if(search_value.length > 0) handleChange();
	}, [showEditDataModal]);
	
	useEffect(() => {
		setDisplayResults([...databaseData]);
		handleChange();
	}, [databaseData, dataIsLoading]);
	
	let student_counter = 1;
	const row_style = {gridTemplateColumns:'100px 2fr 2fr 80px 2fr 70px'};

	//console.log({databaseData, displayResults});
	
	return (
		<>
		{showEditDataModal &&
			<EditStudentData schoolInfo={schoolInfo} dataID={currentDataID} toggleEditData={toggleEditData} scheduleInfo={scheduleInfo} students={databaseData} courses={courses} teachers={teachers} setDatabaseData={setDatabaseData} blocks={blocks} pulloutGroups={pulloutGroups} setPulloutGroups={setPulloutGroups}/>
		}
		{showUploadModal &&
			<UploadModal schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} uploadTitle='Students' uploadAPIName='studentdata' closeModal={() => setShowUploadModal(false)} handlePullNewData={handlePullNewData}/>
		}
		<div className='school-data-content-screen'>
			<h1 className='school-data-main-heading capitalize'>Students</h1>
			<div className='school-data-top-bar'>
				<div className='school-data-search-bar-container'>
					<input className='school-data-search-bar' placeholder="Search for students..." onChange={handleChange} onKeyDown={(e) => handleIsSearching(e)} />
					<FontAwesomeIcon className='school-data-search-bar-icon' icon={faSearch}/>
				</div>
				<div className='btn btn-icon-left turquoise-btn' onClick={() => setShowUploadModal(true)}>
					<FontAwesomeIcon icon={faUpload} />
					<div>Upload</div>
				</div>
			</div>
			<div className='school-data-database-display'>
				<div className='school-data-database-row school-data-database-display-header fixed-heading-on-scroll' style={row_style}>
					<div className='school-data-database-header-col'>Code</div>
					<div className='school-data-database-header-col'>Last Name <SortToggle handleOnClick={handleSortClick} sortKey='last_name' sortType='text' passedData={databaseData}/></div>
					<div className='school-data-database-header-col'>First Name <SortToggle handleOnClick={handleSortClick} sortKey='first_name' sortType='text' passedData={databaseData}/></div>
					<div className='school-data-database-header-col'>Grade <SortToggle handleOnClick={handleSortClick} sortKey='grade' sortType='text' passedData={databaseData}/></div>
					{/* <div className='school-data-database-header-col text-align-right'>Gender <SortToggle handleOnClick={handleSortClick} sortKey='gender' sortType='text' passedData={databaseData}/></div> */}
					<div className='school-data-database-header-col'>Homeroom</div>
					<div className='school-data-database-add-new-container' onClick={() => toggleEditData(null)}>
						<div className='school-data-database-add-new-btn'>
							<FontAwesomeIcon className='white-text' icon={faPlus}/>
						</div>
					</div>
				</div>
				{(!handleChangeComplete && isLoading) ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
					</div>
				): isSearching ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/searching.gif')} alt='searching...' style={{height:'80px'}}/>
					</div>
				):
				(
					<>
					{displayResults.length === 0 &&
						<div className='school-data-database-message-container'>
							<h4>Sorry, no results were found!</h4>
						</div>
					}
					{displayResults.map((data_info, index) => {
						if(student_counter > maxStudentsToShow) return;
						student_counter++;

						const display_grade = (data_info.grade === '0') ? 'K' : data_info.grade;
						const homeroom_teacher_id = data_info.homeroom_teacher_id;
						const homeroom_teacher_info = teachers.find(teacher => teacher.teacher_id === homeroom_teacher_id);
						const homeroom_teacher_last_name = (homeroom_teacher_info) ? homeroom_teacher_info.name : null;
						
						return (
							<div className='school-data-database-row' style={row_style} key={data_info.student_id}>
								<div className='medium-text'>{data_info.student_code}</div>
								<div>{data_info.last_name}</div>
								<div>{data_info.first_name}</div>
								<div className='medium-text'>{display_grade}</div>
								{/* <div className='medium-text'>{data_info.gender}</div> */}
								<div className='medium-text'>{homeroom_teacher_last_name}</div>
								<div className='school-data-database-row-btn-container'>
									<FontAwesomeIcon className='gray-to-dark-blue-link' icon={faEdit} onClick={() => toggleEditData(data_info.student_id)}/>
									<FontAwesomeIcon className='gray-to-red-link' icon={faTrashAlt} onClick={() => handeDeleteData(data_info.student_id)}/>
								</div>
							</div>
						)
					})}
					</>
				)}
			</div>
			{(student_counter > maxStudentsToShow) && 
				<div className='btn blue-btn load-more-btn' onClick={showMoreStudents}>Load More Students</div>
			}
		</div>
		</>
	);
}