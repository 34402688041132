import React from 'react';

import PulloutsCourses from './pullouts_courses';
import PulloutsReview from './pullouts_review';

export default function PulloutsSetup({ isLoading, schoolSubscribed, subscreenRestricted, subscreenLocked, subscreen, schoolInfoLoading, schoolInfo, periods, periodsLoading, scheduleInfoLoading, scheduleInfo, coursesLoading, courses, setCourses, teachersLoading, teachers, setTeachers, studentsLoading, students, setStudents, classroomsLoading, classrooms, setClassrooms, blocksLoading, blocks, setBlocks, pulloutGroups, setPulloutGroups, handleMoveToNextScreen, setLoadScreen }) {
	return (
		<div>
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				) :
				(
					<>
					{(() => {
						switch(subscreen) {
							case 'pullouts_courses':
								return <PulloutsCourses isLoading={isLoading} schoolSubscribed={schoolSubscribed} subscreenLocked={subscreenLocked} subscreenRestricted={subscreenRestricted} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} periods={periods} courses={courses} setCourses={setCourses} teachers={teachers} setTeachers={setTeachers} students={students} setStudents={setStudents} classrooms={classrooms} blocks={blocks} setBlocks={setBlocks} pulloutGroups={pulloutGroups} setPulloutGroups={setPulloutGroups} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							case 'pullouts_review':
								return <PulloutsReview isLoading={isLoading} schoolSubscribed={schoolSubscribed} subscreenLocked={subscreenLocked} subscreenRestricted={subscreenRestricted} schoolInfoLoading={schoolInfoLoading} schoolInfo={schoolInfo} scheduleInfoLoading={scheduleInfoLoading} scheduleInfo={scheduleInfo} periodsLoading={periodsLoading} periods={periods} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} studentsLoading={studentsLoading} students={students} setStudents={setStudents} classroomsLoading={classroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} blocksLoading={blocksLoading} blocks={blocks} setBlocks={setBlocks} setLoadScreen={setLoadScreen}/>;
							default:
								return null;
						}
					})()}
					</>
				)
			}
		</div>
	);
}