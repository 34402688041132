import React, { useState } from 'react';
import swal from '@sweetalert/with-react';

import CleanDuplicates from './clean_duplicates';
import CleanTeacherAvailability from './clean-data-screens/teacher_availability';
import CleanCourseAvailability from './clean-data-screens/course_availability';
import CleanCourseCaps from './clean-data-screens/course_caps';
import CleanCourseOptions from './clean-data-screens/course_options';
import CleanCourseOtherOptions from './clean-data-screens/course_other_options';
import CleanCourseTeachers from './clean-data-screens/course_teachers';
import CleanClassroomDetails from './clean-data-screens/classroom_details';
import CleanCourseClassrooms from './clean-data-screens/course_classrooms';
import CleanTeacherClassrooms from './clean-data-screens/teacher_classrooms';

import { capitalizeFirstLetters } from '../../../js/Helpers';
import { changeDataDepartment } from '../../../js/SaveFunctions';

import '../../../css/hsms/clean_data.css';

export default function CleanData({ isLoading, subscreenRestricted, subscreenLocked, subscreenResponsibleRoleID, supervisors, userIsSupervisor, userIsAdmin, openSupervisorsList, closeSupervisorsList, addSupervisorToDepartment, removeSupervisorFromDepartment, subscreen, departments, setDepartments, departmentID, sortedDepartments, changeDepartment, userInfo, schoolInfo, schoolType, scheduleInfo, scheduleType, periods, scheduleStructureData, scheduleStructureDataLoading, users, appSubscreens, setNotifications, rolePages, setRolePages, teachersLoading, teachers, coursesLoading, courses, classrooms, students, setTeachers, setCourses, setClassrooms, setStudents, sections, labels, submitDepartment, reviewing, subscreenSelect, showNextButton, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo }) {
	const [dontShowChangeDepartments, setDontShowChangeDepartments] = useState(false);
	
	const [addNewTeacherOpen, setAddNewTeacherOpen] = useState(false);
	const [addNewCourseOpen, setAddNewCourseOpen] = useState(false);
	const [addNewClassroomOpen, setAddNewClassroomOpen] = useState(false);

	const changeDepartmentResource = async (changeType, dataType, data_id, old_department_id, new_department_id, _callback=()=>{}) => {
		if(old_department_id === new_department_id) return;
		if(changeType === "change" && new_department_id === '0') changeType = 'remove';
		
		let change_data_department_bool = true;
		
		/////////////////////////
		///// GET DATA INFO /////
		/////////////////////////
		let data_index, data_details, data_name;
		if(dataType === 'teacher')
		{
			data_index = teachers.findIndex(teacher => teacher.teacher_id === data_id);
			data_details = teachers[data_index];
			
			const teacher_first_name = data_details.first_name;
			const teacher_last_name = data_details.name;
			
			data_name = `${teacher_first_name} ${teacher_last_name}`;
		}
		else if(dataType === 'course')
		{
			data_index= courses.findIndex(course => course.course_id === data_id);
			data_details = courses[data_index];
			
			const course_name = capitalizeFirstLetters(data_details.name);
			const course_code = data_details.course_code;
			
			data_name = `${course_name} (${course_code})`;
		}
		else if(dataType === 'classroom')
		{
			data_index= classrooms.findIndex(classroom => classroom.classroom_id === data_id);
			data_details = classrooms[data_index];
			
			data_name = data_details['classroom_name'];
		}
		
		const old_deparment_info = departments.find(department => department.department_id === old_department_id);
		const new_deparment_info = departments.find(department => department.department_id === new_department_id);
		
		const department = (changeType === 'remove') ? old_deparment_info['department'] : new_deparment_info['department'];
		
		//////////////////////
		///// SHOW MODAL /////
		//////////////////////
		// Show modal unless Dont Show Again was selected
		if(!dontShowChangeDepartments)
		{
			const modal_text = (changeType === 'remove') ? `Do you really want to remove ${data_name} from the ${department} department?` : ((changeType === 'add') ? `Do you really want to add ${data_name} to the ${department} department?`: null);
			
			const options =  {
				title: "Are you sure?",
				text: modal_text,
				icon: "warning",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'red-btn'
					},
				},
				content: (
					<div className='sweet-alert-dont-show-message' onClick={() => setDontShowChangeDepartments(!dontShowChangeDepartments)}>
						<label className="checkmark_container">Please don&rsquo;t show me this message again
							<input type="checkbox" />
							<span className="checkmark"></span>
						</label>
					</div>
				)
			}
		
			change_data_department_bool = await swal(options);
		}
		
		////////////////////////////////
		///// UPDATE DATABASE/JSON /////
		////////////////////////////////
		// Add, change, or remove in database
		// Then add, change, or remove in JSON
		
		if(change_data_department_bool)
		{
			const data = {school_id:schoolInfo.school_id, change_type:changeType, data_type:dataType, schedule_version_id:schoolInfo.current_schedule_version_id, data_id:data_id, old_department_id:old_department_id, new_department_id:new_department_id, data_name:data_name};

			changeDataDepartment(data).then(() => {
				// Remove old department
				if(changeType === 'remove')
				{
					if(dataType === 'teacher')
					{
						const old_teacher_department_index = teachers[data_index]['departments'].findIndex(department_id => department_id === old_department_id);
						teachers[data_index]['departments'].splice(old_teacher_department_index, 1);
					}
					else if(dataType === 'course')
					{
						const old_course_department_index = courses[data_index]['departments'].findIndex(department_id => department_id === old_department_id);
						courses[data_index]['departments'].splice(old_course_department_index, 1);
					}
					else if(dataType === 'classroom')
					{
						const old_teacher_department_index = classrooms[data_index]['departments'].findIndex(department_id => department_id === old_department_id);
						classrooms[data_index]['departments'].splice(old_teacher_department_index, 1);
					}
				}
				
				// Add new department
				if(changeType !== 'remove')
				{
					if(dataType === 'teacher')
					{
						teachers[data_index]['departments'].push(new_department_id);
					}
					else if(dataType === 'course')
					{
						courses[data_index]['departments'].push(new_department_id);
					}
					else if(dataType === 'classroom')
					{
						classrooms[data_index]['departments'].push(new_department_id);
					}
				}

				setTeachers([...teachers]);
				setCourses([...courses]);
				setClassrooms([...classrooms]);

				_callback();
			});
		}
	}

	//console.log(subscreen);
	
	return (
		<div className="school-setup-main-container">
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				) :
				(
					<>
					{(() => {
						switch(subscreen) {
							case 'clean_duplicates':
								return <CleanDuplicates subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} userInfo={userInfo} rolePages={rolePages} schoolInfo={schoolInfo} appSubscreens={appSubscreens} setNotifications={setNotifications} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							case 'teacher_availability':
								return <CleanTeacherAvailability isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} supervisors={supervisors} openSupervisorsList={openSupervisorsList} closeSupervisorsList={closeSupervisorsList} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} users={users} userIsSupervisor={userIsSupervisor} userIsAdmin={userIsAdmin} departments={departments} setDepartments={setDepartments} departmentID={departmentID} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} userInfo={userInfo} schoolInfo={schoolInfo} schoolType={schoolType} scheduleInfo={scheduleInfo} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} courses={courses} classrooms={classrooms} students={students} sections={sections} scheduleStructureData={scheduleStructureData} scheduleType={scheduleType} periods={periods} addNewTeacherOpen={addNewTeacherOpen} setAddNewTeacherOpen={setAddNewTeacherOpen} subscreenSelect={subscreenSelect} submitDepartment={submitDepartment} reviewing={reviewing} changeDepartmentResource={changeDepartmentResource} setRolePages={setRolePages} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							case 'course_availability':
								return <CleanCourseAvailability isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} supervisors={supervisors} openSupervisorsList={openSupervisorsList} closeSupervisorsList={closeSupervisorsList} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} users={users} userIsSupervisor={userIsSupervisor} userIsAdmin={userIsAdmin} departments={departments} setDepartments={setDepartments} departmentID={departmentID} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} userInfo={userInfo} schoolInfo={schoolInfo} schoolType={schoolType} scheduleInfo={scheduleInfo} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} teachers={teachers} classrooms={classrooms} students={students} setStudents={setStudents} sections={sections} labels={labels} scheduleStructureData={scheduleStructureData} scheduleType={scheduleType} periods={periods} addNewCourseOpen={addNewCourseOpen} setAddNewCourseOpen={setAddNewCourseOpen} subscreenSelect={subscreenSelect} submitDepartment={submitDepartment} reviewing={reviewing} changeDepartmentResource={changeDepartmentResource} setRolePages={setRolePages} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							case 'course_caps':
								return <CleanCourseCaps isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} supervisors={supervisors} openSupervisorsList={openSupervisorsList} closeSupervisorsList={closeSupervisorsList} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} users={users} userIsSupervisor={userIsSupervisor} userIsAdmin={userIsAdmin} departments={departments} setDepartments={setDepartments} departmentID={departmentID} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} userInfo={userInfo} schoolInfo={schoolInfo} schoolType={schoolType} scheduleInfo={scheduleInfo} courses={courses} setCourses={setCourses} teachers={teachers} classrooms={classrooms} students={students} setStudents={setStudents} sections={sections} labels={labels} scheduleStructureData={scheduleStructureData} scheduleType={scheduleType} periods={periods} addNewCourseOpen={addNewCourseOpen} setAddNewCourseOpen={setAddNewCourseOpen} subscreenSelect={subscreenSelect} submitDepartment={submitDepartment} reviewing={reviewing} changeDepartmentResource={changeDepartmentResource} setRolePages={setRolePages} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							case 'course_options':
								return <CleanCourseOptions isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} supervisors={supervisors} openSupervisorsList={openSupervisorsList} closeSupervisorsList={closeSupervisorsList} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} users={users} userIsSupervisor={userIsSupervisor} userIsAdmin={userIsAdmin} departments={departments} setDepartments={setDepartments} departmentID={departmentID} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} userInfo={userInfo} schoolInfo={schoolInfo} schoolType={schoolType} scheduleInfo={scheduleInfo} courses={courses} setCourses={setCourses} teachers={teachers} classrooms={classrooms} students={students} setStudents={setStudents} sections={sections} labels={labels} scheduleStructureData={scheduleStructureData} addNewCourseOpen={addNewCourseOpen} setAddNewCourseOpen={setAddNewCourseOpen} subscreenSelect={subscreenSelect} submitDepartment={submitDepartment} reviewing={reviewing} changeDepartmentResource={changeDepartmentResource} setRolePages={setRolePages} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							case 'course_other_options':
								return <CleanCourseOtherOptions isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} supervisors={supervisors} openSupervisorsList={openSupervisorsList} closeSupervisorsList={closeSupervisorsList} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} users={users} userIsSupervisor={userIsSupervisor} userIsAdmin={userIsAdmin} departments={departments} setDepartments={setDepartments} departmentID={departmentID} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} userInfo={userInfo} schoolInfo={schoolInfo} schoolType={schoolType} scheduleInfo={scheduleInfo} courses={courses} setCourses={setCourses} teachers={teachers} classrooms={classrooms} students={students} setStudents={setStudents} sections={sections} labels={labels} scheduleStructureData={scheduleStructureData} addNewCourseOpen={addNewCourseOpen} setAddNewCourseOpen={setAddNewCourseOpen} subscreenSelect={subscreenSelect} submitDepartment={submitDepartment} reviewing={reviewing} changeDepartmentResource={changeDepartmentResource} setRolePages={setRolePages} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							case 'course_teachers':
								return <CleanCourseTeachers isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} supervisors={supervisors} openSupervisorsList={openSupervisorsList} closeSupervisorsList={closeSupervisorsList} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} users={users} userIsSupervisor={userIsSupervisor} userIsAdmin={userIsAdmin} departments={departments} setDepartments={setDepartments} departmentID={departmentID} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} userInfo={userInfo} schoolInfo={schoolInfo} schoolType={schoolType} scheduleInfo={scheduleInfo} teachers={teachers} courses={courses} setCourses={setCourses} classrooms={classrooms} students={students} setStudents={setStudents} sections={sections} labels={labels} scheduleStructureData={scheduleStructureData} addNewCourseOpen={addNewCourseOpen} setAddNewCourseOpen={setAddNewCourseOpen} subscreenSelect={subscreenSelect} submitDepartment={submitDepartment} reviewing={reviewing} changeDepartmentResource={changeDepartmentResource} setRolePages={setRolePages} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							case 'classroom_details':
								return <CleanClassroomDetails isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} supervisors={supervisors} openSupervisorsList={openSupervisorsList} closeSupervisorsList={closeSupervisorsList} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} users={users} userIsSupervisor={userIsSupervisor} userIsAdmin={userIsAdmin} departments={departments} setDepartments={setDepartments} departmentID={departmentID} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} userInfo={userInfo} schoolInfo={schoolInfo} schoolType={schoolType} scheduleInfo={scheduleInfo} classrooms={classrooms} setClassrooms={setClassrooms} courses={courses} teachers={teachers} sections={sections} scheduleStructureData={scheduleStructureData} scheduleStructureDataLoading={scheduleStructureDataLoading} addNewClassroomOpen={addNewClassroomOpen} setAddNewClassroomOpen={setAddNewClassroomOpen} subscreenSelect={subscreenSelect} submitDepartment={submitDepartment} reviewing={reviewing} changeDepartmentResource={changeDepartmentResource} setRolePages={setRolePages} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							case 'course_classrooms':
								return <CleanCourseClassrooms isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} supervisors={supervisors} openSupervisorsList={openSupervisorsList} closeSupervisorsList={closeSupervisorsList} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} users={users} userIsSupervisor={userIsSupervisor} userIsAdmin={userIsAdmin} departments={departments} setDepartments={setDepartments} departmentID={departmentID} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} userInfo={userInfo} schoolInfo={schoolInfo} schoolType={schoolType} scheduleInfo={scheduleInfo} classrooms={classrooms} setClassrooms={setClassrooms} courses={courses} setCourses={setCourses} teachers={teachers} students={students} setStudents={setStudents} sections={sections} labels={labels} scheduleStructureData={scheduleStructureData} addNewCourseOpen={addNewCourseOpen} setAddNewCourseOpen={setAddNewCourseOpen} subscreenSelect={subscreenSelect} submitDepartment={submitDepartment} reviewing={reviewing} changeDepartmentResource={changeDepartmentResource} setRolePages={setRolePages} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							case 'teacher_classrooms':
								return <CleanTeacherClassrooms isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} supervisors={supervisors} openSupervisorsList={openSupervisorsList} closeSupervisorsList={closeSupervisorsList} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} users={users} userIsSupervisor={userIsSupervisor} userIsAdmin={userIsAdmin} departments={departments} setDepartments={setDepartments} departmentID={departmentID} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} userInfo={userInfo} schoolInfo={schoolInfo} schoolType={schoolType} scheduleInfo={scheduleInfo} classrooms={classrooms} setClassrooms={setClassrooms} teachers={teachers} setTeachers={setTeachers} courses={courses} students={students} sections={sections} scheduleStructureData={scheduleStructureData} addNewTeacherOpen={addNewTeacherOpen} setAddNewTeacherOpen={setAddNewTeacherOpen} subscreenSelect={subscreenSelect} submitDepartment={submitDepartment} reviewing={reviewing} changeDepartmentResource={changeDepartmentResource} setRolePages={setRolePages} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							default:
								return null;
						}
					})()}
					</>
				)
			}
		</div>
	);
}