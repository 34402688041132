import React, { useState } from 'react';
import Fuse from 'fuse.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { capitalizeFirstLetters } from '../../../js/Helpers';

export default function ConflictMatrix({ scheduleName, students, courses }) {
	const [filterIDs, setFilterIDs] = useState([]);

	const fuzzySearch = (e) => {
		const search_value = e.target.value;

		const filtered_course_ids = [];
		
		// Search courses by name or code
		const courses_fuse = new Fuse(courses, {
			keys: ['name', 'course_code'],
			threshold: .1
		});

		const course_results = courses_fuse.search(search_value);
		const subset_course_results = course_results.map(result => result.item).slice(0,4);

		subset_course_results.forEach(course => {
			const course_id = course.course_id;

			filtered_course_ids.push(course_id);
		});

		setFilterIDs([...filtered_course_ids]);
	}

	return (
		<>
			<h4 className='report-schedule-name'>{scheduleName}</h4>
			<h1 className='report-name'>Conflict Matrix</h1>
			<div className='reports-search-bar-container'>
				<input className='reports-search-bar' placeholder={`Search for a course or course code...`} defaultValue='' onChange={(e) => fuzzySearch(e)}/>
				<FontAwesomeIcon className='reports-search-bar-icon' icon={faSearch}/>
			</div>
			<div className='reports-main-container' style={{width:'450px'}}>
				<div className='reports-main-inner-container'>
					{courses.map((course_info, i) => {
						const course_id = course_info.course_id;

						if(filterIDs.length > 0 && !filterIDs.includes(course_id)) return;

						let course_conflicts = {};

						const students_requesting_course = students.filter(student => (student.student_requests.findIndex(request => request.course_id === course_id && request.is_deleted === '0') !== -1));
						students_requesting_course.forEach(student_info => {
							const student_requests = student_info.student_requests;

							student_requests.forEach(request => {
								const request_course_id = request.course_id;

								if(request_course_id === course_id) return;
								if(!(request_course_id in course_conflicts)) course_conflicts[request_course_id] = 0;

								course_conflicts[request_course_id]++;
							});
						});

						const sorted_course_conflicts = Object.entries(course_conflicts).sort(([,a],[,b]) => b-a);

						return (
							<div className='conflict-matrix-course-container' key={i}>
								<h3>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</h3>
								{sorted_course_conflicts.length === 0 &&
									<div className='conflict-matrix-no-data'>No current conflict data</div>
								}
								<div className='conflict-matrix-course-inner-container'>
									{sorted_course_conflicts.length > 0 &&
										<div className='conflict-matrix-conflict-header'>
											<div>Conflicting Course</div> 
											<div style={{textAlign:'right'}}># Conflicting Students</div>
										</div>
									}
									{sorted_course_conflicts.map((conflicted_course, j) => {
										const conflicted_course_id = conflicted_course[0];
										const conflicted_course_num_requests = conflicted_course[1];

										const conflicted_course_info = courses.find(course => course.course_id === conflicted_course_id);

										return (
											<div className='conflict-matrix-conflict-line' key={j}>
												<div>{capitalizeFirstLetters(conflicted_course_info.name)} ({conflicted_course_info.course_code}) :</div> 
												<div>{conflicted_course_num_requests}</div>
											</div>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</>
	);
}