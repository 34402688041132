import React, { useState , useEffect } from 'react';
import Fuse from 'fuse.js';
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';
import Portal from '../../Portal';
import BulkEditCourseRequest from '../../BulkEditCourseRequest';

import StudentRequests from '../reports/student_requests';

import { loadSpecificData, sortArrayOfObjects, isEmpty, calculateConflicts, recalculateMatchPercent } from '../../../js/Helpers';
import { saveDataToDatabase } from '../../../js/SaveFunctions';

import '../../../css/hsms/edit_modals.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTimes, faSearch, faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSave, faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';

export default function EditCourseData({ dataID, toggleEditData, schoolInfo, scheduleStructureData, scheduleInfo, teachers, students, courses, classrooms, departments, sections, setSections, labels, setDatabaseData, setStudents, defaultDepartmentID = null, filteredResults = [], setFilteredResults = ()=>{} }) {
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState({});
	
	const [tctResults, setTctResults] = useState([]);
	const [classroomRestrictionResults, setClassroomRestrictionResults] = useState([]);
	
	const [addTctOpen, setAddTctOpen] = useState(false);
	const [addClassroomRestrictionOpen, setAddClassroomRestrictionOpen] = useState(false);
	const [addStudentRequestOpen, setAddStudentRequestOpen] = useState(false);

	const [selectedStudents, setSelectedStudents] = useState([]);
	
	const [changesMade, setChangesMade] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	
	////////////////////////////////
	///// SET DROPDOWN OPTIONS /////
	////////////////////////////////
	const primarySecondaryOptions = [{value:'1', display:'Primary'}, {value:'2', display:'Back Up'}];
	const requestOptions = [{value:'1', display:'General'}, {value:'2', display:'Alternate'}];
	
	let maxNumSectionsOptions = [];
	for (let i = 0; i < 16; i++) {
		const display = (i === 0) ? 'None' : i;
		maxNumSectionsOptions.push({value:`${i}`, display:display});
	}
	
	let maxClassSizeOptions = [];
	for (let i = 0; i < 300; i++) {
	  maxClassSizeOptions.push({value:`${i}`, display:i});
	}
	
	const semesterOptions = [
		{value:'FY', display: 'FY - Full Year'},
		{value:'S', display: 'S - Semester'},
		{value:'S1', display: 'S1 - Semester 1'},
		{value:'S2', display: 'S1 - Semester 2'},
		{value:'Q', display: 'Q - Quarter'},
		{value:'Q1', display: 'Q1 - Quarter 1'},
		{value:'Q2', display: 'Q2 - Quarter 2'},
		{value:'Q3', display: 'Q3 - Quarter 3'},
		{value:'Q4', display: 'Q4 - Quarter 4'},
	];

	const electiveOptions = [
		{value:'0', display:'Non-elective'},
		{value:'1', display:'Elective'},
	];
	
	const spanOptions = ['1', '2', '3', '4', '5', '6'];
		
	const toggleAddNewConnection = (connection_type) => {
		if(connection_type === 'tct')
		{
			setAddTctOpen(!addTctOpen);
			setTctResults([]);
		}
		else if(connection_type === 'classroom_restriction')
		{
			setAddClassroomRestrictionOpen(!addClassroomRestrictionOpen);
			setClassroomRestrictionResults([]);
		}
		else if(connection_type === 'student_request')
		{
			setAddStudentRequestOpen(!addStudentRequestOpen);
		}
	}
	
	const fuzzySearch = (e, dataType) => {
		const search_value = e.target.value;
		
		if(dataType === 'teachers')
		{
			const fuse = new Fuse(teachers, {
				keys: ['name', 'last_name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const teacher_results = results.map(result => result.item);
			
			setTctResults(teacher_results);
		}
		else if(dataType === 'classrooms')
		{
			const fuse = new Fuse(classrooms, {
				keys: ['classroom_name', 'classroom_type'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const classroom_results = results.map(result => result.item);
			
			setClassroomRestrictionResults(classroom_results);
		}
	}

	const toggleSelectStudent = (student_id) => {
		const student_selected_index = selectedStudents.findIndex(student => student === student_id);

		if(student_selected_index !== -1)
		{
			selectedStudents.splice(student_selected_index, 1);
		}
		else
		{
			selectedStudents.push(student_id);
		}

		setSelectedStudents([...selectedStudents]);
	}
	
	const updateData = (change_type, data_id, data_key, type = null, sub_data_key = null) => {
		if(data_key === 'student_requests')
		{
			const student_index = students.findIndex(student => student.student_id === data_id)
			const request_index = students[student_index].student_requests.findIndex(request => request.course_id === dataID);
				
			if(change_type === 'add')
			{
				if(request_index !== -1)
				{
					students[student_index].student_requests[request_index].matched = '0';
					students[student_index].student_requests[request_index].is_inclusion = '0';
					students[student_index].student_requests[request_index].is_deleted = '0';
				}
				else
				{
					students[student_index].student_requests.push({course_id: dataID, matched:'0', priority:'1', is_inclusion:'0', data_origin:'2', is_deleted:'0'});
				}
			}
			else if(change_type === 'change')
			{
				if(request_index === -1) return;
				if(sub_data_key === 'is_inclusion') type = (type === '0') ? '1' : '0';

				students[student_index].student_requests[request_index][sub_data_key] = type;
			}
			else if(change_type === 'remove')
			{
				if(request_index === -1) return;
				students[student_index].student_requests[request_index].is_deleted = '1';
			}

			setStudents([...students]);
		}
		else 
		{
			if(change_type === 'add')
			{
				const data_to_add = (data_key === 'teachers_can_teach') ? {teacher_id:data_id, type:'1'} : data_id;
				data[data_key].push(data_to_add);
			}
			else if(change_type === 'change')
			{
				if(data_key === 'teachers_can_teach')
				{
					data[data_key][data_id].type = type;
				}
				else if(data_key === 'departments')
				{

				}
				else
				{
					data[data_key] = data_id;
				}
			}
			else if(change_type === 'remove')
			{
				data[data_key].splice(data_id, 1);
			}
		
			setData({...data});
		}
		
		setAddTctOpen(false);
		setAddClassroomRestrictionOpen(false);
		setAddStudentRequestOpen(false);
		
		setTctResults([]);
		setClassroomRestrictionResults([]);

		setChangesMade(true);
	}
	
	const handleExitWithoutSaving = async () => {
		let exit_without_saving = true;
		
		if(changesMade)
		{
			const options =  {
				title: "Exit without saving?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you really want to exit without saving?</p>
					</div>
				)
			}
		
			exit_without_saving = await swal(options);
		}
		
		if(exit_without_saving) toggleEditData();
	}
	
	const updateCourse = () => {
		// Make sure course data is trimmed
		data.course_code = data.course_code.trim();
		data.name = data.name.trim();

		const course_code = data.course_code;
		const name = data.name;
		const priority = (data.priority) ? Number(data.priority) : 50;
		const valid_priority = (Number.isInteger(priority) && priority > 0 && priority <= 100);
		
		if(!course_code || !name)
		{
			const options =  {
				title: "Missing Data",
				icon:"warning",
				buttons: {
					cancel: {
						text: "Okay",
						value: false,
						visible: true,
						className: 'blue-btn'
					}
				},
				content: (
					<div>
						<p>You are missing data! Please input all background info before saving.</p>
					</div>
				)
			}
		
			swal(options);
		}
		else if(!valid_priority)
		{
			const options =  {
				title: "Invalid Priority",
				icon:"warning",
				buttons: {
					cancel: {
						text: "Okay",
						value: false,
						visible: true,
						className: 'blue-btn'
					}
				},
				content: (
					<div>
						<p>You have an invalid priority number set. It should be an integer value between 1-100!</p>
					</div>
				)
			}
		
			swal(options);
		}
		else
		{
			setIsSaving(true);

			// Add students who requested it to the data
			const student_requests = students.reduce((results, student) => {
				const student_requests = student.student_requests;
				const request_index = student_requests.findIndex(request => request.course_id === dataID);

				if(request_index === -1) return results;
				
				const request = student_requests[request_index];
				request.student_id = student.student_id;
				results.push(request);
				
				return results;
			}, []);

			data.student_requests = student_requests;
			data.priority = String(priority);
			
			saveDataToDatabase({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'course', data_to_save:data}).then(response_data => {
				// change data course id to new database id
				data.course_id = response_data.new_data_id;
				
				if(dataID)
				{
					const index_of_specific_data = courses.findIndex(course => course.course_id === dataID);
					courses[index_of_specific_data] = data;

					const filter_results_index = filteredResults.findIndex(course => course.course_id === dataID);
					filteredResults[filter_results_index] = data;

					// get new departments to add to any sections of this course
					// only have to do this for existing courses
					const data_departments = data.departments;
					const new_sections = sections.map(section => {
						const section_course_id = section.course_id;
						if(section_course_id === dataID) section.departments = data_departments;
						return section;
					});
					setSections(new_sections);
				}
				else
				{
					courses.push(data);
					filteredResults.push(data);
				}
				
				setDatabaseData([...courses]);
				setFilteredResults([...filteredResults]);
				setIsSaving(false);
				toggleEditData();

				////////////////////////
				/// Update conflicts ///
				////////////////////////
				const affected_sections = sections.reduce((results, section) => {
					const section_course_id = section.course_id;
					if(section_course_id && section_course_id === data.course_id) results.push(section.section_id);
					return results;
				},[]);
				const conflicts_to_check = ['course_exceeds_capacity', 'course_period_restriction', 'course_outside_normal_room', 'student_double_booked', 'student_conflicting_periods'];

				if(affected_sections.length > 0) calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:affected_sections, conflicts_to_check:conflicts_to_check, update_all:false});

				// Recalculate match percent
				recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});
			});
		}
	}
	
	/////////////////////////////
	///// FOCUS ON INPUT ////////
	/////////////////////////////
	useEffect(() => {
		if(addTctOpen) document.getElementById("search-tct-input").focus();
	}, [addTctOpen]);
	
	useEffect(() => {
		if(addClassroomRestrictionOpen) document.getElementById("search-classroom-restriction-input").focus();
	}, [addClassroomRestrictionOpen]);
	
	/////////////////////////
	///// DO ON LOAD ////////
	/////////////////////////
	useEffect(() => {
		if(dataID)
		{
			loadSpecificData({data_type:'course', data_id:dataID, data_version_id:schoolInfo.current_data_version_id, schedule_version_id:schoolInfo.current_schedule_version_id, school_id:schoolInfo.school_id}).then(specific_data => {
				const index_of_specific_data = courses.findIndex(course => course.course_id === dataID);
				courses[index_of_specific_data] = specific_data;

				setDatabaseData(courses);
				setData(specific_data);
				setIsLoading(false);
			});
		}
		else
		{	
			setData({new: true,  course_code: null, name: null, departments:[], classroom_type_id: null, credits:"0", elective:"0", priority:"50", semester:'FY', max_num_sections:"0", max_class_size:"30", num_periods_spanned:"1", is_lunch:"0", allow_icr_students:"0", is_outside_course:"0", is_separate_lab:"0", is_swappable_for_lab:"0", classroom_restrictions: [], period_restrictions:[], student_requests:[], teachers_can_teach:[]});
			setIsLoading(false);
		}
	}, []);
	
	//console.log(data);
	//console.log(departments);
	//console.log({sections});

	const scheduleType = scheduleInfo.schedule_type;
	const period_type = (scheduleType === 'daily') ? 'schedule_periods' : 'course_periods';
	const periods_to_show = (isEmpty(scheduleStructureData)) ? {} : (period_type === 'course_periods' ? scheduleStructureData.course_periods : scheduleStructureData.schedule_periods);

	let sortable = [];
	for (const period in periods_to_show) {
		const period_info = periods_to_show[period];
		const period_id = period_info.period_id;
		const period_order = (period_type === 'course_periods') ? period_info.period_name : period_info.period_order;
		const period_name = period_info.period_name;

		sortable.push({period_id: period_id, period_order: parseInt(period_order), period_name: period_name});
	}

	const periodsToShow = sortArrayOfObjects(sortable, 'period_order', 'number', 'desc');
	const availability_schedule_row_style = {gridTemplateColumns : `150px repeat(${Object.keys(scheduleStructureData.schedule_structure).length}, 1fr)`};

	const students_requesting_course = students.filter(student => (student.student_requests.findIndex(request => request.course_id === dataID && request.is_deleted === '0') !== -1));
	const sorted_students_requesting_course = (students_requesting_course.length > 0) ? sortArrayOfObjects(students_requesting_course, 'last_name', 'text', 'desc') : [];

	const requests_style = (scheduleInfo.separate_ICR_codes === '0') ? {gridTemplateColumns:'20px 1fr 2fr 30px 2fr 1fr 1fr 30px'} : {gridTemplateColumns:'20px 1fr 2fr 30px 2fr 1fr 30px'};
	
	return (
		<div className='modal-screen'>
			<div className='modal-screen-content'>
				{!isSaving && 
					<>
					<span className="data-modal-close" onClick={handleExitWithoutSaving}>&times;</span>
					<div className="modal-save-btn" onClick={updateCourse}><FontAwesomeIcon icon={faSave}/>Save Course</div>
					</>
				}
				<div className='full-width'>
					<div className='edit-data-content'>
						<FontAwesomeIcon className='edit-data-main-head-icon' icon={faEdit}/>
						<h1 className='edit-data-main-header'>{dataID ? 'Edit' : 'Add New'} Course</h1>
						{isSaving &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
								<div>Saving...</div>
							</div>
						}
						{isLoading &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						}
						{(!isLoading && !isSaving) &&
							<form className='edit-data-form'>
								<div className='edit-data-grid-25-75'>
									<div>
										<p className='modal-label'>Course Code:</p>
										<input type="text" className='edit-data-input' defaultValue={data.course_code} onChange={(e) => updateData('change', e.target.value, 'course_code')}/>
									</div>
									<div>
										<p className='modal-label'>Course Name:</p>
										<input type="text" className='edit-data-input' defaultValue={data.name} onChange={(e) => updateData('change', e.target.value, 'name')}/>
									</div>
								</div>
								<div className='edit-data-grid-50'>
									<div className='edit-data-dropdown-container'>
										<div className='data-modal-heading'>Elective</div>
										<Dropdown data={electiveOptions} currentValue={data.elective} handleChange={(new_value) => updateData('change', new_value, 'elective')} />
									</div>
									<div className='edit-data-dropdown-container'>
										<div className='data-modal-heading'>Semester</div>
										<Dropdown data={semesterOptions} currentValue={data.semester} handleChange={(new_value) => updateData('change', new_value, 'semester')} />
									</div>
								</div>
								<div>
									<div className='data-modal-heading'>How many periods does a section of this course span?</div>
									{spanOptions.map((span_num, index) => {
										return (
											<div className={`option-btn ${span_num === data.num_periods_spanned ? 'option-btn-selected' : ''}`} key={index} onClick={(e) => updateData('change', span_num, 'num_periods_spanned')}>{span_num}</div>
										)	
									})}
								</div>
								<div className='edit-data-grid-50'>
									<div className='edit-data-dropdown-container'>
										<div className='data-modal-heading'>Credits</div>
										<input type="text" className='edit-data-input' defaultValue={data.credits} onChange={(e) => updateData('change', e.target.value, 'credits')} style={{marginTop:'10px'}}/>
									</div>
									<div className='edit-data-dropdown-container'>
										<div className='data-modal-heading'>Priority</div>
										<div className='small-text' style={{marginTop:'5px'}}>Pick any number 1-100 (1 = highest priority, 100 = lowest priority)</div>
										<input type="text" className='edit-data-input' defaultValue={data.priority} onChange={(e) => updateData('change', e.target.value, 'priority')}/>
									</div>
								</div>
								<div>
									<div className='data-modal-heading'>Department</div>
									{departments.map((department_info, index) => {
										const department_id = department_info.department_id;
										const data_department_index = data.departments.indexOf(department_id);
										const data_department_bool = (data_department_index !== -1) ? true : false;
										
										if(data_department_bool)
										{
											return (
												<div className='option-btn option-btn-selected' key={index} onClick={(e) => updateData('remove', data_department_index, 'departments')}>{department_info.department}</div>
											)
										}
										else
										{
											return (
												<div className='option-btn' key={index} onClick={(e) => updateData('add', department_id, 'departments')}>{department_info.department}</div>
											)
										}
									})}
								</div>
								<div>
									<div className='data-modal-heading'>Teachers Who Teach This Course</div>
									<div>
										{data.teachers_can_teach.length > 0 &&
											<div className="edit-data-subheading edit-data-teacher-can-teach-row">
												<div><strong>Course Name</strong></div>
												<div>Primary/Back Up</div>
											</div>
										}
										{data.teachers_can_teach.length === 0 &&
											<div className='edit-data-no-data-container'>No teachers set yet!</div>
										}
										{data.teachers_can_teach.map((tct_info, index) => {
											const teacher_id = tct_info.teacher_id;
											const primary_secondary = tct_info.type;
											
											const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
											
											return (
												<div className='table-row-inv edit-data-teacher-can-teach-row' key={index}>
													<div>{teacher_info.name}, {teacher_info.first_name}</div>
													<div>
														<Dropdown data={primarySecondaryOptions} currentValue={primary_secondary} handleChange={(new_value) => updateData('change', index, 'teachers_can_teach', new_value)} />
													</div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'teachers_can_teach')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('tct')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Teacher Who Teaches</div>
									</div>
									{addTctOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-tct-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'teachers')} placeholder='Search for teacher'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('tct')}/>
											</div>
											{tctResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{tctResults.map((teacher, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{teacher.name}, {teacher.first_name}</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', teacher.teacher_id, 'teachers_can_teach')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								<div>
									<div className='data-modal-heading'>Classroom(s) this Course <span className='yellow-text'>NEEDS</span> to be in</div>
									<div>
										{data.classroom_restrictions.length > 0 &&
											<div className="edit-data-subheading edit-data-teacher-can-teach-row">
												<div><strong>Classroom</strong></div>
												<div>Classroom Type</div>
											</div>
										}
										{data.classroom_restrictions.length === 0 &&
											<div className='edit-data-no-data-container'>No classrooms set yet!</div>
										}
										{data.classroom_restrictions.map((classroom_id, index) => {
											const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
											
											return (
												<div className='table-row-inv edit-data-course-classroom-row' key={index}>
													<div>{classroom_info.classroom_name}</div>
													<div>{classroom_info.classroom_type}</div>
													<div className="remove-data" onClick={() => updateData('remove', index, 'classroom_restrictions')}><FontAwesomeIcon icon={faTimes}/></div>
												</div>
											)	
										})}
									</div>
									<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('classroom_restriction')}>
										<FontAwesomeIcon icon={faPlusCircle}/>
										<div>Add Classroom Restriction</div>
									</div>
									{addClassroomRestrictionOpen &&
										<div className='search-add-row-container'>
											<div className='search-add-search-container'>
												<div className='search-add-adding-input-container'>
													<input id='search-classroom-restriction-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'classrooms')} placeholder='Search for classroom or classroom type'/>
													<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
												</div>
												<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleAddNewConnection('classroom_restriction')}/>
											</div>
											{classroomRestrictionResults.length !== 0 &&
												<div className='search-add-search-results'>
													<div className='search-add-did-you-mean'>Did you mean:</div>
													{classroomRestrictionResults.map((classroom, i) => {
														return (
															<div className='search-add-search-result' key={i}>
																<div>{classroom.classroom_name} (<span className='orange-text'>Classroom Type</span>: {classroom.classroom_type})</div>
																<div>
																	<div className='search-add-add-btn' onClick={() => updateData('add', classroom.classroom_id, 'classroom_restrictions')}>Add</div>
																</div>
															</div>
														)
													})}
												</div>
											}
										</div>
									}
								</div>
								<div>
									<div className='data-modal-heading'>Course Availability</div>
									<div className='edit-data-availability-screen'>
										<div className='edit-data-availability-select-sidebar'>
											<h3 className='edit-data-availability-sidebar-header'>Period Availability</h3>
											{periodsToShow.map((period_info, index) => {
												const period_id = period_info.period_id;
												const period_name = period_info.period_name;
												const course_period_id = (scheduleType === 'block') ? period_id : scheduleStructureData.schedule_structure[1][period_id];
												const course_period_index = data.period_restrictions.indexOf(course_period_id);
												
												const course_period_available = (course_period_index !== -1) ? false : true;
												
												return (
													<div className='edit-data-availability-period-option-container' key={period_id}>
														<div>{period_name}</div>
														{course_period_available &&
														<div onClick={() => updateData('add', course_period_id, 'period_restrictions')}>
															<FontAwesomeIcon className='fas-checkbox-checked' icon={faCheckSquare}/>
														</div>
														}
														{!course_period_available &&
														<div onClick={() => updateData('remove', course_period_index, 'period_restrictions')}>
															<FontAwesomeIcon className='fas-checkbox-unchecked dark-gray-text' icon={faSquare}/>
														</div>
														}
													</div>
												)
											})}
										</div>
										<div className='edit-data-availability-display-screen'>
											<div className='edit-data-availability-schedule'>
												<div className='edit-data-availability-schedule-row' style={availability_schedule_row_style}>
													<div>Periods</div>
													{Object.keys(scheduleStructureData.schedule_structure).map((day_num, index) => {
														return (
															<div className='edit-data-availability-day-heading' key={index}>Day {day_num}</div>
														)
													})}
												</div>
												{Object.keys(scheduleStructureData.schedule_periods).map((schedule_period_id, index) => {
													const schedule_period_info = scheduleStructureData.schedule_periods[schedule_period_id];
													
													return (
														<div className='edit-data-availability-schedule-row' key={schedule_period_id} style={availability_schedule_row_style}>
															<div className='edit-data-availability-period-heading'>{schedule_period_info.period_name}</div>
															{Object.keys(scheduleStructureData.schedule_structure).map((day_num, index) => {
																const day_structure = scheduleStructureData.schedule_structure[day_num];
																const course_period_id = day_structure[schedule_period_id];
																const course_period_name = scheduleStructureData.course_periods[course_period_id].period_name;
																
																const unavailable = data.period_restrictions.includes(course_period_id) ? true : false;
																
																return (
																	<div className={`edit-data-availability-course-period ${unavailable ? 'edit-data-availability-course-period-unavailable' : ''}`} key={`${day_num}_${schedule_period_id}`}>
																		<div className='edit-data-availability-course-period-name'>{course_period_name}</div>
																		<div className='edit-data-availability-status-container'>
																			<div>{unavailable ? 'Unavailable' : 'Available'}</div>
																			<div className={`edit-data-availability-status ${unavailable ? 'red' : 'green'}`}></div>
																		</div>
																	</div>
																)
															})}
														</div>
													)
												})}
											</div>
										</div>
									</div>
								</div>
								<div className='edit-data-grid-50'>
									<div className='edit-data-dropdown-container'>
										<div className='data-modal-heading'>Max Number of Sections</div>
										<Dropdown data={maxNumSectionsOptions} currentValue={data.max_num_sections} handleChange={(new_value) => updateData('change', new_value, 'max_num_sections')} />
									</div>
									<div className='edit-data-dropdown-container'>
										<div className='data-modal-heading'>Max Class Size</div>
										<Dropdown data={maxClassSizeOptions} currentValue={data.max_class_size} handleChange={(new_value) => updateData('change', new_value, 'max_class_size')} />
									</div>
								</div>
								<div>
									<div className='data-modal-heading'>Students Who Have Requested This Course</div>
									<div>
										{sorted_students_requesting_course.length > 0 &&
											<>
											<div className="edit-data-subheading edit-data-student-request-row" style={requests_style}>
												<div></div>
												<div><strong>Student Code</strong></div>
												<div><strong>Student Name</strong></div>
												<div><strong>Grade</strong></div>
												<div><strong>Type</strong></div>
												{scheduleInfo.separate_ICR_codes === '0' && <div className='text-align-center'><strong>Inclusion?</strong></div>}
												<div>Matched?</div>
											</div>
											<BulkEditCourseRequest courseID={dataID} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} courses={courses} students={students} setStudents={setStudents} sections={sections} setSections={setSections} selectedStudents={selectedStudents} setSelectedStudents={setSelectedStudents}/>
											</>
										}
										<div className='edit-data-list'>
											{sorted_students_requesting_course.length === 0 &&
												<div className='edit-data-no-data-container'>No students have requested this course yet!</div>
											}
											{sorted_students_requesting_course.map((student_info, index) => {
												const student_id = student_info.student_id;
												const student_requests = student_info.student_requests;
												const request_info = student_requests.find(request => request.course_id === dataID);
												const priority = request_info.priority;
												const is_inclusion = request_info.is_inclusion;
												const matched = (sections.findIndex(section => section.course_period_id && section.course_id === dataID && section.student_list.includes(student_id)) !== -1) ? true : false;
												const course_labels = student_info.student_labels.filter(label => label.course_id === dataID);
												const student_selected = selectedStudents.includes(student_id);
												
												return (
													<div className='table-row-inv edit-data-student-request-row' key={index} style={requests_style}>
														<FontAwesomeIcon className={student_selected ? 'blue-text' : 'dark-gray-text'} icon={student_selected ? faCheckSquare : farSquare} onClick={() => toggleSelectStudent(student_id)}/>
														<div>{student_info.student_code}</div>
														<div>
															<div>{student_info.last_name}, {student_info.first_name}</div>
															<div>
																{course_labels.map((course_label, i) => {
																	const label_id = course_label.label_id;
																	const label_info = labels.find(label => label.label_id === label_id);
																	
																	if(!label_info) return;

																	const is_team = (label_info.is_team === '1') ? true : false;

																	return (
																		<div className={`edit-student-schedule-request-label ${is_team ? 'pastel-blue-text' : 'pastel-orange-text'}`} key={i}>{label_info.label}</div>
																	)
																})}
															</div>
														</div>
														<div>{student_info.grade}</div>
														<div>
															<Dropdown data={requestOptions} currentValue={priority} handleChange={(new_value) => updateData('change', student_id, 'student_requests', new_value, 'priority')} />
														</div>
														{scheduleInfo.separate_ICR_codes === '0' &&
															<div className='text-align-center cursor-pointer' onClick={() => updateData('change', student_id, 'student_requests', is_inclusion, 'is_inclusion')}>
																<FontAwesomeIcon className={is_inclusion === '1' ? 'orange-text' : 'dark-gray-text'} icon={is_inclusion === '1' ? faCheckSquare : farSquare}/>
															</div>
														}
														<div className={`medium-text ${matched ? 'green-text' : 'gray-text'}`}>{matched ? '' : "Not "}Matched</div>
														<div className="remove-data" onClick={() => updateData('remove', student_id, 'student_requests')}><FontAwesomeIcon icon={faTimes}/></div>
													</div>
												)	
											})}
										</div>
									</div>
									<div className='edit-data-grid-50'>
										<div className='edit-data-add-new-btn blue-link' onClick={() => toggleAddNewConnection('student_request')}>
											<FontAwesomeIcon icon={faPlusCircle}/>
											<div>Add Student Request</div>
										</div>
										<div className='edit-data-total-requests'>Total Requests: {sorted_students_requesting_course.length}</div>
									</div>
									{addStudentRequestOpen &&
										<Portal windowName="Manage Student Requests" windowWidth="900" windowHeight="700" cleanUpFunction={() => {}}>
											<StudentRequests schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} sections={sections} setSections={setSections} students={students} setStudents={setStudents} courses={courses} courseID={dataID} />
										</Portal>
									}
								</div>
								<div>
									<div className='data-modal-heading'>Other Course Options</div>
									<div className='edit-data-grid-50 edit-data-other-options-container'>
										<div style={{width:'100%',textAlign:'left'}}>
											<div>Is Lunch?</div>
											<div className='dark-gray-text'>Answer "Yes" if this course is a lunch course</div>
										</div>
										<div className='toggle-with-text-btn-container edit-course-toggle'>
											<div className={`toggle-with-text-btn-optn ${data.is_lunch === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'is_lunch')}>No</div><div className={`toggle-with-text-btn-optn ${data.is_lunch === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'is_lunch')}>Yes</div>
										</div>
									</div>
									<div className='edit-data-grid-50 edit-data-other-options-container'>
										<div>
											<div>{scheduleInfo.separate_ICR_codes === '1' ? 'Is Inclusion?' : 'Needs Inclusion Sections?'}</div>
											<div className='dark-gray-text'>{scheduleInfo.separate_ICR_codes === '1' ? 'Answer "Yes" if this course is an inclusion course (i.e. a special ed course that will be combined with a general ed section)' : 'Answer "Yes" if some of the sections for this course will need to be Inclusion sections.'}</div>
										</div>
										<div className='toggle-with-text-btn-container edit-course-toggle'>
											<div className={`toggle-with-text-btn-optn ${data.allow_icr_students === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'allow_icr_students')}>No</div><div className={`toggle-with-text-btn-optn ${data.allow_icr_students === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'allow_icr_students')}>Yes</div>
										</div>
									</div>
									<div className='edit-data-grid-50 edit-data-other-options-container'>
										<div>
											<div>Does this course have an extra lab section?</div>
											<div className='dark-gray-text'>Answer "Yes" if this class, on top of having class every day, has an extra class period for lab once a week.</div>
										</div>
										<div className='toggle-with-text-btn-container edit-course-toggle'>
											<div className={`toggle-with-text-btn-optn ${data.is_separate_lab === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'is_separate_lab')}>No</div><div className={`toggle-with-text-btn-optn ${data.is_separate_lab === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'is_separate_lab')}>Yes</div>
										</div>
									</div>
									<div className='edit-data-grid-50 edit-data-other-options-container'>
										<div>
											<div>Can a student swap this class for his/her lab?</div>
											<div className='dark-gray-text'>Answer "Yes" if a student is allowed to miss this class to go to an extra lab section once a week.</div>
										</div>
										<div className='toggle-with-text-btn-container edit-course-toggle'>
											<div className={`toggle-with-text-btn-optn ${data.is_swappable_for_lab === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'is_swappable_for_lab')}>No</div><div className={`toggle-with-text-btn-optn ${data.is_swappable_for_lab === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'is_swappable_for_lab')}>Yes</div>
										</div>
									</div>
									<div className='edit-data-grid-50 edit-data-other-options-container'>
										<div>
											<div>Should this course be included in the schedule?</div>
											<div className='dark-gray-text'>Answer "No" if this is a class that is offered outside of an official classroom, like an online course or a course at a community college.</div>
										</div>
										<div className='toggle-with-text-btn-container edit-course-toggle'>
											<div className={`toggle-with-text-btn-optn ${data.is_outside_course === '1' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '1', 'is_outside_course')}>No</div><div className={`toggle-with-text-btn-optn ${data.is_outside_course === '0' ? 'toggle-with-text-btn-optn-selected' : ''}`} onClick={(e) => updateData('change', '0', 'is_outside_course')}>Yes</div>
										</div>
									</div>
								</div>
							</form>
						}
					</div>
				</div>
			</div>
		</div>
	);
}