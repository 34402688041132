import React  from 'react';
import { useDrag } from 'react-dnd';
import { API } from "aws-amplify";

import { sortArrayOfObjects, capitalizeFirstLetters, cloneObj, calculateConflicts } from '../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock, faChalkboard, faUserGraduate, faEllipsisH, } from '@fortawesome/free-solid-svg-icons';

export default function MagnetBoardSectionHSMS ({ schoolInfo, scheduleInfo, scheduleType, currentViewType, currentDay, currentScheduleVersionID, teachers, courses, classrooms, sections, setSections, subsections, labels, conflicts, setConflicts, setConflictsLoading, labsOnlyFiltered, nonLabsOnlyFiltered, singletonsFiltered, doubletonsFiltered, noClassroomFiltered, hasClassroomFiltered, toggleEditSection, dataID, coursePeriodID, sectionID, sectionType, handleSelectQuickViewDataID = ()=>{}, selectedQuickViewSectionID}) {
	///////////////////////////
	///// BACKGROUND INFO /////
	///////////////////////////
	let sectionInfo = sections.find(section => section.section_id === sectionID);
	let section_id = sectionInfo.section_id;
	
	let subsection_id = null;
	let section_info = cloneObj(sectionInfo);

	// If section is subsection, get main section info
	const section_with_subsection_index = subsections.findIndex(subsection_section => subsection_section.subsection_id === section_id);
	if(section_with_subsection_index !== -1)
	{
		subsection_id = section_id;
		section_id = subsections[section_with_subsection_index].section_id;
		sectionID = section_id;
		section_info = sections.find(section => section.section_id === section_id);
	}

	/////////////////////////
	///// DRAG FUNCTION /////
	/////////////////////////
    const [{ isDragging }, sectionRef] = useDrag({
        type: sectionType,
        item: { 
			draggedDataID: dataID, 
			draggedCoursePeriodID: coursePeriodID,
			sectionID:sectionID,
			type: sectionType
		 },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

	///////////////////////////
	///// OTHER FUNCTIONS /////
	///////////////////////////
	const toggleSectionQuarterDay = async (section_id, quarter) => {
		/// UPDATE FRONT END ///
		const section_index = sections.findIndex(section => section.section_id === section_id);
		if(section_index === -1) return;
		
		const sectionInfo = sections[section_index];
		const section_subsections = sectionInfo.subsections;
		const section_quarter_days = sectionInfo.quarter_days;
		const section_span_id = sectionInfo.span_id;
		const section_is_lab = sectionInfo.is_lab;

		// Collect all affected sections
		const affected_sections = (section_span_id) ? sections.reduce((results, section) => {
			const temp_section_id = section.section_id;
			const temp_span_id = section.span_id;
			const temp_subsections = section.subsections;
			const temp_is_lab = section.is_lab;

			if(temp_span_id === section_span_id && section_is_lab === temp_is_lab) 
			{
				results.push(temp_section_id);
				if(temp_subsections.length > 0) results.push(...temp_subsections);
			}
			return results;
		}, []) : (section_subsections.length > 0 ? [section_id, ...section_subsections] : [section_id]);

		const quarter_index = section_quarter_days.findIndex(quarter_day => quarter_day.quarter === quarter);
		
		let new_quarter_days = [];

		// If quarter already exists for this section, remove it
		// Otherwise, add quarter
		if(quarter_index !== -1)
		{
			new_quarter_days = section_quarter_days.filter(quarter_day => quarter_day.quarter !== quarter);
		}
		else
		{
			const unique_days = (section_quarter_days.length === 0) ? [...Array(parseInt(scheduleInfo.num_days_in_cycle)+1).keys()].slice(1) : section_quarter_days.reduce((results, quarter_day) => {
				const day = quarter_day.day;
				if(!results.includes(day)) results.push(day);
				return results;
			},[]);

			const additional_quarter_days = unique_days.reduce((results, day) => {
				results.push({day:`${day}`, quarter:quarter});
				return results;
			}, []);

			new_quarter_days = [...section_quarter_days, ...additional_quarter_days];
		}

		// Iterate through all affected sections and change their quarter days
		affected_sections.forEach(affected_section_id => {
			const affected_section_index = sections.findIndex(section => section.section_id === affected_section_id);
			if(affected_section_index === -1) return;
			sections[affected_section_index].quarter_days = new_quarter_days;
		});

		setSections([...sections]);

		/// UPDATE BACK END ///
		const data = {school_id: schoolInfo.school_id, schedule_version_id:currentScheduleVersionID, affected_sections:affected_sections, new_quarter_days:new_quarter_days};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/update-section-quarter-days';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			// Update section quarter days
			await API.post(apiName, url, myInit);

			// Update conflicts
			const conflicts_to_check = ['teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_too_many_sections', 'teacher_too_many_sections_in_a_row'];
			calculateConflicts({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:affected_sections, conflicts_to_check:conflicts_to_check, update_all:false}).then(conflicts_response => {
				const added_conflicts = conflicts_response.added_conflicts;
				const deleted_conflicts = conflicts_response.deleted_conflicts;

				// Remove any deleted conflicts, add any added conflicts
				let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
				new_conflicts = [...new_conflicts, ...added_conflicts];

				setConflicts([...new_conflicts]);
				setConflictsLoading(false);
			});
		} catch(error)
		{
			console.log(error.response);
		}
	}

	const toggleLockSection = async (section_id, locked) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:currentScheduleVersionID, section_id:section_id, locked:locked};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/update-lock-section';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			const response = await API.post(apiName, url, myInit);
			const affected_sections = response.data.affected_sections;
			
			affected_sections.forEach(section_id => {
				const section_index = sections.findIndex(section => section.section_id === section_id);
				if(section_index !== -1) sections[section_index].locked = locked;
			});
			setSections([...sections]);
		} catch(e)
		{
			console.log(e.response);
		}
	}

	///////////////////////////////
	/// BACKGROUND INFO (CONT) ///
	///////////////////////////////
	if(!section_info) return null;
	
	const section_type = section_info.section_type;
	const course_period_id = section_info.course_period_id;
	const course_id = section_info.course_id;
	const classroom_id = section_info.classroom_id;
	const span_id = section_info.span_id;
	const is_lab = section_info.is_lab;
	const is_manual = section_info.is_manual;
	let is_locked = section_info.locked;

	// Figure out if current section is selected
	const selected_section_info = sections.find(section => section.section_id === selectedQuickViewSectionID);
	const selected_section_span_id = (selected_section_info) ? selected_section_info.span_id : null;
	const is_section_selected = (selectedQuickViewSectionID && (section_id === selectedQuickViewSectionID || (span_id && span_id === selected_section_span_id)));

	// Get subsections
	const section_subsections = ('subsections' in section_info) ? section_info.subsections.filter(subsection => sections.findIndex(section => section.section_id === subsection) !== -1) : [];

	const num_students = (section_subsections.length === 0) ? section_info.student_list.length : section_subsections.reduce((total_students, subsection_id) => {
		const subsection_index = sections.findIndex(section => section.section_id === subsection_id);
		if(subsection_index === -1) return total_students;
		const subsection_num_students = sections[subsection_index].student_list.length;
		return total_students + subsection_num_students;
	}, section_info.student_list.length);
	
	const course_info = courses.find(course => course.course_id === course_id);
	const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
	const course_name = (section_type === '2') ? "Prep" : ((section_type === '3') ? "Duty" : (course_info ? `${capitalizeFirstLetters(course_info.name)} (${course_info.course_code})`: null));

	// FIGURE OUT IF CURRENT SECTION COURSE IS A SINGLETON/DOUBLETON
	let is_singleton = false;
	let is_doubleton = false;
	if(singletonsFiltered || doubletonsFiltered)
	{
		// Collect both main section and subsection to see if ANY are singletons or doubletons
		const relevant_sections = [section_id, ...section_subsections];

		relevant_sections.forEach(temp_section_id => {
			const temp_section_info = sections.find(temp_section => temp_section.section_id === temp_section_id);
			if(!temp_section_info) return;
			const temp_section_course_id = temp_section_info.course_id;

			// Figure out how many sections of this course there are
			// First filter only sections of THIS course that have a course period set and also aren't labs
			// Then go through those sections to only count spans once, exclude placeholder inclusions, etc.
			const sections_of_course_data = sections.filter(section => section.course_id === temp_section_course_id && section.course_period_id && section.is_lab === '0').reduce((results, temp_section) => {
				const temp_section_id = temp_section.section_id;

				// If section has already been seen (in span search, etc), then skip it
				// Otherwise add it to logged_sections
				if(results.logged_sections.includes(temp_section_id)) return results;
				results.logged_sections.push(temp_section_id);

				const temp_section_course_id = temp_section.course_id;
				const temp_section_span_id = temp_section.span_id;
				const temp_section_is_inclusion = temp_section.is_inclusion;
				const temp_subsection_index = subsections.findIndex(temp_subsection => temp_subsection.subsection_id === temp_section_id);

				// Ignore placeholder inclusions
				let temp_is_placeholder_inclusion = false;
				if(scheduleInfo.separate_ICR_codes === '0' && temp_section_is_inclusion && temp_subsection_index !== -1)
				{
					const temp_subsection_info = subsections[temp_subsection_index];
					const temp_main_section_id = temp_subsection_info.section_id;

					const temp_main_section_info = sections.find(section => section.section_id === temp_main_section_id);
					const temp_main_course_id = (temp_main_section_info) ? temp_main_section_info.course_id : null;
					
					if(temp_section_course_id === temp_main_course_id) temp_is_placeholder_inclusion = true;
				}
				if(temp_is_placeholder_inclusion) return results;
				
				// If passes is placeholder inclusion test, then add it as section of this course
				results.sections_of_course.push(temp_section_id);

				// If this is a spanned course, add all other spans to logged list (so we skip them in the future)
				if(temp_section_span_id)
				{
					sections.filter(section => section.span_id === temp_section_span_id).forEach(temp_section => {
						const temp_section_id = temp_section.section_id;
						if(!results.logged_sections.includes(temp_section_id)) results.logged_sections.push(temp_section_id);
					});
				}

				return results;
			}, {sections_of_course:[], logged_sections:[]});

			const course_is_singleton = sections_of_course_data.sections_of_course.length === 1;
			const course_is_doubleton = sections_of_course_data.sections_of_course.length === 2;

			if(course_is_singleton) is_singleton = true;
			if(course_is_doubleton) is_doubleton = true;
		});
	}

	const quarter_days = section_info.quarter_days;
	const quarter_1_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '1') !== -1) ? true : false;
	const quarter_2_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '2') !== -1) ? true : false;
	const quarter_3_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '3') !== -1) ? true : false;
	const quarter_4_bool = (quarter_days.findIndex(quarter_day => quarter_day.quarter === '4') !== -1) ? true : false;
	const unique_days = quarter_days.reduce((results, quarter_day) => {
		const day = quarter_day.day;
		if(!results.includes(day)) results.push(day);
		return results;
	},[]);

	let section_labels = section_info.labels;
	section_labels = section_labels.map(label_id => {
		const label_info = labels.find(label => label.label_id === label_id);
		return label_info;
	});
	
	const current_section_teams = section_labels.filter(label => (label && label.is_team === '1'));
	const sorted_section_teams = sortArrayOfObjects(current_section_teams, 'label', 'text', 'desc');
	
	const current_section_labels = section_labels.filter(label => (label && label.is_team === '0'));
	const sorted_section_labels = sortArrayOfObjects(current_section_labels, 'label', 'text', 'desc');

	// If this is a lab course in a block schedule in day view, only show if this day is included for lab
	if(is_lab === '1' && scheduleType === 'block' && currentViewType === 'day' && quarter_days.findIndex(quarter_day => quarter_day.day === currentDay) === -1) return null;
	
	// Get Conflicts
	const applicable_conflicts = conflicts.reduce((result, conflict) => {
		if(conflict.is_deleted === '1') return result;
		const conflict_sections = conflict.sections;
		if(conflict_sections && conflict_sections.includes(section_id) && conflict.hard_conflict === '1' && conflict.type !== 'student_double_booked' && conflict.type !== 'student_conflicting_periods') result.push(conflict);
		return result;
	}, []);
	const has_conflict = (applicable_conflicts.length > 0);
	
	// Get Section Color
	const section_color = (has_conflict) ? 'red' : ((is_lab === '1') ? 'green' : ((section_type === '2') ? 'pastel-light-blue' : ((section_type === '3') ? 'pastel-brown' : 'dark-blue')));

	/// Set Display ///
	if(labsOnlyFiltered && is_lab !== '1') return null;
	if(nonLabsOnlyFiltered && is_lab === '1') return null;

	if(singletonsFiltered && !is_singleton) return null;
	if(doubletonsFiltered && !is_doubleton) return null;
	if(noClassroomFiltered && !hasClassroomFiltered && classroom_id && course_period_id) return null;
	if(hasClassroomFiltered && !noClassroomFiltered && !classroom_id && course_period_id) return null;

	return (
		<div className={`mb-section ${is_locked === '1' && 'mb-section-locked'} ${is_section_selected && 'mb-highlight-section'}`} key={`${course_period_id}-${section_id}`} data-sectionid={section_id} onDoubleClick={is_locked !== '1' ? () => toggleEditSection(section_id, course_period_id, dataID, section_type) : ()=>{}} ref={sectionRef}>
			<div className={`mb-section-top-bar ${section_color}`}>
				<div className='lock-section-btn'>{is_lab === '1' ? "Lab" : ((!course_period_id) ? <div></div> : ((is_locked === '1') ? <FontAwesomeIcon className='cursor-pointer bright-yellow-text' icon={faLock} onClick={() => toggleLockSection(section_id, '0')}/> : <FontAwesomeIcon className='opaque-link' icon={faUnlock} onClick={() => toggleLockSection(section_id, '1')}/>))}</div>
				{is_locked === '0' ?
					(
						<div className='mb-section-more-info' onClick={() => toggleEditSection(section_id, course_period_id, dataID, section_type)}><FontAwesomeIcon icon={faEllipsisH}/></div>
					):
					(
						<div></div>
					)
					
				}
			</div>
			<div className={`mb-section-content ${has_conflict && 'mb-section-conflict'} ${is_manual === '1' ? 'mb-section-manual' : ''}`} onClick={(e) => handleSelectQuickViewDataID(dataID, section_id, e)}>
				<div className='tooltip' data-tooltip-text={course_name}>
					<div className={`mb-section-name ellipsis dark-blue-text`}>{course_name ? course_name : '...'}</div>
				</div>
				{sorted_section_teams.map((label, index) => (
					<div className='mb-section-label extra-small-text' key={label.label_id}>
						<div className='mb-section-label-icon pastel-blue'></div>
						<div>{label.label}</div>
					</div>
				))}
				{sorted_section_labels.map((label, index) => (
					<div className='mb-section-label extra-small-text' key={label.label_id}>
						<div className='mb-section-label-icon pastel-orange'></div>
						<div>{label.label}</div> 
					</div>
				))}
				{section_subsections.map(subsection_id => {
					const section_subsection_info = sections.find(section => section.section_id === subsection_id);
					const subsection_course_id = section_subsection_info.course_id;
					const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
					const subsection_course_name = (subsection_course_info) ? capitalizeFirstLetters(subsection_course_info.name) : null;
					const subsection_course_code = (subsection_course_info) ? subsection_course_info.course_code : null;

					// Check if subsection is placeholder inclusion course (for schools without separate ICR codes)
					const subsection_is_inclusion = section_subsection_info.is_inclusion;
					let is_placeholder_inclusion = false;
					if(scheduleInfo.separate_ICR_codes === '0' && course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;

					let subsection_labels = section_subsection_info.labels;
					subsection_labels = subsection_labels.map(label_id => {
						const label_info = labels.find(label => label.label_id === label_id);
						return label_info;
					});
					
					const current_subsection_teams = subsection_labels.filter(label => (label && label.is_team === '1'));
					const sorted_subsection_teams = sortArrayOfObjects(current_subsection_teams, 'label', 'text', 'desc');
					
					const current_subsection_labels = subsection_labels.filter(label => (label && label.is_team === '0'));
					const sorted_subsection_labels = sortArrayOfObjects(current_subsection_labels, 'label', 'text', 'desc');

					return (
						<React.Fragment key={`${course_period_id}-${subsection_id}`}>
						{is_placeholder_inclusion ?
							(
								<div className='orange-text'>Inclusion</div>
							):
							(
								<>
								{subsection_course_id &&
									<div className='tooltip' data-tooltip-text={`(${subsection_course_code}) ${subsection_course_name && capitalizeFirstLetters(subsection_course_name)}`}>
										<div className='mb-section-name ellipsis blue-text'>{subsection_course_name && capitalizeFirstLetters(subsection_course_name)} {subsection_course_code && `(${subsection_course_code})`}</div>
									</div>
								}
								{sorted_subsection_teams.map((label, index) => (
									<div className='mb-section-label extra-small-text' key={label.label_id}>
										<div className='mb-section-label-icon pastel-blue'></div>
										<div className='blue-text'>{label.label}</div>
									</div>
								))}
								{sorted_subsection_labels.map((label, index) => (
									<div className='mb-section-label extra-small-text' key={label.label_id}>
										<div className='mb-section-label-icon pastel-orange'></div>
										<div className='blue-text'>{label.label}</div> 
									</div>
								))}
								</>
							)
						}
						</React.Fragment>
					)
				})}
				<div className='mb-section-quarter-day-container'>
					<div className={`mb-section-quarter-day ${quarter_1_bool ? 'mb-section-quarter-day-selected' : ''}`} onClick={() => toggleSectionQuarterDay(section_id, '1')}>Q1</div>
					<div className={`mb-section-quarter-day ${quarter_2_bool ? 'mb-section-quarter-day-selected' : ''}`} onClick={() => toggleSectionQuarterDay(section_id, '2')}>Q2</div>
					<div className={`mb-section-quarter-day ${quarter_3_bool ? 'mb-section-quarter-day-selected' : ''}`} onClick={() => toggleSectionQuarterDay(section_id, '3')}>Q3</div>
					<div className={`mb-section-quarter-day ${quarter_4_bool ? 'mb-section-quarter-day-selected' : ''}`} onClick={() => toggleSectionQuarterDay(section_id, '4')}>Q4</div>
				</div>
				{(is_lab === '1') ?
					(
						<div className='mb-lab-days extra-small-text green-text'>{unique_days.length > 0 ? `Day(s) ${unique_days.map(day => (day))}` : 'No lab days'}</div>
					):((scheduleType === 'daily' && unique_days.length > 0 && unique_days.length != scheduleInfo.num_days_in_cycle)) ?
					(
						<div className='mb-lab-days extra-small-text blue-text'>Day(s) {unique_days.map(day => (day))}</div>
					):null
				}
				{section_type === '1' &&
					<div className='mb-section-subname-container extra-small-text'>
						<div className='mb-section-subname'><FontAwesomeIcon icon={faChalkboard}/>{classroom_info ? classroom_info.classroom_name : '--'}</div>
						<div className='mb-section-students'><FontAwesomeIcon icon={faUserGraduate}/> {num_students}</div>
					</div>
				}
			</div>
		</div>
	)
}