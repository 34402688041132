import React from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import homepage_main from '../images/videos/homepage-main.webm';
import homepage_changing_screens from '../images/videos/homepage-changing-screens.webm';
import homepage_edit_schedule from '../images/videos/homepage-edit-schedule.webm';
import homepage_auto_generate from '../images/videos/homepage-auto-generate.webm';
import homepage_conflicts from '../images/videos/homepage-conflicts.webm';
import homepage_admin_reports from '../images/videos/homepage-admin-reports.webm';

import genesis_img from '../images/genesis_logo.jpg';
import realtime_img from '../images/realtime_logo.jpg';
import gradelink_img from '../images/gradelink_logo.jpg';
import powerschool_img from '../images/powerschool_logo.jpg';
import skyward_img from '../images/skyward_logo.jpg';
import infinite_campus_img from '../images/infinite_campus_logo.jpg';

import '../css/home_page.css';

export default function HomePage() {

	return (
		<div id='homepage-content'>
			<div id='homepage-navbar'>
				<div id="homepage-logo-container" className="text-align-center">
					<img className='main-sidebar-logo-img' src={require("../images/logo-dark.png")} alt='Edario Logo' />
					<div className='main-sidebar-logo-text'>edario</div>
				</div>
				<div className='text-align-right'>
					<Link className='dark-blue-text' to='/app'><strong>Log in</strong></Link>
				</div>
			</div>
			<div className='homepage-banner'>
				<div id='homepage-banner-main-inner'>
					<div id='homepage-main-banner'>
						<div>
							<div id='homepage-main-banner-heading'>
								<h1>Master Scheduling,</h1>
								<h1>Simplified</h1>
							</div>
							<p>Easily create and edit your master schedule, even with little to no experience.</p>
							<a className='btn blue-btn btn-large' style={{maxWidth:'250px', margin:'50px 0px'}} href='#features'>Learn More</a>
						</div>
						<div id='homepage-video-container'>
							<img src={require('../images/laptop.svg')} alt='Laptop flat'/>
							<div id='homepage-video-container-background'></div>
							<video autoPlay={true} loop={true} muted name="Moving section" src={homepage_main}></video>
						</div>
					</div>
				</div>
			</div>
			<div id='features' className='homepage-banner'>
				<h2 className='text-align-center'>Scheduling Made Easy</h2>
				<p className='text-align-center'>Learn why Edario is becoming the most popular way to do master scheduling.</p>
				<div className='homepage-feature-container'>
					<div className='homepage-feature-left'>
						<video className='homepage-feature-video' autoPlay={true} loop={true} muted name="Changing screens" src={homepage_changing_screens}></video>
					</div>
					<div className='homepage-feature-explanation-container homepage-feature-right'>
						<div className='homepage-random-blob pastel-light-turquoise'></div>
						<div className='homepage-feature-title-container'>
							<img className='homepage-feature-title-img' src={require("../images/logo-dark.png")} alt='Edario Logo' />
							<h3>Step by Step Process</h3>
						</div>
						<p className='homepage-feature-text'>Our Turbo Tax-like screens take you step by step through the scheduling process.</p>
					</div>
				</div>
				<div className='homepage-feature-container'>
					<div className='homepage-feature-explanation-container homepage-feature-left'>
						<div className='homepage-random-blob pastel-light-purple'></div>
						<div className='homepage-feature-title-container'>
							<img className='homepage-feature-title-img' src={require("../images/logo-dark.png")} alt='Edario Logo' />
							<h3>Flexible Schedule Building</h3>
						</div>
						<p className='homepage-feature-text'>Whether you have a daily schedule, rotating block schedule, or even something more complicated, our flexible schedule builder can handle it.</p>
					</div>
					<div className='homepage-feature-right'>
						<video className='homepage-feature-video' autoPlay={true} loop={true} muted name="Changing screens" src={homepage_edit_schedule}></video>
					</div>
				</div>
				<div className='homepage-feature-container'>
					<div className='homepage-feature-left'>
						<video className='homepage-feature-video' autoPlay={true} loop={true} muted name="Auto generate" src={homepage_auto_generate}></video>
					</div>
					<div className='homepage-feature-explanation-container homepage-feature-right'>
						<div className='homepage-random-blob pastel-light-yellow'></div>
						<div className='homepage-feature-title-container'>
							<img className='homepage-feature-title-img' src={require("../images/logo-dark.png")} alt='Edario Logo' />
							<h3>Powerful Schedule Automation</h3>
						</div>
						<p className='homepage-feature-text'>Our powerful algorithms auto generate thousands of sample schedules and then chooses the best one for you.</p>
					</div>
				</div>
				<div className='homepage-feature-container'>
					<div className='homepage-feature-explanation-container homepage-feature-left'>
						<div className='homepage-feature-title-container'>
							<div className='homepage-random-blob pastel-light-turquoise'></div>
							<img className='homepage-feature-title-img' src={require("../images/logo-dark.png")} alt='Edario Logo' />
							<h3>Easy Conflict Resolution</h3>
						</div>
						<p className='homepage-feature-text'>We make it incredibly easy to both understand and fix conflicts, without needing to recreate or rerun a whole new schedule.</p>
					</div>
					<div className='homepage-feature-right'>
						<video className='homepage-feature-video' autoPlay={true} loop={true} muted name="Conflict loop" src={homepage_conflicts}></video>
					</div>
				</div>
				<div className='homepage-feature-container'>
					<div className='homepage-feature-left'>
						<video className='homepage-feature-video' autoPlay={true} loop={true} muted name="Admin reports" src={homepage_admin_reports}></video>
					</div>
					<div className='homepage-feature-explanation-container homepage-feature-right'>
						<div className='homepage-random-blob pastel-light-purple'></div>
						<div className='homepage-feature-title-container'>
							<img className='homepage-feature-title-img' src={require("../images/logo-dark.png")} alt='Edario Logo' />
							<h3>Admin Reports</h3>
						</div>
						<p className='homepage-feature-text'>We create reports that are both insightful and interactive, allowing you to edit schedules within the reports themselves!</p>
					</div>
				</div>
			</div>
			<div className='homepage-banner light-gray'>
				<h3 className='text-align-center'>Integrates with most SIS</h3>
				<div id='homepage-sis-container'>
					<img className="homepage-sis-logo" src={genesis_img} alt='genesis logo'/>
					<img className="homepage-sis-logo" src={realtime_img} alt='realtime logo'/>
					<img className="homepage-sis-logo" src={gradelink_img} alt='gradelink logo'/>
					<img className="homepage-sis-logo" src={powerschool_img} alt='powerschool logo'/>
					<img className="homepage-sis-logo" src={skyward_img} alt='skyward logo'/>
					<img className="homepage-sis-logo" src={infinite_campus_img} alt='infinite campus logo'/>
				</div>
			</div>
			<div className='homepage-banner blue'>
				<h1 className='text-align-center white-text' style={{margin:'0px'}}>Contact Us</h1>
				<h3 className='text-align-center light-gray-text' style={{margin:'10px 0px 35px 0px'}}>For pricing or more information</h3>
				<a className='btn green-btn btn-large' style={{maxWidth:'200px', border:'none'}} href='mailto:contact@edario.com' target='_blank'>contact@edario.com</a>
			</div>
		</div>
	);
}