import React, { useState } from 'react';
import Fuse from 'fuse.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { capitalizeFirstLetters, sortArrayOfObjects } from '../../../js/Helpers';

export default function ScheduleSummary({ scheduleName, scheduleType, sections, subsections, teachers, students, courses, classrooms, labels, scheduleStructureData, scheduleInfo, toggleEditSection, toggleEditStudentSchedule }) {
	const [filterIDs, setFilterIDs] = useState([]);
	const [toggledCourses, setToggledCourses] = useState([]);
	const [areAllExpanded, setAreAllExpanded] = useState({nonelective:false, elective:false});

	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const fuzzySearch = (e) => {
		const search_value = e.target.value;

		const filtered_course_ids = [];
		
		// Search courses by name or code
		const courses_fuse = new Fuse(courses, {
			keys: ['name', 'course_code'],
			threshold: .1
		});

		const course_results = courses_fuse.search(search_value);
		const subset_course_results = course_results.map(result => result.item).slice(0,4);

		subset_course_results.forEach(course => {
			const course_id = course.course_id;

			filtered_course_ids.push(course_id);
		});

		setFilterIDs([...filtered_course_ids]);
	}

	const toggleCourse = (course_id) => {
		const course_index = toggledCourses.findIndex(course => course === course_id);

		if(course_index !== -1) 
		{
			toggledCourses.splice(course_index, 1);
		}
		else
		{
			toggledCourses.push(course_id);
		}

		setToggledCourses([...toggledCourses]);
	}

	const handleExpandAll = (elective) => {
		const is_expanded = areAllExpanded[elective];
		const new_toggled_courses = (is_expanded) ? toggledCourses.reduce((results, course_id) => {
				const course_info = courses.find(course => course.course_id === course_id);
				const course_elective = (course_info.elective ==- '0') ? 'nonelective' : 'elective';
				if(course_elective !== elective) results.push(course_id);
				return results;
			}, []) : courses.reduce((results, course_info) => {
				const course_id = course_info.course_id;
				const course_elective = (course_info.elective ==- '0') ? 'nonelective' : 'elective';
				if(course_elective === elective) results.push(course_id);
				return results;
			}, []);

		areAllExpanded[elective] = !areAllExpanded[elective];
		setAreAllExpanded({...areAllExpanded});
		setToggledCourses([...new_toggled_courses]);
	}

	////////////////////////////////
	///// INITIALIZE VARIABLES /////
	////////////////////////////////
	const match_stats = students.reduce((results, student) => {
		const student_requests = student.student_requests;

		student_requests.filter(request => request.is_deleted === '0').forEach(request => {
			const course_id = request.course_id;
			const course_index = courses.findIndex(course => course.course_id === course_id);
			const course_info = (course_index !== -1) ? courses[course_index] : null;
			const elective = (course_info) ? course_info.elective : null;

			const student_in_placed_section_of_course = (course_info && sections.findIndex(section => section.course_id === course_id && section.course_period_id && section.student_list.includes(student.student_id)) !== -1) ? true : false;

			if(course_info)
			{
				if(elective === '1')
				{
					if(student_in_placed_section_of_course) results.total_matched_elective_requests++;
					results.total_elective_requests++;
				}
				else
				{
					if(student_in_placed_section_of_course) results.total_matched_non_elective_requests++;
					results.total_non_elective_requests++;
				}
			}
		});
		return results;
	}, {total_non_elective_requests: 0, total_matched_non_elective_requests: 0, total_elective_requests: 0, total_matched_elective_requests: 0, });

	const non_elective_percent_matched = (match_stats.total_non_elective_requests === 0) ? 0 : Math.round(match_stats.total_matched_non_elective_requests/match_stats.total_non_elective_requests*100);
	const elective_percent_matched = (match_stats.total_elective_requests === 0) ? 0 : Math.round(match_stats.total_matched_elective_requests/match_stats.total_elective_requests*100);

	const nonelective_courses = courses.filter(course => course.elective === '0');
	const elective_courses = courses.filter(course => course.elective === '1');

	const sorted_nonelective_courses = sortArrayOfObjects(nonelective_courses, 'course_code', 'text', 'desc');
	const sorted_elective_courses = sortArrayOfObjects(elective_courses, 'course_code', 'text', 'desc');

	return (
		<>
			<h4 className='report-schedule-name'>{scheduleName}</h4>
			<h1 className='report-name'>Schedule Summary</h1>
			<div className='reports-search-bar-container'>
				<input className='reports-search-bar' placeholder={`Search for a course or course code...`} defaultValue='' onChange={(e) => fuzzySearch(e)}/>
				<FontAwesomeIcon className='reports-search-bar-icon' icon={faSearch}/>
			</div>
			<div className='reports-main-container'>
				<div className='reports-main-inner-container'>
					<div className='fixed-heading-on-scroll white'>
						<div className='schedule-summary-name-row'>
							<h1>Non-Elective Courses</h1>
							<h1>{non_elective_percent_matched}% matched</h1>
						</div>
						<div className='blue-link medium-text align-right' onClick={() => handleExpandAll('nonelective')}>{areAllExpanded.nonelective ? 'Collapse All' : 'Expand All'}</div>
						<div className='schedule-summary-course-row'>
							<div className='schedule-summary-course-name'>Code</div>
							<div className='schedule-summary-course-name'>Course</div>
							<div>Requests</div>
							<div>Matched Requests</div>
							<div>Available Seats</div>
							<div>Placed Sections</div>
							<div>Unplaced Sections</div>
							<div></div>
						</div>
					</div>
					{sorted_nonelective_courses.map((course_info, i) => {
						const course_id = course_info.course_id;
						const max_class_size = course_info.max_class_size;

						if(filterIDs.length > 0 && !filterIDs.includes(course_id)) return;

						// Iterate through sections and find how many sections with course were created, and how many students were matched
						let logged_span_ids = [];
						const sections_with_course_data = sections.reduce((result, main_section) => {
							if(main_section.course_id !== course_id) return result;

							const span_id = main_section.span_id;
							if(span_id)
							{
								if(logged_span_ids.includes(span_id)) return result;
								logged_span_ids.push(span_id);
							}

							const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === main_section.section_id);
							const is_subsection = (subsection_index !== -1) ? true : false;

							// Check if is inclusion placeholder
							if(is_subsection)
							{
								const subsection_section_id = subsections[subsection_index].section_id;
								const subsection_section_info = sections.find(section => section.section_id === subsection_section_id);
								const subsection_section_course_id = subsection_section_info.course_id;
								
								if(scheduleInfo.separate_ICR_codes === '0' && main_section.course_id === subsection_section_course_id && main_section.is_inclusion === '1') return result;
							}

							if(main_section.course_period_id)
							{
								result.placed_sections.push(main_section);
							}
							else
							{
								result.unplaced_sections.push(main_section);
							}

							return result;
						}, {placed_sections:[], unplaced_sections:[]});

						const num_placed_sections = sections_with_course_data.placed_sections.length;
						const num_unplaced_sections = sections_with_course_data.unplaced_sections.length;
						const num_available_seats = max_class_size * num_placed_sections;

						/*const primary_teachers = course_info.teachers_can_teach.filter(tct_info => tct_info.type === '1');
						const secondary_teachers = course_info.teachers_can_teach.filter(tct_info => tct_info.type === '2');
						const classroom_restrictions = course_info.classroom_restrictions;*/
						
						const students_requesting_course = students.reduce((result, student) => {
							const student_requests = student.student_requests;
							const student_requested_index = student_requests.findIndex(request => request.course_id === course_id && request.is_deleted === '0');
							const student_requested_course = (student_requested_index !== -1) ? true : false;

							if(student_requested_course) 
							{
								const student_request_info = student_requests[student_requested_index];
								const priority = student_request_info.priority;
								const student_in_placed_section_of_course = (sections.findIndex(section => section.course_id === course_id && section.course_period_id && section.student_list.includes(student.student_id)) !== -1) ? true : false;

								if(student_in_placed_section_of_course)
								{
									if(priority !== '1') result.matched_alternates.push(student.student_id);
									result.matched.push(student.student_id);
								}
								else
								{	
									result.unmatched.push(student.student_id);
								}
								
								if(priority !== '1') result.all_alternates.push(student.student_id);
								result.all.push(student.student_id);
							}

							return result;
						}, {'matched':[], 'unmatched':[], 'all':[], 'matched_alternates':[], 'all_alternates':[]});

						const unmatched_students = students_requesting_course.unmatched.filter(student_id => !students_requesting_course.all_alternates.includes(student_id));
						const unmatch_alt_students = students_requesting_course.unmatched.filter(student_id => students_requesting_course.all_alternates.includes(student_id));

						const total_requests = students_requesting_course.all.length;
						const total_alternates = students_requesting_course.all_alternates.length;
						const total_requests_without_alternates = total_requests - total_alternates;
						
						// If no requests and no sections created then we don't need to worry about this course, skip it
						if(total_requests === 0 && ((num_placed_sections + num_unplaced_sections) === 0)) return;
						
						const total_matched = students_requesting_course.matched.length;
						const matched_alternates = students_requesting_course.matched_alternates.length;

						const seat_percent = (num_available_seats == 0) ? 0 : Math.min(total_matched/num_available_seats, 1);
						const request_percent = (total_requests === 0) ? 0 : Math.min(total_matched/total_requests_without_alternates, 1);
						const match_percent = Math.max(seat_percent, request_percent);
						
						const course_toggled = toggledCourses.findIndex(course => course === course_id) !== -1;

						const status_background = (match_percent == 1) ? {backgroundColor:'#c3f0c3'} : ((match_percent <= .5) ? {backgroundColor:'#F5C9CB'} : {backgroundColor:'#ffeda6'});
						

						return (
							<React.Fragment key={i}>
								<div className='schedule-summary-course-row' style={status_background}>
									<div className='schedule-summary-course-name'>{course_info.course_code}</div>
									<div className='schedule-summary-course-name tooltip' data-tooltip-text={`${capitalizeFirstLetters(course_info.name)}`}>{capitalizeFirstLetters(course_info.name)}</div>
									<div style={{textAlign:'center'}}>
										<div>{total_requests}</div>
										{total_alternates > 0 &&
											<div className='small-text purple-text'>{total_alternates} alternates</div>
										}
									</div>
									<div style={{textAlign:'center'}}>
										<div>{total_matched}</div>
										{total_alternates > 0 &&
											<div className='small-text purple-text'>{matched_alternates} alternates</div>
										}
									</div>
									<div>{num_available_seats}</div>
									<div>{num_placed_sections}</div>
									<div>{num_unplaced_sections}</div>
									<FontAwesomeIcon className='schedule-summary-toggle-arrow' icon={course_toggled ? faAngleUp : faAngleDown} onClick={() => toggleCourse(course_id)}/>
								</div>
								{course_toggled &&
									<div className='schedule-summary-more-info-container'>
										<h4 style={{margin:'10px 0px 10px 0px'}}>Current Sections</h4>
										<div>
											{sections_with_course_data.placed_sections.length > 0 ? 
												(
													<div className='schedule-summary-section-row dark-gray-text'>
														<div className='schedule-summary-section-inner-row small-text' style={{padding:'5px 10px 5px 10px'}}>
															<div>Section #</div>
															<div>Code</div>
															<div>Course</div>
															<div>Teacher(s)</div>
															<div>Period</div>
															<div className='schedule-summary-section-center'>Room</div>
															<div className='schedule-summary-section-center'># Students</div>
														</div>
														<div></div>
													</div>
												) : 
												(
													<div>There are no sections currently placed.</div>
												)
											}
											{sections_with_course_data.placed_sections.map((section_info, i) => {
												const section_id = section_info.section_id;
												const section_number = section_info.section_number;
												const section_type = section_info.section_type;
												const course_period_id = section_info.course_period_id;
												const course_id = section_info.course_id;
												const teacher_id = section_info.teacher_id;
												const classroom_id = section_info.classroom_id;
												const data_id = (teacher_id) ? teacher_id : classroom_id;
												const num_students = section_info.student_list.length;
												const is_lab = section_info.is_lab;
												const span_id = section_info.span_id;

												if(is_lab === '1') return;

												let period_name = null;
												if(scheduleType === 'daily')
												{
													const schedule_period_id = scheduleStructureData.course_periods[course_period_id].days['1'];
													period_name = scheduleStructureData.schedule_periods[schedule_period_id].period_name;
												}
												else
												{
													period_name = scheduleStructureData.course_periods[course_period_id].period_name;
												}

												let periods = (span_id) ? sections.reduce((results, section_info) => {
													const section_span_id = section_info.span_id;
													if(section_span_id !== span_id) return results;

													const section_course_period_id = section_info.course_period_id;
													let period_name = null;
													if(scheduleType === 'daily')
													{
														const schedule_period_id = scheduleStructureData.course_periods[section_course_period_id].days['1'];
														period_name = scheduleStructureData.schedule_periods[schedule_period_id].period_name;
													}
													else
													{
														period_name = scheduleStructureData.course_periods[section_course_period_id].period_name;
													}

													if(!results.includes(period_name)) results.push(period_name);
													return results;
												}, []) : [period_name];

												const course_info = courses.find(course => course.course_id === course_id);
												const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
												const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
												const teacher_full_name = (teacher_id) ? `${teacher_info.name}, ${teacher_info.first_name}` : null;
												
												// Get num students from all subsections too
												const max_class_size = course_info.max_class_size
												const section_subsections = ('subsections' in section_info) ? section_info.subsections : [];

												const total_num_students = (section_subsections.length === 0) ? num_students : section_subsections.reduce((total_students, subsection_id) => {
													const subsection_num_students = sections.find(section => section.section_id === subsection_id).student_list.length;
													return total_students + subsection_num_students;
												}, num_students);

												// Get all labels
												let section_all_labels = section_info.labels;		
												section_all_labels = section_all_labels.map(label_id => {
													const label_info = labels.find(label => label.label_id === label_id);
													return label_info;
												});
												
												const section_teams = section_all_labels.filter(label => label.is_team === '1');
												const sorted_section_teams = sortArrayOfObjects(section_teams, 'label', 'text', 'desc');
												
												const section_labels = section_all_labels.filter(label => label.is_team === '0');
												const sorted_section_labels = sortArrayOfObjects(section_labels, 'label', 'text', 'desc');

												return (
													<React.Fragment key={section_id}>
													<div className='schedule-summary-section-row'>
														<div className='schedule-summary-section-inner-row'>
															<div>{section_number}</div>
															<div>{course_info.course_code}</div>
															<div>
																<div>{course_info.name}</div>
																<div>
																	{sorted_section_teams.map((course_label, i) => {
																		const label_id = course_label.label_id;
																		const label_info = labels.find(label => label.label_id === label_id);
																		
																		if(!label_info) return;

																		return (
																			<div className={`edit-student-schedule-request-label pastel-blue-text`} key={i}>{label_info.label}</div>
																		)
																	})}
																	{sorted_section_labels.map((course_label, i) => {
																		const label_id = course_label.label_id;
																		const label_info = labels.find(label => label.label_id === label_id);
																		
																		if(!label_info) return;

																		return (
																			<div className={`edit-student-schedule-request-label pastel-orange-text`} key={i}>{label_info.label}</div>
																		)
																	})}
																</div>
															</div>
															<div>{teacher_full_name}</div>
															<div>
																{periods.map((period_name, i) => (
																	<div key={i}>{period_name}</div>
																))}
															</div>
															<div className='schedule-summary-section-center'>{classroom_info ? classroom_info.classroom_name : "--"}</div>
															<div className='schedule-summary-section-center'>{total_num_students}/{max_class_size}</div>
															{section_subsections.map((subsection_id, i) => {
																const section_subsection_info = sections.find(section => section.section_id === subsection_id);

																const subsection_course_id = section_subsection_info.course_id;
																const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
																const subsection_course_name = subsection_course_info.name;
																const subsection_course_code = subsection_course_info.course_code;

																const subsection_teacher_id = section_subsection_info.teacher_id;
																const subsection_teacher_info = (subsection_teacher_id) ? teachers.find(teacher => teacher.teacher_id === subsection_teacher_id) : null;
																const subsection_teacher_first_name = (subsection_teacher_id) ? subsection_teacher_info.first_name : null;
																const subsection_teacher_last_name = (subsection_teacher_id) ? subsection_teacher_info.name : null;

																// Check if subsection is placeholder inclusion course (for schools without separate ICR codes)
																const subsection_is_inclusion = section_subsection_info.is_inclusion;
																let is_placeholder_inclusion = false;
																if(scheduleInfo.separate_ICR_codes === '0' && course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;

																// Figure out max class size (if inclusion then 10)
																const subsection_max_class_size = (subsection_is_inclusion) ? 10 : subsection_course_info.max_class_size;
																let subsection_num_students = section_subsection_info.student_list.length;

																// Get all labels
																let subsection_all_labels = section_subsection_info.labels;		
																subsection_all_labels = subsection_all_labels.map(label_id => {
																	const label_info = labels.find(label => label.label_id === label_id);
																	return label_info;
																});
																
																const subsection_teams = subsection_all_labels.filter(label => label.is_team === '1');
																const sorted_subsection_teams = sortArrayOfObjects(subsection_teams, 'label', 'text', 'desc');
																
																const subsection_labels = subsection_all_labels.filter(label => label.is_team === '0');
																const sorted_subsection_labels = sortArrayOfObjects(subsection_labels, 'label', 'text', 'desc');

																return (
																	<React.Fragment key={subsection_id}>
																		<div className={`small-text ${is_placeholder_inclusion ? 'orange-text' : 'blue-text'}`}>{subsection_course_code}</div>
																		<div className={`small-text ${is_placeholder_inclusion ? 'orange-text' : 'blue-text'}`}>
																			<div>{subsection_course_name}</div>
																			<div>
																				{sorted_subsection_teams.map((course_label, i) => {
																					const label_id = course_label.label_id;
																					const label_info = labels.find(label => label.label_id === label_id);
																					
																					if(!label_info) return;

																					return (
																						<div className={`edit-student-schedule-request-label pastel-blue-text`} key={i}>{label_info.label}</div>
																					)
																				})}
																				{sorted_subsection_labels.map((course_label, i) => {
																					const label_id = course_label.label_id;
																					const label_info = labels.find(label => label.label_id === label_id);
																					
																					if(!label_info) return;

																					return (
																						<div className={`edit-student-schedule-request-label pastel-orange-text`} key={i}>{label_info.label}</div>
																					)
																				})}
																			</div>
																		</div>
																		<div className={`small-text ${is_placeholder_inclusion ? 'orange-text' : 'blue-text'}`}>{subsection_teacher_last_name}, {subsection_teacher_first_name}</div>
																		<div>--</div>
																		<div className='schedule-summary-section-center small-text'>--</div>
																		<div className={`schedule-summary-section-center small-text ${is_placeholder_inclusion ? 'orange-text' : 'blue-text'}`}>{subsection_num_students}/{subsection_max_class_size}</div>
																	</React.Fragment>
																)
															})}
														</div>
														<div className='blue-link medium-text' onClick={() => toggleEditSection(section_id, course_period_id, data_id, section_type)}>More Details</div>
													</div>
													</React.Fragment>
												)
											})}
										</div>
										<div>
											{unmatched_students.length > 0 && <h4 style={{margin:'40px 0px 10px 0px'}}>Unmatched Students</h4>}
											<div className='schedule-summary-unmatched-students-container'>
												{unmatched_students.map(student_id => {
													const student_info = students.find(student => student.student_id === student_id);
													if(!student_info) return;

													return (
														<div className='schedule-summary-unmatched-student medium-text' key={student_id} onClick={() => toggleEditStudentSchedule(student_id)}>
															<div>{student_info.student_code}</div>
															<div className='blue-link'>{student_info.last_name}, {student_info.first_name} </div>
															<div>{student_info.grade}</div>
														</div>
													)
												})}
											</div>
											{unmatch_alt_students.length > 0 && <h4 style={{margin:'40px 0px 10px 0px'}}>Unmatched Alternates</h4>}
											<div className='schedule-summary-unmatched-students-container'>
												{unmatch_alt_students.map(student_id => {
													const student_info = students.find(student => student.student_id === student_id);
													if(!student_info) return;

													return (
														<div className='schedule-summary-unmatched-student medium-text' key={student_id} onClick={() => toggleEditStudentSchedule(student_id)}>
															<div>{student_info.student_code}</div>
															<div className='purple-link'>{student_info.last_name}, {student_info.first_name} </div>
															<div>{student_info.grade}</div>
														</div>
													)
												})}
											</div>
										</div>
									</div>
								}
							</React.Fragment>
						)
					})}
					<div className='fixed-heading-on-scroll white'>
						<div className='schedule-summary-name-row'>
							<h1>Elective Courses</h1>
							<h1>{elective_percent_matched}% matched</h1>
						</div>
						<div className='blue-link medium-text align-right' onClick={() => handleExpandAll('elective')}>{areAllExpanded.elective ? 'Collapse All' : 'Expand All'}</div>
						<div className='schedule-summary-course-row'>
							<div className='schedule-summary-course-name'>Code</div>
							<div className='schedule-summary-course-name'>Course</div>
							<div>Requests</div>
							<div>Matched Requests</div>
							<div>Available Seats</div>
							<div>Placed Sections</div>
							<div>Unplaced Sections</div>
						</div>
					</div>
					{sorted_elective_courses.map((course_info, i) => {
						const course_id = course_info.course_id;
						const max_class_size = course_info.max_class_size;

						if(filterIDs.length > 0 && !filterIDs.includes(course_id)) return;

						// Iterate through sections and find how many sections with course were created, and how many students were matched
						let logged_span_ids = [];
						const sections_with_course_data = sections.reduce((result, main_section) => {
							if(main_section.course_id !== course_id) return result;

							const span_id = main_section.span_id;
							if(span_id)
							{
								if(logged_span_ids.includes(span_id)) return result;
								logged_span_ids.push(span_id);
							}

							const subsection_index = subsections.findIndex(subsection => subsection.subsection_id === main_section.section_id);
							const is_subsection = (subsection_index !== -1) ? true : false;

							// Check if is inclusion placeholder
							if(is_subsection)
							{
								const subsection_section_id = subsections[subsection_index].section_id;
								const subsection_section_info = sections.find(section => section.section_id === subsection_section_id);
								const subsection_section_course_id = subsection_section_info.course_id;
								
								if(scheduleInfo.separate_ICR_codes === '0' && main_section.course_id === subsection_section_course_id && main_section.is_inclusion === '1') return result;
							}

							if(main_section.course_period_id)
							{
								result.placed_sections.push(main_section);
							}
							else
							{
								result.unplaced_sections.push(main_section);
							}

							return result;
						}, {placed_sections:[], unplaced_sections:[]});

						const num_placed_sections = sections_with_course_data.placed_sections.length;
						const num_unplaced_sections = sections_with_course_data.unplaced_sections.length;
						const num_available_seats = max_class_size * num_placed_sections;

						/*const primary_teachers = course_info.teachers_can_teach.filter(tct_info => tct_info.type === '1');
						const secondary_teachers = course_info.teachers_can_teach.filter(tct_info => tct_info.type === '2');
						const classroom_restrictions = course_info.classroom_restrictions;*/
						
						const students_requesting_course = students.reduce((result, student) => {
							const student_requests = student.student_requests;
							const student_requested_index = student_requests.findIndex(request => request.course_id === course_id && request.is_deleted === '0');
							const student_requested_course = (student_requested_index !== -1) ? true : false;

							if(student_requested_course) 
							{
								const student_request_info = student_requests[student_requested_index];
								const priority = student_request_info.priority;
								const student_in_placed_section_of_course = (sections.findIndex(section => section.course_id === course_id && section.course_period_id && section.student_list.includes(student.student_id)) !== -1) ? true : false;

								if(student_in_placed_section_of_course)
								{
									if(priority !== '1') result.matched_alternates.push(student.student_id);
									result.matched.push(student.student_id);
								}
								else
								{	
									result.unmatched.push(student.student_id);
								}
								
								if(priority !== '1') result.all_alternates.push(student.student_id);
								result.all.push(student.student_id);
							}

							return result;
						}, {'matched':[], 'unmatched':[], 'all':[], 'matched_alternates':[], 'all_alternates':[]});

						const total_requests = students_requesting_course.all.length;
						const total_alternates = students_requesting_course.all_alternates.length;
						const total_requests_without_alternates = total_requests - total_alternates;
						
						// If no requests and no sections created then we don't need to worry about this course, skip it
						if(total_requests === 0 && ((num_placed_sections + num_unplaced_sections) === 0)) return;
						
						const total_matched = students_requesting_course.matched.length;
						const matched_alternates = students_requesting_course.matched_alternates.length;
						
						const seat_percent = (num_available_seats == 0) ? 0 : Math.min(total_matched/num_available_seats, 1);
						const request_percent = (total_requests === 0) ? 0 : Math.min(total_matched/total_requests_without_alternates, 1);
						const match_percent = Math.max(seat_percent, request_percent);
						
						const course_toggled = toggledCourses.findIndex(course => course === course_id) !== -1;

						const status_background = (match_percent == 1) ? {backgroundColor:'#c3f0c3'} : ((match_percent <= .5) ? {backgroundColor:'#F5C9CB'} : {backgroundColor:'#ffeda6'});

						return (
							<React.Fragment key={i}>
								<div className='schedule-summary-course-row' style={status_background}>
									<div className='schedule-summary-course-name'>{course_info.course_code}</div>
									<div className='schedule-summary-course-name tooltip' data-tooltip-text={`${capitalizeFirstLetters(course_info.name)}`}>{capitalizeFirstLetters(course_info.name)}</div>
									<div style={{textAlign:'center'}}>
										<div>{total_requests}</div>
										{total_alternates > 0 &&
											<div className='small-text purple-text'>{total_alternates} alternates</div>
										}
									</div>
									<div style={{textAlign:'center'}}>
										<div>{total_matched}</div>
										{total_alternates > 0 &&
											<div className='small-text purple-text'>{matched_alternates} alternates</div>
										}
									</div>
									<div>{num_available_seats}</div>
									<div>{num_placed_sections}</div>
									<div>{num_unplaced_sections}</div>
									<FontAwesomeIcon className='schedule-summary-toggle-arrow' icon={course_toggled ? faAngleUp : faAngleDown} onClick={() => toggleCourse(course_id)}/>
								</div>
								{course_toggled &&
									<div className='schedule-summary-more-info-container'>
										<h4 style={{margin:'10px 0px 10px 0px'}}>Current Sections</h4>
										<div>
											{sections_with_course_data.placed_sections.length > 0 ? 
												(
													<div className='schedule-summary-section-row dark-gray-text'>
														<div className='schedule-summary-section-inner-row small-text' style={{padding:'5px 10px 5px 10px'}}>
															<div>Code</div>
															<div>Course</div>
															<div>Teacher(s)</div>
															<div>Period</div>
															<div className='schedule-summary-section-center'>Room</div>
															<div className='schedule-summary-section-center'># Students</div>
														</div>
														<div></div>
													</div>
												) : 
												(
													<div>There are no sections currently placed.</div>
												)
											}
											{sections_with_course_data.placed_sections.map((section_info, i) => {
												const section_id = section_info.section_id;
												const section_type = section_info.section_type;
												const course_period_id = section_info.course_period_id;
												const course_id = section_info.course_id;
												const teacher_id = section_info.teacher_id;
												const classroom_id = section_info.classroom_id;
												const data_id = (teacher_id) ? teacher_id : classroom_id;
												const num_students = section_info.student_list.length;
												const is_lab = section_info.is_lab;
												const span_id = section_info.span_id;

												if(is_lab === '1') return;

												let period_name = null;
												if(scheduleType === 'daily')
												{
													const schedule_period_id = scheduleStructureData.course_periods[course_period_id].days['1'];
													period_name = scheduleStructureData.schedule_periods[schedule_period_id].period_name;
												}
												else
												{
													period_name = scheduleStructureData.course_periods[course_period_id].period_name;
												}

												let periods = (span_id) ? sections.reduce((results, section_info) => {
													const section_span_id = section_info.span_id;
													if(section_span_id !== span_id) return results;

													const section_course_period_id = section_info.course_period_id;
													let period_name = null;
													if(scheduleType === 'daily')
													{
														const schedule_period_id = scheduleStructureData.course_periods[section_course_period_id].days['1'];
														period_name = scheduleStructureData.schedule_periods[schedule_period_id].period_name;
													}
													else
													{
														period_name = scheduleStructureData.course_periods[section_course_period_id].period_name;
													}

													if(!results.includes(period_name)) results.push(period_name);
													return results;
												}, []) : [period_name];

												const course_info = courses.find(course => course.course_id === course_id);
												const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
												const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
												const teacher_full_name = (teacher_id) ? `${teacher_info.name}, ${teacher_info.first_name}` : null;
												
												// Get num students from all subsections too
												const max_class_size = course_info.max_class_size
												const section_subsections = ('subsections' in section_info) ? section_info.subsections : [];

												const total_num_students = (section_subsections.length === 0) ? num_students : section_subsections.reduce((total_students, subsection_id) => {
													const subsection_num_students = sections.find(section => section.section_id === subsection_id).student_list.length;
													return total_students + subsection_num_students;
												}, num_students);

												// Get all labels
												let section_all_labels = section_info.labels;		
												section_all_labels = section_all_labels.map(label_id => {
													const label_info = labels.find(label => label.label_id === label_id);
													return label_info;
												});
												
												const section_teams = section_all_labels.filter(label => label.is_team === '1');
												const sorted_section_teams = sortArrayOfObjects(section_teams, 'label', 'text', 'desc');
												
												const section_labels = section_all_labels.filter(label => label.is_team === '0');
												const sorted_section_labels = sortArrayOfObjects(section_labels, 'label', 'text', 'desc');

												return (
													<React.Fragment key={section_id}>
													<div className='schedule-summary-section-row'>
														<div className='schedule-summary-section-inner-row'>
															<div>{course_info.course_code}</div>
															<div>
																<div>{course_info.name}</div>
																<div>
																	{sorted_section_teams.map((course_label, i) => {
																		const label_id = course_label.label_id;
																		const label_info = labels.find(label => label.label_id === label_id);
																		
																		if(!label_info) return;

																		return (
																			<div className={`edit-student-schedule-request-label pastel-blue-text`} key={i}>{label_info.label}</div>
																		)
																	})}
																	{sorted_section_labels.map((course_label, i) => {
																		const label_id = course_label.label_id;
																		const label_info = labels.find(label => label.label_id === label_id);
																		
																		if(!label_info) return;

																		return (
																			<div className={`edit-student-schedule-request-label pastel-orange-text`} key={i}>{label_info.label}</div>
																		)
																	})}
																</div>
															</div>
															<div>{teacher_full_name}</div>
															<div>
																{periods.map((period_name, i) => (
																	<div key={i}>{period_name}</div>
																))}
															</div>
															<div className='schedule-summary-section-center'>{classroom_info ? classroom_info.classroom_name : "--"}</div>
															<div className='schedule-summary-section-center'>{total_num_students}/{max_class_size}</div>
															{section_subsections.map((subsection_id, i) => {
																const section_subsection_info = sections.find(section => section.section_id === subsection_id);

																const subsection_course_id = section_subsection_info.course_id;
																const subsection_course_info = courses.find(course => course.course_id === subsection_course_id);
																const subsection_course_name = subsection_course_info.name;
																const subsection_course_code = subsection_course_info.course_code;

																const subsection_teacher_id = section_subsection_info.teacher_id;
																const subsection_teacher_info = (subsection_teacher_id) ? teachers.find(teacher => teacher.teacher_id === subsection_teacher_id) : null;
																const subsection_teacher_first_name = (subsection_teacher_id) ? subsection_teacher_info.first_name : null;
																const subsection_teacher_last_name = (subsection_teacher_id) ? subsection_teacher_info.name : null;

																// Check if subsection is placeholder inclusion course (for schools without separate ICR codes)
																const subsection_is_inclusion = section_subsection_info.is_inclusion;
																let is_placeholder_inclusion = false;
																if(scheduleInfo.separate_ICR_codes === '0' && course_id === subsection_course_id && subsection_is_inclusion === '1') is_placeholder_inclusion = true;

																// Figure out max class size (if inclusion then 10)
																const subsection_max_class_size = (subsection_is_inclusion) ? 10 : subsection_course_info.max_class_size;
																let subsection_num_students = section_subsection_info.student_list.length;

																// Get all labels
																let subsection_all_labels = section_subsection_info.labels;		
																subsection_all_labels = subsection_all_labels.map(label_id => {
																	const label_info = labels.find(label => label.label_id === label_id);
																	return label_info;
																});
																
																const subsection_teams = subsection_all_labels.filter(label => label.is_team === '1');
																const sorted_subsection_teams = sortArrayOfObjects(subsection_teams, 'label', 'text', 'desc');
																
																const subsection_labels = subsection_all_labels.filter(label => label.is_team === '0');
																const sorted_subsection_labels = sortArrayOfObjects(subsection_labels, 'label', 'text', 'desc');

																return (
																	<React.Fragment key={subsection_id}>
																		<div className={`small-text ${is_placeholder_inclusion ? 'orange-text' : 'blue-text'}`}>{subsection_course_code}</div>
																		<div className={`small-text ${is_placeholder_inclusion ? 'orange-text' : 'blue-text'}`}>
																			<div>{subsection_course_name}</div>
																			<div>
																				{sorted_subsection_teams.map((course_label, i) => {
																					const label_id = course_label.label_id;
																					const label_info = labels.find(label => label.label_id === label_id);
																					
																					if(!label_info) return;

																					return (
																						<div className={`edit-student-schedule-request-label pastel-blue-text`} key={i}>{label_info.label}</div>
																					)
																				})}
																				{sorted_subsection_labels.map((course_label, i) => {
																					const label_id = course_label.label_id;
																					const label_info = labels.find(label => label.label_id === label_id);
																					
																					if(!label_info) return;

																					return (
																						<div className={`edit-student-schedule-request-label pastel-orange-text`} key={i}>{label_info.label}</div>
																					)
																				})}
																			</div>
																		</div>
																		<div className={`small-text ${is_placeholder_inclusion ? 'orange-text' : 'blue-text'}`}>{subsection_teacher_last_name}, {subsection_teacher_first_name}</div>
																		<div>--</div>
																		<div className='schedule-summary-section-center small-text'>--</div>
																		<div className={`schedule-summary-section-center small-text ${is_placeholder_inclusion ? 'orange-text' : 'blue-text'}`}>{subsection_num_students}/{subsection_max_class_size}</div>
																	</React.Fragment>
																)
															})}
														</div>
														<div className='blue-link medium-text' onClick={() => toggleEditSection(section_id, course_period_id, data_id, section_type)}>More Details</div>
													</div>
													</React.Fragment>
												)
											})}
										</div>
										<div>
											<h4 style={{margin:'40px 0px 10px 0px'}}>Unmatched Students</h4>
											<div className='schedule-summary-unmatched-students-container'>
												{students_requesting_course.unmatched.filter(student_id => !students_requesting_course.all_alternates.includes(student_id)).map(student_id => {
													const student_info = students.find(student => student.student_id === student_id);
													if(!student_info) return;

													return (
														<div className='schedule-summary-unmatched-student medium-text' key={student_id} onClick={() => toggleEditStudentSchedule(student_id)}>
															<div>{student_info.student_code}</div>
															<div className='blue-link'>{student_info.last_name}, {student_info.first_name} </div>
															<div>{student_info.grade}</div>
														</div>
													)
												})}
											</div>
											<h4 style={{margin:'40px 0px 10px 0px'}}>Unmatched Alternates</h4>
											<div className='schedule-summary-unmatched-students-container'>
												{students_requesting_course.unmatched.filter(student_id => students_requesting_course.all_alternates.includes(student_id)).map(student_id => {
													const student_info = students.find(student => student.student_id === student_id);
													if(!student_info) return;

													return (
														<div className='schedule-summary-unmatched-student medium-text' key={student_id} onClick={() => toggleEditStudentSchedule(student_id)}>
															<div>{student_info.student_code}</div>
															<div className='purple-link'>{student_info.last_name}, {student_info.first_name} </div>
															<div>{student_info.grade}</div>
														</div>
													)
												})}
											</div>
										</div>
									</div>
								}
							</React.Fragment>
						)
					})}
				</div>
			</div>
		</>
	);
}