import React from 'react';

import SectionReview from './section_review';
import CreateLabels from './create_labels';
import StudentLabels from './student_labels';

import '../../../css/hsms/section_creation.css';

export default function SectionCreation({ isLoading, setIsLoading, schoolSubscribed, subscreenRestricted, subscreenLocked, subscreenResponsibleRoleID, getSubscreenDepartmentProgress, getNewData, subscreen, departmentProgressLoading, departments, sortedDepartments, departmentID, changeDepartment, setDepartments, usersLoading, userInfo, schoolInfo, scheduleInfoLoading, scheduleInfo, scheduleStructureData, teachersLoading, teachers, setTeachers, coursesLoading, courses, setCourses, studentsLoading, students, setStudents, classrooms, sectionsLoading, sections, setSections, subsections, setSubsections, labels, setLabels, users, userIsAdmin, userIsSupervisor, supervisors, closeSupervisorsList, openSupervisorsList, addSupervisorToDepartment, removeSupervisorFromDepartment, subscreenSelect, submitDepartment, reviewing, toggleReviewDepartment, resetProgress, changesSavedMessage, setChangesSavedMessage, setRolePages, showNextButton, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo }) {
	return (
		<div className="school-setup-main-container">
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				) :
				(
					<>
					{(() => {
						switch(subscreen) {
							case 'section_review':
								return <SectionReview isLoading={isLoading} setIsLoading={setIsLoading} schoolSubscribed={schoolSubscribed} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreenResponsibleRoleID={subscreenResponsibleRoleID} getSubscreenDepartmentProgress={getSubscreenDepartmentProgress} getNewData={getNewData} subscreen={subscreen} departmentProgressLoading={departmentProgressLoading} departments={departments} sortedDepartments={sortedDepartments} departmentID={departmentID} changeDepartment={changeDepartment} setDepartments={setDepartments} userInfo={userInfo} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} students={students} setStudents={setStudents} classrooms={classrooms} sectionsLoading={sectionsLoading} sections={sections} setSections={setSections} subsections={subsections} setSubsections={setSubsections} labels={labels} users={users} userIsAdmin={userIsAdmin} userIsSupervisor={userIsSupervisor} supervisors={supervisors} closeSupervisorsList={closeSupervisorsList} openSupervisorsList={openSupervisorsList} addSupervisorToDepartment={addSupervisorToDepartment} removeSupervisorFromDepartment={removeSupervisorFromDepartment} subscreenSelect={subscreenSelect} submitDepartment={submitDepartment} reviewing={reviewing} toggleReviewDepartment={toggleReviewDepartment} resetProgress={resetProgress} changesSavedMessage={changesSavedMessage} setChangesSavedMessage={setChangesSavedMessage} setRolePages={setRolePages} showNextButton={showNextButton} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							case 'create_labels':
								return <CreateLabels isLoading={isLoading} setIsLoading={setIsLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} getNewData={getNewData} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} departments={departments} userInfo={userInfo} coursesLoading={coursesLoading} courses={courses} teachersLoading={teachersLoading} teachers={teachers} sectionsLoading={sectionsLoading} sections={sections} setSections={setSections} subsections={subsections} labels={labels} setLabels={setLabels} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							case 'student_labels':
								return <StudentLabels isLoading={isLoading} setIsLoading={setIsLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} getNewData={getNewData} userInfo={userInfo} schoolInfo={schoolInfo} studentsLoading={studentsLoading} students={students} setStudents={setStudents} coursesLoading={coursesLoading} courses={courses} sectionsLoading={sectionsLoading} sections={sections} labels={labels} handleMoveToNextScreen={handleMoveToNextScreen} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo}/>;
							default:
								return null;
						}
					})()}
					</>
				)
			}
		</div>
	);
}