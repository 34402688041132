import React, { useState , useRef, useEffect } from 'react';
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import Fuse from 'fuse.js';

import DepartmentProgressScreen from '../department_progress_screen';
import EditCourseData from '../../modals/edit_course';
import Button from '../../../Button';

import { capitalizeFirstLetters, useOutsideClick, getData } from '../../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUserCircle, faSquare, faCheckSquare, faHourglassHalf, faCheckCircle, faCheck, faInfoCircle, faTimes, faExclamationCircle, faSearch, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function CleanCourseOtherOptions({isLoading, subscreenRestricted, subscreenLocked, subscreen, subscreenResponsibleRoleID, supervisors, openSupervisorsList, closeSupervisorsList, addSupervisorToDepartment, removeSupervisorFromDepartment, users, userIsSupervisor, userIsAdmin, departments, setDepartments, departmentID, sortedDepartments, changeDepartment, userInfo, schoolInfo, schoolType, scheduleInfo, courses, setCourses, teachers, classrooms, students, setStudents, sections, labels, scheduleStructureData, addNewCourseOpen, setAddNewCourseOpen, subscreenSelect, submitDepartment, reviewing, toggleReviewDepartment, changeDepartmentResource, setRolePages, showNextButton, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo}) {
	const ref = useRef();
	
	const [addCourseOpen, setAddCourseOpen] = useState(false);
	const [courseResults, setCourseResults] = useState([...courses]);
	const [changesSavedMessage, setChangesSavedMessage] = useState(false);
	
	const [showExplanations, setShowExplanations] = useState({'is_lunch':false, 'allow_icr_students': false, 'is_separate_lab': false, 'is_swappable_for_lab':false, 'is_outside_course':false});
	
	/////////////////////////////
	///// UTILITY FUNCTIONS /////
	/////////////////////////////
	useOutsideClick(ref, () => {
		closeSupervisorsList();
	});

	const addSearch = (e) => {
		const search_value = e.target.value;
		const fuse = new Fuse(courses, {
			keys: ['name', 'course_code'],
			threshold: .6
		})
		
		const results = fuse.search(search_value);
		const course_results = results.map(result => result.item).splice(0,5);
		
		setCourseResults(course_results);
	}
	
	////////////////////////////
	///// UPDATE FUNCTIONS /////
	////////////////////////////
	const updateCourseOtherOptions = async (course_id, update_key, new_value) => {
		const course_index = courses.findIndex(course => course.course_id === course_id);
		
		if(course_index === -1) return false;		
		
		const course_info = courses[course_index];
		
		const is_lunch = (update_key === 'is_lunch') ? new_value : course_info.is_lunch;
		const allow_icr_students = (update_key === 'allow_icr_students') ? new_value : course_info.allow_icr_students;
		const is_separate_lab = (update_key === 'is_separate_lab') ? new_value : course_info.is_separate_lab;
		const is_swappable_for_lab = (update_key === 'is_swappable_for_lab') ? new_value : course_info.is_swappable_for_lab;
		const is_outside_course = (update_key === 'is_outside_course') ? new_value : course_info.is_outside_course;
		
		const data = {school_id: schoolInfo.school_id, department_id:departmentID, schedule_version_id:schoolInfo.current_schedule_version_id, course_id:course_id, is_lunch:is_lunch, allow_icr_students:allow_icr_students, is_separate_lab:is_separate_lab, is_swappable_for_lab:is_swappable_for_lab, is_outside_course:is_outside_course};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/clean-data/update-course-other-options';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			await API.post(apiName, url, myInit);
			
			///////////////////////
			///// UPDATE JSON /////
			///////////////////////
			const department_index = departments.findIndex(department => department.department_id === departmentID);

			if(userIsSupervisor) 
			{
				if(department_index !== -1)
				{
					departments[department_index].subscreens[subscreen].submitted = false;
					departments[department_index].subscreens[subscreen].confirmed = false;
				}

				// Reset overall progress to update the Progress screen
				getData('schoolProgress', '/get-school-progress', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
					const role_pages = user_progress_data.role_pages;
					
					setRolePages(role_pages);
				});
			}

			if(userIsAdmin && departments[department_index].subscreens[subscreen].confirmed) setChangesSavedMessage(true);
			
			course_info[update_key] = new_value;
			
			setCourses([...courses]);
			setDepartments([...departments]);
		} catch(e)
		{
			console.log(e.response);
		}
	}

	const updateCourse = (changeType, dataType, data_id, old_department_id, new_department_id) => {
		changeDepartmentResource(changeType, dataType, data_id, old_department_id, new_department_id, () => {
			if(changeType === 'add' && userIsSupervisor) 
			{
				const department_index = departments.findIndex(department => department.department_id === departmentID);

				if(department_index !== -1)
				{
					departments[department_index].subscreens[subscreen].submitted = false;
					departments[department_index].subscreens[subscreen].confirmed = false;
				}
				
				// Update cleaning screen progress (for check marks)
				departments[department_index].subscreens['course_availability'].submitted = false;
				departments[department_index].subscreens['course_availability'].confirmed = false;

				departments[department_index].subscreens['course_caps'].submitted = false;
				departments[department_index].subscreens['course_caps'].confirmed = false;

				departments[department_index].subscreens['course_options'].submitted = false;
				departments[department_index].subscreens['course_options'].confirmed = false;

				departments[department_index].subscreens['course_other_options'].submitted = false;
				departments[department_index].subscreens['course_other_options'].confirmed = false;

				departments[department_index].subscreens['course_teachers'].submitted = false;
				departments[department_index].subscreens['course_teachers'].confirmed = false;

				departments[department_index].subscreens['course_classrooms'].submitted = false;
				departments[department_index].subscreens['course_classrooms'].confirmed = false;

				setDepartments([...departments]);

				// Reset overall progress to update the Progress screen
				getData('schoolProgress', '/get-school-progress', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(user_progress_data => {
					const role_pages = user_progress_data.role_pages;
					
					setRolePages(role_pages);
				});
			}
		});
	}
	
	//////////////////////////////
	//// USE EFFECT FUNCTIONS ////
	//////////////////////////////
	useEffect(() => {
		if(!departmentID || !userInfo || !users || !departments) return;

		const department_index = departments.findIndex(department => department.department_id === departmentID);
		const department_info = (department_index !== -1) ? departments[department_index] : null;
		const department_supervisors = (department_info) ? department_info.supervisors : [];

		const available_users = users.filter(user => (user.role_id === '1' || user.role_id === '2') || department_supervisors.findIndex(supervisor => supervisor.user_id === user.user_id) !== -1);

		setScreenAllowsUniversalSidebar(true);
		setUniversalSidebarLoadingInfo({sidebar_name:"Course Other Options Notes", sidebar_type:'notes', subscreen:'course_other_options', department_id:departmentID, user_id:userInfo.user_id, available_users:available_users, help_link_path:'/app/hsms/documentation/clean_data/clean_department_data'});
	}, [departmentID, userInfo, users, departments]);
	
	const toggleExplanation = (explanation) => {
		setShowExplanations({...showExplanations, [explanation]: !showExplanations[explanation]});
	}
	
	const row_style = {gridTemplateColumns:`250px repeat(5, 1fr) 40px`, gridColumnGap: '5px'};
	
	const department_index = departments.findIndex(department => department.department_id === departmentID);
	const department_info = departments[department_index];
	const subscreen_info = (department_info) ? department_info.subscreens[subscreen] : null;
	
	const current_screen_submitted = (subscreen_info) ? subscreen_info.submitted : false;
	const current_screen_confirmed = (subscreen_info) ? subscreen_info.confirmed : false;
	const current_screen_unlocked= (subscreen_info) ? subscreen_info.unlocked : false;
	
	const show_current_screen = (current_screen_unlocked && (reviewing || userIsSupervisor || userIsAdmin));
	const screen_complete_for_user = ((current_screen_confirmed) || (userIsSupervisor && !userIsAdmin && current_screen_submitted));
	
	const department_courses = courses.filter(course => course.departments.includes(departmentID));
	
	return (
		<>
		<h1 className='progress-main-header'>Course Other Options</h1>
		<p className='progress-subheader'>Set some important flags for your courses.</p>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):(!departmentID) ? 
		(
			<DepartmentProgressScreen subscreenResponsibleRoleID={subscreenResponsibleRoleID} subscreen={subscreen} sortedDepartments={sortedDepartments} changeDepartment={changeDepartment} subscreenSelect={subscreenSelect} userInfo={userInfo} userIsAdmin={userIsAdmin} userIsSupervisor={userIsSupervisor} />
		):(!userIsSupervisor) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to change this department</div>	
			</div>
		):(departmentID) ?
			(
				<>
				<div id='clean-data-content'>
					<div>
						<FontAwesomeIcon icon={faChevronLeft} className='progress-back-to-departments-btn' onClick={()=>changeDepartment(null)}/>
					</div>
					<div className='clean-data-content-inner-content'>
						<div className='clean-data-submit-btn-container'>
							{!isLoading &&
								<>
								{(reviewing && screen_complete_for_user) &&
									<>
									{changesSavedMessage && 
										<div className='all-changes-saved-message' style={{margin:'45px 0px 0px 0px'}}>
											<FontAwesomeIcon className='green-text' icon={faCheckCircle}/>
											<div>
												<h5>Changes Saved</h5>
												<div className='small-text'>All your changes have been saved</div>
											</div>
											<FontAwesomeIcon className='x-remove' icon={faTimes} onClick={() => setChangesSavedMessage(false)}/>
										</div>
									}
									</>
								}
								{(!screen_complete_for_user && show_current_screen) &&
									<Button key={departmentID} classes={['mark-as-complete-btn']} selected={false} text={`${!current_screen_submitted ? 'Mark As Complete' : 'Confirm Department'}`} icon={faCheck} iconPosition='left' onClickFunction={() => submitDepartment(departmentID)} />
								}
								</>
							}
						</div>
						<div className='clean-data-content-header-container'>
							<h1 className='clean-data-content-header'>{department_info && department_info.department} Department</h1>
							{current_screen_confirmed ?
								(
									<div className='clean-data-status green-text'><div>Complete</div><FontAwesomeIcon icon={faCheckCircle}/></div>
								): (current_screen_submitted) ?
								(
									<div className='clean-data-status bright-yellow-text'><div>Pending Confirmation</div><FontAwesomeIcon icon={faCheckCircle}/></div>
								): (null)
							}
						</div>
						<div className='department-supervisor-parent-container'>
							<>
							{(department_info && department_info.supervisors.length === 0) &&
								<>
								<div className='department-supervisor-inner-container'>
									<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
									<div className='department-supervisor-name'>No Supervisor Assigned</div>
								</div>
								</>
							}
							{department_info && department_info.supervisors.map((supervisor, index) => {
								return (
									<div className='department-supervisor-outer-container' key={index}>
										<div className='department-supervisor-inner-container'>
											{supervisor.img_url &&
												<img className='department-supervisor-img' src={require(`../../../../images/users/${supervisor.img_url}`)} alt='user' />
											}
											{!supervisor.img_url &&
												<FontAwesomeIcon className='department-supervisor-user-icon gray-text' icon={faUserCircle}/>
											}
											<div className='department-supervisor-name'>{supervisor.last_name}, {supervisor.first_name}</div>
											{userIsAdmin &&
												<FontAwesomeIcon className='gray-text-hover' icon={faTimes} onClick={() => removeSupervisorFromDepartment(supervisor.user_id)}/>
											}
										</div>
									</div>
								)
							})}
							{userIsAdmin &&
								<>
								<div className={`clean-data-add-supervisor-link small-text click-restricted ${(department_info && department_info.supervisors.length === 0) ? 'blue-link' : 'gray-to-blue-link'}`} onClick={() => openSupervisorsList(departmentID)}>Add a Supervisor</div>
								<div className='supervisor-options-container'>
									{(department_info && department_info.supervisors_open) &&
										<div className='data-options-container' ref={ref}>
											{supervisors.map((supervisor_info, index) => {
												
												return (
													<div className='data-options-option assign-resourses-supervisor-option' key={index} onClick={() => addSupervisorToDepartment(supervisor_info)}>
														{supervisor_info.img_url &&
															<img className='department-supervisor-img' src={require(`../../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
														}
														{!supervisor_info.img_url &&
															<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
														}
														<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
													</div>
												)	
											})}
											<Link className='data-options-option blue-text' to={{pathname:`/app/${schoolType}/settings/users`, redirect:`/app/${schoolType}/progress/clean_data/course_other_options`}} style={{borderTop:'1px solid #efefef'}} onClick={() => closeSupervisorsList(departmentID)}>Add new Supervisor</Link>
										</div>
									}
								</div>
								</>
							}
							</>
						</div>
						<div className='clean-data-container'>
							{isLoading ?
								(
									<div className="center-text">
										<img className='clean-data-loader' src={require(`../../../../images/balls.gif`)} alt='loading' />
									</div>
								):
								(
									<>
									{(!current_screen_unlocked) ?
										(
											<div className='general-screen-message'>
												<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
												<h1>Locked</h1>
												<div>This department has previous steps which have not been completed. Please review previous steps and make sure they are completed and saved. This page should open automatically once all previous steps have been finished.</div>
											</div>
										):(userIsAdmin && current_screen_unlocked && !show_current_screen && !current_screen_submitted && !userIsSupervisor) ?
										(
											<div className='general-screen-message'>
												<FontAwesomeIcon className='turquoise-text' style={{fontSize:'60px'}} icon={faHourglassHalf}/>
												<h1>Pending...</h1>
												<div>Waiting on confirmation from department supervisor</div>
												<p className='blue-link' onClick={toggleReviewDepartment}>Review course options yourself</p>	
											</div>
										):(show_current_screen) ?
										(
											<>
											{department_courses.length === 0 ?
												(
													<div className='clean-data-no-data-container'>
														<FontAwesomeIcon className='clean-data-no-data-icon dark-blue-text' icon={faExclamationCircle}/>
														<div>No courses found</div>
														<p>Please confirm that this is correct by submitting the screen</p>
													</div>
												):
												(
													<>
													<div className='fixed-heading-on-scroll'>
														<div className='clean-data-header-row' style={{gridTemplateColumns:'250px 1fr 40px', margin:'0px 0px 5px 0px'}}>
															<div></div>
															<div className='dark-blue' style={{textAlign:'center', padding:'5px', borderRadius:'5px'}}>Course Options</div>
														</div>
														<div className='clean-data-header-row' style={row_style}>
															<div></div>
															<div className='clean-data-col'>
																<div className="clean-data-heading small-text clean-data-other-option-heading" onClick={() => toggleExplanation('is_lunch')}>Is Lunch? <FontAwesomeIcon className='clean-data-more-info-icon' icon={faInfoCircle}/></div>
																{showExplanations.is_lunch &&
																	<div className="explanation-popup clean-data-popup">
																		<FontAwesomeIcon className='close-explanation' icon={faTimes} onClick={() => toggleExplanation('is_lunch')}/>
																		<h3>Is Lunch?</h3>
																		<div className='clean-data-popup-separator'></div>
																		<p>Check this box if this course is one of your lunch courses.</p>
																	</div>
																}
															</div>
															<div className='clean-data-col'>
																<div className="clean-data-heading small-text clean-data-other-option-heading" onClick={() => toggleExplanation('allow_icr_students')}>{scheduleInfo.separate_ICR_codes === '1' ? 'Is Inclusion?' : 'Inclusion Sections Needed?'} <FontAwesomeIcon className='clean-data-more-info-icon' icon={faInfoCircle}/></div>
																{showExplanations.allow_icr_students &&
																	<div className="explanation-popup clean-data-popup">
																		<FontAwesomeIcon className='close-explanation' icon={faTimes} onClick={() => toggleExplanation('allow_icr_students')}/>
																		<h3>{scheduleInfo.separate_ICR_codes === '1' ? 'Is Inclusion?' : 'Needs Inclusion Sections?'}</h3>
																		<div className='clean-data-popup-separator'></div>
																		<p>{scheduleInfo.separate_ICR_codes === '1' ? 'Check this box if this course is an inclusion course (i.e. a special ed course that will be combined with a general ed section)' : 'Check this box if some of the sections for this course will need to be Inclusion sections.'}</p>
																	</div>
																}
															</div>
															<div className='clean-data-col'>
																<div className="clean-data-heading small-text clean-data-other-option-heading" onClick={() => toggleExplanation('is_separate_lab')}>Needs Lab? <FontAwesomeIcon className='clean-data-more-info-icon' icon={faInfoCircle}/></div>
																{showExplanations.is_separate_lab &&
																	<div className="explanation-popup clean-data-popup">
																		<FontAwesomeIcon className='close-explanation' icon={faTimes} onClick={() => toggleExplanation('is_separate_lab')}/>
																		<h3>Needs Lab?</h3>
																		<div className='clean-data-popup-separator'></div>
																		<p>Check this box if this course has an extra lab period during the week (e.g. AP Chem, Physics, Biology, etc.)</p>
																	</div>
																}
															</div>
															<div className='clean-data-col'>
																<div className="clean-data-heading small-text clean-data-other-option-heading" onClick={() => toggleExplanation('is_swappable_for_lab')}>Swappable for Lab? <FontAwesomeIcon className='clean-data-more-info-icon' icon={faInfoCircle}/></div>
																{showExplanations.is_swappable_for_lab && 
																	<div className="explanation-popup clean-data-popup">
																		<FontAwesomeIcon className='close-explanation' icon={faTimes} onClick={() => toggleExplanation('is_swappable_for_lab')}/>
																		<h3>Swappable for Lab?</h3>
																		<div className='clean-data-popup-separator'></div>
																		<p>Check this box if a student is allowed to miss this course once a week to attend an extra lab section (e.g. Phys Ed or other electives)</p>
																	</div>
																}
															</div>
															<div className='clean-data-col'>
																<div className="clean-data-heading small-text clean-data-other-option-heading" onClick={() => toggleExplanation('is_outside_course')}>Not in Schedule? <FontAwesomeIcon className='clean-data-more-info-icon' icon={faInfoCircle}/></div>
																{showExplanations.is_outside_course && 
																	<div className="explanation-popup clean-data-popup">
																		<FontAwesomeIcon className='close-explanation' icon={faTimes} onClick={() => toggleExplanation('is_outside_course')}/>
																		<h3>Not in Schedule?</h3>
																		<div className='clean-data-popup-separator'></div>
																		<p>Check this box if this course is not held in a physical classroom (e.g. online courses, early dismissal, senior privilege, etc.)</p>
																	</div>
																}
															</div>
															<div></div>
														</div>
													</div>
													{department_courses.map((course_info, i) => {
														const course_id = course_info.course_id;
														const is_lunch = (course_info.is_lunch === '1') ? true : false;
														const allow_icr_students = (course_info.allow_icr_students === '1') ? true : false;
														const is_separate_lab = (course_info.is_separate_lab === '1') ? true: false;
														const is_swappable_for_lab = (course_info.is_swappable_for_lab === '1') ? true : false;
														const is_outside_course = (course_info.is_outside_course === '1') ? true : false;
														
														return (
															<div className='clean-data-row' key={i} style={row_style} data-courseid={course_id}>
																<div className='clean-data-col'>{capitalizeFirstLetters(course_info.name)} ({course_info.course_code})</div>
																{!is_lunch ?
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-unchecked' icon={faSquare} onClick={() => updateCourseOtherOptions(course_id, 'is_lunch', '1')}/>
																	) :
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateCourseOtherOptions(course_id, 'is_lunch', '0')}/>
																	)
																}
																{!allow_icr_students ?
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-unchecked' icon={faSquare} onClick={() => updateCourseOtherOptions(course_id, 'allow_icr_students', '1')}/>
																	) :
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateCourseOtherOptions(course_id, 'allow_icr_students', '0')}/>
																	)
																}
																{!is_separate_lab ?
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-unchecked' icon={faSquare} onClick={() => updateCourseOtherOptions(course_id, 'is_separate_lab', '1')}/>
																	) : 
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateCourseOtherOptions(course_id, 'is_separate_lab', '0')}/>
																	)
																}
																{!is_swappable_for_lab ?
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-unchecked' icon={faSquare} onClick={() => updateCourseOtherOptions(course_id, 'is_swappable_for_lab', '1')}/>
																	) : 
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateCourseOtherOptions(course_id, 'is_swappable_for_lab', '0')}/>
																	)
																}
																{!is_outside_course ?
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-unchecked' icon={faSquare} onClick={() => updateCourseOtherOptions(course_id, 'is_outside_course', '1')}/>
																	) : 
																	(
																		<FontAwesomeIcon className='period-availability-checkmark fas-checkbox-checked' icon={faCheckSquare} onClick={() => updateCourseOtherOptions(course_id, 'is_outside_course', '0')}/>
																	)
																}
																<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => updateCourse('remove', 'course', course_id, departmentID, null)} style={{justifySelf:'center'}}/>
															</div>
														)
													})}
													</>
												)
											}
											<div className='clean-data-add-new-data blue-link assign-resources-supervisor-confirm-add-new' onClick={() => setAddCourseOpen(!addCourseOpen)}>Add A Course</div>
											{addCourseOpen &&
												<div className='search-add-row-container'>
													<div className='search-add-search-container'>
														<div className='search-add-adding-input-container'>
															<input id='teacher-search-input' className='search-add-adding-input' onChange={(e) => addSearch(e)} placeholder='Search for course'/>
															<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
														</div>
														<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => setAddCourseOpen(!addCourseOpen)}/>
													</div>
													{courseResults.length !== 0 &&
														<div className='search-add-search-results'>
															<div className='search-add-did-you-mean'>Did you mean:</div>
															{courseResults.map((course, i) => {
																return (
																	<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr auto'}}>
																		<div>{capitalizeFirstLetters(course.name)} ({course.course_code})</div>
																		<div>
																			<div className='search-add-add-btn' onClick={() => updateCourse('add', 'course', course.course_id, null, departmentID)}>Add</div>
																		</div>
																	</div>
																)
															})}
															<div className='clean-data-add-new-data-text dark-blue-text'>If you don&rsquo;t see the course you are looking for, you should first add them <span className='blue-link' onClick={() => setAddNewCourseOpen(true)}>Add A New Course</span></div>
														</div>
													}
												</div>
											}
											{addNewCourseOpen &&
												<EditCourseData schoolInfo={schoolInfo} dataID={null} toggleEditData={() => setAddNewCourseOpen(false)} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} courses={courses} students={students} classrooms={classrooms} teachers={teachers} departments={departments} setDatabaseData={setCourses} setStudents={setStudents} sections={sections} labels={labels} defaultDepartmentID={departmentID}/>
											}
											</>
										):null
									}
									</>
								)
							}
						</div>
					</div>
				</div>
				</>
			):null}
			{showNextButton &&
				<div className='fixed-bottom-save-container'>
					<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Go to Next Step: Course Teachers' onClickFunction={handleMoveToNextScreen} />
				</div>
			}
		</>
	);
}