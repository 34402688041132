import React from 'react';

import ProgressHSMS from './hsms/ProgressHSMS';
import ProgressElementary from './elementary/ProgressElementary';

export default function Progress({ match, isAuth, authComplete, setShowSwitchingYearModal, schoolSubscribed, rolePages, setRolePages, schoolProgressLoaded, schoolInfo, schoolInfoLoading, setSchoolInfoLoading, setSchoolInfo, schoolType, scheduleInfo, setScheduleInfo, scheduleInfoLoading, scheduleStructureData, setScheduleStructureData, scheduleStructureDataLoading, periods, setPeriods, periodsLoading, users, userInfo, usersLoading, appSubscreens, setNotifications, loadScreen, setLoadScreen, departments, setDepartments, teachersLoading, setTeachersLoading, teachers, setTeachers, coursesLoading, setCoursesLoading, courses, setCourses, classroomsLoading, setClassroomsLoading, classrooms, setClassrooms, studentsLoading, setStudentsLoading, students, setStudents, studentMatchPercentages, sectionsLoading, blocksLoading, blocks, setBlocks, pulloutGroups, setPulloutGroups, sections, setSections, subsections, setSubsections, lunchInfo, labels, setLabels, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo, handlePullNewData }) {
	return (
		<>
			{(() => {
				switch(match.params.school_type) {
					case 'hsms':
						return <ProgressHSMS match={match} isAuth={isAuth} authComplete={authComplete} setShowSwitchingYearModal={setShowSwitchingYearModal} schoolSubscribed={schoolSubscribed} rolePages={rolePages} setRolePages={setRolePages} schoolProgressLoaded={schoolProgressLoaded} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} setSchoolInfoLoading={setSchoolInfoLoading} setSchoolInfo={setSchoolInfo} schoolType={schoolType} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} scheduleInfoLoading={scheduleInfoLoading} scheduleStructureData={scheduleStructureData} setScheduleStructureData={setScheduleStructureData} scheduleStructureDataLoading={scheduleStructureDataLoading} users={users} userInfo={userInfo} usersLoading={usersLoading} appSubscreens={appSubscreens} setNotifications={setNotifications} loadScreen={loadScreen} setLoadScreen={setLoadScreen} departments={departments} setDepartments={setDepartments} teachersLoading={teachersLoading} setTeachersLoading={setTeachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} setCoursesLoading={setCoursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} setClassroomsLoading={setClassroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} studentsLoading={studentsLoading} setStudentsLoading={setStudentsLoading} students={students} setStudents={setStudents} studentMatchPercentages={studentMatchPercentages} sectionsLoading={sectionsLoading} sections={sections} setSections={setSections} subsections={subsections} setSubsections={setSubsections} lunchInfo={lunchInfo} labels={labels} setLabels={setLabels} setScreenAllowsUniversalSidebar={setScreenAllowsUniversalSidebar} setUniversalSidebarLoadingInfo={setUniversalSidebarLoadingInfo} handlePullNewData={handlePullNewData} />;
					case 'elementary':
						return <ProgressElementary match={match} isAuth={isAuth} authComplete={authComplete} setShowSwitchingYearModal={setShowSwitchingYearModal} schoolSubscribed={schoolSubscribed} rolePages={rolePages} setRolePages={setRolePages} schoolProgressLoaded={schoolProgressLoaded} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} setSchoolInfoLoading={setSchoolInfoLoading} setSchoolInfo={setSchoolInfo} schoolType={schoolType} scheduleInfoLoading={scheduleInfoLoading} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} periods={periods} setPeriods={setPeriods} periodsLoading={periodsLoading}  users={users} userInfo={userInfo} usersLoading={usersLoading} appSubscreens={appSubscreens} setNotifications={setNotifications} loadScreen={loadScreen} setLoadScreen={setLoadScreen} teachersLoading={teachersLoading} setTeachersLoading={setTeachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} setCoursesLoading={setCoursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} setClassroomsLoading={setClassroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} studentsLoading={studentsLoading} setStudentsLoading={setStudentsLoading} students={students} setStudents={setStudents} blocksLoading={blocksLoading} blocks={blocks} setBlocks={setBlocks} pulloutGroups={pulloutGroups} setPulloutGroups={setPulloutGroups}/>;
					default:
						return null;
				}
			})()}
		</>
	);
}