import React, { useState , useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import { useOutsideClick, convertObjectToArray } from '../js/Helpers';

export default function ListFilter({ handleOnClick, passedData, passedDataFilterKey, filterOptions, filterKey, filterDisplayKey }) {
	const ref = useRef();
	
	const [visible, setVisible] = useState(false);
	const [filters, setFilters] = useState([]);
	
	const filterOptionsArray = (Array.isArray(filterOptions)) ? filterOptions : convertObjectToArray(filterOptions);
	
	const toggleVisible = (e) => {
		setVisible(!visible);
	}
	
	useOutsideClick(ref, () => {
		setVisible(false);
	});
	
	const updateFilters = (update_type, passed_filter) => {
		let new_filters = filters;
		
		if(update_type === 'add')
		{
			if(!new_filters.includes(passed_filter)) new_filters.push(passed_filter);
		}
		else if(update_type === 'remove')
		{
			new_filters = new_filters.filter(filter => filter !== passed_filter);
		}
		
		setFilters([...new_filters]);
	}
	
	useEffect(() => {
		if(filters.length !== 0)
		{
			const passedDataFiltered = passedData.filter((data_row) => {
				let include = 0;
				
				const is_data_filter_key_array = Array.isArray(data_row[passedDataFilterKey]);
				
				filters.forEach(filter => {
					
					if(is_data_filter_key_array)
					{
						if(data_row[passedDataFilterKey].includes(filter)) include = 1;
					}
					else
					{
						if(data_row[passedDataFilterKey] === filter) include = 1;
					}
				});
				
				return include;
			});
			
			handleOnClick(passedDataFiltered);
			setVisible(true);
		}
		else
		{
			handleOnClick(passedData);
		}
	}, [filters]);
	
	//console.log(filterOptionsArray);
	
	return (
		<div className="list-filter-container" ref={ref}>
			<FontAwesomeIcon className='list-filter-icon' icon={faFilter} onClick={toggleVisible} />
			{visible &&
				<div className='list-filter-options-container click-restricted'>
					{filterOptionsArray.map((filter_data, i) => {
						const possible_filter = filter_data[filterKey];
						const is_filtered = filters.includes(possible_filter);
						const filter_display = filter_data[filterDisplayKey];
						
						return (
							<div className='parent-div list-filter-option' key={i}>
								<div className='list-filter-option-name'>{filter_display}</div>
								{is_filtered &&
									<FontAwesomeIcon className='fas-checkbox-checked click-restricted' icon={faCheckSquare} onClick={() => updateFilters('remove', possible_filter)}/>
								}
								{!is_filtered &&
									<FontAwesomeIcon className='fas-checkbox-unchecked click-restricted' icon={faSquare} onClick={() => updateFilters('add', possible_filter)}/>
								}
							</div>
						)
					})}
				</div>
			}
		</div>
	);
}