import React from 'react';

import ScheduleType from './schedule_type';
import LunchStructure from './lunch_structure';
import ScheduleStructure from './schedule_structure';

import '../../../css/hsms/structure_setup.css';

export default function StructureSetup({ isLoading, subscreenRestricted, subscreenLocked, subscreen, schoolInfo, scheduleInfo, scheduleInfoLoading, setScheduleInfo, setScheduleStructureData, handleMoveToNextScreen }) {
	return (
		<div className='school-setup-main-container'>
			{isLoading ?
				(
					<div className='center-text'>
						<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
					</div>
				) :
				(
					<>
					{(() => {
						switch(subscreen) {
							case 'schedule_type':
								return <ScheduleType subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleInfoLoading={scheduleInfoLoading} setScheduleInfo={setScheduleInfo} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							case 'lunch_structure':
								return <LunchStructure subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleInfoLoading={scheduleInfoLoading} setScheduleInfo={setScheduleInfo} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							case 'schedule_structure':
								return <ScheduleStructure subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} setScheduleStructureData={setScheduleStructureData} handleMoveToNextScreen={handleMoveToNextScreen}/>;
							default:
								return null;
						}
					})()}
					</>
				)
			}
		</div>
	);
}