import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export default function DepartmentProgressScreen({ subscreenResponsibleRoleID, subscreen, sortedDepartments, changeDepartment, subscreenSelect, userInfo, userIsAdmin, userIsSupervisor }) {
	return (
		<div className='progress-choose-department-screen'>
			<div className='progress-choose-department-heading'>Choose a department to work on:</div>
			{(subscreenResponsibleRoleID === '3') &&
				<div className='progress-choose-department-container'>
					{sortedDepartments.map((department_info, j) => {
						const department_id = department_info.department_id;
						const department_name = department_info.department;

						const department_submitted = department_info.subscreens[subscreen].submitted;
						const department_confirmed = department_info.subscreens[subscreen].confirmed;
						const department_unlocked = department_info.subscreens[subscreen].unlocked;

						const user_is_supervisor = (userIsAdmin || department_info.supervisors.findIndex(supervisor => supervisor.user_id === userInfo.user_id) !== -1);

						const text_color = (!department_unlocked) ? 'gray' : ((department_confirmed) ? 'green' : ((department_submitted) ? 'yellow' : 'gray'));
						const button_color = (!department_unlocked) ? 'gray' : ((department_confirmed) ? 'green' : ((department_submitted) ? 'yellow' : 'dark-blue-gray'));
						const status = (!department_unlocked) ? 'Locked' : ((department_confirmed) ? 'Complete' : ((department_submitted) ? 'Awaiting Confirmation' : 'Incomplete'));
						const status_icon = (!department_unlocked) ? faLock : faCheckCircle;
						const button_text = (!department_unlocked) ? '' : ((department_confirmed) ? 'Review' : ((department_submitted) ? 'Continue' : 'Start'));

						const last_unfinished_department_subscreen = (department_unlocked) ? null : Object.keys(department_info.subscreens).reduce((results, subscreen) => {
							const subscreen_progress_info = department_info.subscreens[subscreen];
							const subscreen_screen = (subscreen_progress_info) ? subscreen_progress_info.screen : null;
							const subscreen_order = (subscreen_progress_info) ? parseInt(subscreen_progress_info.subscreen_order) : 999;
							const subscreen_submitted = (subscreen_progress_info) ? subscreen_progress_info.submitted : false;
							const subscreen_confirmed = (subscreen_progress_info) ? subscreen_progress_info.confirmed : false;
							
							const screen_complete_for_user = ((subscreen_confirmed) || (userIsSupervisor && subscreen_submitted));

							if(!screen_complete_for_user && subscreen_order < results.logged_min)
							{
								results.last_screen = subscreen_screen;
								results.last_subscreen = subscreen;
								results.logged_min = subscreen_order;
							}

							return results;
						}, {last_screen: null, last_subscreen: null, logged_min:999});


						return (
							<div className={`progress-choose-department-row  ${!user_is_supervisor ? 'opacity-50':''}`} key={j}>
								<div className='progress-choose-department-name'>{department_name}</div>
								<div className={`progress-choose-department-status ${text_color}-text`}>
									<div>{status}</div> 
									<FontAwesomeIcon className='progress-choose-department-status-icon' icon={status_icon}/>
								</div>
								<div className='small-text text-align-right'>
									{department_unlocked ?
										(
											<div className={`progress-choose-department-btn btn ${button_color}-btn align-right`} onClick={() => changeDepartment(department_id)}>{button_text}</div>
										):
										(
											<div>
												<div>This screen is locked because there is a previously unfinished screen.</div>
												<div style={{marginTop:'3px'}} className='blue-link full-width' onClick={()=>subscreenSelect(last_unfinished_department_subscreen.last_subscreen, last_unfinished_department_subscreen.last_screen, department_id)}>Go to Last Unfinished Step</div>
											</div>
										)
									}
								</div>
							</div>
						)
					})}
				</div>
			}
		</div>
	)
}