import React, { useState , useEffect, useRef } from 'react';
import swal from '@sweetalert/with-react';

import { filterArrayOfObjects, deleteData } from '../../../js/Helpers';

import SortToggle from '../../SortToggle';
import UploadModal from '../../UploadModal';
import EditTeacherData from '../modals/edit_teacher';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import '../../../css/uploads.css';

export default function DatabaseDataTeachers({ schoolInfo, periods, databaseData, setDatabaseData, scheduleInfo, students, setStudents, courses, classrooms, dataIsLoading, blocks, handlePullNewData}) {
	const [isLoading, setIsLoading] = useState(true);
	const [handleChangeComplete, setHandleChangeComplete] = useState(false);

	const [filteredResults, setFilteredResults] = useState([]);
	const [displayResults, setDisplayResults] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	
	const [showEditDataModal, setShowEditDataModal] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [currentDataID, setCurrentDataID] = useState(null);
	
	const [dontShowDeletePopup, setDontShowDeletePopup] = useState(false);

	const filteredResultsRef = useRef(filteredResults);
	filteredResultsRef.current = filteredResults;
	
	let typingTimeout = null;
	
	const handleIsSearching = (e) => {
		if(e.keyCode !== 8)
		{
			setIsLoading(false);
			setIsSearching(true);
		}
		else
		{
			setIsLoading(true);
			setIsSearching(false);
		}
	}
	
	const handleChange = () => {
		if(filteredResultsRef.current.length === 0 && dataIsLoading) return;
		if(typingTimeout) clearTimeout(typingTimeout);
		
		const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
		
		typingTimeout = setTimeout(() => {
			if(search_value.length > 1)
			{
				const filtered_results = filterArrayOfObjects(filteredResultsRef.current, search_value, ['name', 'first_name']);
				setDisplayResults([...filtered_results]);
			}
			else
			{
				setDisplayResults([...filteredResultsRef.current]);
			}
			
			setIsLoading(false);
			setIsSearching(false);
			setHandleChangeComplete(true);
		}, 200);
	}
	
	const handleSortClick = (sortedData) => {
		setDisplayResults([...sortedData]);
	}
	
	const toggleEditData = (dataID) => {
		setCurrentDataID(dataID);
		setShowEditDataModal(!showEditDataModal);
	}
	
	const toggleDontShow = () => {
		setDontShowDeletePopup(!dontShowDeletePopup);
	}
	
	const handeDeleteData = async (data_id) => {
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_type:'teacher', data_ids:[data_id], database:'elementary'};
		let delete_data_bool = true;
		
		const data_index = databaseData.findIndex(teacher => teacher.teacher_id === data_id);
		const filter_results_index = filteredResults.findIndex(teacher => teacher.teacher_id === data_id);
		const data_info = databaseData[data_index];
		
		if(!dontShowDeletePopup)
		{
			const options =  {
				title: "Are you sure?",
				icon: "warning",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'red-btn'
					},
				},
				content: (
					<div>
						<div>
							<p>Do you really want to delete {data_info.first_name} {data_info.name}?</p>
							<p className='red-text'>This will <strong>permanently</strong> remove them from the database.</p>
						</div>
						<div className='sweet-alert-dont-show-message' onClick={() => toggleDontShow('remove')}>
							<label className="checkmark_container">Please don&rsquo;t show me this message again
								<input type="checkbox" />
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
				)
			}
		
			delete_data_bool = await swal(options);
		}
		
		if(delete_data_bool)
		{
			databaseData.splice(data_index, 1);
			filteredResults.splice(filter_results_index, 1);

			setDatabaseData([...databaseData]);
			setFilteredResults([...filteredResults]);
			
			deleteData(data);
		}
	}
	
	useEffect(() => {
		if(!showEditDataModal) 
		{
			setCurrentDataID(null);

			const search_value = document.getElementsByClassName("school-data-search-bar")[0].value;
			if(search_value.length > 0) handleChange();
		}
	}, [showEditDataModal]);
	
	useEffect(() => {
		handleChange();
	}, [filteredResults]);

	useEffect(() => {
		if(!handleChangeComplete) setFilteredResults([...databaseData]);
	}, [databaseData, dataIsLoading]);

	const row_style = {gridTemplateColumns:'1fr 2fr 2fr 70px'};
	
	//console.log(displayResults);
	//console.log(databaseData);
	//console.log({dataIsLoading, isLoading, handleChangeComplete, filteredResults, databaseData, displayResults});
	
	return (
		<>
		{showEditDataModal &&
			<EditTeacherData schoolInfo={schoolInfo} dataID={currentDataID} toggleEditData={toggleEditData} scheduleInfo={scheduleInfo} periods={periods} teachers={databaseData} courses={courses} classrooms={classrooms} students={students} setStudents={setStudents} setDatabaseData={setDatabaseData}filteredResults={filteredResults} setFilteredResults={setFilteredResults} blocks={blocks}/>
		}
		{showUploadModal &&
			<UploadModal schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} uploadTitle='Teachers' uploadAPIName='teacherdata' closeModal={() => setShowUploadModal(false)} handlePullNewData={handlePullNewData}/>
		}
		<div className='school-data-content-screen'>
			<h1 className='school-data-main-heading capitalize'>Teachers</h1>
			<div className='school-data-top-bar'>
				<div className='school-data-search-bar-container'>
					<input className='school-data-search-bar' placeholder="Search for teachers..." onChange={handleChange} onKeyDown={(e) => handleIsSearching(e)} />
					<FontAwesomeIcon className='school-data-search-bar-icon' icon={faSearch}/>
				</div>
				<div className='btn btn-icon-left turquoise-btn' onClick={() => setShowUploadModal(true)}>
					<FontAwesomeIcon icon={faUpload} />
					<div>Upload</div>
				</div>
			</div>
			<div className='school-data-database-display'>
				<div className='school-data-database-row school-data-database-display-header fixed-heading-on-scroll' style={row_style}>
					<div className='school-data-database-header-col'>Code</div>
					<div className='school-data-database-header-col'>Last Name <SortToggle handleOnClick={handleSortClick} sortKey='name' passedData={displayResults}/></div>
					<div className='school-data-database-header-col'>First Name <SortToggle handleOnClick={handleSortClick} sortKey='first_name' passedData={displayResults}/></div>
					<div className='school-data-database-add-new-container' onClick={() => toggleEditData(null)}>
						<div className='school-data-database-add-new-btn'>
							<FontAwesomeIcon className='white-text' icon={faPlus}/>
						</div>
					</div>
				</div>
				{(!handleChangeComplete && isLoading) ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
					</div>
				): isSearching ?
				(
					<div className='school-data-database-message-container'>
						<img src={require('../../../images/searching.gif')} alt='searching...' style={{height:'80px'}}/>
					</div>
				):
				(
					<>
					{(displayResults.length === 0) &&
						<div className='school-data-database-message-container'>
							<h4>Sorry, no results were found!</h4>
						</div>
					}
					{displayResults.map((data_info, index) => {
						return (
							<div className='school-data-database-row' style={row_style} key={data_info.teacher_id} data-teacherid={data_info.teacher_id}>
								<div>{data_info.teacher_code ? data_info.teacher_code : '--'}</div>
								<div>{data_info.name}</div>
								<div>{data_info.first_name}</div>
								<div className='school-data-database-row-btn-container'>
									<FontAwesomeIcon className='gray-to-dark-blue-link' icon={faEdit} onClick={() => toggleEditData(data_info.teacher_id)}/>
									<FontAwesomeIcon className='gray-to-red-link' icon={faTrashAlt} onClick={() => handeDeleteData(data_info.teacher_id)}/>
								</div>
							</div>
						)
					})}
					</>
				)}
			</div>
		</div>
		</>
	);
}