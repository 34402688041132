import React from 'react';

import DashboardHSMS from './hsms/DashboardHSMS';
import '../css/dashboard.css';


export default function Dashboard({ match, userInfo, schoolInfo, scheduleInfo, scheduleStructureData, goToScreen, rolePages, lastScreen, coursesLoading, courses, studentsLoading, students, studentMatchPercentages, classroomsLoading, classrooms, teachersLoading, teachers, sections }) {
	return (
		<>
			{(() => {
				switch(match.params.school_type) {
					case 'hsms':
						return <DashboardHSMS userInfo={userInfo} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} goToScreen={goToScreen} rolePages={rolePages} lastScreen={lastScreen} coursesLoading={coursesLoading} courses={courses} studentsLoading={studentsLoading} students={students} studentMatchPercentages={studentMatchPercentages} classroomsLoading={classroomsLoading} classrooms={classrooms} teachersLoading={teachersLoading} teachers={teachers} sections={sections}/>;
					case 'elementary':
						return <DashboardHSMS userInfo={userInfo} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} goToScreen={goToScreen} rolePages={rolePages} lastScreen={lastScreen} coursesLoading={coursesLoading} courses={courses} studentsLoading={studentsLoading} students={students} classroomsLoading={classroomsLoading} classrooms={classrooms} teachersLoading={teachersLoading} teachers={teachers} sections={sections}/>;
					default:
						return null;
				}
			})()}
		</>
	);
}