import React, { useState , useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faEllipsisV, faEdit, faCheck, faTimes, faLock, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

import { saveScheduleStructure } from '../../../js/SaveFunctions';
import { JSONexists, getJSONFromStorage, getData, isEmpty, sortObject, reverseSortObject, areArraysOrObjectsEqual, createNewKeyForObject, cloneObj, recalculateMatchPercent, calculateConflicts } from '../../../js/Helpers';

import Button from '../../Button';

import '../../../css/hsms/structure_setup.css';
import '../../../css/hsms/schedule_structure.css';

export default function ScheduleStructure({subscreenRestricted, subscreenLocked, schoolInfo, setScheduleStructureData, handleMoveToNextScreen}) {
	const [isLoading, setIsLoading] = useState(true);
	const [scheduleStructureLoading, setScheduleStructureLoading] = useState(true);
	
	const [scheduleType, setScheduleType] = useState(null);
	const [numDaysInCycle, setNumDaysInCycle] = useState(null);
	const [numAcademicCoursesPerDay, setNumAcademicCoursesPerDay] = useState(null);
	
	const [schedulePeriodsBeforeLunch, setSchedulePeriodsBeforeLunch] = useState({});
	const [schedulePeriodsDuringLunch, setSchedulePeriodsDuringLunch] = useState({});
	const [schedulePeriodsAfterLunch, setSchedulePeriodsAfterLunch] = useState({});
	
	const [hideInstructions, setHideInstructions] = useState(true);
	const [rotationOptionsStart, setRotationOptionsStart] = useState(1);
	const [academicOptionsStart, setAcademicOptionsStart] = useState(1);
	
	const [scheduleInfoLoading, setScheduleInfoLoading] = useState(true);
	const [lunchInfoLoading, setLunchInfoLoading] = useState(true);
	const [scheduleStructureDataLoading, setScheduleStructureDataLoading] = useState(true);
	
	const [errorMessage, setErrorMessage] = useState(null);
	
	const [coursePeriods, setCoursePeriods] = useState({
		'1' : {
			course_period_id: '1',
			period_name: '1',
			currently_editing: false,
			color: 'orange'
		},
		'2' : {
			course_period_id: '2',
			period_name: '2',
			currently_editing: false,
			color: 'yellow'
		},
		'3' : {
			course_period_id: '3',
			period_name: '3',
			currently_editing: false,
			color: 'green'
		},
		'4' : {
			course_period_id: '4',
			period_name: '4',
			currently_editing: false,
			color: 'blue'
		},
		'5' : {
			course_period_id: '5',
			period_name: '5',
			currently_editing: false,
			color: 'purple'
		},
		'6' : {
			course_period_id: '6',
			period_name: '6',
			currently_editing: false,
			color: 'dark-blue-grey'
		},
		'7' : {
			course_period_id: '7',
			period_name: '7',
			currently_editing: false,
			color: 'pastel-orange'
		},
		'8' : {
			course_period_id: '8',
			period_name: '8',
			currently_editing: false,
			color: 'pastel-yellow'
		},
		'9' : {
			course_period_id: '9',
			period_name: '9',
			currently_editing: false,
			color: 'turquoise'
		},
		'10' : {
			course_period_id: '10',
			period_name: '10',
			currently_editing: false,
			color: 'pastel-light-blue'
		},
		'11' : {
			course_period_id: '11',
			period_name: '11',
			currently_editing: false,
			color: 'pastel-light-purple'
		},
		'12' : {
			course_period_id: '12',
			period_name: '12',
			currently_editing: false,
			color: 'dark-blue'
		},
		'13' : {
			course_period_id: '13',
			period_name: '13',
			currently_editing: false,
			color: 'dark-blue'
		},
		'14' : {
			course_period_id: '14',
			period_name: '14',
			currently_editing: false,
			color: 'dark-blue'
		},
		'15' : {
			course_period_id: '15',
			period_name: '15',
			currently_editing: false,
			color: 'dark-blue'
		},
		'16' : {
			course_period_id: '16',
			period_name: '16',
			currently_editing: false,
			color: 'dark-blue'
		},
		'17' : {
			course_period_id: '17',
			period_name: '17',
			currently_editing: false,
			color: 'dark-blue'
		},
		'18' : {
			course_period_id: '18',
			period_name: '18',
			currently_editing: false,
			color: 'dark-blue'
		},
		'19' : {
			course_period_id: '19',
			period_name: '19',
			currently_editing: false,
			color: 'dark-blue'
		},
		'20' : {
			course_period_id: '20',
			period_name: '20',
			currently_editing: false,
			color: 'dark-blue'
		}
		
	});
	
	////////////////////////////////////////////////
	/////////// UPDATE FUNCTIONS ///////////////////
	////////////////////////////////////////////////
	const scrollDown = (scroll) => {
		if(!numDaysInCycle || !numAcademicCoursesPerDay || numDaysInCycle === 'null' || numAcademicCoursesPerDay === 'null') return;
		
		const div_exists = ($("#edit-schedule-structure-container").length > 0) ? true : false;
		
		if(div_exists && scroll)
		{
			$('#main-content').animate({
	            scrollTop: $("#edit-schedule-structure-container").offset().top
	        }, 1000);
		}
	}
	
	const handleFocus = (event) => event.target.select();

	const getNewData = () => {
		// Get schedule info data then set schedule type
		getData('scheduleInfo', '/get-schedule-info', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(schedule_info_data => {
			const schedule_type = schedule_info_data.schedule_type;
			const num_courses_during_lunch_block = schedule_info_data.num_courses_during_lunch_block;
			
			const num_days_in_cycle = (schedule_info_data.num_days_in_cycle) ? schedule_info_data.num_days_in_cycle : (JSONexists('num_days_in_cycle','session') ? getJSONFromStorage('num_days_in_cycle', false, 'session') : null);
			const num_academic_courses_per_day = (schedule_info_data.num_academic_courses_per_day) ? schedule_info_data.num_academic_courses_per_day : (JSONexists('num_academic_courses_per_day','session') ? getJSONFromStorage('num_academic_courses_per_day', false, 'session') : null);
			
			if(num_days_in_cycle && parseInt(num_days_in_cycle) >= 8) setRotationOptionsStart(8);
			if(num_academic_courses_per_day && parseInt(num_academic_courses_per_day) >= 8) setAcademicOptionsStart(8);
			
			sessionStorage.setItem('num_courses_during_lunch_block', num_courses_during_lunch_block);
			sessionStorage.setItem('num_academic_courses_per_day', num_academic_courses_per_day);
			sessionStorage.setItem('num_days_in_cycle', num_days_in_cycle);
			
			setScheduleType(schedule_type);
			setScheduleInfoLoading(false);
		});
		
		getData('lunchInfo', '/get-lunch-info', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(() => {
			setLunchInfoLoading(false);
		});
		
		getData('scheduleStructure', '/get-schedule-structure', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(schedule_structure_data => {
			const schedule_structure = schedule_structure_data.schedule_structure;
			const schedule_periods = schedule_structure_data.schedule_periods;
			const course_periods = schedule_structure_data.course_periods;
			
			let course_periods_json = JSONexists('course_periods', 'session') ? getJSONFromStorage('course_periods', true, 'session') : {};
			let schedule_periods_json = JSONexists('schedule_periods', 'session') ? getJSONFromStorage('schedule_periods', true, 'session') : {};
			
			let colors = ['orange', 'yellow', 'green', 'blue', 'purple', 'dark-blue-grey', 'pastel-orange', 'pastel-yellow', 'turquoise', 'pastel-light-blue', 'pastel-light-purple', 'dark-blue', 'bright-yellow'];
			
			if(!isEmpty(course_periods))
			{
				let index = 0;
				
				for(const course_period_id in course_periods)
				{
					const period_name = course_periods[course_period_id]['period_name'];
					
					course_periods_json[course_period_id] = {course_period_id:course_period_id, period_name:period_name, currently_editing:false, color:colors[index]};
					
					index++;
				}
				
				// Add more course periods to get to at least 10
				for(var i = index; i < 10; i++)
				{
					const course_period_id = createNewKeyForObject(course_periods_json);
					course_periods_json[course_period_id] = {course_period_id:course_period_id, period_name:'--', currently_editing:false, color:colors[i]};
				}
			}
			else
			{
				course_periods_json = coursePeriods;	
			}
			
			if(!isEmpty(schedule_periods))
			{
				for(const schedule_period_id in schedule_periods)
				{
					const {period_name, start_time, end_time, is_lunch, period_order} = schedule_periods[schedule_period_id];
					const currently_editing = false;
					
					schedule_periods_json[schedule_period_id] = {schedule_period_id:schedule_period_id, period_name:period_name, start_time:start_time, end_time:end_time, period_order:period_order, is_lunch:is_lunch, currently_editing:currently_editing, days:[]};
				}
			}
			
			if(!isEmpty(schedule_structure))
			{
				for(const day in schedule_structure)
				{
					let schedule_periods = schedule_structure[day];
					
					for(const schedule_period_id in schedule_periods)
					{
						let course_period_id = schedule_periods[schedule_period_id];
						
						schedule_periods_json[schedule_period_id]['days'].push({day: day, course_period_id:`${course_period_id}`});
					}
				}
			}
			
			sessionStorage.setItem('original_course_periods', JSON.stringify(course_periods_json));
			sessionStorage.setItem('course_periods', JSON.stringify(course_periods_json));
			
			sessionStorage.setItem('original_schedule_periods', JSON.stringify(schedule_periods_json));
			sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods_json));
			
			setScheduleStructureDataLoading(false);
		});
		
		if(JSONexists('hide_instructions', 'session'))
		{
			const hide_instructions = getJSONFromStorage('hide_instructions', true, 'session');
			setHideInstructions(hide_instructions);
		}
	}
	
	const createScheduleStructure = (scroll) => {
		setScheduleStructureLoading(true);
		
		const num_academic_courses_per_day = getJSONFromStorage('num_academic_courses_per_day', false, 'session');
		const num_days_in_cycle = getJSONFromStorage('num_days_in_cycle', false, 'session');
		
		setNumAcademicCoursesPerDay(num_academic_courses_per_day);
		setNumDaysInCycle(num_days_in_cycle);
		
		if(!num_academic_courses_per_day || !num_days_in_cycle ) return;
		
		const lunchInfo = getJSONFromStorage('lunchInfo', true);
		
		let schedule_periods = JSONexists('schedule_periods', 'session') ? getJSONFromStorage('schedule_periods', true, 'session') : {};
		let course_periods = JSONexists('course_periods', 'session') ? getJSONFromStorage('course_periods', true, 'session') : {};
		let num_courses_during_lunch_block = getJSONFromStorage('num_courses_during_lunch_block', false, 'session');
		
		let schedule_periods_json = {};
		let schedule_periods_before_lunch = {};
		let schedule_periods_during_lunch = {};
		let schedule_periods_after_lunch = {};
		
		// If schedule structure already exists, then use it to create schedule structure
		// Else use the inputs and create your own schedule structure
		if(!isEmpty(schedule_periods))
		{
			let schedule_periods_sorted = sortObject(schedule_periods, 'period_order');
			
			let lunch_passed = false;
			let period_order = 1;
			
			for(const schedule_period_info of schedule_periods_sorted)
			{	
				const { schedule_period_id, is_lunch } = schedule_period_info;
				
				schedule_period_info.period_order = `${period_order}`;
				schedule_periods_json[schedule_period_id] = schedule_period_info;
				
				if(is_lunch === '1' || is_lunch === 1)
				{
					schedule_periods_during_lunch[schedule_period_id] = schedule_period_info;
					lunch_passed = true;
				}
				else if(lunch_passed)
				{
					schedule_periods_after_lunch[schedule_period_id] = schedule_period_info;
				}
				else
				{
					schedule_periods_before_lunch[schedule_period_id] = schedule_period_info;
				}
				
				period_order++;
			}
		}
		else 
		{
			const lunches = lunchInfo.lunches;
			const num_lunches = Object.keys(lunches).length;
			let number_possible_periods_during_lunch_periods = 0;
			
			if(scheduleType === 'daily')
			{
				if(num_lunches === 1)
				{
					number_possible_periods_during_lunch_periods = 1;
				}
				else if(num_lunches === 2)
				{
					number_possible_periods_during_lunch_periods = 2;
				}
				else if(num_lunches === 3 || num_lunches === 4)
				{
					// Step 1: Find lunch duration by finding first start time and last end time and subtracting
					// Step 2: Find lunch duration by (single lunch duration x number of lunches) + passing time
					// Step 3: Compare both lunch durations to figure out how many schedule periods to create
					let min_start_time, max_end_time, total_lunch_span, single_lunch_duration, total_lunch_time;
					
					let acceptable_passing_time = 7 * 60 * 1000; // 7 minutes converted to milliseconds
					let num_passing_times = (num_lunches - 1);
					let total_passing_time = acceptable_passing_time * num_passing_times;
					
					for(const lunch_index in lunches)
					{
						let start_time = lunches[lunch_index]['start_time'];
						let end_time = lunches[lunch_index]['end_time'];
						
						let converted_start_time = new Date(`2013-09-01T${start_time}Z`);
						let converted_end_time = new Date(`2013-09-01T${end_time}Z`);
						
						min_start_time = (!min_start_time || converted_start_time < min_start_time) ? converted_start_time : min_start_time;
						max_end_time = (!max_end_time || converted_end_time > max_end_time) ? converted_end_time : max_end_time;
						
						if(!single_lunch_duration) single_lunch_duration = converted_end_time - converted_start_time;
					}
					
					total_lunch_span = max_end_time - min_start_time;
					total_lunch_time = (single_lunch_duration * num_lunches) + total_passing_time;
					
					if(total_lunch_time < total_lunch_span)
					{
						if(num_lunches === 3)
						{
							number_possible_periods_during_lunch_periods = 4;
						}
						else if(num_lunches === 4)
						{
							number_possible_periods_during_lunch_periods = 6;
						}
					}
					else
					{
						number_possible_periods_during_lunch_periods = num_lunches;
					}
				}
				else if(num_lunches === 5 || num_lunches === 6)
				{
					number_possible_periods_during_lunch_periods = num_lunches;
				}
			}
			else if(scheduleType === 'block')
			{
				number_possible_periods_during_lunch_periods = (num_lunches == 1) ? 1 : num_courses_during_lunch_block;
			}
			
			const num_courses_outside_lunch_block = Math.max((num_academic_courses_per_day - num_courses_during_lunch_block), 0);
			
			const num_periods_before_lunch = (num_courses_outside_lunch_block > 0) ? Math.floor(parseInt(num_courses_outside_lunch_block)/2) : 0;
			const num_periods_after_lunch = (num_courses_outside_lunch_block > 0) ? (parseInt(num_courses_outside_lunch_block) - num_periods_before_lunch) : 0;
			
			// Create empty array for as many days in rotation
			let day_array = [];
			
			if(scheduleType === "block")
			{
				for(var day = 1; day <= num_days_in_cycle; day++)
				{
					day_array.push({day:day, course_period_id: null});
				}
			}
			
			let period_index = 1;
			let lunch_period_index = 1;
			
			for(let i = 0; i < num_periods_before_lunch; i++)
			{
				if(scheduleType === 'daily')
				{
					let course_period_id = course_periods[period_index]['course_period_id'];
					day_array = [{day:1, course_period_id: course_period_id},{day:2, course_period_id: course_period_id},{day:3, course_period_id: course_period_id},{day:4, course_period_id: course_period_id},{day:5, course_period_id: course_period_id}];
				}
				
				schedule_periods_json[period_index] = {schedule_period_id:`${period_index}`, period_name: `Time Slot ${period_index}`, start_time: '00:00:00', end_time: '00:00:00', is_lunch:'0', period_order:`${period_index}`, days:day_array, currently_editing:false};
				schedule_periods_before_lunch[period_index] = {schedule_period_id:`${period_index}`, period_name: `Time Slot ${period_index}`, start_time: '00:00:00', end_time: '00:00:00', period_order:`${period_index}`, days: day_array, currently_editing:false};
				period_index++;
			}
			
			for(let i = 0; i < number_possible_periods_during_lunch_periods; i++)
			{
				if(scheduleType === 'daily')
				{
					let course_period_id = course_periods[period_index]['course_period_id'];
					day_array = [{day:1, course_period_id: course_period_id},{day:2, course_period_id: course_period_id},{day:3, course_period_id: course_period_id},{day:4, course_period_id: course_period_id},{day:5, course_period_id: course_period_id}];
				}
				
				schedule_periods_json[period_index] = {schedule_period_id:`${period_index}`, period_name: `Lunch Class ${lunch_period_index}`, start_time: '00:00:00', end_time: '00:00:00', is_lunch:'1', period_order:`${period_index}`, days:day_array, currently_editing:false};
				schedule_periods_during_lunch[period_index] = {schedule_period_id:`${period_index}`, period_name: `Lunch Class ${lunch_period_index}`, start_time: '00:00:00', end_time: '00:00:00', period_order:`${period_index}`, days: day_array, currently_editing:false};
				period_index++;
				lunch_period_index++;
			}
			
			for(let i = 0; i < num_periods_after_lunch; i++)
			{
				if(scheduleType === 'daily')
				{
					let course_period_id = course_periods[period_index]['course_period_id'];
					day_array = [{day:1, course_period_id: course_period_id},{day:2, course_period_id: course_period_id},{day:3, course_period_id: course_period_id},{day:4, course_period_id: course_period_id},{day:5, course_period_id: course_period_id}];
				}
				
				schedule_periods_json[period_index] = {schedule_period_id:`${period_index}`, period_name: `Time Slot ${period_index}`, start_time: '00:00:00', end_time: '00:00:00', is_lunch:'0', period_order:`${period_index}`, days:day_array, currently_editing:false};
				schedule_periods_after_lunch[period_index] = {schedule_period_id:`${period_index}`, period_name: `Time Slot ${period_index}`, start_time: '00:00:00', end_time: '00:00:00', period_order:`${period_index}`, days: day_array, currently_editing:false};
				period_index++;
			}
		}
		
		sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods_json));
		
		//console.log({schedule_periods_before_lunch, schedule_periods_during_lunch, schedule_periods_after_lunch});
		
		setSchedulePeriodsBeforeLunch(schedule_periods_before_lunch);
		setSchedulePeriodsDuringLunch(schedule_periods_during_lunch);
		setSchedulePeriodsAfterLunch(schedule_periods_after_lunch);
		
		setCoursePeriods(course_periods);
		setScheduleStructureLoading(false);
	}
	
	const selectNumDaysInCycle = (num_days_in_cycle) => {
		setScheduleStructureLoading(true);
		
		const current_num_days_in_cycle = parseInt(getJSONFromStorage('num_days_in_cycle', false, 'session'));
		
		if(num_days_in_cycle === current_num_days_in_cycle) return;
		
		const schedule_periods = getJSONFromStorage('schedule_periods', true, 'session');
		
		if(!isEmpty(schedule_periods))
		{
			for(var schedule_period_id in schedule_periods)
			{	
				if(current_num_days_in_cycle < num_days_in_cycle)
				{
					for(var day_index = current_num_days_in_cycle; day_index < num_days_in_cycle; day_index++)
					{
						schedule_periods[schedule_period_id]['days'].push({day: day_index, course_period_id:null});
					}
				}
				else
				{
					const num_days_to_subtract = current_num_days_in_cycle - num_days_in_cycle;
					
					for(var i = 0; i < num_days_to_subtract; i++)
					{
						schedule_periods[schedule_period_id]['days'].pop();
					}
				}
			}
			
			sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods));
		}
		
		sessionStorage.setItem('num_days_in_cycle', num_days_in_cycle);
		createScheduleStructure(true);
	}
	
	const selectNumAcademicCoursesPerDay = (num_academic_courses_per_day) => {
		setScheduleStructureLoading(true);
		
		let current_num_academic_courses_per_day = getJSONFromStorage('num_academic_courses_per_day', false, 'session');
		
		// If same number of academic courses clicked, do nothing
		if(num_academic_courses_per_day === current_num_academic_courses_per_day) return;
		
		let schedule_periods = getJSONFromStorage('schedule_periods', true, 'session');
		
		// If schedule periods already exist, then just add/subtract only as many schedule periods as necessary
		if(!isEmpty(schedule_periods))
		{
			// if num academic courses does not exist, then clear schedule periods
			// if new number is higher than old, then add new schedule periods
			// otherwise if new number is lower, subtract schedule periods
			if(current_num_academic_courses_per_day === 'null')
			{
				schedule_periods = {};
			}
			else 
			{
				current_num_academic_courses_per_day = parseInt(current_num_academic_courses_per_day);
				num_academic_courses_per_day = parseInt(num_academic_courses_per_day);
				
				if(current_num_academic_courses_per_day < num_academic_courses_per_day)
				{
					for(var index = current_num_academic_courses_per_day; index < num_academic_courses_per_day; index++)
					{
						let schedule_periods_sorted = reverseSortObject(schedule_periods, 'period_order');
						let last_schedule_period = cloneObj(schedule_periods_sorted[0]);
						let days = last_schedule_period.days;
						
						const schedule_period_id = createNewKeyForObject(schedule_periods);
						const period_order = (parseInt(last_schedule_period.period_order) + 1).toString();
						
						// clear any course periods for the new schedule periods
						for(const day in days)
						{
							days[day]['course_period_id'] = (scheduleType === 'daily') ?  period_order : null;
						}
						
						// update all info for new schedule period
						last_schedule_period.period_order = period_order;
						last_schedule_period.schedule_period_id = schedule_period_id;
						last_schedule_period.period_name = `Time Slot ${period_order}`;
						last_schedule_period.is_lunch = '0';
						last_schedule_period.days = days;
						
						// push the new schedule period
						schedule_periods[schedule_period_id] = last_schedule_period;
					}
				}
				else
				{
					// Reverse sort schedule periods to start taking periods off from the end
					let schedule_periods_sorted = reverseSortObject(schedule_periods, 'period_order');
					const num_schedule_periods_to_subtract = parseInt(current_num_academic_courses_per_day) - parseInt(num_academic_courses_per_day);
					
					let num_periods_subtracted = 0;
					let index = 0;
					
					while(num_periods_subtracted !== num_schedule_periods_to_subtract)
					{
						let schedule_period_info = schedule_periods_sorted[index];
						let is_lunch = schedule_period_info.is_lunch;
						index++;
						
						// do not remove lunch periods
						if(is_lunch === '1' || is_lunch === 1) continue;
						
						let schedule_period_id = schedule_period_info.schedule_period_id;
						
						delete schedule_periods[schedule_period_id];
						num_periods_subtracted++;
					}
				}
			}
		}
		
		sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods));
		sessionStorage.setItem('num_academic_courses_per_day', num_academic_courses_per_day);
		createScheduleStructure(true);
	}
	
	const toggleInstructions = () => {
		const hide_instructions = !hideInstructions;
		
		setHideInstructions(hide_instructions);
		sessionStorage.setItem('hide_instructions', hide_instructions);
	}
	
	const openImg = (e) => {
		const src = e.target.getAttribute('data-src');
		window.open(src,'Image','width=600,height=800,resizable=1');
	}
	
	const onTimeChange = (e) => {
		let schedule_periods = getJSONFromStorage('schedule_periods', true, 'session');
		
		const target = $(e.target);
		const schedule_period_id = target.closest('.ess-period-info-outer-container').attr('data-scheduleperiodid');
		const time_type = target.attr('data-type');
		const time_value = target.val();
		
		schedule_periods[schedule_period_id][time_type] = time_value;
		
		sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods));
		
		//console.log("CALLING FROM ON TIME CHANGE");
		createScheduleStructure();
	}
	
	const toggleRotationOptions = () => {
		if(rotationOptionsStart === 1)
		{
			setRotationOptionsStart(8);
		}
		else
		{
			setRotationOptionsStart(1);
		}
	}
	
	const toggleAcademicOptions = () => {
		if(academicOptionsStart === 1)
		{
			setAcademicOptionsStart(8);
		}
		else
		{
			setAcademicOptionsStart(1);
		}
	}
	
	const openEditSchedulePeriod = (e) => {
		const parentDiv = $(e.target).closest('.ess-period-info-outer-container');
		const schedule_period_id = parentDiv.attr('data-scheduleperiodid');
		
		let schedule_periods = getJSONFromStorage('schedule_periods', true, 'session');
		
		schedule_periods[schedule_period_id]['currently_editing'] = true;
		
		sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods));
		createScheduleStructure();
	}
	
	const saveSchedulePeriodName = (e) => {
		if(e.key !== 'Enter' && e.type !== 'click') return;
		
		const target = $(e.target);
		
		const parentDiv = $(e.target).closest('.ess-period-info-outer-container');
		const schedule_period_id = parentDiv.attr('data-scheduleperiodid');
		
		const period_name = target.closest('.ess-period-name-container').find('.schedule-setup-period-name-input').val();
		
		if(period_name === '') return;
		
		let schedule_periods = getJSONFromStorage('schedule_periods', true, 'session');
		
		schedule_periods[schedule_period_id]['period_name'] = period_name;
		schedule_periods[schedule_period_id]['currently_editing'] = false;
		
		sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods));
		createScheduleStructure();
	}
	
	const cancelEditSchedulePeriodName = (e) => {
		const parentDiv = $(e.target).closest('.ess-period-info-outer-container');
		const schedule_period_id = parentDiv.attr('data-scheduleperiodid');
		
		let schedule_periods = getJSONFromStorage('schedule_periods', true, 'session');
		
		schedule_periods[schedule_period_id]['currently_editing'] = false;
		
		sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods));
		createScheduleStructure();
	}
	
	const openEditCoursePeriod = (e) => {
		const parentDiv = $(e.target).closest('.course-period-draggable');
		const course_period_id = parentDiv.attr('data-courseperiodid');
		const period_name = parentDiv.attr('data-courseperiodname');
		const color = parentDiv.attr('data-color');
		
		setCoursePeriods({...coursePeriods, [course_period_id]: {course_period_id:course_period_id, period_name: period_name, currently_editing:true, color:color}});
	}
	
	const saveCoursePeriodName = (e) => {
		if(e.key !== 'Enter' && e.type !== 'click') return;
		
		const target = $(e.target);
		
		const parentDiv = target.closest('.course-period-draggable');
		const course_period_id = parentDiv.attr('data-courseperiodid');
		const color = parentDiv.attr('data-color');
		const period_name = target.closest('.schedule-setup-course-period-name-input-container').find('.schedule-setup-course-period-name-input').val();
		
		if(period_name === '') return;
		
		setCoursePeriods({...coursePeriods, [course_period_id]: {course_period_id:course_period_id, period_name: period_name, currently_editing:false, color:color}});
	}
	
	const cancelEditCoursePeriodName = (e) => {
		const parentDiv = $(e.target).closest('.course-period-draggable');
		const course_period_id = parentDiv.attr('data-courseperiodid');
		const period_name = parentDiv.attr('data-courseperiodname');
		const color = parentDiv.attr('data-color');
		
		setCoursePeriods({...coursePeriods, [course_period_id]: {course_period_id:course_period_id, period_name: period_name, currently_editing:false, color:color}});
	}
	
	const removeCoursePeriod = (e) => {
		const target = $(e.target);
		const day_index = target.closest('.ess-droppable-inner-container').attr('data-dayindex');
		const schedule_period_id = target.closest('.ess-period-inner-container').attr('data-scheduleperiodid');
		
		let schedule_periods = getJSONFromStorage('schedule_periods', true, 'session');
		
		schedule_periods[schedule_period_id]['days'][day_index]['course_period_id'] = null;
		
		sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods));
		createScheduleStructure();
	}
	
	const initializeSortable = () => {
		$("#ess-sortable-schedule-container").sortable({
			helper: "clone",
			axis: "y",
			forcePlaceholderSize: true,
			items: '.ess-period-parent-container',
			placeholder: "sortable-placeholder",
			tolerance: "pointer",
			update: function (event, ui) {
				let schedule_periods = getJSONFromStorage('schedule_periods', true, 'session');
				let schedule_periods_json = {};
				let period_order = 1;
				
				$('.ess-period-info-outer-container').each(function(){
					const $this = $(this);
					const schedule_period_id = $this.attr('data-scheduleperiodid');
					const schedule_period_info = schedule_periods[schedule_period_id];
					
					schedule_periods_json[schedule_period_id] = {...schedule_period_info, period_order:`${period_order}`};
					
					period_order++;
				});
				
				sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods_json));
				//console.log("CALLING FROM SORTABLE UPDATE");
				createScheduleStructure();
			}
		});
			
		$("#ess-sortable-schedule-container").sortable("refresh");
			
		$(".course-period-draggable").draggable({
			helper: "clone",
			snap: ".schedule-structure-droppable",
			revert: "invalid",
			zIndex: 9999,
		});
			
		$(".course-period-droppable").droppable({
			drop: function (event, ui) {
				const draggedDiv = ui.draggable;
				let droppedDiv = $(this);
				
				if(draggedDiv.hasClass('ess-period-parent-container')) return;
				
				let schedule_periods = getJSONFromStorage('schedule_periods', true, 'session');
				
				const coursePeriodID = draggedDiv.attr("data-courseperiodid");
				const dayIndex = droppedDiv.attr("data-dayindex");
				const schedulePeriodID = droppedDiv.closest('.ess-period-inner-container').attr('data-scheduleperiodid');
			
				$(ui.helper).remove();
				
				droppedDiv.attr('class', "ess-droppable-inner-container course-period-droppable ui-droppable");
				
				schedule_periods[schedulePeriodID]['days'][dayIndex]['course_period_id'] = coursePeriodID;
				
				sessionStorage.setItem('schedule_periods', JSON.stringify(schedule_periods));
				//console.log("CALLING FROM DROPPABLE");
				createScheduleStructure();
			},
		});
	}
	
	const submitStructure = async () => {
		let schedule_periods = getJSONFromStorage('schedule_periods', true, 'session');
		let original_schedule_periods = getJSONFromStorage('original_schedule_periods', true, 'session');
		
		let course_periods = getJSONFromStorage('course_periods', true, 'session');
		let original_course_periods = getJSONFromStorage('original_course_periods', true, 'session');
		
		let error_message = null;
		setErrorMessage(error_message);
		
		//console.log(areArraysOrObjectsEqual(schedule_periods, original_schedule_periods));
		//console.log(areArraysOrObjectsEqual(course_periods, original_course_periods));
		
		if(!areArraysOrObjectsEqual(schedule_periods, original_schedule_periods) || !areArraysOrObjectsEqual(course_periods, original_course_periods))
		{
			$(".time-select-input").removeClass('error');
			$(".course-period-droppable").removeClass('error');
			
			for(const schedule_period_id in schedule_periods)
			{
				const schedule_period_info = schedule_periods[schedule_period_id];
				
				const { start_time, end_time, days } = schedule_period_info;
				
				const start_time_date = new Date("1970-01-01 " + start_time + ":00 Z");
				const end_time_date = new Date("1970-01-01 " + end_time + ":00 Z");
				
				// Make sure start time and end times are all okay
				if(!start_time || !end_time || start_time === '00:00' || start_time === '00:00:00' || end_time === '00:00' || end_time === '00:00:00')
				{
					error_message = "Make sure to enter a start and end time for all time slots!";
					
					setErrorMessage(error_message);
					$(`.ess-period-inner-container[data-scheduleperiodid='${schedule_period_id}']`).find(".time-select-input").addClass('error');
					
					return false;
					
				}
				else if(start_time_date > end_time_date || start_time_date.getTime() === end_time_date.getTime())
				{
					error_message = "Start times need to be before end times! Make sure to check them carefully.";
					
					setErrorMessage(error_message);
					$(`.ess-period-inner-container[data-scheduleperiodid='${schedule_period_id}']`).find(".time-select-input").addClass('error');
					
					return false;
				}
				
				for(const day_info of days)
				{
					const course_period_id = day_info.course_period_id;
					
					if(!course_period_id)
					{
						error_message = "Make sure every period has a course set for all days.";
						
						setErrorMessage(error_message);
						$(`.ess-period-inner-container[data-scheduleperiodid='${schedule_period_id}']`).find(".course-period-droppable").addClass('error');
						
						return false;
					}
				}
			}
			
			//-----------------------------------------------------------------------
			// 2) Send a http request with AJAX http://api.jquery.com/jQuery.ajax/
			//-----------------------------------------------------------------------
			const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, schedule_periods:schedule_periods, course_periods:coursePeriods, num_academic_courses_per_day:numAcademicCoursesPerDay, num_days_in_cycle:numDaysInCycle};
			
			await saveScheduleStructure(data);

			// Get new schedule structure and update state
			getData('scheduleStructure', '/get-schedule-structure', {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}, true).then(schedule_structure_data => {
				setScheduleStructureData(schedule_structure_data);
			});

			// Update conflicts
			const conflicts_to_check = ['course_period_restriction', 'teacher_period_restriction', 'teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_too_many_sections_in_a_row'];
			calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, update_all:true});

			// Recalculate match percent
			recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id});
		}
		
		if(error_message) return false;
		
		handleMoveToNextScreen();
	}
	
	/////////////////////////////
	///// DO ON STATE CHANGE ////
	////////////////////////////
	// Run every render
	useEffect(() => {
		initializeSortable();
	});
	
	useEffect(() => {
		if(!isLoading) scrollDown(true);
	}, [numDaysInCycle, numAcademicCoursesPerDay, isLoading]);
	
	useEffect(() => {
		if(!scheduleInfoLoading && !lunchInfoLoading && !scheduleStructureDataLoading) 
		{
			createScheduleStructure();
			setIsLoading(false);
		}
	}, [scheduleInfoLoading, lunchInfoLoading, scheduleStructureDataLoading]);
	
	////////////////////////////////////////////////
	//////////////////// ON LOAD ///////////////////
	////////////////////////////////////////////////
	useEffect(() => {
		getNewData();
	}, []);
	
	////////////////////////////////////////////////
	//////////////////// DISPLAY ///////////////////
	////////////////////////////////////////////////
	const num_days_in_cycle_array = (numDaysInCycle > 0) ? [...Array(parseInt(numDaysInCycle)).keys()] : [];
	
	let rotation_options = [];
	for(var i = rotationOptionsStart; i < (rotationOptionsStart + 7); i++)
	{
		rotation_options.push(i);
	}
	
	let academic_options = [];
	for(var j = academicOptionsStart; j < (academicOptionsStart + 7); j++)
	{
		academic_options.push(j);
	}
	
	let show_num_days_in_cycle = ((numDaysInCycle && numDaysInCycle !== 'null') || scheduleType === 'daily') ? true : false;
	
	//console.log({schedulePeriodsBeforeLunch, schedulePeriodsDuringLunch, schedulePeriodsAfterLunch, coursePeriods, numDaysInCycle, numAcademicCoursesPerDay, scheduleType});
	
	return (
		<div className='schedule-structure-screen' id="create_schedule_structure_container">
			{isLoading ? 
				(
					<div className='center-text'>
						<img src={require("../../../images/balls.gif")} alt='loading...' style={{height:'40px'}}/>
					</div>
				):(subscreenRestricted) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>No Access</h1>
						<div>You do not have access to this screen</div>	
					</div>
				):(subscreenLocked) ?
				(
					<div className='general-screen-message'>
						<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
						<h1>Temporarily Locked</h1>
						<div>This screen is locked until all other prior steps are completed</div>	
					</div>
				): 
				(
					<>
					<h1 className='progress-main-header'>Schedule Structure</h1>
					<p className='progress-subheader'>Let's set up the rest of your schedule structure!</p>
					<div id='schedule-structure-options-container'>
						{scheduleType === 'block' &&
							<div id="days-in-rotation-container">
								<h2 className='schedule-structure-header'>How many days do you have in your rotation?</h2>
								{rotationOptionsStart !== 1 &&
									<FontAwesomeIcon className='toggle-lower-options' icon={faChevronLeft} onClick={toggleRotationOptions}/>
								}
								{rotation_options.map((option, i) => (
										<div className={`option-btn ${numDaysInCycle == option ? 'option-btn-selected' : ''}`} key={i} onClick={() => selectNumDaysInCycle(option)}>{option}</div>
									)
								)}
								{rotationOptionsStart === 1 &&
									<FontAwesomeIcon className='toggle-higher-options' icon={faChevronRight} onClick={toggleRotationOptions}/>
								}
							</div>
						}
						{show_num_days_in_cycle &&
							<div id="non-lunch-periods">
								<h2 className='schedule-structure-header'>How many academic courses does a student have in their day?</h2>
								{academicOptionsStart !== 1 &&
									<FontAwesomeIcon className='toggle-lower-options' icon={faChevronLeft} onClick={toggleAcademicOptions}/>
								}
								{academic_options.map((option, i) => (
									<div className={`option-btn ${numAcademicCoursesPerDay == option ? 'option-btn-selected' : ''}`} key={i} onClick={() => selectNumAcademicCoursesPerDay(option)}>{option}</div>
								))}
								{academicOptionsStart === 1 &&
									<FontAwesomeIcon className='toggle-higher-options' icon={faChevronRight} onClick={toggleAcademicOptions}/>
								}
							</div>
						}
					</div>
					{(numDaysInCycle && numAcademicCoursesPerDay && numDaysInCycle !== 'null' && numAcademicCoursesPerDay !== 'null') &&
						<div id='ess-container'>
							<div id='ess-parent-container'>
								{/* <div id='ess-main-header-container'>
									<img src={require('../../../images/build.png')} alt='build' style={{height:'50px'}}/>
									<h1 id='ess-parent-header'>Schedule Structure</h1>
									{hideInstructions &&
										<p id='show-instructions' onClick={toggleInstructions}>Show Instructions</p>
									}
									{!hideInstructions &&
										<>
											<div id='ess-hide-instructions' className='blue-text' onClick={toggleInstructions}>Hide Instructions</div>
											<div id='ess-instruction-box-container'>
												<div className='ess-instruction-box'>
													<div className='ess-instruction-line-number'>1</div>
													<img className='ess-instruction-icon' src={require('../../../images/scroll.png')} alt='drag up and down' />
													<p className='ess-instruction-line-text'>Move the periods up and down until the lunch block is in the right spot in the schedule.</p>
												</div>
												<div className='ess-instruction-box'>
													<div className='ess-instruction-line-number'>2</div>
													<img className='ess-instruction-icon' src={require('../../../images/clock.png')} alt='set start/end time' />
													<p className='ess-instruction-line-text'>Put in the correct start and end times for your periods. <span className='orange-text'>This is very important to ensure your schedule prints out correctly.</span></p>
												</div>
												{scheduleType === 'block' && 
													<>
													<div className='ess-instruction-box'>
														<div className='ess-instruction-line-number'>3</div>
													<img className='ess-instruction-icon' src={require('../../../images/drag.png')} alt='drag courses' />
														<p className='ess-instruction-line-text'>Drag the courses on the right into the schedule to represent how courses rotate in your schedule.</p>
													</div>
													</>
												}
											</div>
											{scheduleType === 'block' && 
												<div id='ess-example-line'>
													<img id='ess-instruction-star' src={require('../../../images/star.png')} alt='star' />
													<p id='ess-instruction-text'>Your goal is to get a schedule that looks like <span id='example-schedule-link' data-src='/images/schedule-structure-example.png' onClick={openImg}>this</span>.</p>
													<div className="clear"></div>
												</div>
												
											}
										</>
									}
								</div> */}
								<div id="edit-schedule-structure-container">
									{scheduleStructureLoading ? 
										(
											<div className='center-text'>
												<img src={require('../../../images/balls.gif')} alt='loading' />
											</div>
										) : 
										(
											<>
											<div id='ess-sortable-schedule-container'>
												<div id='ess-header-container' className='unsortable' style={{gridTemplateColumns: `24px 300px repeat(${numDaysInCycle}, 1fr)`}}>
													<div></div>
													<div id='ess-header-label'></div>
													{num_days_in_cycle_array.map((option, i) => {
														let index = i + 1;
														return (
															<div className='ess-header-day' key={i}>
																<div className='ess-header-day-inner'>Day {index}</div>
															</div>
														)
														
													})}
												</div>
												{Object.keys(schedulePeriodsBeforeLunch).map(index => {
													const schedule_period_info = schedulePeriodsBeforeLunch[index];
													const { schedule_period_id, period_name, period_order, start_time, end_time, currently_editing } = schedule_period_info;
													
													return (
														<div className='ess-period-parent-container grabbable' key={`before-${schedule_period_id}`}>
															<div className='ess-drag-dots-container'><FontAwesomeIcon className='ess-drag-dots' icon={faEllipsisV}/></div>
															<div className='ess-period-inner-container' style={{gridTemplateColumns: `300px repeat(${numDaysInCycle}, 1fr)`}}  data-scheduleperiodid={schedule_period_id}>
																<div className='ess-period-info-outer-container' data-scheduleperiodid={schedule_period_id}>
																	<div className='ess-period-info-inner-container'>
																		<div className='ess-period-name-container'>
																		{!currently_editing &&
																			<>
																			<div className='ess-period-info-label' onDoubleClick={openEditSchedulePeriod}>{period_name}</div>
																			<FontAwesomeIcon className='schedule-setup-edit-schedule-period-name' icon={faEdit} onClick={openEditSchedulePeriod}/>
																			</>
																		}
																		{currently_editing &&
																			<>
																			<input className='schedule-setup-period-name-input' type='text' defaultValue={period_name} onKeyDown={saveSchedulePeriodName} autoFocus={true} onFocus={handleFocus}/>
																			<FontAwesomeIcon className='ess-cancel-edit-schedule-period' icon={faTimes} onClick={cancelEditSchedulePeriodName}/>
																			<FontAwesomeIcon className='ess-confirm-edit-schedule-period' icon={faCheck} onClick={saveSchedulePeriodName}/>
																			</>
																		}
																		</div>
																		<div className='time-select-container'>
																			<div className='time-select' key={`starttime-${schedule_period_id}`}>
																				<div className='time-select-label dark-blue-text'>Starts</div>
																				<input className="time-select-input time-select-start-time" type="time" data-type='start_time' defaultValue={start_time} onChange={onTimeChange}/>
																			</div><div className='time-select' key={`endtime-${schedule_period_id}`}>
																				<div className='time-select-label dark-blue-text'>Ends</div>
																				<input className="time-select-input time-select-end-time" type="time" data-type='end_time' defaultValue={end_time} onChange={onTimeChange} />
																			</div>
																		</div>
																	</div>
																</div>
																{schedulePeriodsBeforeLunch[schedule_period_id]['days'].map((day_info, i) => {
																	const course_period_id = day_info.course_period_id;
																	
																	if(course_period_id)
																	{
																		const color = coursePeriods[course_period_id]['color'];
																		const period_name = coursePeriods[course_period_id]['period_name'];
																		
																		return (
																			<div className="ess-droppable-outer-container" key={i}>
																				<div className={`ess-droppable-inner-container course-period-droppable ${color}`} data-courseperiodid={course_period_id} data-courseperiodname={period_name} data-dayindex={i}>{scheduleType === 'block' ? period_name : period_order}{scheduleType !== 'daily' && <FontAwesomeIcon className='ess-remove-course-period' icon={faTimes} onClick={removeCoursePeriod}/>}</div>
																			</div>
																		)
																	}
																	else
																	{
																		return (
																			<div className='ess-droppable-outer-container' key={i}>
																				<div className='ess-droppable-inner-container course-period-droppable schedule-structure-empty' data-courseperiodid='' data-courseperiodname='' data-dayindex={i}>[Drop Here]</div>
																			</div>
																		)
																	}
																})}
															</div>
														</div>
													)
												})}
												{Object.keys(schedulePeriodsDuringLunch).length !== 0 &&
													<div className='ess-period-parent-container grabbable' style={{padding:'0px'}}>
														<div className='ess-drag-dots-container purple' style={{borderRadius: '8px 0px 0px 8px'}}>
															<FontAwesomeIcon className='ess-drag-dots white-text' icon={faEllipsisV}/>
														</div>
														<div id='ess-lunch-block-parent-container'>
														{Object.keys(schedulePeriodsDuringLunch).map(index => {
															const schedule_period_info = schedulePeriodsDuringLunch[index];
															const { schedule_period_id, period_name, period_order, start_time, end_time, currently_editing } = schedule_period_info;
															
															return (
																<div className='ess-period-inner-container' style={{gridTemplateColumns: `300px repeat(${numDaysInCycle}, 1fr)`, padding:'10px 0px 10px 0px'}} key={`during-${schedule_period_id}`} data-scheduleperiodid={`${schedule_period_id}`}>
																	<div className='ess-period-info-outer-container' data-scheduleperiodid={`${schedule_period_id}`}>
																		<div className='ess-period-info-inner-container'>
																			<div className='ess-period-name-container'>
																			{!currently_editing &&
																				<>
																				<div className='ess-period-info-label' onDoubleClick={openEditSchedulePeriod}>{period_name}</div>
																				<FontAwesomeIcon className='schedule-setup-edit-schedule-period-name' icon={faEdit} onClick={openEditSchedulePeriod}/>
																				</>
																			}
																			{currently_editing &&
																				<>
																				<input className='schedule-setup-period-name-input' type='text' defaultValue={period_name} onKeyDown={saveSchedulePeriodName} />
																				<FontAwesomeIcon className='ess-cancel-edit-schedule-period' icon={faTimes} onClick={cancelEditSchedulePeriodName}/>
																				<FontAwesomeIcon className='ess-confirm-edit-schedule-period' icon={faCheck} onClick={saveSchedulePeriodName}/>
																				</>
																			}
																			</div>
																			<div className='time-select-container'>
																				<div className='time-select'>
																					<div className='time-select-label dark-blue-text'>Starts</div>
																					<input className="time-select-input time-select-start-time" type="time" data-type='start_time' defaultValue={start_time} onChange={onTimeChange}/>
																				</div><div className='time-select'>
																					<div className='time-select-label dark-blue-text'>Ends</div>
																					<input className="time-select-input time-select-end-time" type="time" data-type='end_time' defaultValue={end_time} onChange={onTimeChange} />
																				</div>
																			</div>
																		</div>
																	</div>
																	{schedulePeriodsDuringLunch[schedule_period_id]['days'].map((day_info, i) => {
																		let course_period_id = day_info.course_period_id;
																		
																		if(course_period_id)
																		{
																			const color = coursePeriods[course_period_id]['color'];
																			const period_name = coursePeriods[course_period_id]['period_name'];
																			
																			return (
																				<div className="ess-droppable-outer-container" key={i}>
																					<div className={`ess-droppable-inner-container course-period-droppable ${color}`} data-courseperiodid={course_period_id} data-courseperiodname={period_name} data-dayindex={i}>{scheduleType === 'block' ? period_name : period_order}{scheduleType !== 'daily' && <FontAwesomeIcon className='ess-remove-course-period' icon={faTimes} onClick={removeCoursePeriod}/>}</div>
																				</div>
																			)
																		}
																		else
																		{
																			return (
																				<div className='ess-droppable-outer-container' key={i}>
																					<div className='ess-droppable-inner-container course-period-droppable schedule-structure-empty' data-courseperiodid='' data-courseperiodname='' data-dayindex={i}>[Drop Here]</div>
																				</div>
																			)
																		}
																	})}
																</div>
															)	
														})}
														</div>
													</div>
												}
												{Object.keys(schedulePeriodsAfterLunch).map(index => {
													const schedule_period_info = schedulePeriodsAfterLunch[index];
													const { schedule_period_id, period_name, period_order, start_time, end_time, currently_editing } = schedule_period_info;
													
													return (
														<div className='ess-period-parent-container grabbable' key={`after-${schedule_period_id}`}>
															<div className='ess-drag-dots-container'><FontAwesomeIcon className='ess-drag-dots' icon={faEllipsisV}/></div>
															<div className='ess-period-inner-container' style={{gridTemplateColumns: `300px repeat(${numDaysInCycle}, 1fr)`}} data-scheduleperiodid={`${schedule_period_id}`}>
																<div className='ess-period-info-outer-container' data-scheduleperiodid={`${schedule_period_id}`}>
																	<div className='ess-period-info-inner-container'>
																		<div className='ess-period-name-container'>
																		{!currently_editing &&
																			<>
																			<div className='ess-period-info-label' onDoubleClick={openEditSchedulePeriod}>{period_name}</div>
																			<FontAwesomeIcon className='schedule-setup-edit-schedule-period-name' icon={faEdit} onClick={openEditSchedulePeriod}/>
																			</>
																		}
																		{currently_editing &&
																			<>
																			<input className='schedule-setup-period-name-input' type='text' defaultValue={period_name} onKeyDown={saveSchedulePeriodName} autoFocus={true} onFocus={handleFocus}/>
																			<FontAwesomeIcon className='ess-cancel-edit-schedule-period' icon={faTimes} onClick={cancelEditSchedulePeriodName}/>
																			<FontAwesomeIcon className='ess-confirm-edit-schedule-period' icon={faCheck} onClick={saveSchedulePeriodName}/>
																			</>
																		}
																		</div>
																		<div className='time-select-container'>
																			<div className='time-select'>
																				<div className='time-select-label dark-blue-text'>Starts</div>
																				<input className="time-select-input time-select-start-time" type="time" data-type='start_time' defaultValue={start_time} onChange={onTimeChange}/>
																			</div><div className='time-select'>
																				<div className='time-select-label dark-blue-text'>Ends</div>
																				<input className="time-select-input time-select-end-time" type="time" data-type='end_time' defaultValue={end_time} onChange={onTimeChange} />
																			</div>
																		</div>
																	</div>
																</div>
																{schedulePeriodsAfterLunch[schedule_period_id]['days'].map((day_info, i) => {
																	const course_period_id = day_info.course_period_id;
																	
																	if(course_period_id)
																	{
																		const color = coursePeriods[course_period_id]['color'];
																		const period_name = coursePeriods[course_period_id]['period_name'];
																		
																		return (
																			<div className="ess-droppable-outer-container" key={i}>
																				<div className={`ess-droppable-inner-container course-period-droppable ${color}`} data-courseperiodid={course_period_id} data-courseperiodname={period_name} data-dayindex={i}>{scheduleType === 'block' ? period_name : period_order}{scheduleType !== 'daily' && <FontAwesomeIcon className='ess-remove-course-period' icon={faTimes} onClick={removeCoursePeriod}/>}</div>
																			</div>
																		)
																	}
																	else
																	{
																		return (
																			<div className='ess-droppable-outer-container' key={i}>
																				<div className='ess-droppable-inner-container course-period-droppable schedule-structure-empty' data-courseperiodid='' data-courseperiodname='' data-dayindex={i}>[Drop Here]</div>
																			</div>
																		)
																	}
																})}
															</div>
														</div>
													)
												})}
											</div>
											{scheduleType === 'block' &&
												<div id='ess-possible-courses-container'>
													<h3 id='ess-possible-courses-header'>Courses</h3>
													{Object.keys(coursePeriods).map((course_period_id, i) => {
														let course_period_info = coursePeriods[course_period_id];
														const { period_name, currently_editing, color } = course_period_info;
														
														return (
															<div className={`grabbable course-period-draggable ${color}`} data-courseperiodid={course_period_id} data-courseperiodname={period_name} data-color={color} key={course_period_id}>
																{!currently_editing &&
																	<>
																	<div className='schedule-setup-course-period-dots'>
																		<FontAwesomeIcon className='ess-drag-dots white-text' icon={faEllipsisV}/>
																	</div>
																	<div className='schedule-setup-course-period-name' onDoubleClick={openEditCoursePeriod}>	
																		<div className='schedule-setup-course-period-name-text' onDoubleClick={openEditCoursePeriod}>{period_name}</div>
																		<FontAwesomeIcon className='schedule-setup-edit-course-period-name' icon={faEdit} onClick={openEditCoursePeriod}/>
																	</div>
																	</>
																}
																{currently_editing &&
																	<div className="schedule-setup-course-period-name-input-container">
																		<input className="schedule-setup-course-period-name-input" type="text" defaultValue={period_name} onKeyDown={saveCoursePeriodName} autoFocus={true} onFocus={handleFocus}/>
																		<div className='ess-edit-icon-container'>
																			<FontAwesomeIcon className='ess-edit-icon' icon={faCheck} onClick={saveCoursePeriodName}/>
																			<FontAwesomeIcon className='ess-edit-icon' icon={faTimes} onClick={cancelEditCoursePeriodName}/>
																		</div>
																	</div>
																}
															</div> 
														)
													})}
												</div>
											}
											</>
										)
										
									}
								</div>
							</div>
							<div className="error-message-no-box">{errorMessage}</div>
							<div className='fixed-bottom-save-container'>
								<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Submit Schedule Structure' passedLoading={errorMessage ? false : true} onClickFunction={submitStructure} />
							</div>
						</div>
					}
					</>
				)
			}
		</div>
	);
}