import React, { useState , useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import swal from '@sweetalert/with-react';

import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';

import Button from '../../Button';
import MagnetboardHSMS from '../MagnetboardHSMS';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle,faLock, faHourglassHalf, faTimes, faUserCircle, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { getJSONFromStorage, useOutsideClick} from '../../../js/Helpers';

import '../../../css/hsms/section_placement.css';

export default function ManualPlacements({ permissionChecked, schoolSubscribed, subscreenRestricted, subscreenLocked, subscreenResponsibleRoleID, subscreen, departmentsLoading, departments, departmentID, sortedDepartments, changeDepartment, userInfo, schoolInfo, schoolInfoLoading, scheduleInfo, scheduleInfoLoading, setScheduleInfo, scheduleStructureData, setScheduleStructureData, scheduleStructureDataLoading, lunchInfo, coursesLoading, courses, setCourses, teachersLoading, teachers, setTeachers, classroomsLoading, classrooms, studentsLoading, students, setStudents, sectionsLoading, sections, setSections, subsections, setSubsections, labels, setLabels, reviewing, toggleReviewDepartment, users, userIsAdmin, userIsSupervisor, supervisors, openSupervisorsList, closeSupervisorsList, addSupervisorToDepartment, removeSupervisorFromDepartment, subscreenSelect, submitDepartment, changesSavedMessage, setChangesSavedMessage, showNextButton, handleMoveToNextScreen, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo}) {
	const ref = useRef();

	const [isLoading, setIsLoading] = useState(true);

	const [departmentSections, setDepartmentSections] = useState([]);
	const [departmentTeacherIDs, setDepartmentTeacherIDs] = useState(['None']);
	const [departmentClassroomIDs, setDepartmentClassroomIDs] = useState(['None']);

	/////////////////////////////////
	///// SUPERVISORS FUNCTIONS /////
	/////////////////////////////////
	useOutsideClick(ref, () => {
		closeSupervisorsList();
	});

	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////
	useEffect(() => {
		if(departmentID) 
		{
			if(!sectionsLoading)
			{
				const department_sections = sections.filter(section => section.departments.includes(departmentID));
				setDepartmentSections([...department_sections]);
			}
		}
	}, [departmentID, teachersLoading, sectionsLoading]);
	
	useEffect(() => {
		if(permissionChecked && !coursesLoading && !classroomsLoading && !teachersLoading && !departmentsLoading) setIsLoading(false);
	}, [permissionChecked, coursesLoading, classroomsLoading, teachersLoading, departmentsLoading]);

	useEffect(() => {
		// Update subsections if sections change
		const new_subsections = sections.reduce((results, section) => {
			const section_id = section.section_id;
			const section_subsections = ('subsections' in section) ? section.subsections : [];
			if(section_subsections.length > 0)
			{
				section_subsections.forEach(subsection_id => {
					results.push({subsection_id:subsection_id, section_id:section_id});
				});
			}
	
			return results;
		}, []);

		setSubsections([...new_subsections]);
	}, [sections]);

	useEffect(() => {
		if(!teachersLoading)
		{
			const department_teacher_ids = teachers.reduce((result, teacher) => {
				const teacher_in_department = teacher.departments.includes(departmentID);
				const teacher_teaches_section_in_department = (departmentSections.findIndex(section => section.teacher_id === teacher.teacher_id) !== -1);

				if(teacher_in_department || teacher_teaches_section_in_department) result.push(teacher.teacher_id);

				return result;
			}, []);

			// Set initial teacher
			if(department_teacher_ids.length > 0)
			{
				setDepartmentTeacherIDs(department_teacher_ids);

				let current_teacher_id = getJSONFromStorage('current_teacher_id', false, 'session');
				const teacher_in_department_index = teachers.findIndex(teacher => teacher.teacher_id === current_teacher_id && teacher.departments.includes(departmentID));

				if(teacher_in_department_index === -1) current_teacher_id = department_teacher_ids[0];
			}
			else
			{
				setDepartmentTeacherIDs(['None']);
			}
		}

		if(!classroomsLoading)
		{
			const department_classroom_ids = classrooms.reduce((result, classroom) => {

				const classroom_in_department = classroom.departments.includes(departmentID);
				const classroom_has_section_in_department = (departmentSections.findIndex(section => section.classroom_id === classroom.classroom_id) !== -1);

				if(classroom_in_department || classroom_has_section_in_department) result.push(classroom.classroom_id);

				return result;
			}, []);

			if(department_classroom_ids.length > 0) 
			{
				setDepartmentClassroomIDs(department_classroom_ids);
			}
			else
			{
				setDepartmentClassroomIDs(['None']);
			}
		}
	}, [departmentSections, teachersLoading, classroomsLoading]);

	useEffect(() => {
		if(!departmentID || !userInfo || !users || !departments) return;

		const department_index = departments.findIndex(department => department.department_id === departmentID);
		const department_info = (department_index !== -1) ? departments[department_index] : null;
		const department_supervisors = (department_info) ? department_info.supervisors : [];

		const available_users = users.filter(user => (user.role_id === '1' || user.role_id === '2') || department_supervisors.findIndex(supervisor => supervisor.user_id === user.user_id) !== -1);

		setScreenAllowsUniversalSidebar(true);
		setUniversalSidebarLoadingInfo({sidebar_name:"Manual Placement Notes", sidebar_type:'notes', subscreen:'manual_placements', department_id:departmentID, user_id:userInfo.user_id, available_users:available_users, help_link_path:'/app/hsms/documentation/section_placement/manual_placements'});
	}, [departmentID, userInfo, users, departments]);
	
	//console.log(multipleDepartments);
	// console.log(departments);
	// console.log({departmentID});
	//console.log(userDepartments);
	//console.log({departmentTeacherIDs, departmentClassroomIDs});
	//console.log(currentTeacherUnassignedSections);

	const department_index = departments.findIndex(department => department.department_id === departmentID);
	const department_info = (department_index !== -1) ? departments[department_index] : null;
	const supervisor_index = (department_info) ? department_info.supervisors.findIndex(supervisor => supervisor.user_id === userInfo.user_id) : null;
	const supervisor_info = (department_info) ? department_info.supervisors[supervisor_index] : null;
	const subscreen_info = (department_info) ? department_info.subscreens[subscreen] : null;
	
	const current_department = (department_info) ? department_info.department : null;
	const current_screen_submitted = (subscreen_info) ? subscreen_info.submitted : false;
	const current_screen_confirmed = (subscreen_info) ? subscreen_info.confirmed : false;
	const current_screen_unlocked= (subscreen_info) ? subscreen_info.unlocked : false;
	const current_user_can_add_supervisors = (userInfo.role_id === '1'|| userInfo.role_id === '2');
	const current_user_has_permissions = (supervisor_info) ? (supervisor_info.allow_manual_placements === '1') : false;
	
	const show_current_screen = (department_info) ? (current_screen_unlocked && (reviewing || (userIsSupervisor && current_user_has_permissions) || userIsAdmin)) : false;
	const screen_complete_for_user = (department_info) ? ((current_screen_confirmed) || (!userIsAdmin && current_screen_submitted)) : false;

	return (
		<div>
			<h1 className='progress-main-header'>Manual Placements</h1>
			<p className='progress-subheader'>Use this step to manually place any sections. Any sections that are not manually placed will be auto-placed by Edario afterwards!</p>
			{isLoading ? 
			(
				<div className='center-text'>
					<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
				</div>
			):(subscreenRestricted) ?
			(
				<div className='general-screen-message'>
					<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
					<h1>No Access</h1>
					<div>You do not have access to this screen</div>	
				</div>
			):(subscreenLocked) ?
			(
				<div className='general-screen-message'>
					<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
					<h1>Temporarily Locked</h1>
					<div>This screen is locked until all other prior steps are completed</div>	
				</div>
			):(!departmentID) ? 
			(
				<div className='progress-choose-department-screen'>
				<div className='progress-choose-department-heading'>Choose a department to work on:</div>
				{(subscreenResponsibleRoleID === '3') &&
					<div className='progress-choose-department-container'>
						{sortedDepartments.map((department_info, j) => {
							const department_id = department_info.department_id;
							const department_name = department_info.department;

							const department_submitted = department_info.subscreens[subscreen].submitted;
							const department_confirmed = department_info.subscreens[subscreen].confirmed;
							const department_unlocked = department_info.subscreens[subscreen].unlocked;

							const user_is_supervisor = (userIsAdmin || department_info.supervisors.findIndex(supervisor => supervisor.user_id === userInfo.user_id) !== -1);

							const text_color = (!department_unlocked) ? 'gray' : ((department_confirmed) ? 'green' : ((department_submitted) ? 'yellow' : 'gray'));
							const status = (!department_unlocked) ? 'Locked' : ((department_confirmed) ? 'Complete' : ((department_submitted) ? 'Awaiting Confirmation' : 'Incomplete'));
							const status_icon = (!department_unlocked) ? faLock : faCheckCircle;
							const button_text = (!department_unlocked) ? '' : ((department_confirmed) ? 'Review' : ((department_submitted) ? 'Continue' : 'Start'));

							const last_unfinished_department_subscreen = (department_unlocked) ? null : Object.keys(department_info.subscreens).reduce((results, subscreen) => {
								const subscreen_progress_info = department_info.subscreens[subscreen];
								const subscreen_screen = (subscreen_progress_info) ? subscreen_progress_info.screen : null;
								const subscreen_order = (subscreen_progress_info) ? parseInt(subscreen_progress_info.subscreen_order) : 999;
								const subscreen_submitted = (subscreen_progress_info) ? subscreen_progress_info.submitted : false;
								const subscreen_confirmed = (subscreen_progress_info) ? subscreen_progress_info.confirmed : false;
								
								const screen_complete_for_user = ((subscreen_confirmed) || (userIsSupervisor && subscreen_submitted));

								if(!screen_complete_for_user && subscreen_order < results.logged_min)
								{
									results.last_screen = subscreen_screen;
									results.last_subscreen = subscreen;
									results.logged_min = subscreen_order;
								}

								return results;
							}, {last_screen: null, last_subscreen: null, logged_min:999});


							return (
								<div className={`progress-choose-department-row  ${!user_is_supervisor && 'opacity-50'}`} key={j}>
									<div className='progress-choose-department-name'>{department_name}</div>
									<div className={`progress-choose-department-status ${text_color}-text`}>
										<div>{status}</div> 
										<FontAwesomeIcon className='progress-choose-department-status-icon' icon={status_icon}/>
									</div>
									<div className='small-text text-align-right'>
										{department_unlocked ?
											(
												<div className={`progress-choose-department-btn btn ${text_color}-btn align-right`} onClick={() => changeDepartment(department_id)}>{button_text}</div>
											):
											(
												<div>
													<div>This screen is locked because there is a previously unfinished screen.</div>
													<div style={{marginTop:'3px'}} className='blue-link full-width' onClick={()=>subscreenSelect(last_unfinished_department_subscreen.last_subscreen, last_unfinished_department_subscreen.last_screen, department_id)}>Go to Last Unfinished Step</div>
												</div>
											)
										}
									</div>
								</div>
							)
						})}
					</div>
				}
			</div>
			):(!userIsSupervisor) ?
			(
				<div className='section-creation-screen-message'>
					<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
					<h1>No Access</h1>
					<div>You do not have access to change this department</div>	
				</div>
			):(
				<>
				{departmentID &&
					<>
					<div id='manual-placement-container'>
						<div id='manual-placement-top-bar-container'>
							<div className='manual-placement-top-bar-inner-container'>
								<div className='department-supervisor-parent-container' style={{margin:'10px 0px -5px 0px'}}>
									<>
									{department_info.supervisors.length === 0 &&
										<>
										<div className='department-supervisor-inner-container'>
											<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
											<div className='department-supervisor-name'>No Supervisor Assigned</div>
										</div>
										</>
									}
									{department_info.supervisors.map((supervisor, index) => {
										return (
											<div className='department-supervisor-outer-container' key={index}>
												<div className='department-supervisor-inner-container'>
													{supervisor.img_url &&
														<img className='department-supervisor-img' src={require(`../../../images/users/${supervisor.img_url}`)} alt='user' />
													}
													{!supervisor.img_url &&
														<FontAwesomeIcon className='department-supervisor-user-icon gray-text' icon={faUserCircle}/>
													}
													<div className='department-supervisor-name'>{supervisor.last_name}, {supervisor.first_name}</div>
													{current_user_can_add_supervisors &&
														<FontAwesomeIcon className='gray-text-hover' icon={faTimes} onClick={() => removeSupervisorFromDepartment(supervisor.user_id)}/>
													}
												</div>
											</div>
										)
									})}
									{current_user_can_add_supervisors &&
										<>
										<div className={`section-placement-add-supervisor small-text click-restricted ${(department_info.supervisors.length === 0) ? 'blue-link' : 'gray-to-blue-link'}`} onClick={() => openSupervisorsList(departmentID)}>Add a Supervisor</div>
										<div className='supervisor-options-container'>
											{department_info.supervisors_open &&
												<div className='data-options-container' ref={ref}>
													{supervisors.map((supervisor_info, index) => {
														
														return (
															<div className='data-options-option assign-resourses-supervisor-option' key={index} onClick={() => addSupervisorToDepartment(supervisor_info)}>
																{supervisor_info.img_url &&
																	<img className='department-supervisor-img' src={require(`../../../images/users/${supervisor_info.img_url}`)} alt='supervisor' />
																}
																{!supervisor_info.img_url &&
																	<FontAwesomeIcon className='department-supervisor-user-icon' icon={faUserCircle}/>
																}
																<div className='department-supervisor-name'>{supervisor_info.last_name}, {supervisor_info.first_name}</div>
															</div>
														)	
													})}
													<Link className='data-options-option blue-text' to={{pathname:`/app/settings/users`, redirect:`/app/progress/section_creation`}} style={{borderTop:'1px solid #efefef'}} onClick={() => closeSupervisorsList(departmentID)}>Add new Supervisor</Link>
												</div>
											}
										</div>
										</>
									}
									</>
								</div>
							</div>
							<div id='section-placement-submit-btn-container'>
									{(!screen_complete_for_user && show_current_screen) &&
										<Button key={departmentID} classes={['mark-as-complete-btn']} selected={false} text={`${!current_screen_submitted ? 'Mark As Complete' : 'Confirm Department'}`} icon={faCheck} iconPosition='left' onClickFunction={() => submitDepartment(departmentID)} />
									}
									{changesSavedMessage && 
										<div className='all-changes-saved-message' style={{margin:'20px 0px 0px 0px'}}>
											<FontAwesomeIcon className='green-text' icon={faCheckCircle}/>
											<div>
												<h5>Changes Saved</h5>
												<div className='small-text'>All your changes have been saved</div>
											</div>
											<FontAwesomeIcon className='x-remove' icon={faTimes} onClick={() => setChangesSavedMessage(false)}/>
										</div>
									}
								</div>
						</div>
						{!current_screen_unlocked ?
							(
								<div className='section-placement-screen-message'>
									<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
									<h1>Locked</h1>
									<div>This department has previous steps which have not been completed. Please review previous steps and make sure they are completed and saved. This page should open automatically once all previous steps have been finished.</div>
									<div className='blue-link' style={{marginTop:'30px'}} onClick={()=>changeDepartment(null)}>Go back to Departments</div>
								</div>
							):(userIsAdmin && current_screen_unlocked && !show_current_screen && !current_screen_submitted && !department_info.current_user_is_supervisor) ?
							(
								<div className='assign-resources-screen-message'>
									<FontAwesomeIcon className='blue-text' style={{fontSize:'60px'}} icon={faHourglassHalf}/>
									<h1>Pending...</h1>
									<div>Waiting on confirmation from department supervisor</div>
									<p className='blue-link' onClick={toggleReviewDepartment}>Review department yourself</p>	
								</div>
							): (show_current_screen) ?
							(
								<div className='section-placement-content'>
									<div>
										<FontAwesomeIcon icon={faChevronLeft} className='progress-back-to-departments-btn' onClick={()=>changeDepartment(null)} style={{marginTop:'45px'}}/>
									</div>
									<div className='section-placement-content-inner-content'>
										<MagnetboardHSMS schoolSubscribed={schoolSubscribed} schoolInfoLoading={schoolInfoLoading} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} scheduleStructureData={scheduleStructureData} setScheduleStructureData={setScheduleStructureData} scheduleInfoLoading={scheduleInfoLoading} scheduleStructureDataLoading={scheduleStructureDataLoading} userInfo={userInfo} departmentsLoading={departmentsLoading} departments={departments} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} classrooms={classrooms} studentsLoading={studentsLoading} students={students} setStudents={setStudents} sectionsLoading={sectionsLoading} sections={sections} setSections={setSections} subsections={subsections} setSubsections={setSubsections} lunchInfo={lunchInfo} labels={labels} setLabels={setLabels} magnetboardOptions={{show_top_bar:true, allow_schedule_name_editing:false, allow_filtering:false, show_match_percent:false, show_conflicts:true, show_menu:false, allow_new_sections:true, allow_placeholders:false, allow_hotlinks:false, current_department_id:departmentID, current_department:current_department, allowed_teacher_ids:departmentTeacherIDs, allowed_classroom_ids:departmentClassroomIDs, view_options:[{value:'teacher', display:'Teacher View'}, {value:'classroom', display:'Classroom View'}]}}/>
									</div>
								</div>
							): null
						}
					</div>
					</>
				}
				{showNextButton &&
					<div className='fixed-bottom-save-container'>
						{userIsAdmin ?
							(
								<Button classes={['btn', 'btn-extra-large', 'green-btn', 'align-right', 'move-to-next-screen-btn']} selected={false} text='Go to Next Step: Placement Review' onClickFunction={handleMoveToNextScreen} />
							):
							(
								<Link className="next_clean_step supervisor" to={`/progress`}>Save & Continue</Link>
							)	
						}
					</div>
				}
				</>
			)}
		</div>
	);
}