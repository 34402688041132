import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faLock } from '@fortawesome/free-solid-svg-icons';

import genesis_img from '../../../images/genesis_logo.jpg';
import realtime_img from '../../../images/realtime_logo.jpg';
import oncourse_img from '../../../images/oncourse_logo.png';
import powerschool_img from '../../../images/powerschool_logo.jpg';
import skyward_img from '../../../images/skyward_logo.jpg';
import infinite_campus_img from '../../../images/infinite_campus_logo.jpg';

import '../../../css/choose_sis.css';

export default function ChooseSIS({ subscreenRestricted, subscreenLocked, schoolName, currentSIS, handleSelectSIS }) {
	const sis_list = [
		{sis:'genesis', name: 'Genesis', img:genesis_img}, 
		{sis:'realtime', name: 'Realtime', img:realtime_img}, 
		{sis:'oncourse', name: 'OnCourse', img:oncourse_img}, 
		{sis:'powerschool', name: 'Powerschool', img:powerschool_img}, 
		{sis:'skyward', name: 'Skyward', img:skyward_img}, 
		{sis:'infinite_campus', name: 'Infinite Campus', img:infinite_campus_img}];
	
	const onSISSelect = (sis) => {
		const data = {sis: sis};
		
		handleSelectSIS(data);
	}
	
	return (
		<>	
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):
		(
			<>
			<h1 className='progress-main-header'>Choose SIS</h1>
			<p className='progress-subheader'>We can integrate with the most commonly used student information systems.</p>
			<h2 style={{margin:'10px 0px 30px 0px'}}>Which system does {schoolName} currently use?</h2>
			<div id="management_systems_container">
				{sis_list.map((sis, i) => {
					return (
						<div className={`management_system_container ${currentSIS === sis.sis ? 'management_system_selected' : ''}`} onClick={() => onSISSelect(sis.sis)} key={i}>
							{currentSIS === sis.sis &&
								<FontAwesomeIcon className='choose-sis-check green-text' icon={faCheckCircle}/>
							}
							<img className="management_system_logo" src={sis.img} alt={`${sis.sis}`}/>
							<p className="management_system_name capitalize">{sis.name}</p>
						</div>
					)
				})}
			</div>
			</>
		)}
		</>
	);
}