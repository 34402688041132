import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { FileUpload } from '../../../js/upload';

import { checkConflicts } from '../../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

export default function FillSections({ schoolSubscribed, subscreenRestricted, subscreenLocked, isLoading, setIsLoading, schoolInfo, scheduleInfo, userInfo, showNextButton, handleMoveToNextScreen, setLoadScreen }) {
	const [studentPlacementsHaveStarted, setStudentPlacementsHaveStarted] = useState(false);
	const [studentPlacementsFinished, setStudentPlacementsFinished] = useState(false);

	const placeStudents = async () => {
		setStudentPlacementsHaveStarted(true); // switch screen to show that placements have started

		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/place-students';
		const myInit = { // OPTIONAL
			response: true,
			queryStringParameters: data,
		};
	
		try {
			await API.get(apiName, url, myInit);
			
			const fileUpload = new FileUpload(schoolInfo.current_data_version_id, scheduleInfo.school_id);
			await fileUpload.checkUploading('studentplace', true, (event) => {
				if(event.done) 
				{
					if(event.failure) 
					{
						setStudentPlacementsHaveStarted(false);
						setStudentPlacementsFinished(false);
					} 
					else 
					{
						setStudentPlacementsFinished(true);

						const conflicts_to_check = ['course_exceeds_capacity', 'teacher_student_restriction', 'student_student_restriction', 'student_double_booked', 'student_conflicting_periods'];
						const update_all = true;

						checkConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, update_all:update_all});
					}
					
					setIsLoading(false);
				}
			});
		} catch(error)
		{
			console.log(error.response);
		}
	}

	const current_user_is_admin = (userInfo.role_id === '1'|| userInfo.role_id === '2' || userInfo.role_id === '5');
	
	return (
		<div className="school-setup-main-container">
			<h1 id='schedule-type-main-header'>Let&rsquo;s place students into sections</h1>
			<p id='schedule-type-subheader'>This is the final step in your schedule! We will now fill sections with students and show you the match result.</p>
			{isLoading ? 
			(
				<div className='center-text'>
					<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
				</div>
			): (subscreenRestricted) ?
			(
				<div className='general-screen-message'>
					<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
					<h1>No Access</h1>
					<div>You do not have access to this screen</div>	
				</div>
			):(subscreenLocked) ?
			(
				<div className='general-screen-message'>
					<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
					<h1>Temporarily Locked</h1>
					<div>This screen is locked until all other prior steps are completed</div>	
				</div>
			):(!schoolSubscribed) ? 
			(
				<div className='section-placement-screen-message' style={{maxWidth:'600px'}}>
					<img style={{height:'80px'}} src={require("../../../images/fill-students.png")} alt='Place students'/>
					<h2>Place Students into Sections</h2>
					<div>In this step, we will place students into sections, going through millions of different combinations to maximize the number of requests fulfilled!</div>
					<div style={{padding:'20px', backgroundColor:'#efefef', border:'2px solid #ddd', margin:'20px 0px 20px 0px', borderRadius:'10px'}}>
						<div>You need a paid subscription for this feature.</div>
						<div style={{margin:'6px 0px 0px 0px'}}>But don't let that stop you-- you can still skip this step and keep working!</div>	
						<div style={{fontSize:'18px', margin:'45px 0px 0px 0px'}}><strong>Please contact us at contact@edario.com to get full access in Edario.</strong></div>
					</div>
					<Link className="blue-link align-right" style={{maxWidth:'250px', margin:'0px 0px -30px 0px'}} to={`/app/magnetboard`} onClick={() => setLoadScreen('/app/magnetboard')}>Skip to Next Step</Link>
				</div>
			):((studentPlacementsHaveStarted && !studentPlacementsFinished) || (!studentPlacementsHaveStarted && !studentPlacementsFinished && userInfo.role_id === '3')) ? (
				<div className='placements-in-progress-container'>
					<div id='custom-section-creation-loader-container'>
						<div className='custom-section-creation-loader-column'>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top pastel-orange'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top pastel-orange'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
						</div>
						<div className='custom-section-creation-loader-column'>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top yellow'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top yellow'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top yellow'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
						</div>
						<div className='custom-section-creation-loader-column'>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top pastel-green'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
						</div>
						<div className='custom-section-creation-loader-column'>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top pastel-light-blue'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
							<div className='custom-section-creation-loader-row'>
								<div className='custom-section-creation-loader-row-top pastel-light-blue'></div>
								<div className='custom-section-creation-loader-row-bottom'></div>
							</div>
						</div>
					</div>
					<h2>Students are being placed...</h2>
					<div>We are currently placing students into sections. Please check back shortly to review your schedule!</div>
				</div>
			): (!studentPlacementsHaveStarted && !studentPlacementsFinished && current_user_is_admin) ? 
			(
				<div className='fill-sections-screen-message' style={{maxWidth:'600px'}}>
					<img style={{height:'80px'}} src={require("../../../images/fill-students.png")} alt='Place students'/>
					<h2>Start placing students!</h2>
					<div>All sections have been placed and approved. You are now ready to add students into the schedule!</div>
					<div id='fill-sections-btn' className='btn green-btn' onClick={placeStudents}>Place Students</div>
				</div>
			): (
				<>
				<div className='fill-sections-screen-message' style={{maxWidth:'600px'}}>
					<img style={{height:'80px'}} src={require("../../../images/complete.png")} alt='Schedule complete'/>
					<h2>Your schedule is complete!</h2>
					<div>It will probably still need some editing, so click Go To Magnetboard to revising your schedule.</div>
				</div>
				{showNextButton &&
					<div className='fixed-bottom-save-container'>
						<Link className="next_clean_step supervisor" to={`/app/magnetboard`} onClick={() => setLoadScreen('magnetboard')}>Go to Magnetboard</Link>
					</div>
				}
				</>
			)}
		</div>
	);
}