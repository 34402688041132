import React, { useState , useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSquare, faCheckSquare, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { createCommaSeparatedListFromArray, copyPreviousYearData } from '../js/Helpers';

import '../css/switch_years.css';

export default function NewYearModal({selectedScheduleYearID, schoolInfo, scheduleInfo, closeModal, callBack=()=>{}}) {
	////////////////////////////////
	///// SETTINGS/CONSTRAINTS /////
	////////////////////////////////
	const data_settings = (schoolInfo.type === '2') ? [
		{value:'teachers', display:'Teachers'}, 
		{value:'courses', display:'Courses'}, 
		{value:'classrooms', display:'Classrooms'}, 
		{value:'schedule_structure', display:'Periods'}, 
		{value:'blocks', display:'Blocks'}
	] : 
	[
		{value:'teachers', display:'Teachers'}, 
		{value:'courses', display:'Courses'}, 
		{value:'classrooms', display:'Classrooms'}, 
		{value:'departments', display:'Departments'}, 
		{value:'teacher_departments', display:'Teacher Departments', prereqs:['teachers', 'departments']}, 
		{value:'course_departments', display:'Course Departments', prereqs:['courses', 'departments']}, 
		{value:'classroom_departments', display:'Classroom Departments', prereqs:['classrooms', 'departments']}, 
		{value:'department_supervisors', display:'Department Supervisors', prereqs:['departments']}, 
		{value:'schedule_structure', display:'Schedule Structure'}, 
		{value:'sections', display:'Sections'},
		{value:'section_placements', display:'Section Placements', prereqs:['schedule_structure', 'sections']}, 
		{value:'section_labels', display:'Section Labels', explanation:'This would copy over any section labels you created. This could be anything from a "Bilingual" label to a "Team" label.', prereqs:['sections']}];

	const data_constraints = (schoolInfo.type === '2') ? [
		{value:'teacher_availability', display:'Teacher Availability', explanation:'This would copy over any rules about what periods teachers can/cannot teach.', prereqs:['teachers', 'blocks', 'schedule_structure']},
		{value:'course_availability', display:'Course Availability', explanation:'This would copy over any rules about what periods courses can/cannot run.', prereqs:['courses', 'blocks', 'schedule_structure']},
		{value:'course_options', display:'Course Options', explanation:'This would copy over any settings about course length (semester vs full year), type of course (lunch, special, or pullout), and number of times a special/pullout is needed', prereqs:['courses']},
		{value:'homeroom_specials', display:'Homeroom Specials', explanation:'This would copy over any info about which homerooms need certain specials', prereqs:['courses', 'teachers']},
		{value:'course_teachers', display:'Course Teachers', explanation:'This would copy over any settings about what courses teachers can teach.', prereqs:['courses', 'teachers']},
		{value:'course_classrooms', display:'Course Classrooms', explanation:'This would copy over any rules about whether courses need to be in a specific classroom (like Music needs to be in a Music room).', prereqs:['courses', 'classrooms']},
		{value:'teacher_classrooms', display:'Teacher Classrooms', explanation:"This would copy over any rules about teachers' classrooms.", prereqs:['teachers', 'classrooms']}
	] : 
	[
		{value:'teacher_availability', display:'Teacher Availability', explanation:'This would copy over any rules about what periods teachers can/cannot teach.', prereqs:['teachers', 'schedule_structure']},
		{value:'course_availability', display:'Course Availability', explanation:'This would copy over any rules about what periods courses can/cannot run.', prereqs:['courses', 'schedule_structure']},
		{value:'course_caps', display:'Course Caps', explanation:'This would copy over any rules about max number of sections and max number of students per course.', prereqs:['courses']},
		{value:'course_options', display:'Course Options', explanation:'This would copy over any settings about course length (semester vs full year), course type (elective vs non-elective), and course span (1 period, 2 periods, etc).', prereqs:['courses']},
		{value:'course_other_options', display:'Course Other Options', explanation:'This would copy over if a course is flagged as a lunch, inclusion, lab, or virtual course.', prereqs:['courses']},
		{value:'course_teachers', display:'Course Teachers', explanation:'This would copy over any settings about what courses teachers can teach.', prereqs:['courses', 'teachers']},
		{value:'classroom_details', display:'Classroom Details', explanation:'This would copy over any settings about classroom types (Chem lab, Media room, etc) and max classroom capacities.', prereqs:['classrooms']},
		{value:'course_classrooms', display:'Course Classrooms', explanation:'This would copy over any rules about whether courses need to be in a specific classroom (like Chem needs to be in a Chem lab).', prereqs:['courses', 'classrooms']},
		{value:'teacher_classrooms', display:'Teacher Classrooms', explanation:"This would copy over any rules about teachers' preferred classrooms.", prereqs:['teachers', 'classrooms']}
	];

	/////////////////
	///// STATE /////
	/////////////////
	const [currentScreen, setCurrentScreen] = useState(1);
	const [settings, setSettings] = useState(null);
	const [schoolHasOnlyOneScheduleVersionID, setSchoolHasOnlyOneScheduleVersionID] = useState(false);
	const [constraintExplanationOpen, setConstraintExplanationOpen] = useState([]);
	const [errorMessage, setErrorMessage] = useState(null);

	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const handleCloseModal = () => {
		closeModal();
	}

	const handleMoveToNextPage = () => {
		// Collect all the necessary checks
		// Check if, user wants sections rolled over
		// Check if, any constraints are set
		const schedule_version_set = (settings.schedule_version_ids_to_copy.length > 0);

		const data_cleaning_constraints_are_set = data_constraints.reduce((bool, constraint) => {
			const constraint_value = constraint.value;
			if(settings[constraint_value]) bool = true;
			return bool;
		}, false);

		const constraint_that_needs_schedule_version_is_set = (settings.departments || settings.department_supervisors || settings.schedule_structure || settings.sections || data_cleaning_constraints_are_set);

		// If no sections or constraints need to be rolled over, then remove schedule version id (if it's been set),and skip the screen
		// Otherwise, if sections or constraints ARE set, and there is only one schedule
		if(currentScreen === 2)
		{
			if(!constraint_that_needs_schedule_version_is_set)
			{
				settings.schedule_version_ids_to_copy = [];
				setSettings({...settings});
			}
			
			if(!constraint_that_needs_schedule_version_is_set || schoolHasOnlyOneScheduleVersionID)
			{
				setCurrentScreen(4);
				handleCopyData();
				return false;
			}
			
		}
		else if(currentScreen === 3 && !schedule_version_set)
		{
			if(settings.departments || settings.department_supervisors || settings.schedule_structure || settings.sections)
			{
				setErrorMessage("Sorry if you want to rollover your departments, supervisors, schedule structure, or sections you need to select at least one schedule to roll them over from.");
			}
			else if(data_cleaning_constraints_are_set)
			{
				setErrorMessage("Sorry if you want to rollover your rules/constraints you need to select at least one schedule to roll them over from.");
			}
			
			return false;
		}

		setCurrentScreen(currentScreen + 1);
	}

	const handleMoveToPrevPage = () => {
		setCurrentScreen(currentScreen - 1);
	}


	const toggleSettings = (setting_type, schedule_version_id = null) => {
		const current_setting = settings[setting_type];

		if(setting_type === 'schedule_version_id') 
		{
			const schedule_version_index = settings.schedule_version_ids_to_copy.findIndex(temp_schedule_version_id => temp_schedule_version_id === schedule_version_id);

			if(schedule_version_index !== -1)
			{
				settings.schedule_version_ids_to_copy.splice(schedule_version_index, 1);
			}
			else
			{
				settings.schedule_version_ids_to_copy.push(schedule_version_id);
			}
		}
		else 
		{
			settings[setting_type] = !current_setting;
		}

		// Check if prereqs are met for every setting
		// If not set as false in settings
		data_settings.forEach(data_setting => {
			const data_setting_val = data_setting.value;
			const setting_prereqs = data_setting.prereqs;
			const all_prereqs_met = (!setting_prereqs) ? true : setting_prereqs.reduce((result, prereq) => {
				if(!settings[prereq]) result = false;
				return result;
			}, true);

			if(!all_prereqs_met) settings[data_setting_val] = false;
		});

		// Check if prereqs are met for every constraint
		// If not set as false in settings
		data_constraints.forEach(data_constraint => {
			const data_constraint_val = data_constraint.value;
			const constraint_prereqs = data_constraint.prereqs;
			const all_prereqs_met = (!constraint_prereqs) ? true : constraint_prereqs.reduce((result, prereq) => {
				if(!settings[prereq]) result = false;
				return result;
			}, true);

			if(!all_prereqs_met) settings[data_constraint_val] = false;
		});

		setSettings({...settings})
	}

	const toggleConstraintExplanation = (constraint) => {
		const constraint_index = constraintExplanationOpen.findIndex(temp_constraint => temp_constraint === constraint);

		if(constraint_index !== -1)
		{
			constraintExplanationOpen.splice(constraint_index, 1);
		}
		else
		{
			constraintExplanationOpen.push(constraint);
		}

		setConstraintExplanationOpen([...constraintExplanationOpen]);
	}

	const handleCopyData = async () => {
		const database = (schoolInfo.type === '2') ? 'elementary' : 'edario';

		const passed_data = {school_id:schoolInfo.school_id, selected_schedule_year_id:selectedScheduleYearID, last_subscribed_data_version_id:schoolInfo.last_subscribed_data_version_id, settings:settings, new_year:false, database:database};

		await copyPreviousYearData(passed_data);
		closeModal();
		callBack(passed_data);
	}

	//////////////////////
	///// USE EFFECT /////
	//////////////////////
	useEffect(() => {
		let temp_settings = null;
		const school_type = schoolInfo.type;

		if(school_type === '2')
		{
			temp_settings = {
				schedule_version_ids_to_copy: [],
				teachers: true,
				courses: true,
				classrooms: true,
				schedule_structure: true,
				blocks: false,
				teacher_availability: true,
				course_availability: true,
				course_options: true,
				homeroom_specials: true,
				course_teachers: true,
				course_classrooms: true,
				teacher_classrooms: true}
		}
		else 
		{
			temp_settings = {
				schedule_version_ids_to_copy: [],
				courses: true,
				teachers: true,
				classrooms: true,
				departments: true,
				teacher_departments: true,
				course_departments:true,
				classroom_departments:true,
				department_supervisors: true,
				schedule_structure: true,
				sections: false,
				section_placements:false,
				section_labels:false,
				teacher_availability: true,
				course_availability: true,
				course_caps: true,
				course_options: true,
				course_other_options: true,
				course_teachers: true,
				classroom_details: true,
				course_classrooms: true,
				teacher_classrooms: true
			}
		}

		// If there is only one schedule version from the selected year, set it as the selected schedule version id
		const schedules_from_current_schedule_year = scheduleInfo.schedules.filter(schedule => schedule.data_version_id === schoolInfo.current_data_version_id);
		if(schedules_from_current_schedule_year.length === 1)
		{
			const schedule_version_id = schedules_from_current_schedule_year[0].schedule_version_id;
			temp_settings.schedule_version_ids_to_copy = [schedule_version_id];
			
			setSchoolHasOnlyOneScheduleVersionID(true);
		}

		setSettings(temp_settings);
	}, []);

	////////////////////////////////
	///// INITIALIZE VARIABLES /////
	////////////////////////////////
	const schedules_from_current_schedule_year = scheduleInfo.schedules.filter(schedule => schedule.data_version_id === schoolInfo.current_data_version_id);

	return (
		<div className='modal'>
			<div id='switch-years-modal-content' className='modal-content'>
				{currentScreen !== 4 &&
					<>
						<span className="modal-close-right" onClick={handleCloseModal}>×</span>
						<h1 id='switch-years-modal-title'>Start A New Year</h1>
					</>
				}
				<div className='switch-years-page'>
					{(currentScreen === 1) ?
						(
							<>
							<div className='medium-text'>Congrats, as a repeat user of Edario a lot of your work from last year can be reused!</div>
							<h4 className='switch-years-subtitle'>Tell us what data you would like to copy over:</h4>
							{settings && data_settings.map((data_setting, i) => {
								const data_type = data_setting.value;
								const setting_title = data_setting.display;
								const explanation_open = constraintExplanationOpen.includes(data_type);

								let missing_prereqs = [];
								const setting_prereqs = data_setting.prereqs;
								const all_prereqs_met = (!setting_prereqs) ? true : setting_prereqs.reduce((result, prereq) => {
									if(!settings[prereq])
									{
										result = false;
										
										// Find prerequisite display so you can show a list of missing prereqs
										const setting_index = data_settings.findIndex(data_setting => data_setting.value === prereq);
										const setting_info = (setting_index !== -1) ? data_settings[setting_index] : null;
										const setting_display = (setting_info) ? setting_info.display : null;
										if(setting_display) missing_prereqs.push(setting_display);
									}
									return result;
								}, true);
								const missing_prereqs_string = createCommaSeparatedListFromArray(missing_prereqs);

								return (
									<React.Fragment key={i}>
										<div className={`switch-years-schedule-select-container ${!all_prereqs_met && 'switch-years-schedule-select-container-disabled'}`}>
											<div onClick={all_prereqs_met ? ()=>toggleSettings(data_type): null}>
												{settings[data_type] ?
													(
														<FontAwesomeIcon className='switch-years-schedule-select-box fas-checkbox-checked' icon={faCheckSquare}/>
													):
													(
														<FontAwesomeIcon className='switch-years-schedule-select-box fas-checkbox-unchecked dark-gray-text' icon={faSquare}/>
													)
												}
											</div>
											
											<div className='capitalize'>{setting_title}</div>
											{'explanation' in data_setting && <FontAwesomeIcon icon={faInfoCircle} onClick={()=>toggleConstraintExplanation(data_type)}/>}
										</div>
										{!all_prereqs_met &&
											<div className='switch-years-constraint-explanation red-text'>This option is not available because you are not rolling over {missing_prereqs_string}.</div>
										}
										{explanation_open &&
											<div className='switch-years-constraint-explanation'>{data_setting.explanation}</div>
										}
									</React.Fragment>
								)
							})}
							<div className='switch-years-btn-container' style={{gridTemplateColumns:'1fr'}}>
								<div className='btn green-btn btn-large' onClick={handleMoveToNextPage}>Next</div>
							</div>
							</>
						):(currentScreen === 2) ?
						(
							<>
							<h4 className='switch-years-subtitle'>Which scheduling rules/constraints would you like to copy over:</h4>
							{settings && data_constraints.map((data_constraint, i) => {
								const data_type = data_constraint.value;
								const constraint_display = data_constraint.display;
								const explanation_open = constraintExplanationOpen.includes(data_type);

								let missing_prereqs = [];
								const constraint_prereqs = data_constraint.prereqs;
								const all_prereqs_met = (!constraint_prereqs) ? true : constraint_prereqs.reduce((result, prereq) => {
									if(!settings[prereq]) 
									{
										result = false;

										// Find prerequisite display so you can show a list of missing prereqs
										const setting_index = data_settings.findIndex(data_setting => data_setting.value === prereq);
										const setting_info = (setting_index !== -1) ? data_settings[setting_index] : null;
										const setting_display = (setting_info) ? setting_info.display : null;
										if(setting_display) missing_prereqs.push(setting_display);
									}
									return result;
								}, true);

								const missing_prereqs_string = createCommaSeparatedListFromArray(missing_prereqs);

								return (
									<React.Fragment key={i}>
										<div className={`switch-years-schedule-select-container ${!all_prereqs_met && 'switch-years-schedule-select-container-disabled'}`}>
											<div onClick={all_prereqs_met ? ()=>toggleSettings(data_type) : null}>
												{settings[data_type] ?
													(
														<FontAwesomeIcon className='switch-years-schedule-select-box fas-checkbox-checked' icon={faCheckSquare}/>
													):
													(
														<FontAwesomeIcon className='switch-years-schedule-select-box fas-checkbox-unchecked dark-gray-text' icon={faSquare}/>
													)
												}
											</div>
											<div className='capitalize'>{constraint_display}</div>
											<FontAwesomeIcon icon={faInfoCircle} onClick={()=>toggleConstraintExplanation(data_type)}/>
										</div>
										{!all_prereqs_met &&
											<div className='switch-years-constraint-explanation red-text'>This option is not available because you are not rolling over {missing_prereqs_string} in the previous step.</div>
										}
										{explanation_open &&
											<div className='switch-years-constraint-explanation'>{data_constraint.explanation}</div>
										}
									</React.Fragment>
								)
							})}
							<div className='switch-years-btn-container' style={schoolHasOnlyOneScheduleVersionID ? {gridTemplateColumns:'1fr 1fr'}: {gridTemplateColumns:'1fr 2fr'}}>
								<div className='btn gray-btn btn-large' onClick={handleMoveToPrevPage}>Back</div>
								<div className='btn green-btn btn-large' onClick={handleMoveToNextPage}>{schoolHasOnlyOneScheduleVersionID ? 'Start New Year' : 'Next'}</div>
							</div>
							</>
						):(currentScreen === 3) ?
						(
							<>
							<h4 className='switch-years-subtitle'>Tell us which schedule you would like to roll over:</h4>
							{schedules_from_current_schedule_year.map((schedule, i) => {
								const schedule_version_id = schedule.schedule_version_id;
								const schedule_name = schedule.schedule_name;
								const schedule_date = schedule.time_stamp;
								const schedule_selected = settings.schedule_version_ids_to_copy.findIndex(selected_schedule_version_id => selected_schedule_version_id === schedule_version_id) !== -1;

								return (
									<div className='switch-years-schedule-select-container' key={i} onClick={()=>toggleSettings('schedule_version_id', schedule_version_id)}>
										{schedule_selected ?
											(
												<FontAwesomeIcon className='switch-years-schedule-select-box fas-checkbox-checked' icon={faCheckSquare}/>
											):
											(
												<FontAwesomeIcon className='switch-years-schedule-select-box fas-checkbox-unchecked dark-gray-text' icon={faSquare}/>
											)
										}
										<div>
											<div>{schedule_name}</div>
											<div className='small-text'>{schedule_date}</div>
										</div>
									</div>
								)
							})}
							{errorMessage &&
								<div className='error-message-no-box'>{errorMessage}</div>
							}
							<div className='switch-years-btn-container' style={{gridTemplateColumns:'1fr 2fr'}}>
								<div className='btn gray-btn btn-large' onClick={handleMoveToPrevPage}>Back</div>
								<div className='btn green-btn btn-large' onClick={handleMoveToNextPage}>Next</div>
							</div>
							</>
						):(currentScreen === 4) ?
						(
							<div className='text-align-center'>
								<img src={require("../images/copying.png")} alt='loading...' style={{width:'200px', margin:'50px 0px 25px 0px'}}/>
								<div style={{marginBottom:'30px'}}>Copying data over to the new year...</div>
							</div>
						):null
					}
				</div>
			</div>
		</div>
	)
}