import React from 'react';

import MagnetboardElementary from './elementary/MagnetboardElementary';
import MagnetboardHSMS from './hsms/MagnetboardHSMS';

export default function Magnetboard({ match, isAuth, authComplete, schoolSubscribed, schoolInfoLoading, schoolInfo, scheduleInfo, setScheduleInfo, scheduleStructureData, setScheduleStructureData, scheduleInfoLoading, scheduleStructureDataLoading, periodsLoading, periods, userInfo, departmentsLoading, departments, setDepartments, teachersLoading, setTeachersLoading, teachers, setTeachers, coursesLoading, setCoursesLoading, courses, setCourses, classroomsLoading, setClassroomsLoading, classrooms, setClassrooms, studentsLoading, setStudentsLoading, students, setStudents, studentMatchPercentages, blocksLoading, blocks, setBlocks, sectionsLoading, sections, setSections, subsections, setSubsections, lunchInfo, labels, setLabels }) {
	return (
		<>
			{(() => {
				switch(match.params.school_type) {
					case 'hsms':
						return <div className='main-mb-content'><MagnetboardHSMS isAuth={isAuth} authComplete={authComplete} schoolSubscribed={schoolSubscribed} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} scheduleStructureData={scheduleStructureData} setScheduleStructureData={setScheduleStructureData} scheduleInfoLoading={scheduleInfoLoading} scheduleStructureDataLoading={scheduleStructureDataLoading}  userInfo={userInfo} departmentsLoading={departmentsLoading} departments={departments} setDepartments={setDepartments} teachersLoading={teachersLoading} setTeachersLoading={setTeachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} setCoursesLoading={setCoursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} setClassroomsLoading={setClassroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} studentsLoading={studentsLoading} setStudentsLoading={setStudentsLoading} students={students} setStudents={setStudents} studentMatchPercentages={studentMatchPercentages} sectionsLoading={sectionsLoading} sections={sections} setSections={setSections} subsections={subsections} setSubsections={setSubsections} lunchInfo={lunchInfo} labels={labels} setLabels={setLabels}/></div>;
					case 'elementary':
						return <MagnetboardElementary schoolSubscribed={schoolSubscribed} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} scheduleInfo={scheduleInfo} scheduleInfoLoading={scheduleInfoLoading} periodsLoading={periodsLoading} periods={periods} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} studentsLoading={studentsLoading} students={students} setStudents={setStudents} blocksLoading={blocksLoading} blocks={blocks} setBlocks={setBlocks} />;
					default:
						return null;
				}
			})()}
		</>
	);
}