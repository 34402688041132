import React, { useState , useEffect, useRef } from 'react';
import Fuse from 'fuse.js';
import { API } from "aws-amplify";
import swal from '@sweetalert/with-react';

import Dropdown from '../../Dropdown';
import Availability from './Availability';

import { createUniqueObjectID, findDeletedValues, findAddedValues, cloneObj, sortArrayOfObjects, coursePeriodToSchedulePeriod, getNextSchedulePeriod, getNextCoursePeriod, getPreviousCoursePeriod, recalculateMatchPercent, useOutsideClick, deleteSection, calculateConflicts } from '../../../js/Helpers';

import '../../../css/hsms/edit_modals.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTimes, faSearch, faTrashAlt, faSquare, faCheckSquare, faCheck, faUserGraduate, faSortDown, faPeopleArrows, faMinusSquare, faLock } from '@fortawesome/free-solid-svg-icons';
import { faSave, faSquare as farSquare, faObjectUngroup } from '@fortawesome/free-regular-svg-icons';

export default function EditSection({ currentView, currentDay, currentViewType, scheduleType, sectionType, sectionID, passedCoursePeriodID, dataID, toggleEditSection, schoolInfo, scheduleInfo, scheduleStructureData, classroomsLoading, classrooms, coursesLoading, courses, teachersLoading, teachers, studentsLoading, students, sectionsLoading, sections, subsections, labels, setCourses, setStudents, setTeachers, setSections, setLabels, conflicts, setConflicts, setConflictsLoading, setMatchPercent, setMatchPercentLoading, toggleEditStudentSchedule, allow_hotlinks, updateSectionPlacement, filteredResults = [], setFilteredResults = ()=>{} }) {
	const ref = useRef();

	const [isLoading, setIsLoading] = useState(true);
	
	const quarters = ['1', '2', '3', '4'];
	const semester_to_quarters = {'FY':['1','2','3','4'], 'S':['1','2'], 'S1':['1','2'], 'S2':['3','4'], 'Q':['1'], 'Q1':['1'], 'Q2':['2'], 'Q3':['3'], 'Q4':['4']};

	const [coursePeriodID, setCoursePeriodID] = useState(passedCoursePeriodID);
	const [periodOptions, setPeriodOptions] = useState([]);
	const [numDaysInCycle, setNumDaysInCycle] = useState([]);

	const [sectionsData, setSectionsData] = useState({});
	const [editingTracker, setEditingTracker] = useState({}); 
	const [searchResults, setSearchResults] = useState({});

	const [teachersAvailability, setTeachersAvailability] = useState([]);
	const [studentsAvailability, setStudentsAvailability] = useState([]);
	const [classroomsAvailability, setClassroomsAvailability] = useState([]);
	const [availabilitiesCalculated, setAvailabilitiesCalculated] = useState(false);

	const [selectedStudents, setSelectedStudents] = useState([]);

	const [availabilityDataType, setAvailabilityDataType] = useState(null);
	const [availabilityViewID, setAvailabilityViewID] = useState(null);
	const [availabilityDataPosition, setAvailabilityDataPosition] = useState({'top':null, 'left':null, 'right':null});
	
	const [changesMade, setChangesMade] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	
	/////////////////////
	///// FUNCTIONS /////
	/////////////////////
	const handleFocus = (event) => event.target.select();

	const toggleEditing = (section_id, input_type) => {
		editingTracker[section_id][input_type] = !editingTracker[section_id][input_type];
		setEditingTracker({...editingTracker});

		if(!editingTracker[section_id][input_type]) hideAllSectionEditing(section_id);
	}

	const handleLoadAvailability = (e, data_type, data_id) => {
		const data_position = e.target.getBoundingClientRect();
		const availability_data_position = (data_position.left > 550) ? {top:data_position.top + 50, left:data_position.left-550} : {top:data_position.top, left:data_position.left + 75};
		
		setAvailabilityDataPosition(availability_data_position);
		setAvailabilityDataType(data_type);
		setAvailabilityViewID(data_id);
	}
	
	const addNewSubsection = (is_inclusion = '0') => {
		const newID = createUniqueObjectID(5, sectionsData);
		
		// Add subsection to main section
		sectionsData['main'].subsections.push(newID);
		
		// Add subsection data to sections data
		const subsection_course_id = (is_inclusion === '1' && scheduleInfo.separate_ICR_codes === '0') ? sectionsData.main.course_id : null;
		sectionsData[newID] = {new:true, section_id:newID, course_id:subsection_course_id, teacher_id:null, student_list:[], locked_students:[], labels:[], is_inclusion:is_inclusion};
		setSectionsData({...sectionsData});
		
		// Add subsection to edit tracking
		editingTracker[newID] = {course: false, teacher: false, students: false};
		setEditingTracker({...editingTracker});
		
		// Add empty results to subsection
		searchResults[newID] = {courses:{suggested:[], search:[]}, teachers:{suggested:[], search:[]}, students:{suggested_filter_ids:[], suggested:[], search:[]}};
		setSearchResults({...searchResults});
	}
	
	const removeSubsection = (subsection_id, subsection_index) => {
		// Remove subsection from main section
		sectionsData['main'].subsections.splice(subsection_index, 1);
		
		// Remove subsection data from sections data
		delete sectionsData[subsection_id]
		setSectionsData({...sectionsData});
		
		// Remove subsection from edit tracking
		delete editingTracker[subsection_id]
		setEditingTracker({...editingTracker});
		
		// Remove subsection from results
		delete searchResults[subsection_id];
		setSearchResults({...searchResults});

		setChangesMade(true);
	}

	const splitOffSubsection = async (subsection_id, subsection_index) => {
		const main_section_info = sectionsData['main'];
		const main_section_id = main_section_info.section_id;
		const main_course_id = main_section_info.course_id;
		const main_course_period_id = main_section_info.course_period_id;
		const main_teacher_id = main_section_info.teacher_id;
		const main_classroom_id = main_section_info.classroom_id;
		const main_quarter_days = main_section_info.quarter_days;
		const main_span_id = main_section_info.main_span_id;
		const main_is_lab = main_section_info.is_lab;
		const main_locked = main_section_info.locked;

		const subsection_info = sectionsData[subsection_id];
		const subsection_span_id = subsection_info.span_id;
		subsection_info.main_course_id = main_course_id;
		subsection_info.course_period_id = main_course_period_id;
		subsection_info.classroom_id = main_classroom_id;
		subsection_info.quarter_days = main_quarter_days;
		subsection_info.is_lab = main_is_lab;
		subsection_info.locked = main_locked;

		const main_section_previous_info = sections.find(section => section.section_id === main_section_id);
		const main_section_previous_course_period_id = (main_section_previous_info) ? main_section_previous_info.course_period_id : null;
		const course_period_has_changed = (main_course_period_id !== main_section_previous_course_period_id);

		// Prompt with modal 
		const options =  {
			title: "Split off this subsection?",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Split Subsection",
					value: true,
					visible: true,
					className: 'blue-btn'
				},
			},
			content: (
				<div>
					<p>This will split off the subsection into its own standalone section (this will break the section-subsection connection). Are you sure you want to split off this subsection?</p>
				</div>
			)
		}
	
		const split_off_subsection = await swal(options);
		
		if(split_off_subsection)
		{
			// If course period has changed, update placements first
			if(course_period_has_changed)  
			{
				await updateSectionPlacement(main_section_id, main_course_period_id, main_teacher_id, main_classroom_id);
				sessionStorage.setItem('undoneActions', JSON.stringify([]));
			}

			/// UPDATE FRONTEND ///
			// Remove the subsection from the current edit section page
			removeSubsection(subsection_id, subsection_index);

			// Collect all affected subsections
			let affected_subsections = [subsection_id];
			if(subsection_span_id)
			{
				affected_subsections = sections.reduce((results, section) => {
					if(section.span_id === subsection_span_id) results.push(section.section_id);
					return results;
				}, []);
			} 

			// Remove subsections from main sections
			// Update any spanned sections with the current subsection data
			const affected_section_ids = affected_subsections;
			const new_sections = sections.map(section => {
				const section_span_id = section.span_id;
				const sections_subsections = section.subsections;

				// If this is a main section with the same span id, add it to affected sections
				if(main_span_id && section_span_id === main_span_id) affected_section_ids.push(section.section_id);

				// remove subsection from any listed subsections
				const new_subsections = sections_subsections.filter(subsection => !affected_subsections.includes(subsection));
				section.subsections = new_subsections;

				if(affected_subsections.includes(section.section_id))
				{
					section.section_type = '1';
					section.teacher_id = subsection_info.teacher_id;
					section.course_id = subsection_info.course_id;
					section.classroom_id = subsection_info.classroom_id;
					section.quarter_days = subsection_info.quarter_days;
					section.is_lab = subsection_info.is_lab;
					section.locked = subsection_info.locked;
				}
				return section;
			});

			setSections([...new_sections]);

			/// UPDATE BACKEND ///
			const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, section_data:subsection_info};
			
			const apiName = process.env.REACT_APP_ENDPOINT_NAME;
			const url = '/admin/split-off-subsection';
			const myInit = { // OPTIONAL
				response: true,
				body: JSON.stringify(data),
			};
		
			try {
				// Split off the subsection
				await API.post(apiName, url, myInit);

				// Update conflicts
				const conflicts_to_check = ['teacher_double_booked', 'classroom_double_booked','teacher_student_restriction', 'course_exceeds_capacity', 'inclusion_too_many_students', 'inclusion_over_half'];
				
				calculateConflicts({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, affected_sections:affected_section_ids, update_all:false}).then(conflicts_response => {
					const added_conflicts = conflicts_response.added_conflicts;
					const deleted_conflicts = conflicts_response.deleted_conflicts;

					// Remove any deleted conflicts, add any added conflicts
					let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
					new_conflicts = [...new_conflicts, ...added_conflicts];

					setConflicts([...new_conflicts]);
					setConflictsLoading(false);
				});
			} catch(e)
			{
				console.log(e.response);
			}
		}
	}
	
	const hideAllSectionEditing = (section_id) => {
		// Close all inputs for this section
		for(const input_type in editingTracker[section_id])
		{
			editingTracker[section_id][input_type] = false;
		}
		
		// Clear all results for this section
		for(const search_type in searchResults[section_id])
		{
			if(search_type === 'classrooms')
			{
				searchResults[section_id][search_type] = {suggested:{course_classroom_restrictions:[], teacher_classroom_restrictions:[]}, search:[]};
			}
			else if(search_type === 'students')
			{
				searchResults[section_id][search_type] = {suggested_filter_ids:[], suggested: [], search: []};
			}
			else
			{
				searchResults[section_id][search_type] = {suggested: [], search: []};
			}
		}
		
		setEditingTracker({...editingTracker});
		setSearchResults({...searchResults});
	}
	
	const fuzzySearch = (e, section_id, dataType) => {
		const search_value = e.target.value;
		
		if(dataType === 'courses')
		{
			const fuse = new Fuse(courses, {
				keys: ['name', 'course_code'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const course_results = results.map(result => result.item).slice(0, 6);
			
			searchResults[section_id][dataType]['search'] = (course_results.length === 0) ? courses : course_results;
			setSearchResults({...searchResults});
		}
		else if(dataType === 'teachers')
		{
			const fuse = new Fuse(teachers, {
				keys: ['first_name', 'name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const teacher_results = results.map(result => result.item).slice(0, 6);
			
			searchResults[section_id][dataType]['search'] = (teacher_results.length === 0) ? teachers : teacher_results;
			setSearchResults({...searchResults});
		}
		else if(dataType === 'classrooms')
		{
			const fuse = new Fuse(classrooms, {
				keys: ['classroom_name', 'classroom_type'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const classroom_results = results.map(result => result.item).slice(0, 6);
			
			searchResults[section_id][dataType]['search'] = (classroom_results.length === 0) ? classrooms : classroom_results;
			setSearchResults({...searchResults});
		}
		else if(dataType === 'students')
		{
			const fuse = new Fuse(students, {
				keys: ['first_name', 'last_name'],
				threshold: .1
			})
			
			const results = fuse.search(search_value);
			const student_results = results.map(result => result.item).slice(0, 30);

			// filter out students who are requesting (so they don't show up in both lists)
			const other_students_results = (student_results.length === 0) ? students : student_results.filter(student => searchResults[section_id][dataType]['suggested'].findIndex(suggested_student_info => suggested_student_info.student_id === student.student_id) === -1);
			searchResults[section_id][dataType]['search'] = other_students_results;

			// add searched students to suggested filter ids so it narrows down search for requesting students too
			searchResults[section_id][dataType]['suggested_filter_ids'] = [];
			student_results.forEach(student => {
				const student_id = student.student_id;
				searchResults[section_id][dataType]['suggested_filter_ids'].push(student_id);
			});

			setSearchResults({...searchResults});
		}
	}

	const updateQuarterDays = (type, value) => {
		const main_section_quarter_days = sectionsData.main.quarter_days;
		if(type === 'quarter')
		{
			const quarter_index = main_section_quarter_days.findIndex(quarter_day => quarter_day.quarter === value);

			// If no index found then we know this is selecting quarter for first time
			// If index found then we know this is unselecting
			if(quarter_index === -1)
			{
				// Get all unique days that are currently selected
				let all_current_days = main_section_quarter_days.reduce((result, quarter_day) => {
					if(!result.includes(quarter_day.day)) result.push(quarter_day.day);
					return result;
				}, []);

				// If no days are selected yet, select them all by default
				all_current_days = (all_current_days.length === 0) ? numDaysInCycle : all_current_days;

				// For each unique day, add this quarter-day combo
				all_current_days.forEach(day => {
					main_section_quarter_days.push({quarter:value, day:day});
				});
			}
			else
			{
				const new_quarter_days = main_section_quarter_days.filter(quarter_day => quarter_day.quarter !== value);
				sectionsData.main.quarter_days = new_quarter_days;
			}
		}
		else if(type === 'day')
		{
			const day_index = main_section_quarter_days.findIndex(quarter_day => quarter_day.day === value);

			// If no index found then we know this is selecting day for first time
			// If index found then we know this is unselecting
			if(day_index === -1)
			{
				// Get all unique quarters that are currently selected
				let all_current_quarters = main_section_quarter_days.reduce((result, quarter_day) => {
					if(!result.includes(quarter_day.quarter)) result.push(quarter_day.quarter);
					return result;
				}, []);

				all_current_quarters = (all_current_quarters.length === 0) ? quarters : all_current_quarters;

				// For each unique quarter, add this quarter-day combo
				all_current_quarters.forEach(quarter => {
					main_section_quarter_days.push({quarter:quarter, day:value});
				});
			}
			else
			{
				const new_quarter_days = main_section_quarter_days.filter(quarter_day => quarter_day.day !== value);
				sectionsData.main.quarter_days = new_quarter_days;
			}
		}

		setSectionsData({...sectionsData});
		setChangesMade(true);
	}

	/////////////////////////////
	////// LABEL FUNCTIONS //////
	/////////////////////////////
	const addTempLabel = (section_id, team_bool) => {
		const new_label_id = createUniqueObjectID(5);
		
		labels.push({new: true, label_id:new_label_id, label:null, is_team:team_bool});
		sectionsData[section_id]['labels'].push(new_label_id);

		closeLabelOptions(section_id);
		setLabels([...labels]);
		setSectionsData({...sectionsData});
		setChangesMade(true);
	}

	const removeLabel = async (section_id, label_id) => {
		const data_index = sectionsData[section_id]['labels'].findIndex(data => data === label_id);
		if(data_index !== -1) sectionsData[section_id]['labels'].splice(data_index, 1);
		
		const label_index = labels.findIndex(label => label.label_id === label_id);
		const label_info = labels[label_index];
		const is_new = ('new' in label_info) ? true : false;
		
		if(is_new) labels.splice(label_index, 1);

		setSectionsData({...sectionsData});
		setLabels([...labels]);
		setChangesMade(true);
	}

	const updateLabel = async (e, section_id, label_id) => {
		const label_index = labels.findIndex(label => label.label_id === label_id);
		const label_info = labels[label_index];
		
		const label_input = e.target.closest('.create-labels-editing-label').querySelector('.create-labels-input');
		const label = label_input.value;
		
		if(!label)
		{
			label_input.classList.add('error');
			return;
		}
		
		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, update_type:'add', label_id:label_id, label:label, is_team:label_info.is_team};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/admin/update-label';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			const response = await API.post(apiName, url, myInit);
			const new_label_id = response.data.label_id;
			
			labels[label_index]['label_id'] = new_label_id;
			labels[label_index]['label'] = label;
			
			delete labels[label_index]['new'];
			
			// Update section label id
			const section_label_index = sectionsData[section_id]['labels'].findIndex(label => label === label_id);
			if(section_label_index !== -1) sectionsData[section_id]['labels'].splice(section_label_index, 1);
			sectionsData[section_id]['labels'].push(new_label_id);

			setLabels([...labels]);
			setSectionsData({...sectionsData});
		} catch(e)
		{
			console.log(e.response);
		}
	}

	const toggleLabelOptionsOpen = (section_id) => {
		editingTracker[section_id]['label_options_open'] = !editingTracker[section_id]['label_options_open'];
		setEditingTracker({...editingTracker});
	}

	const closeLabelOptions = (section_id) => {
		editingTracker[section_id]['label_options_open'] = false;
		setEditingTracker({...editingTracker});
	}
	
	useOutsideClick(ref, () => {
		const section_id = ref.current.closest('.create-labels-label-options-container').getAttribute('data-sectionid');
		
		closeLabelOptions(section_id);
	});

	const toggleSelectStudent = (student_id) => {
		const student_selected_index = selectedStudents.findIndex(student => student === student_id);

		if(student_selected_index !== -1)
		{
			selectedStudents.splice(student_selected_index, 1);
		}
		else
		{
			selectedStudents.push(student_id);
		}

		setSelectedStudents([...selectedStudents]);
	}

	const toggleSelectAll = (section_id) => {
		const student_list = sectionsData[section_id].student_list.filter(student_id => !sectionsData[section_id].locked_students.includes(student_id));
		const any_students_selected = selectedStudents.filter(student_id => student_list.includes(student_id)).length > 0;
		
		let new_selected_students = [];

		if(any_students_selected)
		{
			new_selected_students = selectedStudents.filter(student_id => !student_list.includes(student_id));
		}
		else
		{
			new_selected_students = [...selectedStudents, ...student_list];
		}

		setSelectedStudents([...new_selected_students]);
	}

	const toggleLockStudents = (section_id, lock_type, student_id = null) => {
		if(lock_type === 'lock')
		{
			if(student_id)
			{
				const lock_index = sectionsData[section_id].locked_students.findIndex(locked_student_id => locked_student_id === student_id);
				if(lock_index === -1) sectionsData[section_id].locked_students.push(student_id);
			}
			else
			{
				selectedStudents.forEach(student_id => {
					sectionsData[section_id].locked_students.push(student_id);
				});
	
				setSelectedStudents([]);
			}
			
		}
		else if(lock_type === 'unlock')
		{
			const lock_index = sectionsData[section_id].locked_students.findIndex(locked_student_id => locked_student_id === student_id);
			if(lock_index !== -1) sectionsData[section_id].locked_students.splice(lock_index, 1);
		}
		
		setSectionsData({...sectionsData});
		setChangesMade(true);
	}

	const bulkRemoveStudents = async (section_id) => {
		const options =  {
			title: "Are you sure?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div>
					<p>Do you really want to remove the selected students from this section?</p>
				</div>
			)
		}

		if(await swal(options))
		{
			const selected_students_from_section = selectedStudents.filter(student_id => sectionsData[section_id].student_list.includes(student_id));
			const selected_students_not_from_section = selectedStudents.filter(student_id => !sectionsData[section_id].student_list.includes(student_id));
			
			selected_students_from_section.forEach(student_id => {
				updateData('remove', section_id, 'student_list', student_id);
			});

			setSelectedStudents([...selected_students_not_from_section]);

			setChangesMade(true);
		}
	}

	// Component for bulk sending students to other section
	// Need to separate this out so it updates on state change in the modal (needed for search)
	const OtherSectionList = ({ oldSectionID }) => {
		const [otherSectionsSearchResults, setOtherSectionsSearchResults] = useState([...sections]);

		const otherSectionsSearch = (e) => {
			const search_value = e.target.value;
			
			let section_results = [];

			if(search_value.length === 0)
			{
				section_results = sections;
			}
			else
			{
				// Get courses with search term
				const fuse_courses = new Fuse(courses, {
					keys: ['name', 'course_code'],
					threshold: .1
				});
				
				const course_search_results = fuse_courses.search(search_value);
				const course_results = course_search_results.map(result => result.item).slice(0, 6);

				// Get teachers with search term
				const fuse_teachers = new Fuse(teachers, {
					keys: ['name', 'first_name'],
					threshold: .1
				});
				
				const teacher_search_results = fuse_teachers.search(search_value);
				const teacher_results = teacher_search_results.map(result => result.item).slice(0, 6);
				
				// Get any sections that have either a course or teacher with search term
				section_results = sections.reduce((results, section) => {
					const course_id = section.course_id;
					const teacher_id = section.teacher_id;
					if(course_results.findIndex(course => course.course_id === course_id) !== -1 || teacher_results.findIndex(teacher => teacher.teacher_id === teacher_id) !== -1) results.push(section);
					return results;
				}, []);
			}

			setOtherSectionsSearchResults([...section_results]);
		}

		const selected_students_in_section = selectedStudents.filter(student_id => sectionsData[oldSectionID].student_list.includes(student_id));

		let logged_spanned_ids = [];
		const sections_with_course_codes = sections.reduce((results, section) => {
			const span_id = section.span_id;

			if(span_id && logged_spanned_ids.includes(span_id)) return results;
			if(span_id) logged_spanned_ids.push(span_id);

			const course_id = section.course_id;
			const course_index = courses.findIndex(course => course.course_id === course_id);

			if(course_index !== -1)
			{
				const course_info = courses[course_index];
				const course_code = course_info.course_code;
				
				section.course_code = course_code;

				results.push(section);
			}

			return results;
		}, []);

		const sorted_sections_by_course_code = sortArrayOfObjects(sections_with_course_codes, 'course_code', 'text', 'desc');

		const old_section_id = sectionsData[oldSectionID].section_id;
		const old_span_id = sectionsData[oldSectionID].span_id;

		return (
			<div className='text-align-left'>
				<h1 style={{margin:'0px'}}>Choose a Section</h1>
				<p style={{margin:'10px 0px 20px 0px'}}>Which section would you like to send these students to?</p>
				<div className='search-add-adding-input-container' style={{marginBottom:'10px'}}>
					<input className='search-add-adding-input' onChange={(e) => otherSectionsSearch(e)} placeholder='Search for a section by course code, course name, or teacher'/>
					<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
				</div>
				<div className='edit-section-move-student-section-row' style={{backgroundColor:'#fff'}}>
					<div className='text-align-center'><strong><u>Section ID #</u></strong></div>
					<div><strong><u>Course</u></strong></div>
					<div><strong><u>Periods</u></strong></div>
					<div className='white-text'>.</div>
					<div className='white-text'>.</div>
				</div>
				{sorted_sections_by_course_code.filter(section => otherSectionsSearchResults.findIndex(other_section => other_section.section_id === section.section_id) !== -1).map((section, i) => {
					const section_id = section.section_id;
					const course_period_id = section.course_period_id;
					const teacher_id = section.teacher_id;
					const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
					const span_id = section.span_id;
					const num_students = section.student_list.length;
					const course_id = section.course_id;
					const course_index = courses.findIndex(course => course.course_id === course_id);
					const course_info = (course_index !== -1) ? courses[course_index] : null;
					const course_code = (course_info) ? course_info.course_code : null;
					const course_name = (course_info) ? course_info.name : null;
					
					// Collect all course periods for this section
					const course_periods = [];

					if(course_period_id) course_periods.push(course_period_id);

					if(span_id)
					{
						for(const section of sections)
						{
							const section_span_id = section.span_id;

							if(section_span_id !== span_id) continue;

							const section_course_period_id = section.course_period_id;

							if(section_course_period_id && !course_periods.includes(section_course_period_id)) course_periods.push(section_course_period_id);
						}
					}

					course_periods.sort();

					return (
						<div key={i} className='edit-section-move-student-section-row'>
							<div className='text-align-center'>{section.section_number}</div>
							<div>
								<div>{course_code} - {course_name}</div>
								{teacher_id &&
									<div className='dark-gray-text'>{teacher_info.name}, {teacher_info.first_name}</div>
								}
							</div>
							<div>
								{course_periods.length === 0 ?
									(
										<>--</>
									):
									(
										<>
										{course_periods.map(course_period_id => {
											const period_id = (scheduleInfo.schedule_type === 'daily') ? scheduleStructureData.course_periods[course_period_id].days['1'] : course_period_id;
											const period_name = (scheduleInfo.schedule_type === 'daily') ? scheduleStructureData.schedule_periods[period_id].period_name : scheduleStructureData.course_periods[period_id].period_name;

											return (
												<div key={`${i}-${course_period_id}`}>{period_name}</div>
											)
										})}
										</>
									)
								}
							</div>
							<div className='mb-section-students text-align-center'><FontAwesomeIcon icon={faUserGraduate}/> {num_students}</div>
							{(section_id === old_section_id || (span_id && span_id === old_span_id)) ?
								(
									<div className='small-text align-right'>Current Section</div>
								):
								(
									<div className='btn-small btn-round blue-btn align-right' style={{maxWidth:'70px'}} onClick={() => bulkSendStudentsToNewSection(selected_students_in_section, oldSectionID, section_id)}>Select</div>
								)
							}
						</div>
					)
				})}
			</div>
		)
	}

	const showOtherSections = async (old_section_id) => {
		const options =  {
			button: false,
			className: 'swal-extra-wide',
			content: (
				<OtherSectionList oldSectionID={old_section_id} />
			)
		}

		await swal(options);
	}

	const bulkSendStudentsToNewSection = async (student_list, oldSectionID, new_section_id) => {
		setConflictsLoading(true);
		setMatchPercentLoading(true);

		const old_section_id = sectionsData[oldSectionID].section_id;

		// Remove these students from edit section screen
		bulkRemoveStudents(oldSectionID);

		// Close modal
		swal.close();

		// Move students from one section to another in FRONT END state
		const new_section_info = sections.find(section => section.section_id === new_section_id);
		const new_section_course_id = new_section_info.course_id;
		const new_section_span_id = new_section_info.span_id;
		
		// Remove students from old section
		// Remove student from any sections of the same course as the new section (takes care of spans)
		// Add students to new section(s)
		const new_sections = sections.map(section => {
			const section_id = section.section_id;
			const section_course_id = section.course_id;
			const section_student_list = section.student_list;
			const section_span_id =section.span_id;
		
			if((section_id === new_section_id) || (section_span_id && new_section_span_id === section_span_id))
			{
				const new_student_list = [...new Set([...student_list ,...section_student_list])];
				section.student_list = new_student_list;
			}
			else if((section_id === old_section_id) || (section_course_id === new_section_course_id))
			{
				const student_set = new Set(student_list);
				const new_student_list = section_student_list.filter(student_id => !student_set.has(student_id));
				section.student_list = new_student_list;
			}
			
			return section;
		});

		setSections([...new_sections]);

		// Add requests for the new section course for all students
		student_list.forEach(student_id => {
			const student_index = students.findIndex(student => student.student_id === student_id);
			const student_info = students[student_index];
			const student_requests = student_info.student_requests;

			const already_requested_index = student_requests.findIndex(request => request.course_id === new_section_course_id);

			if(already_requested_index === -1) 
			{
				students[student_index].student_requests.push({request_id: 'new', course_id:new_section_course_id, priority:'1', matched:'1', data_origin:'2', is_deleted:'0'});
			}
			else
			{
				students[student_index].student_requests[already_requested_index].is_deleted ='0';
			}
		});

		setStudents([...students]);

		///////////////////////////
		///// SEND TO BACKEND /////
		///////////////////////////
		// Move students from one section to another in the BACK END
		const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id,  student_list:student_list, old_section_id:old_section_id, new_section_id:new_section_id};
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = '/move-students-to-new-section';
		const myInit = { // OPTIONAL
			response: true,
			body: JSON.stringify(data),
		};
		
		try {
			// MOVE STUDENTS TO NEW SECTION
			await API.post(apiName, url, myInit);

			// Update conflicts
			const conflicts_to_check = ['student_double_booked', 'student_conflicting_periods', 'student_student_restriction', 'teacher_student_restriction', 'course_exceeds_capacity', 'inclusion_too_many_students', 'inclusion_over_half'];
			const affected_sections = [old_section_id, new_section_id];
			calculateConflicts({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:affected_sections, conflicts_to_check:conflicts_to_check, update_all:false}).then(conflicts_response => {
				const added_conflicts = conflicts_response.added_conflicts;
				const deleted_conflicts = conflicts_response.deleted_conflicts;

				// remove any deleted conflicts, add any added conflicts
				let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
				new_conflicts = [...new_conflicts, ...added_conflicts];

				setConflicts([...new_conflicts]);
				setConflictsLoading(false);
			});

			// Recalculate match percent
			recalculateMatchPercent({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}).then(match_percent => {
				setMatchPercent(match_percent);
				setMatchPercentLoading(false);
			});
		} catch(error)
		{
			console.log(error.response);
		}
	}
	
	const handleExitWithoutSaving = async () => {
		let exit_without_saving = true;
		
		if(changesMade)
		{
			const options =  {
				title: "Exit without saving?",
				dangerMode: true,
				buttons: {
					cancel: {
						text: "Cancel",
						value: false,
						visible: true,
						className: 'gray-btn'
					},
					confirm: {
						text: "Yes",
						value: true,
						visible: true,
						className: 'blue-btn'
					},
				},
				content: (
					<div>
						<p>You have changes that have not been saved. Do you really want to exit without saving?</p>
					</div>
				)
			}
		
			exit_without_saving = await swal(options);
		}
		
		if(exit_without_saving) toggleEditSection();
	}

	const handleDeleteSection = async () => {
		const options =  {
			title: "Are you sure?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: 'gray-btn'
				},
				confirm: {
					text: "Yes",
					value: true,
					visible: true,
					className: 'red-btn'
				},
			},
			content: (
				<div>
					<p>Do you really want to delete this section? Deleting it will remove:</p>
					<ul style={{textAlign:'left'}}>
						<li>this section from your schedule</li>
						<li>all students from this section</li>
						<li>all spanning sections connected with this section</li>	
					</ul>
				</div>
			)
		}

		const delete_section_bool = await swal(options);

		if(delete_section_bool)
		{
			setIsSaving(true);
			setConflictsLoading(true);
			setMatchPercentLoading(true);

			/////////////////////////
			//// UPDATE FRONTEND ////
			/////////////////////////
			const section_info = sections.find(section => section.section_id === sectionID);
			const span_id = (section_info) ? section_info.span_id : null;
			const affected_sections = sections.reduce((results, section, section_index) => {
				const section_id = section.section_id;
				const section_span_id = section.span_id;
				const section_subsections = section.subsections;
				if(section_id === sectionID || (span_id && span_id === section_span_id))
				{
					if(!results.section_indexes.includes(section_index)) results.section_indexes.push(section_index);
					if(!results.section_ids.includes(section_id)) results.section_ids.push(section_id);
					
					for(const section_subsection_id of section_subsections)
					{
						const subsection_section_index = sections.findIndex(section => section.section_id === section_subsection_id);
						const subsection_subsection_index = subsections.findIndex(subsection => subsection.subsection_id === section_subsection_id);

						// Add subsection section id to deleted list
						if(subsection_section_index !== -1 && !results.section_ids.includes(section_subsection_id)) results.section_ids.push(section_subsection_id);

						// Add subsection section index to indexes to delete from sections
						if(subsection_section_index !== -1 && !results.section_indexes.includes(subsection_section_index)) results.section_indexes.push(subsection_section_index);

						// Add subsection subsection index to indexes to delete from subsections
						if(subsection_subsection_index !== -1 && !results.subsection_indexes.includes(subsection_subsection_index)) results.subsection_indexes.push(subsection_subsection_index);
					}
				}
				return results;
			}, {section_ids:[], section_indexes:[], subsection_indexes:[]});

			for(const section_index of affected_sections.section_indexes)
			{
				if(section_index !== -1) sections.splice(section_index, 1);
			}

			for(const subsection_index of affected_sections.subsection_indexes)
			{
				if(subsection_index !== -1) subsections.splice(subsection_index, 1);
			}
			
			for(const affected_section_id of affected_sections.section_ids)
			{
				const filtered_results_index = filteredResults.findIndex(section => section.section_id === affected_section_id);
				if(filtered_results_index !== -1) filteredResults.splice(filtered_results_index, 1);
			}

			setSections([...sections]);
			setFilteredResults([...filteredResults]);
			
			toggleEditSection();
			setIsSaving(false);

			////////////////////////
			//// UPDATE BACKEND ////
			////////////////////////
			const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, section_id:sectionID};

			deleteSection(data).then(response_data => {
				const deleted_sections = response_data.deleted_sections;
				
				// Delete all deleted sections
				deleted_sections.forEach(section_id => {
					const section_index = sections.findIndex(section => section.section_id === section_id);
					const filtered_results_index = filteredResults.findIndex(section => section.section_id === section_id);
		
					if(section_index !== -1) sections.splice(section_index, 1);
					if(filtered_results_index !== -1) filteredResults.splice(filtered_results_index, 1);
				});

				setSections([...sections]);
				setFilteredResults([...filteredResults]);

				// Update conflicts
				const conflicts_to_check = ['teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_missing', 'teacher_period_restriction', 'teacher_too_many_sections', 'teacher_too_many_sections_in_a_row', 'student_student_restriction', 'teacher_student_restriction', 'course_period_restriction', 'course_exceeds_capacity', 'course_outside_normal_room', 'classroom_missing', 'inclusion_too_many_students', 'inclusion_over_half'];
				calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, affected_sections:deleted_sections, conflicts_to_check:conflicts_to_check, update_all:false}).then(conflicts_response => {
					const added_conflicts = conflicts_response.added_conflicts;
					const deleted_conflicts = conflicts_response.deleted_conflicts;

					// remove any deleted conflicts, add any added conflicts
					let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
					new_conflicts = [...new_conflicts, ...added_conflicts];
				
					setConflicts([...new_conflicts]);
					setConflictsLoading(false);
				});

				recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}).then(match_percent => {
					setMatchPercent(match_percent);
					setMatchPercentLoading(false);
				});
			});
		}
	}

	const checkTeacherAvailability = (teacher_id, potential_course_period_quarter_days) => 
	{
		const teacher_potentials = cloneObj(potential_course_period_quarter_days);

		let teacher_available = true; 

		const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === teacher_id);
		if(teacher_index === -1) teacher_available = false;

		let course_periods_needed = [];
		let i = teacher_potentials.length;

		// Iterate backward to be able to delete any potentials that have conflicts
		while(i--) 
		{
			const potential_course_period_info = teacher_potentials[i];
			const potential_course_period_id = potential_course_period_info.course_period_id;
			const potential_course_period_conflicting_course_periods = potential_course_period_info.conflicting_course_periods;
			const potential_quarter_days = potential_course_period_info.quarter_days;
			const potential_excluded_sections = potential_course_period_info.excluded_sections;

			let potential_available = true;

			// Keep track of any course periods that show up in potentials as course periods that need to be cleared to be available
			if(!course_periods_needed.includes(potential_course_period_id)) course_periods_needed.push(potential_course_period_id);

			// Get all of teachers sections during this potential
			const teacher_sections_during_course_period = sections.filter(section => section.teacher_id === teacher_id && potential_course_period_conflicting_course_periods.includes(section.course_period_id) && !potential_excluded_sections.includes(section.section_id));

			// If teacher has courses check if quarter days conflict with potential
			if(teacher_sections_during_course_period.length > 0 && potential_quarter_days.length > 0)
			{
				teacher_sections_during_course_period.forEach(section => {
					const section_quarter_days = section.quarter_days;

					section_quarter_days.forEach(quarter_day => {
						const section_day = quarter_day.day;
						const section_quarter = quarter_day.quarter;

						const section_quarter_day_index = potential_quarter_days.findIndex(quarter_day => quarter_day.day === section_day && quarter_day.quarter === section_quarter);

						if(section_quarter_day_index !== -1) potential_available = false;
					});
				});
			}

			// If something conflicts with potential, remove potential from list
			if(!potential_available) teacher_potentials.splice(i, 1);
		}

		// At the end, there should be a potential open for every course period needed to be open in order for this teacher to be considered available
		course_periods_needed.forEach(needed_course_period_id => {
			if(teacher_potentials.findIndex(potential_course_period_info => potential_course_period_info.course_period_id === needed_course_period_id) === -1) teacher_available = false;
		});

		return teacher_available;
	}

	const checkStudentAvailability = (student_id, potential_course_period_quarter_days) =>
	{
		const student_potentials = cloneObj(potential_course_period_quarter_days);
		let student_available = true; 

		const student_index = students.findIndex(student => student.student_id === student_id);
		if(student_index === -1) student_available = false;

		const student_lunch_schedule_period_id = students[student_index].lunch_schedule_period_id;
		const student_lunch_course_period_id = (scheduleType === 'daily') ? scheduleStructureData.schedule_structure['1'][student_lunch_schedule_period_id] : null;

		let course_periods_needed = [];
		let i = student_potentials.length;

		// Iterate backward to be able to delete any potentials that have conflicts
		while(i--) 
		{
			const potential_course_period_info = student_potentials[i];
			const potential_course_period_id = potential_course_period_info.course_period_id;
			const potential_course_period_conflicting_course_periods = potential_course_period_info.conflicting_course_periods;
			const potential_quarter_days = potential_course_period_info.quarter_days;
			const potential_excluded_sections = potential_course_period_info.excluded_sections;

			let potential_available = true;

			// Keep track of any course periods that show up in potentials as course periods that need to be cleared to be available
			if(!course_periods_needed.includes(potential_course_period_id)) course_periods_needed.push(potential_course_period_id);

			// Get all of students sections during this potential
			const student_sections_during_course_period = sections.filter(section => section.student_list.includes(student_id) && potential_course_period_conflicting_course_periods.includes(section.course_period_id) && !potential_excluded_sections.includes(section.section_id));

			// If student has courses check if quarter days conflict with potential
			if(student_sections_during_course_period.length > 0 && potential_quarter_days.length > 0)
			{
				student_sections_during_course_period.forEach(section => {
					const section_quarter_days = section.quarter_days;

					section_quarter_days.forEach(quarter_day => {
						const section_day = quarter_day.day;
						const section_quarter = quarter_day.quarter;

						const section_quarter_day_index = potential_quarter_days.findIndex(quarter_day => quarter_day.day === section_day && quarter_day.quarter === section_quarter);

						if(section_quarter_day_index !== -1) potential_available = false;
					});
				});
			}

			// If this potential conflicts with student lunch then it is unavailable
			if(student_lunch_course_period_id && student_lunch_course_period_id === potential_course_period_id) potential_available = false;

			// If something conflicts with potential, remove potential from list
			if(!potential_available) student_potentials.splice(i, 1);
		}

		// At the end, there should be a potential open for every course period needed to be open in order for this student to be considered available
		course_periods_needed.forEach(needed_course_period_id => {
			if(student_potentials.findIndex(potential_course_period_info => potential_course_period_info.course_period_id === needed_course_period_id) === -1) student_available = false;
		});

		return student_available;
	}

	const checkClassroomAvailability = (classroom_id, potential_course_period_quarter_days) => 
	{
		const classroom_potentials = cloneObj(potential_course_period_quarter_days);

		let classroom_available = true; 

		const classroom_index = classrooms.findIndex(classroom => classroom.classroom_id === classroom_id);
		if(classroom_index === -1) classroom_available = false;
		const classroom_info = (classroom_index !== -1) ? classrooms[classroom_index] : null;
		const is_multi_class = (classroom_info) ? classroom_info.multi_class : false;

		let course_periods_needed = [];
		let i = classroom_potentials.length;

		// Iterate backward to be able to delete any potentials that have conflicts
		while(i--) 
		{
			const potential_course_period_info = classroom_potentials[i];
			const potential_course_period_id = potential_course_period_info.course_period_id;
			const potential_course_period_conflicting_course_periods = potential_course_period_info.conflicting_course_periods;
			const potential_quarter_days = potential_course_period_info.quarter_days;
			const potential_excluded_sections = potential_course_period_info.excluded_sections;

			let potential_available = true;

			// Keep track of any course periods that show up in potentials as course periods that need to be cleared to be available
			if(!course_periods_needed.includes(potential_course_period_id)) course_periods_needed.push(potential_course_period_id);

			// Get all of classrooms sections during this potential
			const classroom_sections_during_course_period = sections.filter(section => section.classroom_id === classroom_id && potential_course_period_conflicting_course_periods.includes(section.course_period_id) && !potential_excluded_sections.includes(section.section_id));

			// If classroom has courses check if quarter days conflict with potential
			if(classroom_sections_during_course_period.length > 0 && potential_quarter_days.length > 0)
			{
				classroom_sections_during_course_period.forEach(section => {
					const section_quarter_days = section.quarter_days;

					section_quarter_days.forEach(quarter_day => {
						const section_day = quarter_day.day;
						const section_quarter = quarter_day.quarter;

						const section_quarter_day_index = potential_quarter_days.findIndex(quarter_day => quarter_day.day === section_day && quarter_day.quarter === section_quarter);

						if(section_quarter_day_index !== -1) potential_available = false;
					});
				});
			}

			// If something conflicts with potential, remove potential from list
			if(!potential_available) classroom_potentials.splice(i, 1);
		}

		if(is_multi_class === '1') return classroom_available;

		// At the end, there should be a potential open for every course period needed to be open in order for this classroom to be considered available
		course_periods_needed.forEach(needed_course_period_id => {
			if(classroom_potentials.findIndex(potential_course_period_info => potential_course_period_info.course_period_id === needed_course_period_id) === -1) classroom_available = false;
		});

		return classroom_available;
	}
	
	const updateData = (change_type, section_id, data_key, data_id) => {
		if(change_type === 'add')
		{
			const data_add_index = sectionsData[section_id][data_key].findIndex(data_val => data_val === data_id);
			if(data_add_index === -1) sectionsData[section_id][data_key].push(data_id)
		}
		else if(change_type === 'change')
		{
			// If changing course, then change quarter days and clear student list
			if(data_key === 'course_id') 
			{
				if(section_id === 'main')
				{
					sectionsData[section_id]['quarter_days'] = [];

					if(!data_id)
					{
						sectionsData[section_id]['quarter_days'] = [];
					}
					else
					{
						const course_info = courses.find(course => course.course_id === data_id);
						const course_semester = course_info.semester;

						if(course_semester)
						{
							const course_default_quarters = semester_to_quarters[course_semester];

							numDaysInCycle.forEach(day => {
								course_default_quarters.forEach(quarter => {
									sectionsData[section_id]['quarter_days'].push({quarter:quarter, day:day});
								});
							});
						}
					}
					
				}

				sectionsData[section_id]['student_list'] = [];
				setAvailabilitiesCalculated(false);
			}
			
			sectionsData[section_id][data_key] = data_id;
		}
		else if(change_type === 'remove')
		{
			const data_index = sectionsData[section_id][data_key].findIndex(data => data === data_id);
			if(data_index !== -1) sectionsData[section_id][data_key].splice(data_index, 1);
		}
		
		setSectionsData({...sectionsData});
		if(data_key !== 'student_list') hideAllSectionEditing(section_id);
		setChangesMade(true);
	}

	const updateSectionJSON = (new_students, returned_sections, deleted_sections, student_id = null) => {
		// Delete all deleted sections, and remove student matches
		deleted_sections.forEach(section_id => {
			const section_index = sections.findIndex(section => section.section_id === section_id);
			const filtered_results_index = filteredResults.findIndex(section => section.section_id === section_id);
			const section_info = sections[section_index];
			const section_course_id = section_info.course_id;
			const deleted_students = section_info.student_list;
			
			// delete sections from students and reset student matches
			deleted_students.forEach(student_id => {
				const student_index = new_students.findIndex(student => student.student_id === student_id);
				const student_info = new_students[student_index];
				const student_requests = student_info.student_requests;
				const student_sections = student_info.student_sections;
				const student_locked_sections = student_info.student_locked_sections;

				// remove student section
				const student_section_index = student_sections.findIndex(section => section === section_id);
				if(student_section_index !== -1) student_sections.splice(student_section_index, 1);

				// remove student locked_section
				const student_locked_section_index = student_locked_sections.findIndex(section => section === section_id);
				if(student_locked_section_index !== -1) student_locked_sections.splice(student_locked_section_index, 1);
				
				// mark as unmatched
				const request_index = student_requests.findIndex(request => request.course_id === section_course_id);
				if(request_index !== -1) student_requests[request_index]['matched'] = '0';
			});

			sections.splice(section_index, 1);
			if(filtered_results_index !== -1) filteredResults.splice(filtered_results_index, 1);
		});

		returned_sections.forEach(section_info => {
			const section_id = section_info.section_id;
			const section_type = section_info.section_type;
			const course_id = section_info.course_id;
			const teacher_id = section_info.teacher_id;
			const student_list = section_info.student_list;
			const span_id = section_info.span_id;

			const course_index = courses.findIndex(course => course.course_id === course_id);
			const course_info = (course_index !== -1) ? courses[course_index] : null;
			const teacher_index = teachers.findIndex(teacher => teacher.teacher_id === teacher_id);
			const teacher_info = (teacher_index !== -1) ? teachers[teacher_index] : null;

			// Add departments to section info
			section_info['departments'] = course_info.departments;

			// See if previous section id existed
			// If yes, we will replace old data
			// If no, then push new data
			const previous_section_index = sections.findIndex(section => section.section_id === section_id);
			const previous_filtered_results_index = filteredResults.findIndex(section => section.section_id === section_id);

			/////////////////////////////////////////
			///// UPDATE student request matches /////
			/////////////////////////////////////////
			if(section_type === '1')
			{
				// If previous section id existed then pull old student list to make sure we are updating their matches/schedules
				let previous_student_list = [];
				let previous_course_id = null;
				
				if(previous_section_index !== -1) 
				{
					const previous_section_info = cloneObj(sections[previous_section_index]);
					previous_student_list = previous_section_info.student_list;
					previous_course_id = previous_section_info.course_id;
				}
				
				const deleted_students = (course_id !== previous_course_id) ? previous_student_list : findDeletedValues(previous_student_list, student_list);
				const added_students = (course_id !== previous_course_id) ? student_list : findAddedValues(previous_student_list, student_list);

				// delete student matches
				deleted_students.forEach(student_id => {
					const student_index = new_students.findIndex(student => student.student_id === student_id);
					const student_info = new_students[student_index];
					const student_requests = student_info.student_requests;
					const student_sections = student_info.student_sections;
					const student_locked_sections = student_info.student_locked_sections;

					// remove student section
					const student_section_index = student_sections.findIndex(section => section === section_id);
					if(student_section_index !== -1) student_sections.splice(student_section_index, 1);

					// remove student locked_section
					const student_locked_section_index = student_locked_sections.findIndex(section => section === section_id);
					if(student_locked_section_index !== -1) student_locked_sections.splice(student_locked_section_index, 1);

					// remove match for student
					const request_index = student_requests.findIndex(request => request.course_id === previous_course_id);
					if(request_index !== -1) student_requests[request_index]['matched'] = '0';
				});

				// remove added students from any previous sections of this course
				const non_span_sections_with_course = sections.filter(section => section.course_id === course_id && section.section_id !== section_id && section.span_id !== span_id);
				non_span_sections_with_course.forEach(swc_section_info => {
					const swc_section_id = swc_section_info.section_id;
					const swc_section_index = sections.findIndex(section => section.section_id === swc_section_id);
					
					const swc_student_list = swc_section_info.student_list;
					const swc_student_list_without_added_students = swc_student_list.reduce((results, student_id) => {
						const in_added_student_list = added_students.findIndex(added_student_id => added_student_id === student_id) !== -1;
						
						const student_index = students.findIndex(student => student.student_id === student_id);
						const student_info = (student_index !== -1) ? students[student_index] : null;
						const student_locked_sections = (student_info) ? student_info.student_locked_sections : [];

						const student_has_section_locked = student_locked_sections.findIndex(locked_section_id => locked_section_id === swc_section_id) !== -1;

						if(in_added_student_list && !student_has_section_locked) return results;

						results.push(student_id);
						return results;
					}, []);

					sections[swc_section_index]['student_list'] = swc_student_list_without_added_students;
				});
				
				// add student match
				added_students.forEach(student_id => {
					const student_index = new_students.findIndex(student => student.student_id === student_id);
					const student_info = new_students[student_index];
					const student_requests = student_info.student_requests;
					const student_sections = student_info.student_sections;

					// add section to student sections
					const student_section_index = student_sections.findIndex(section => section === section_id);
					if(student_section_index === -1) student_sections.push(section_id);

					// add request match
					const request_index = student_requests.findIndex(request => request.course_id === course_id);
					
					if(request_index !== -1)
					{
						student_requests[request_index]['matched'] = '1';
						student_requests[request_index]['is_deleted'] = '0';
					}
					else
					{
						student_requests.push({request_id:'new', course_id:course_id, matched:'1', priority:'1', data_origin:'2', is_deleted:'0'});
					}
				});

				// Update teacher can teach
				if(teacher_id)
				{
					const teachers_can_teach = course_info.teachers_can_teach;
					const teacher_can_teach = teacher_info.teacher_can_teach;
					
					const teachers_can_teach_index = teachers_can_teach.findIndex(tct_teacher => tct_teacher.teacher_id === teacher_id);
					const teacher_can_teach_index = teacher_can_teach.findIndex(tct_course => tct_course.course_id === course_id);
					
					if(teachers_can_teach_index === -1) teachers_can_teach.push({teacher_id:teacher_id, type:'primary'});
					if(teacher_can_teach_index === -1) teacher_can_teach.push({course_id:course_id, type:'primary'});
				}
			}

			// Finally, UPDATE sections JSON... either replace old section data or push new data
			if(previous_section_index !== -1) 
			{
				sections[previous_section_index] = section_info;
				
				if(previous_filtered_results_index !== -1) filteredResults[previous_filtered_results_index] = section_info;
			}
			else
			{
				if('new' in section_info) delete section_info.new;
				sections.push(section_info);
				filteredResults.push(section_info);
			}
		});

		setSections([...sections]);
		setFilteredResults([...filteredResults]);
		setCourses([...courses]);
		setStudents([...new_students]);
		setTeachers([...teachers]);
		
		setIsSaving(false);

		if(student_id)
		{
			toggleEditStudentSchedule(student_id);
		}
		else
		{
			toggleEditSection();
		}
	}
	
	const saveSection = async (student_id = null) => {
		/////////////////////////////////////////////////
		//// Check if all necessary data is inputted ////
		/////////////////////////////////////////////////
		let all_data_inputted = true;

		Object.keys(sectionsData).forEach(section_key => {
			const section_data = sectionsData[section_key];
			
			if(section_key === 'main')
			{
				const course_id = section_data.course_id;
				const teacher_id = section_data.teacher_id;
				const classroom_id = section_data.classroom_id;
				const quarter_days = section_data.quarter_days;
				
				if(quarter_days.length === 0) all_data_inputted = false;
				if(sectionType === '1' && (!course_id || (!teacher_id && !classroom_id))) all_data_inputted = false;
			}
			else
			{
				const course_id = section_data.course_id;
				if(!course_id) all_data_inputted = false;
			}
		});
		
		if(!all_data_inputted)
		{
			const options =  {
				title: "Missing Data",
				icon:"warning",
				buttons: {
					cancel: {
						text: "Okay",
						value: false,
						visible: true,
						className: 'blue-btn'
					}
				},
				content: (
					<div>
						<p>You are missing data! Make sure the section has:</p>
						<ul style={{textAlign:'left', paddingLeft:'100px'}}>
							{sectionType === '1' &&
								<>
								<li>a course</li>
								<li>a teacher OR classroom assigned</li>
								</>
							}
							<li>quarters/days set</li>	
						</ul>
						{sectionType === '1' &&
							<p>You also cannot have subsections without courses!</p>
						}
					</div>
				)
			}
		
			swal(options);
			return;
		}
		
		setIsSaving(true);

		if(sectionID && !changesMade)
		{
			setIsSaving(false);

			if(student_id)
			{
				toggleEditStudentSchedule(student_id);
				return;
			}
			else
			{
				toggleEditSection();
				return;
			}
		}

		setConflictsLoading(true);
		setMatchPercentLoading(true);

		/////////////////////////////////////////////////////////////////////
		//// Update Section Placement (if the course period has changed) ////
		/////////////////////////////////////////////////////////////////////
		const main_section_info = sectionsData['main']
		const main_section_id = main_section_info.section_id;
		const new_course_period_id = main_section_info.course_period_id;

		const main_section_previous_info = sections.find(section => section.section_id === main_section_id);
		const main_section_previous_course_period_id = (main_section_previous_info) ? main_section_previous_info.course_period_id : null;
		const course_period_has_changed = (main_section_previous_course_period_id && new_course_period_id !== main_section_previous_course_period_id);

		// If course period has changed, update placements first
		if(course_period_has_changed) sessionStorage.setItem('undoneActions', JSON.stringify([]));
		
		const data = {school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, section_data:sectionsData, section_type:sectionType};
		
		const apiName = process.env.REACT_APP_ENDPOINT_NAME;
		const url = (sectionType === '1') ? '/update-section' : '/update-section-placeholder';
	    const myInit = { // OPTIONAL
	        response: true,
	        body: JSON.stringify(data),
	    };
	   
		try {
			const result = await API.post(apiName, url, myInit);
			const response_data = result.data;
			//console.log({response_data});
			
			const returned_sections = response_data.returned_sections;
			const deleted_sections = response_data.deleted_sections;
			const returned_student_locked_sections = response_data.student_locked_sections;

			const new_students = cloneObj(students);

			returned_student_locked_sections.forEach(student_section_info => {
				const section_id = student_section_info.section_id;
				const student_id = student_section_info.student_id;
				const is_locked = student_section_info.is_locked;

				const student_index = new_students.findIndex(student => student.student_id === student_id);
				const student_info = (student_index !== -1) ? new_students[student_index]: null;
				const student_locked_sections = (student_info) ? student_info.student_locked_sections : [];

				const section_index = student_locked_sections.findIndex(section => section === section_id);

				if(is_locked === '0' && section_index !== -1)
				{
					new_students[student_index]['student_locked_sections'].splice(section_index, 1);
				}
				else if(is_locked === '1' && section_index === -1)
				{
					new_students[student_index]['student_locked_sections'].push(section_id);
				}
			});

			///////////////////////////
			// Update section info
			///////////////////////////
			updateSectionJSON(new_students, returned_sections, deleted_sections, student_id);
			
			//if(sectionType === '1') createInclusionLabels({school_id: schoolInfo.school_id, update_type:'create'});
			//console.log("Got through create inclusion labels");

			///////////////////////////
			// Update conflicts
			///////////////////////////
			const conflicts_to_check = ['teacher_double_booked', 'classroom_double_booked', 'student_double_booked', 'teacher_conflicting_periods', 'classroom_conflicting_periods', 'student_conflicting_periods', 'teacher_missing', 'teacher_period_restriction', 'teacher_too_many_sections', 'teacher_too_many_sections_in_a_row', 'student_student_restriction', 'teacher_student_restriction', 'course_period_restriction', 'course_exceeds_capacity', 'course_outside_normal_room', 'classroom_missing', 'inclusion_too_many_students', 'inclusion_over_half'];

			const affected_section_ids = returned_sections.reduce((results, returned_section) => {
				const section_id = returned_section.section_id;
				results.push(section_id);
				return results;
			}, deleted_sections);

			calculateConflicts({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, conflicts_to_check:conflicts_to_check, affected_sections:affected_section_ids, update_all:false}).then(conflicts_response => {
				const added_conflicts = conflicts_response.added_conflicts;
				const deleted_conflicts = conflicts_response.deleted_conflicts;

				// Remove any deleted conflicts, add any added conflicts
				let new_conflicts = conflicts.filter(conflict => !deleted_conflicts.includes(conflict.conflict_id));
				new_conflicts = [...new_conflicts, ...added_conflicts];

				setConflicts([...new_conflicts]);
				setConflictsLoading(false);
			});

			///////////////////////////
			// Recalculate match percent
			///////////////////////////
			recalculateMatchPercent({school_id:schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id}).then(match_percent => {
				setMatchPercent(match_percent);
				setMatchPercentLoading(false);
			});
		} catch(error)
		{
			console.log(error.response);
		}
	}
	
	/////////////////////////////
	///// FOCUS ON INPUT ////////
	/////////////////////////////
	useEffect(() => {
		for(const section_id in editingTracker)
		{
			for(const input_type in editingTracker[section_id])
			{
				if(editingTracker[section_id][input_type])
				{	
					////////////////////////////////////////////
					///// GET SUGGESTED DATA AFTER OPENING /////
					////////////////////////////////////////////
					// Show data with restrictions or connections first
					// First get teachers who can teach this course
					let tct_courses = [];
					let tct_teachers = [];
					let course_classroom_restrictions = [];
					let teacher_classroom_restrictions = [];
					let students_requesting = [];
					
					if(input_type === 'course')
					{
						const teacher_id = sectionsData[section_id].teacher_id;

						if(teacher_id)
						{
							const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
							
							const teacher_can_teach = teacher_info.teacher_can_teach;
							const primary_courses = teacher_can_teach.filter(tct_info => tct_info.type === '1');
							const secondary_courses = teacher_can_teach.filter(tct_info => tct_info.type === '2');
							
							primary_courses.forEach(tct_info => {
								const course_id = tct_info.course_id;
								const course_info = courses.find(course => course.course_id === course_id);
								
								tct_courses.push(course_info);
							});
							
							secondary_courses.forEach(tct_info => {
								const course_id = tct_info.course_id;
								const course_info = courses.find(course => course.course_id === course_id);
								
								tct_courses.push(course_info);
							});
							
							searchResults[section_id]['courses'].suggested = tct_courses;
						}
						
						searchResults[section_id]['courses'].search = courses;

						setSearchResults({...searchResults});
					}
					else if(input_type === 'teacher')
					{
						const course_id = sectionsData[section_id].course_id;
					
						if(course_id)
						{
							const course_info = courses.find(course => course.course_id === course_id);
							const teachers_can_teach = course_info.teachers_can_teach;
							const primary_teachers = teachers_can_teach.filter(tct_info => tct_info.type === '1');
							const secondary_teachers = teachers_can_teach.filter(tct_info => tct_info.type === '2');
							
							primary_teachers.forEach(tct_info => {
								const teacher_id = tct_info.teacher_id;
								const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
								
								tct_teachers.push(teacher_info);
							});
							
							secondary_teachers.forEach(tct_info => {
								const teacher_id = tct_info.teacher_id;
								const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
								
								tct_teachers.push(teacher_info);
							});
							
							searchResults[section_id]['teachers'].suggested = tct_teachers;
						}
						
						searchResults[section_id]['teachers'].search = teachers;
							
						setSearchResults({...searchResults});
					}
					else if(input_type === 'classroom')
					{
						const course_id = sectionsData[section_id].course_id;
						const teacher_id = sectionsData[section_id].teacher_id;
						
						// Get any course-classroom restrictions
						if(course_id)
						{
							const course_info = courses.find(course => course.course_id === course_id);
							const classroom_restrictions = course_info.classroom_restrictions;
							
							classroom_restrictions.forEach(classroom_id => {
								const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
								
								course_classroom_restrictions.push(classroom_info);
							});
							
							searchResults[section_id]['classrooms'].suggested.course_classroom_restrictions = course_classroom_restrictions;
						}

						// Get any teacher-classroom preferences
						if(teacher_id)
						{
							const teacher_info = teachers.find(teacher => teacher.teacher_id === teacher_id);
							const classroom_restrictions = teacher_info.classroom_restrictions;
							
							classroom_restrictions.forEach(classroom_id => {
								const classroom_info = classrooms.find(classroom => classroom.classroom_id === classroom_id);
								
								teacher_classroom_restrictions.push(classroom_info);
							});
							
							searchResults[section_id]['classrooms'].suggested.teacher_classroom_restrictions = teacher_classroom_restrictions;
						}

						searchResults.main.classrooms.search = classrooms;

						setSearchResults({...searchResults});
					}
					else if(input_type === 'students')
					{
						const course_id = sectionsData[section_id].course_id;
					
						if(course_id)
						{
							const student_requests = students.reduce((results, student) => {
								const requests = student.student_requests;
								const request_index = requests.findIndex(request => request.course_id === course_id && request.is_deleted === '0');

								if(request_index !== -1)
								{
									const priority = requests[request_index].priority;
									const matched = sectionsData[section_id].student_list.includes(student.student_id) || (sections.findIndex(section => section.course_id === course_id && section.student_list.includes(student.student_id)) !== -1);

									results.push({student_id:student.student_id, priority:priority, matched:matched});
								}

								return results;
							}, []);
							const unmatched = student_requests.filter(request_info => !request_info.matched);
							const matched = student_requests.filter(request_info => request_info.matched);
							
							unmatched.forEach(request_info => {
								const student_id = request_info.student_id;
								const student_info = students.find(student => student.student_id === student_id);
								
								students_requesting.push(student_info);
							});
							
							matched.forEach(request_info => {
								const student_id = request_info.student_id;
								const student_info = students.find(student => student.student_id === student_id);
								
								students_requesting.push(student_info);
							});
							
							searchResults[section_id]['students'].suggested = students_requesting;
						}
						
						if(searchResults[section_id]['students'].suggested.length === 0) searchResults[section_id]['students'].search = students;
							
						setSearchResults({...searchResults});
					}
				}
			}
		}
	}, [editingTracker]);
	
	
	/////////////////////////
	///// DO ON LOAD ////////
	/////////////////////////
	useEffect(() => {
		let num_days_array = [];
		let num_days_in_cycle = Object.keys(scheduleStructureData.schedule_structure).length;
		for(var day = 1; day<=num_days_in_cycle; day++)
		{
			num_days_array.push(`${day}`);
		}
		setNumDaysInCycle(num_days_array);

		if(sectionID)
		{
			// Set main section data and input editing tracker
			const main_section_index = sections.findIndex(section => section.section_id === sectionID);
			if(main_section_index === -1) return;
			const main_section_data = cloneObj(sections[main_section_index]);

			const course_period_id = (passedCoursePeriodID) ? passedCoursePeriodID : main_section_data.course_period_id;
			setCoursePeriodID(course_period_id);
			const locked_students = main_section_data.student_list.reduce((results, student_id) => {
				const student_index = students.findIndex(student => student.student_id === student_id);
				if(student_index === -1) return results;
				const student_info = students[student_index];
				const student_locked_sections = student_info.student_locked_sections;
				if(student_locked_sections.includes(sectionID)) results.push(student_id);
				return results;
			}, []);
			
			// Set up main section data
			sectionsData.main = main_section_data;
			sectionsData.main.locked_students = locked_students;

			// Set up main section trackers and search results
			editingTracker.main = {course: false, teacher: false, classroom:false, label_options_open:false};
			searchResults.main = {courses: {suggested:[], search:[]}, teachers:{suggested:[], search:[]}, classrooms: {suggested:{course_classroom_restrictions:[], teacher_classroom_restrictions:[]}, search:[]}, students:{suggested_filter_ids:[], suggested:[], search:[]}};
			
			// Set subsection data and input editing tracker
			const subsections = sectionsData.main.subsections;
			
			/// Set up all Subsections ////
			subsections.forEach(subsection_id => {
				const subsection_index = sections.findIndex(section => section.section_id === subsection_id);

				if(subsection_index !== -1)
				{
					const subsection_data = cloneObj(sections[subsection_index]);

					const subsection_locked_students = subsection_data.student_list.reduce((results, student_id) => {
						const student_index = students.findIndex(student => student.student_id === student_id);
						if(student_index === -1) return results;
						const student_info = students[student_index];
						const student_locked_sections = student_info.student_locked_sections;
						if(student_locked_sections.includes(subsection_id)) results.push(student_id);
						return results;
					}, []);
				
					// Set up subsection data
					sectionsData[subsection_id] = subsection_data;
					sectionsData[subsection_id].locked_students = subsection_locked_students;

					// Set up subsection trackers and search results
					editingTracker[subsection_id] = {course: false, teacher: false, students: false, label_options_open:false};
					searchResults[subsection_id] = {courses:{suggested:[], search:[]}, teachers:{suggested:[], search:[]}, students:{suggested_filter_ids:[], suggested:[], search:[]}};	
				}
			});

			// Create predictive quarter days if they don't exist already
			if(main_section_data.quarter_days.length <= 0 && main_section_data.course_id)
			{
				const course_info = courses.find(course => course.course_id === main_section_data.course_id);
				const course_semester = course_info.semester;
				const course_default_quarters = semester_to_quarters[course_semester];

				num_days_array.forEach(day => {
					course_default_quarters.forEach(quarter => {
						sectionsData['main']['quarter_days'].push({quarter:quarter, day:day});
					});
				});

				setChangesMade(true);
			}
			
			// Set section data
			setSectionsData({...sectionsData});
			
			// Set editing trackers
			setEditingTracker({...editingTracker});
			
			// Set search results
			setSearchResults({...searchResults});
		}
		else
		{
			const newSectionID = createUniqueObjectID(5, sectionsData);
			const teacher_id = (currentView === 'teacher') ? dataID : null;
			const classroom_id = (currentView === 'classroom') ? dataID : null;

			let default_quarter_days = [];
			num_days_array.forEach(day => {
				quarters.forEach(quarter => {
					default_quarter_days.push({day:day, quarter:quarter});
				});
			});
			
			// Set section data
			setSectionsData({'main': {new: true, section_type:sectionType, section_id:newSectionID, course_id:null, teacher_id:teacher_id, classroom_id:classroom_id, course_period_id:passedCoursePeriodID, quarter_days:default_quarter_days, student_list:[], locked_students:[], subsections:[], span_id:null, labels:[], is_lab:'0', is_inclusion:'0', locked:'0'}});
			
			// Set editing trackers
			setEditingTracker({'main': {course: false, teacher: false, classroom:false, students:false}});
			
			// Set search results
			setSearchResults({'main': {courses: {suggested:[], search:[]}, teachers:{suggested:[], search:[]}, classrooms: {suggested:{course_classroom_restrictions:[], teacher_classroom_restrictions:[]}, search:[]}, students:{suggested_filter_ids:[], suggested:[], search:[]}}});
		}

		const period_options = (scheduleType === 'daily') ? Object.keys(scheduleStructureData.schedule_periods).map(schedule_period_key => {
			const schedule_period_info = scheduleStructureData.schedule_periods[schedule_period_key];
			const schedule_period_id = schedule_period_info.period_id;
			const schedule_period_name = schedule_period_info.period_name;
			const course_period_id = scheduleStructureData.schedule_structure['1'][schedule_period_id];
			return {value:course_period_id, display:schedule_period_name};
		}) : Object.keys(scheduleStructureData.course_periods).map(course_period_key => {
			const course_period_info = scheduleStructureData.course_periods[course_period_key];
			const course_period_id = course_period_info.period_id;
			const course_period_name = course_period_info.period_name;
			return {value:course_period_id, display:course_period_name};
		});
		
		setPeriodOptions(period_options);	
		setIsLoading(false);
	}, [sections, students, passedCoursePeriodID]);

	// Calculate Teacher, Student, and Classroom Availabilities
	useEffect(() => {
		if(!studentsLoading && !teachersLoading && !coursesLoading && !classroomsLoading && !sectionsLoading && !isLoading)
		{
			if(availabilitiesCalculated) return;
			if(sectionType !== '1' || !coursePeriodID || sectionsData['main'].quarter_days.length <= 0) return;

			const schedule_structure = scheduleStructureData.schedule_structure;
			let excluded_sections = (sectionID) ? [sectionID] : [];

			/////////////////////////////////////////////////////////////////////////////////////////////////////////
			//// COLLECT ALL SPACES IN THE SCHEDULE THAT WOULD NEED TO BE (COULD BE) OPEN TO FULFILL THIS COURSE ////
			/////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Start by collecting the main sections course period
			let potential_course_period_quarter_days = [{course_period_id:coursePeriodID, quarter_days: sectionsData['main'].quarter_days, conflicting_course_periods:[coursePeriodID], excluded_sections:excluded_sections}];

			// If span, collect the spans as course periods/quarters that would need to be open for something to be available
			// Else, if this is a new section and course needs multiple periods, then just find all periods that would need to be open for something to be available (including spans that would be created)
			if(sectionsData['main'].span_id)
			{
				const main_section_info = sectionsData['main']
				const main_section_id = main_section_info.section_id;

				// Get all other spanned sections
				const spanned_sections = sections.filter(section => section.span_id === sectionsData['main'].span_id && section.section_id !== main_section_id);

				potential_course_period_quarter_days = spanned_sections.reduce((results, section) => {
					const section_id = section.section_id;
					const section_course_period_id = section.course_period_id;
					const section_quarter_days = section.quarter_days;
					const section_is_lab = section.is_lab;

					if(!excluded_sections.includes(section_id)) excluded_sections.push(section_id);

					if(results.findIndex(course_period_quarter_day => course_period_quarter_day.course_period_id === section_course_period_id) === -1 && section_quarter_days.length !== 0) results.push({course_period_id:section_course_period_id, quarter_days:section_quarter_days, conflicting_course_periods:[section_course_period_id], excluded_sections:excluded_sections});

					// If lab, then any quarter needs to be open for this to be available
					if(section_is_lab && section_quarter_days.length === 0)
					{
						let section_quarters = [];
						sectionsData['main'].quarter_days.forEach(quarter_day => {
							const section_quarter = quarter_day.quarter;
							const section_day = quarter_day.day;
							if(section_quarters.findIndex(section_quarter => section_quarter.day === section_day && section_quarter.quarter === section_quarter) === -1) section_quarters.push({day:section_day, quarter:section_quarter});
						});

						numDaysInCycle.forEach(day => {
							results.push({course_period_id:section_course_period_id, quarter_days:section_quarters, conflicting_course_periods:[section_course_period_id], excluded_sections:excluded_sections});
						});
					}
					
					return results;
				}, potential_course_period_quarter_days);
			}
			else if(!sectionID && sectionsData['main'].course_id)
			{
				const course_info = courses.find(course => course.course_id === sectionsData['main'].course_id);

				const is_separate_lab = course_info.is_separate_lab;
				const num_periods_spanned = course_info.num_periods_spanned;
				const total_num_periods_spanned = (is_separate_lab === '1') ? (parseInt(num_periods_spanned) + 1) : parseInt(num_periods_spanned);

				// If this is spanned course then find all course periods that would be created and add potential space
				if(total_num_periods_spanned > 1)
				{
					const logged_course_periods = [coursePeriodID];
					let next_course_period_id = coursePeriodID;

					for(var extra_sections_counter=1; extra_sections_counter < total_num_periods_spanned; extra_sections_counter++)
					{
						const is_lab = (is_separate_lab && extra_sections_counter === (total_num_periods_spanned - 1)) ? true : false;

						while(logged_course_periods.includes(next_course_period_id))
						{
							const temp_period_id = (currentViewType === 'day') ? coursePeriodToSchedulePeriod(scheduleStructureData, next_course_period_id, currentDay) : next_course_period_id;
							const temp_next_period_id = (currentViewType === 'day') ? getNextSchedulePeriod(scheduleStructureData, temp_period_id) : getNextCoursePeriod(scheduleStructureData, temp_period_id);
							const temp_next_course_period_id = (currentViewType === 'day') ? scheduleStructureData.schedule_structure[currentDay][temp_next_period_id] : temp_next_period_id;

							// If next course period doesnt exist, grab previous course period
							if(!temp_next_course_period_id || logged_course_periods.includes(temp_next_course_period_id))
							{
								next_course_period_id = getPreviousCoursePeriod(scheduleStructureData, next_course_period_id);
							}
							else
							{
								next_course_period_id = temp_next_course_period_id;
							}
						}
						
						logged_course_periods.push(next_course_period_id);
						
						if(is_lab) 
						{
							sectionsData['main'].quarter_days.forEach(quarter_day => {
								const section_quarter = quarter_day.quarter;

								numDaysInCycle.forEach(day => {
									potential_course_period_quarter_days.push({course_period_id:next_course_period_id, quarter_days:[{day:day, quarter:section_quarter}], conflicting_course_periods:[next_course_period_id], excluded_sections:excluded_sections});
								});
							});
						}
						else
						{
							potential_course_period_quarter_days.push({course_period_id:next_course_period_id, quarter_days:sectionsData['main'].quarter_days, conflicting_course_periods:[next_course_period_id], excluded_sections:excluded_sections});
						}
					}
				}
			}

			// If daily schedule, then there could be overlapping periods that create conflicts for some of the periods
			if(scheduleInfo.schedule_type === 'daily')
			{
				// For each potential, add all conflicting periods to potentials that needs to be open for this to be considered available
				for(const potential_course_period_info of potential_course_period_quarter_days)
				{
					const potential_course_period_id = potential_course_period_info.course_period_id;
					const potential_conflicting_course_periods = potential_course_period_info.conflicting_course_periods;
					const course_period_schedule_periods = scheduleStructureData.course_periods[potential_course_period_id]['days'];

					for(const day in course_period_schedule_periods)
					{
						const schedule_period_id = course_period_schedule_periods[day];

						if(!schedule_period_id) continue;
						
						const conflicting_schedule_periods = scheduleStructureData.availability_matrix[schedule_period_id];
						for(const day in schedule_structure)
						{
							const day_schedule_periods = schedule_structure[day];

							for(const temp_schedule_period_id in day_schedule_periods)
							{
								if(conflicting_schedule_periods.includes(temp_schedule_period_id))
								{
									const temp_course_period_id = day_schedule_periods[temp_schedule_period_id];

									if(!potential_conflicting_course_periods.includes(temp_course_period_id)) potential_conflicting_course_periods.push(temp_course_period_id);
								}
							}
						}
					}
				}
			}

			const teacher_potentials = cloneObj(potential_course_period_quarter_days);
			const student_potentials = cloneObj(potential_course_period_quarter_days);
			const classroom_potentials = cloneObj(potential_course_period_quarter_days);

			let teachers_availability = [];
			teachers.forEach(teacher_info => {
				const teacher_availability = checkTeacherAvailability(teacher_info.teacher_id, teacher_potentials);
				teachers_availability.push({teacher_id:teacher_info.teacher_id, availability:teacher_availability});
			});
			setTeachersAvailability([...teachers_availability]);

			let students_availability = [];
			students.forEach(student_info => {
				const student_availability = checkStudentAvailability(student_info.student_id, student_potentials);
				students_availability.push({student_id:student_info.student_id, availability:student_availability});
			});
			setStudentsAvailability([...students_availability]);

			let classrooms_availability = [];
			classrooms.forEach(classroom_info => {
				const classroom_availability = checkClassroomAvailability(classroom_info.classroom_id, classroom_potentials);
				classrooms_availability.push({classroom_id:classroom_info.classroom_id, availability:classroom_availability});
			});
			setClassroomsAvailability([...classrooms_availability]);

			setAvailabilitiesCalculated(true);
		}
	}, [sectionsData, coursePeriodID, teachers, students, courses, classrooms]);

	//// Collect all Teams/Labels ////
	const teams = labels.filter(label => label.is_team === '1');
	const sorted_teams = sortArrayOfObjects(teams, 'label', 'text', 'desc');
	
	const section_labels = labels.filter(label => label.is_team === '0');
	const sorted_labels = sortArrayOfObjects(section_labels, 'label', 'text', 'desc');

	///// Collect All Main Section Data /////
	const mainSectionLoaded = ('main' in sectionsData);
	
	const main_course_info = (mainSectionLoaded && sectionsData.main.course_id) ? courses.find(course => course.course_id === sectionsData.main.course_id) : null;
	const main_teacher_info = (mainSectionLoaded && sectionsData.main.teacher_id) ? teachers.find(teacher => teacher.teacher_id === sectionsData.main.teacher_id) : null;
	const main_classroom_info = (mainSectionLoaded && sectionsData.main.classroom_id) ? classrooms.find(classroom => classroom.classroom_id === sectionsData.main.classroom_id) : null;
	
	const main_course_name = (main_course_info) ? `${main_course_info.name} (${main_course_info.course_code})` : null;
	const main_teacher_name = (main_teacher_info) ? `${main_teacher_info.name}, ${main_teacher_info.first_name}` : null;
	const main_classroom_name = (main_classroom_info) ? `${main_classroom_info.classroom_name}` : null;

	const main_quarter_days = (mainSectionLoaded && sectionsData.main.quarter_days) ? sectionsData.main.quarter_days : [];

	let main_section_all_labels = (mainSectionLoaded && sectionsData.main.labels) ? sectionsData.main.labels : [];												
	main_section_all_labels = main_section_all_labels.map(label_id => {
		const label_info = labels.find(label => label.label_id === label_id);
		return label_info;
	});
	
	const main_section_teams = main_section_all_labels.filter(label => label.is_team === '1');
	const sorted_main_section_teams = sortArrayOfObjects(main_section_teams, 'label', 'text', 'desc');
	
	const main_section_labels = main_section_all_labels.filter(label => label.is_team === '0');
	const sorted_main_section_labels = sortArrayOfObjects(main_section_labels, 'label', 'text', 'desc');

	const total_student_count = Object.keys(sectionsData).reduce(function (total_students, key) {
		const section_student_count = sectionsData[key].student_list.length;
		return total_students + section_student_count;
	}, 0);

	const main_students_selected = (selectedStudents.filter(student_id => sectionsData.main.student_list.includes(student_id)).length > 0);

	//console.log(sectionsData);
	//console.log(changesMade);
	//console.log({dataID, classrooms, courses, teachers, students});
	//console.log(searchResults);
	
	return (
		<div className='modal-screen'>
			<div className='modal-screen-content'>
				{!isSaving &&
					<>
					<span className="data-modal-close" onClick={handleExitWithoutSaving}>&times;</span>
					{sectionID &&
						<div className="modal-delete-btn" onClick={handleDeleteSection}><FontAwesomeIcon icon={faTrashAlt}/></div>
					}
					<div className="modal-save-btn" onClick={() => saveSection(null)}><FontAwesomeIcon icon={faSave}/>Save {sectionType === '2' ? 'Prep' : sectionType === '3' ? 'Duty' : 'Section'}</div>
					</>
				}
				<div className='full-width'>
					<div id='edit-section-data-content'>
						<FontAwesomeIcon className={`edit-data-main-head-icon ${sectionType === '2' ? 'pastel-light-blue-text' : sectionType === '3' ? 'pastel-brown-text' : 'dark-blue-text'}`} icon={faEdit}/>
						<h1 className={`edit-data-main-header ${sectionType === '2' ? 'pastel-light-blue-text' : sectionType === '3' ? 'pastel-brown-text' : 'dark-blue-text'}`}>{sectionID ? 'Edit' : 'Add New'} {sectionType === '2' ? 'Prep' : sectionType === '3' ? 'Duty' : 'Section'}</h1>
						{isSaving &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
								<div>Saving...</div>
							</div>
						}
						{isLoading &&
							<div className='dashboard-database-message-container'>
								<img src={require('../../../images/balls.gif')} alt='loading...' style={{height:'80px'}}/>
							</div>
						}
						{(!isLoading && !isSaving) &&
							<form className={`edit-data-form ${sectionType === '1' ? 'edit-section-form' : ''}`} style={{gridColumnGap:'50px'}}>
								{(availabilityViewID) &&
									<Availability availabilityDataType={availabilityDataType} availabilityViewID={availabilityViewID} availabilityDataPosition={availabilityDataPosition} scheduleType={scheduleType} currentDay={currentDay} teachers={teachers} classrooms={classrooms} students={students} courses={courses} sections={sections} scheduleStructureData={scheduleStructureData} />
								}
								<div className='edit-section-sections-container'>
									<div className='edit-section-main-section-container'>
										<div className={`data-modal-heading edit-section-heading ${sectionType === '2' ? 'pastel-light-blue' : sectionType === '3' ? 'pastel-brown' : ''}`}>
											<div>{sectionType === '2' ? 'Prep' : sectionType === '3' ? 'Duty' : 'Main Section'}</div>
										</div>
										<div className='edit-section-main-section-content'>
											{sectionType === '1' &&
												<>
												{sectionID &&
													<>
													<div className='edit-section-label'>Section #</div>
													<div className='edit-section-data-row'>
														<div>Section {sectionsData.main.section_number}</div>
													</div>
													</>
												}
												<div className='edit-section-label'>Course</div>
												<div className='edit-section-data-row-container'>
													{!editingTracker.main.course ?
														(
															<div className='edit-section-data-row'>
																<div>{sectionsData.main.course_id ? main_course_name : '--'}</div>
																<div className='blue-link medium-text' onClick={() => toggleEditing('main', 'course')}>{sectionsData.main.course_id ? 'Change' : 'Add'}...</div>
															</div>
														) :
														(
															<>
															<div className='search-add-search-container'>
																<div className='search-add-adding-input-container'>
																	<input id='main-course-input' className='edit-section-input' placeholder='Search for a course' defaultValue={main_course_name} onChange={(e) => fuzzySearch(e, 'main', 'courses')} autoFocus={true} onFocus={handleFocus}/>
																	<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
																</div>
																<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes}  onClick={() => toggleEditing('main', 'course')}/>
															</div>
															{searchResults.main.courses.suggested.length !== 0 &&
																<>
																<div className='search-add-did-you-mean'>Courses this Teacher Teaches:</div>
																<div className='search-add-search-results'>
																	{searchResults.main.courses.suggested.map((course, i) => {
																		if(!course) return null;
																		return (
																			<div className='search-add-search-result' key={i}>
																				<div>{course.name} ({course.course_code})</div>
																				<div>
																					<div className='search-add-add-btn' onClick={() => updateData('change', 'main', 'course_id', course.course_id)}>Select</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
																</>
															}
															{searchResults.main.courses.search.length !== 0 &&
																<>
																<div className='search-add-did-you-mean'>Other Courses:</div>
																<div className='search-add-search-results'>
																	{searchResults.main.courses.search.map((course, i) => {
																		return (
																			<div className='search-add-search-result' key={i}>
																				<div>{course.name} ({course.course_code})</div>
																				<div>
																					<div className='search-add-add-btn' onClick={() => updateData('change', 'main', 'course_id', course.course_id)}>Select</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
																</>
															}
															</>
														)											
													}
												</div>
												<div className='edit-section-label'>Teacher</div>
												<div className='edit-section-data-row-container'>
													{!editingTracker.main.teacher ?
														(
															<div className='edit-section-data-row'>
																<div>{sectionsData.main.teacher_id ? <span className='edit-section-current-data'>{main_teacher_name} <span><FontAwesomeIcon icon={faTimes} className='medium-text gray-to-red-link' onClick={() => updateData('change', 'main', 'teacher_id', null)}/></span></span> : '--'}</div>
																<div className='blue-link medium-text' onClick={() => toggleEditing('main', 'teacher')}>{sectionsData.main.teacher_id ? 'Change' : 'Add'}...</div>
															</div>
														) :
														(
															<>
															<div className='search-add-search-container'>
																<div className='search-add-adding-input-container'>
																	<input id='main-teacher-input' className='edit-section-input' placeholder='Search for a teacher' defaultValue={main_teacher_name} onChange={(e) => fuzzySearch(e, 'main', 'teachers')} autoFocus={true} onFocus={handleFocus}/>
																	<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
																</div>
																<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes}  onClick={() => toggleEditing('main', 'teacher')}/>
															</div>
															{searchResults.main.teachers.suggested.length !== 0 &&
																<>
																<div className='search-add-did-you-mean'>Teachers Who Can Teach:</div>
																<div className='search-add-search-results'>
																	{searchResults.main.teachers.suggested.map((teacher, i) => {
																		const teacher_availability_index = teachersAvailability.findIndex(teacher_availability => teacher_availability.teacher_id === teacher.teacher_id);
																		const teacher_available = (teacher_availability_index !== -1) ? teachersAvailability[teacher_availability_index].availability : false;

																		return (
																			<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px auto'}}>
																				<div>{teacher.name}, {teacher.first_name}</div>
																				<div className={`availability-hover small-text ${teacher_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'teacher', teacher.teacher_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'teacher', null)}>
																					{teacher_available ? 'Available' : 'Unavailable'}
																				</div>
																				<div>
																					<div className='search-add-add-btn' onClick={() => updateData('change', 'main', 'teacher_id', teacher.teacher_id)}>Select</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
																</>
															}
															{searchResults.main.teachers.search.length !== 0 &&
																<>
																<div className='search-add-did-you-mean'>Other Teachers:</div>
																<div className='search-add-search-results'>
																	{searchResults.main.teachers.search.map((teacher, i) => {
																		const teacher_availability_index = teachersAvailability.findIndex(teacher_availability => teacher_availability.teacher_id === teacher.teacher_id);
																		const teacher_available = (teacher_availability_index !== -1) ? teachersAvailability[teacher_availability_index].availability : false;
																		
																		return (
																			<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px auto'}}>
																				<div>{teacher.name}, {teacher.first_name}</div>
																				<div className={`availability-hover small-text ${teacher_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'teacher', teacher.teacher_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'teacher', null)}>{teacher_available ? 'Available' : 'Unavailable'}</div>
																				<div>
																					<div className='search-add-add-btn' onClick={() => updateData('change', 'main', 'teacher_id', teacher.teacher_id)}>Select</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
																</>
															}
															</>
														)											
													}
												</div>
												<div className='edit-section-label'>Classroom</div>
												<div className='edit-section-data-row-container'>
													{!editingTracker.main.classroom ?
														(
															<div className='edit-section-data-row'>
																<div>{sectionsData.main.classroom_id ? <span className='edit-section-current-data'>{main_classroom_name} <span><FontAwesomeIcon icon={faTimes} className='medium-text gray-to-red-link' onClick={() => updateData('change', 'main', 'classroom_id', null)}/></span></span> : '--'}</div>
																<div className='blue-link medium-text' onClick={() => toggleEditing('main', 'classroom')}>{sectionsData.main.classroom_id ? 'Change' : 'Add'}...</div>
															</div>
														) :
														(
															<>
															<div className='search-add-search-container'>
																<div className='search-add-adding-input-container'>
																	<input id='main-classroom-input' className='edit-section-input' placeholder='Search for a classroom' defaultValue={main_classroom_name} onChange={(e) => fuzzySearch(e, 'main', 'classrooms')} autoFocus={true} onFocus={handleFocus}/>
																	<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
																</div>
																<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes}  onClick={() => toggleEditing('main', 'classroom')}/>
															</div>
															{searchResults.main.classrooms.suggested.course_classroom_restrictions.length !== 0 &&
																<>
																<div className='search-add-did-you-mean'>Classroom(s) for this Course:</div>
																<div className='search-add-search-results'>
																	{searchResults.main.classrooms.suggested.course_classroom_restrictions.map((classroom, i) => {
																		const classroom_availability_index = classroomsAvailability.findIndex(classroom_availability => classroom_availability.classroom_id === classroom.classroom_id);
																		const classroom_available = (classroom_availability_index !== -1) ? classroomsAvailability[classroom_availability_index].availability : false;

																		return (
																			<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px auto'}}>
																				<div>{classroom.classroom_name}</div>
																				<div className={`availability-hover small-text ${classroom_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'classroom', classroom.classroom_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'classroom', null)}>{classroom_available ? 'Available' : 'Unavailable'}</div>
																				<div>
																					<div className='search-add-add-btn' onClick={() => updateData('change', 'main', 'classroom_id', classroom.classroom_id)}>Select</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
																</>
															}
															{searchResults.main.classrooms.suggested.teacher_classroom_restrictions.length !== 0 &&
																<>
																<div className='search-add-did-you-mean'>Teacher's Preferred Classroom(s):</div>
																<div className='search-add-search-results'>
																	{searchResults.main.classrooms.suggested.teacher_classroom_restrictions.map((classroom, i) => {
																		const classroom_availability_index = classroomsAvailability.findIndex(classroom_availability => classroom_availability.classroom_id === classroom.classroom_id);
																		const classroom_available = (classroom_availability_index !== -1) ? classroomsAvailability[classroom_availability_index].availability : false;
																		
																		return (
																			<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px auto'}}>
																				<div>{classroom.classroom_name}</div>
																				<div className={`availability-hover small-text ${classroom_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'classroom', classroom.classroom_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'classroom', null)}>{classroom_available ? 'Available' : 'Unavailable'}</div>
																				<div>
																					<div className='search-add-add-btn' onClick={() => updateData('change', 'main', 'classroom_id', classroom.classroom_id)}>Select</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
																</>
															}
															{searchResults.main.classrooms.search.length !== 0 &&
																<>
																<div className='search-add-did-you-mean'>Search Results:</div>
																<div className='search-add-search-results'>
																	{searchResults.main.classrooms.search.map((classroom, i) => {
																		const classroom_availability_index = classroomsAvailability.findIndex(classroom_availability => classroom_availability.classroom_id === classroom.classroom_id);
																		const classroom_available = (classroom_availability_index !== -1) ? classroomsAvailability[classroom_availability_index].availability : false;
																		
																		return (
																			<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px auto'}}>
																				<div>{classroom.classroom_name}</div>
																				<div className={`availability-hover small-text ${classroom_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'classroom', classroom.classroom_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'classroom', null)}>{classroom_available ? 'Available' : 'Unavailable'}</div>
																				<div>
																					<div className='search-add-add-btn' onClick={() => updateData('change', 'main', 'classroom_id', classroom.classroom_id)}>Select</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
																</>
															}
															</>
														)											
													}
												</div>
												</>
											}
											<div className='edit-section-label'>Period</div>
											<Dropdown data={periodOptions} currentValue={sectionsData['main'].course_period_id} handleChange={(new_value) => updateData('change', 'main', 'course_period_id', new_value)} />
											<div className='edit-section-label'>Quarters</div>
											{quarters.map(quarter => {
												const quarter_index = main_quarter_days.findIndex(quarter_day => quarter_day.quarter === quarter);
												const quarter_selected = (quarter_index !== -1) ? true : false;
												return (
													<div className='option-btn option-btn-no-hover small-text' key={quarter} onClick={() => updateQuarterDays('quarter', quarter)}>
														<div>
															<FontAwesomeIcon className={`quarter_day_icon ${quarter_selected ? 'fas-checkbox-checked' : 'fas-checkbox-unchecked'} ${(quarter_selected && sectionType === '2') ? 'pastel-light-blue-text' : (quarter_selected && sectionType === '3') ? 'pastel-brown-text' : ''}`} icon={quarter_selected ? faCheckSquare : faSquare}/>
														</div>
														<div>Q{quarter}</div>
													</div>
												)
											})}
											<div className='edit-section-label'>Days</div>
											{numDaysInCycle.map(day => {
												const day_index = main_quarter_days.findIndex(quarter_day => quarter_day.day === day);
												const day_selected = (day_index !== -1) ? true : false;
												return (
													<div className='option-btn option-btn-no-hover small-text' key={day} onClick={() => updateQuarterDays('day', day)}>
														<div>
															<FontAwesomeIcon className={`quarter_day_icon ${day_selected ? 'fas-checkbox-checked' : 'fas-checkbox-unchecked'} ${(day_selected && sectionType === '2') ? 'pastel-light-blue-text' : (day_selected && sectionType === '3') ? 'pastel-brown-text' : ''}`} icon={day_selected ? faCheckSquare : faSquare}/>
														</div>
														<div>Day {day}</div>
													</div>
												)
											})}
											{sectionType === '1' &&
												<>
												<div className='edit-section-labels-container'>
													<>
													{sorted_main_section_teams.map((label, index) => (
														<div key={label.label_id}>
															{label.label ?
																(
																	<div className='edit-section-section-label'>
																		<div className='create-labels-icon pastel-blue'></div>
																		<div>{label.label}</div>
																		<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => removeLabel('main', label.label_id)}/>
																	</div>
																) :
																(
																	<div className='create-labels-editing-label'>
																		<div className='create-labels-icon pastel-blue'></div>
																		<input className='create-labels-input' placeholder='Add label name...' onKeyPress={(e) => {if(e.key === 'Enter') updateLabel(e, 'main', label.label_id)}} autoFocus={true}/>
																		<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => removeLabel('main', label.label_id)}/>
																		<FontAwesomeIcon className='gray-to-blue-link' icon={faCheck} onClick={(e) => updateLabel(e, 'main', label.label_id)}/>
																	</div>
																)
															}
														</div>
													))}
													{sorted_main_section_labels.map((label, index) => (
														<div key={label.label_id}>
															{label.label ?
																(
																	<div className='edit-section-section-label'>
																		<div className='create-labels-icon pastel-orange'></div>
																		<div>{label.label}</div>
																		<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => removeLabel('main', label.label_id)}/>
																	</div>
																) :
																(
																	<div className='create-labels-editing-label'>
																		<div className='create-labels-icon pastel-orange'></div>
																		<input className='create-labels-input' placeholder='Add label name...' onKeyPress={(e) => {if(e.key === 'Enter') updateLabel(e, 'main', label.label_id)}} autoFocus={true}/>
																		<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => removeLabel('main', label.label_id)}/>
																		<FontAwesomeIcon className='gray-to-blue-link' icon={faCheck} onClick={(e) => updateLabel(e, 'main', label.label_id)}/>
																	</div>
																)
															}
														</div>
													))}
													</>
												</div>
												<div className='add-label-to-section blue-link small-text click-restricted' onClick={() => toggleLabelOptionsOpen('main')}>Add Team/Label</div>
												<div className='create-labels-label-options-container' data-sectionid='main'>
													{editingTracker.main.label_options_open &&
														<div className='data-options-container' ref={ref}>
															<div className='data-options-heading'>Teams</div>
															{sorted_teams.length === 0 &&
																<div className='create-labels-no-label-options'>No teams added yet...</div>
															}
															{sorted_teams.map((label, index) => (
																<div className='data-options-option create-labels-label-option' key={index} onClick={() => updateData('add', 'main', 'labels', label.label_id)}>
																	<div className='create-labels-icon pastel-blue'></div>
																	<div>{label.label}</div>
																</div>
															))}
															<div className='blue-link small-text edit-section-add-new-label-link' onClick={() => addTempLabel('main', '1')}>Add New Team</div>
															<div className='data-options-heading'>Labels</div>
															{sorted_labels.length === 0 &&
																<div className='create-labels-no-label-options'>No labels added yet...</div>
															}
															{sorted_labels.map((label, index) => (
																<div className='data-options-option create-labels-label-option' key={index} onClick={() => updateData('add', 'main', 'labels', label.label_id)}>
																	<div className='create-labels-icon pastel-orange'></div>
																	<div>{label.label}</div>
																</div>
															))}
															<div className='blue-link small-text edit-section-add-new-label-link' onClick={() => addTempLabel('main', '0')}>Add New Label</div>
														</div>
													}
												</div>
												</>
											}
										</div>
									</div>
									{sectionType === '1' &&
										<>
										{sectionsData['main'].subsections.map((subsection_id, subsection_index) => {
											const subsection_info = sectionsData[subsection_id];
											const is_new = ('new' in subsection_info) ? true : false;
											const subsection_course_id = subsection_info.course_id;
											const subsection_teacher_id = subsection_info.teacher_id;
											
											const subsection_course_info = (subsection_course_id) ? courses.find(course => course.course_id === subsection_course_id) : null;
											const subsection_teacher_info = (subsection_teacher_id) ? teachers.find(teacher => teacher.teacher_id === subsection_teacher_id) : null;
											
											const subsection_course_name = (subsection_course_info) ? `${subsection_course_info.name} (${subsection_course_info.course_code})` : '';
											const subsection_teacher_name = (subsection_teacher_info) ? `${subsection_teacher_info.name}, ${subsection_teacher_info.first_name}` : '';
											const subsection_is_inclusion = (subsection_info.is_inclusion === '1');

											let is_placeholder_inclusion = false;
											if(scheduleInfo.separate_ICR_codes === '0' && sectionsData.main.course_id === subsection_course_id && subsection_is_inclusion) is_placeholder_inclusion = true;
											
											let subsection_all_labels = subsection_info.labels;		
											subsection_all_labels = subsection_all_labels.map(label_id => {
												const label_info = labels.find(label => label.label_id === label_id);
												return label_info;
											});
											
											const subsection_teams = subsection_all_labels.filter(label => label.is_team === '1');
											const sorted_subsection_teams = sortArrayOfObjects(subsection_teams, 'label', 'text', 'desc');
											
											const subsection_labels = subsection_all_labels.filter(label => label.is_team === '0');
											const sorted_subsection_labels = sortArrayOfObjects(subsection_labels, 'label', 'text', 'desc');
											
											return (
												<div className='edit-section-subsection-container' key={subsection_id}>
													<div className={`data-modal-heading edit-section-heading ${subsection_is_inclusion ? 'yellow' : 'blue'}`}>
														<div>{subsection_is_inclusion && 'Inclusion'} Subsection</div>
														<div className='edit-section-heading-btn-container'>
															{(!is_placeholder_inclusion && !is_new) &&
																<span title='Split off subsection'><FontAwesomeIcon className='edit-section-heading-icon'  icon={faObjectUngroup} onClick={() => splitOffSubsection(subsection_id, subsection_index)} style={{fontSize: '16px',
																	margin:'0px 0px -1px 0px'}}/></span>
															}
															<span title='Remove subsection'><FontAwesomeIcon className='edit-section-heading-icon' icon={faTrashAlt} onClick={() => removeSubsection(subsection_id, subsection_index)}/></span>
														</div>
													</div>
													<div className='edit-section-main-section-content'>
														{!is_placeholder_inclusion &&
															<>
															<div className='edit-section-label'>Course</div>
															<div className='edit-section-data-row-container'>
																{!editingTracker[subsection_id].course ?
																	(
																		<div className='edit-section-data-row'>
																			<div>{sectionsData[subsection_id].course_id ? subsection_course_name : '--'}</div>
																			<div className='blue-link medium-text' onClick={() => toggleEditing(subsection_id, 'course')}>{subsection_course_id ? 'Change' : 'Add'}...</div>
																		</div>
																	) :
																	(
																		<>
																		<div className='search-add-search-container'>
																			<div className='search-add-adding-input-container'>
																				<input id={`${subsection_id}-course-input`} className='edit-section-input' placeholder='Search for a course' defaultValue={subsection_course_name} onChange={(e) => fuzzySearch(e, subsection_id, 'courses')} />
																				<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
																			</div>
																			<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes}  onClick={() => toggleEditing(subsection_id, 'course')}/>
																		</div>
																		{searchResults[subsection_id].courses.suggested.length !== 0 &&
																			<>
																			<div className='search-add-did-you-mean'>Courses this Teacher Teaches:</div>
																			<div className='search-add-search-results'>
																				{searchResults[subsection_id].courses.suggested.map((course, i) => {
																					return (
																						<div className='search-add-search-result' key={i}>
																							<div>{course.name} ({course.course_code})</div>
																							<div>
																								<div className='search-add-add-btn' onClick={() => updateData('change', subsection_id, 'course_id', course.course_id)}>Select</div>
																							</div>
																						</div>
																					)
																				})}
																			</div>
																			</>
																		}
																		{searchResults[subsection_id].courses.search.length !== 0 &&
																			<>
																			<div className='search-add-did-you-mean'>Other Courses:</div>
																			<div className='search-add-search-results'>
																				{searchResults[subsection_id].courses.search.map((course, i) => {
																					return (
																						<div className='search-add-search-result' key={i}>
																							<div>{course.name} ({course.course_code})</div>
																							<div>
																								<div className='search-add-add-btn' onClick={() => updateData('change', subsection_id, 'course_id', course.course_id)}>Select</div>
																							</div>
																						</div>
																					)
																				})}
																			</div>
																			</>
																		}
																		</>
																	)											
																}
															</div>
															</>
														}
														<div className='edit-section-label'>{is_placeholder_inclusion ? 'Inclusion' : ''} Teacher</div>
														<div className='edit-section-data-row-container'>
															{!editingTracker[subsection_id].teacher ?
																(
																	<div className='edit-section-data-row'>
																		<div>{sectionsData[subsection_id].teacher_id ? subsection_teacher_name : '--'}</div>
																		<div className='blue-link medium-text' onClick={() => toggleEditing(subsection_id, 'teacher')}>{subsection_teacher_id ? 'Change' : 'Add'}...</div>
																	</div>
																) :
																(
																	<>
																	<div className='search-add-search-container'>
																		<div className='search-add-adding-input-container'>
																			<input id={`${subsection_id}-teacher-input`} className='edit-section-input' placeholder='Search for a teacher' defaultValue={subsection_teacher_name} onChange={(e) => fuzzySearch(e, subsection_id, 'teachers')} autoFocus={true} onFocus={handleFocus}/>
																			<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
																		</div>
																		<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes}  onClick={() => toggleEditing(subsection_id, 'teacher')}/>
																	</div>
																	{searchResults[subsection_id].teachers.suggested.length !== 0 &&
																		<>
																		<div className='search-add-did-you-mean'>Teachers Who Can Teach:</div>
																		<div className='search-add-search-results'>
																			{searchResults[subsection_id].teachers.suggested.map((teacher, i) => {
																				const teacher_availability_index = teachersAvailability.findIndex(teacher_availability => teacher_availability.teacher_id === teacher.teacher_id);
																				const teacher_available = (teacher_availability_index !== -1) ? teachersAvailability[teacher_availability_index].availability : false;

																				return (
																					<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px auto'}}>
																						<div>{teacher.name}, {teacher.first_name}</div>
																						<div className={`availability-hover small-text ${teacher_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'teacher', teacher.teacher_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'teacher', null)}>{teacher_available ? 'Available' : 'Unavailable'}</div>
																						<div>
																							<div className='search-add-add-btn' onClick={() => updateData('change', subsection_id, 'teacher_id', teacher.teacher_id)}>Select</div>
																						</div>
																					</div>
																				)
																			})}
																		</div>
																		</>
																	}
																	{searchResults[subsection_id].teachers.search.length !== 0 &&
																		<>
																		<div className='search-add-did-you-mean'>Other Teachers:</div>
																		<div className='search-add-search-results'>
																			{searchResults[subsection_id].teachers.search.map((teacher, i) => {
																				const teacher_availability_index = teachersAvailability.findIndex(teacher_availability => teacher_availability.teacher_id === teacher.teacher_id);
																				const teacher_available = (teacher_availability_index !== -1) ? teachersAvailability[teacher_availability_index].availability : false;
																				
																				return (
																					<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px auto'}}>
																						<div>{teacher.name}, {teacher.first_name}</div>
																						<div className={`availability-hover small-text ${teacher_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'teacher', teacher.teacher_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'teacher', null)}>{teacher_available ? 'Available' : 'Unavailable'}</div>
																						<div>
																							<div className='search-add-add-btn' onClick={() => updateData('change', subsection_id, 'teacher_id', teacher.teacher_id)}>Select</div>
																						</div>
																					</div>
																				)
																			})}
																		</div>
																		</>
																	}
																	</>
																)											
															}
														</div>
														<div className='edit-section-labels-container'>
															<>
															{sorted_subsection_teams.map((label, index) => (
																<div key={label.label_id}>
																	{label.label ?
																		(
																			<div className='edit-section-section-label'>
																				<div className='create-labels-icon pastel-blue'></div>
																				<div>{label.label}</div>
																				<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => removeLabel(subsection_id, label.label_id)}/>
																			</div>
																		) :
																		(
																			<div className='create-labels-editing-label'>
																				<div className='create-labels-icon pastel-blue'></div>
																				<input className='create-labels-input' placeholder='Add label name...' onKeyPress={(e) => {if(e.key === 'Enter') updateLabel(e, subsection_id, label.label_id)}} autoFocus={true}/>
																				<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => removeLabel(subsection_id, label.label_id)}/>
																				<FontAwesomeIcon className='gray-to-blue-link' icon={faCheck} onClick={(e) => updateLabel(e, subsection_id, label.label_id)}/>
																			</div>
																		)
																	}
																</div>
															))}
															{sorted_subsection_labels.map((label, index) => (
																<div key={label.label_id}>
																	{label.label ?
																		(
																			<div className='edit-section-section-label'>
																				<div className='create-labels-icon pastel-orange'></div>
																				<div>{label.label}</div>
																				<FontAwesomeIcon className='cancel-link' icon={faTimes} onClick={() => removeLabel(subsection_id, label.label_id)}/>
																			</div>
																		) :
																		(
																			<div className='create-labels-editing-label'>
																				<div className='create-labels-icon pastel-orange'></div>
																				<input className='create-labels-input' placeholder='Add label name...' onKeyPress={(e) => {if(e.key === 'Enter') updateLabel(e, label.label_id)}} autoFocus={true}/>
																				<FontAwesomeIcon className='gray-to-red-link' icon={faTimes} onClick={() => removeLabel(subsection_id, label.label_id)}/>
																				<FontAwesomeIcon className='gray-to-blue-link' icon={faCheck} onClick={(e) => updateLabel(e, subsection_id, label.label_id)}/>
																			</div>
																		)
																	}
																</div>
															))}
															</>
														</div>
														<div className='edit-section-subsection-tag-container'>
															<div className='add-label-to-section blue-link small-text click-restricted' onClick={() => toggleLabelOptionsOpen(subsection_id)}>Add Team/Label</div>
														</div>
														<div className='create-labels-label-options-container' data-sectionid={subsection_id}>
															{editingTracker[subsection_id].label_options_open &&
																<div className='data-options-container' ref={ref}>
																	<div className='data-options-heading'>Teams</div>
																	{sorted_teams.length === 0 &&
																		<div className='create-labels-no-label-options'>No teams added yet...</div>
																	}
																	{sorted_teams.map((label, index) => (
																		<div className='data-options-option create-labels-label-option' key={index} onClick={() => updateData('add', subsection_id, 'labels', label.label_id)}>
																			<div className='create-labels-icon pastel-blue'></div>
																			<div>{label.label}</div>
																		</div>
																	))}
																	<div className='blue-link small-text edit-section-add-new-label-link' onClick={() => addTempLabel(subsection_id, '1')}>Add New Team</div>
																	<div className='data-options-heading'>Labels</div>
																	{sorted_labels.length === 0 &&
																		<div className='create-labels-no-label-options'>No labels added yet...</div>
																	}
																	{sorted_labels.map((label, index) => (
																		<div className='data-options-option create-labels-label-option' key={index} onClick={() => updateData('add', subsection_id, 'labels', label.label_id)}>
																			<div className='create-labels-icon pastel-orange'></div>
																			<div>{label.label}</div>
																		</div>
																	))}
																	<div className='blue-link small-text edit-section-add-new-label-link' onClick={() => addTempLabel(subsection_id, '0')}>Add New Label</div>
																</div>
															}
														</div>
													</div>
												</div>
											)
										})}
										<div className='edit-section-add-subsection-container'>
											<div className='edit-section-add-new-btn blue-link' onClick={() => addNewSubsection('0')}>
												<FontAwesomeIcon icon={faPlusCircle}/>
												<div>Add Subsection</div>
											</div>
											<div className='edit-section-add-new-btn orange-link' onClick={() => addNewSubsection('1')}>
												<FontAwesomeIcon icon={faPlusCircle}/>
												<div>Add Inclusion Subsection</div>
											</div>
										</div>
										</>
									}
								</div>
								{sectionType === '1' &&
									<>
									<div className='edit-section-students-container'>
										<div className='data-modal-heading edit-section-heading'>
											<div>Students</div>
											<div className='edit-section-students-heading-student-count large-text'>
												<div>Total Students:</div>
												<div>{total_student_count}</div>
											</div>
										</div>
										<div className='edit-section-main-section-content'>
											<div className='edit-section-students-inner-parent-container'>
												<div className='edit-section-students-heading first fixed-heading-on-scroll dark-blue-text'>
													<div className='edit-section-students-heading-label dark-blue'>Main Section</div> 
													<div className='large-text'>{main_course_name}</div>
													<div className='edit-section-students-heading-student-count'>
														<FontAwesomeIcon icon={faUserGraduate}/>
														<div>{sectionsData.main.student_list.length}</div>
													</div>
												</div>
												{sectionsData.main.student_list.length > 0 &&
													<div className='select-all-row'>
														<div className={`select-all-container ${main_students_selected && 'blue-text'}`} onClick={() => toggleSelectAll('main')}>
															<FontAwesomeIcon icon={main_students_selected ? faMinusSquare : farSquare}/>
															<FontAwesomeIcon className='select-all-arrow' icon={faSortDown}/>
														</div>
														{main_students_selected &&
															<div className='select-all-actions-container'>
																<div className='tooltip' data-tooltip-text='Lock students into section' onClick={() => toggleLockStudents('main', 'lock')}>
																	<FontAwesomeIcon className='select-all-action-btn tooltip' icon={faLock}/>
																</div>
																<div className='tooltip' data-tooltip-text='Move students to different section' onClick={() => showOtherSections('main')}>
																	<FontAwesomeIcon className='select-all-action-btn tooltip' icon={faPeopleArrows}/>
																</div>
																<div className='tooltip' data-tooltip-text='Remove students' onClick={() => bulkRemoveStudents('main')}>
																	<FontAwesomeIcon className='select-all-action-btn tooltip' icon={faTrashAlt}/>
																</div>
															</div>
														}
													</div>
												}
												<div className='edit-section-student-list-container'>
													{sectionsData.main.student_list.length === 0 &&
														<div className='edit-data-no-data-container'>No students are in this section yet!</div>
													}
													{sectionsData.main.student_list.map((student_id, index) => {
														const student_index = students.findIndex(student => student.student_id === student_id);
														if(student_index === -1) return null;
														const student_info = students[student_index];
														const student_selected = selectedStudents.includes(student_id);
														const student_locked = sectionsData.main.locked_students.includes(student_id);
														const opposite_locked_type = student_locked ? 'unlock' : 'lock';
														
														return (
															<div className='edit-section-student' key={student_id}>
																<FontAwesomeIcon className={student_locked ? 'light-gray-text dropdown_disabled' : (student_selected ? 'blue-text' : 'dark-gray-text')} icon={student_locked ? faSquare : (student_selected ? faCheckSquare : farSquare)} onClick={student_locked ? ()=>{} : () => toggleSelectStudent(student_id)}/>
																<div>
																	<div className={`default-student ${allow_hotlinks ? 'blue-link' : 'dark-blue-text'}`} onClick={allow_hotlinks ? () => saveSection(student_id) : null}>{student_info.last_name}, {student_info.first_name} ({student_info.grade}th)</div>
																	<FontAwesomeIcon icon={faLock} className={`edit-section-lock-student-icon cursor-pointer ${student_locked ? 'bright-yellow-text' : 'gray-text'}`} onClick={() => toggleLockStudents('main', opposite_locked_type, student_id)}/>
																</div>
																{student_locked ?
																	(
																		<div></div>
																	):
																	(
																		<div className="x-remove" onClick={() => updateData('remove', 'main', 'student_list', student_id)}><FontAwesomeIcon icon={faTimes}/></div>
																	)
																}
															</div>
														)
													})}
												</div>
												<div className='edit-section-add-new-btn blue-link' onClick={() => toggleEditing('main', 'students')}>
													<FontAwesomeIcon icon={faPlusCircle}/>
													<div>Add Student</div>
												</div>
												{editingTracker.main.students &&
													<div className='search-add-row-container'>
														<div className='search-add-search-container'>
															<div className='search-add-adding-input-container'>
																<input id='main-students-input' className='search-add-adding-input' onChange={(e) => fuzzySearch(e, 'main', 'students')} placeholder='Search for students'/>
																<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch} autoFocus={true} onFocus={handleFocus}/>
															</div>
															<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleEditing('main', 'students')}/>
														</div>
														{searchResults.main.students.suggested.length !== 0 &&
															<>
															<div className='search-add-did-you-mean'>Students Who Requested This Course:</div>
															<div className='search-add-search-results'>
																{searchResults.main.students.suggested.map((student, i) => {
																	if(searchResults.main.students.suggested_filter_ids.length > 0 && !searchResults.main.students.suggested_filter_ids.includes(student.student_id)) return null;

																	const student_requests = student.student_requests;
																	const student_labels = student.student_labels.filter(label => label.course_id === sectionsData.main.course_id);
																	
																	const request_info = student_requests.find(request => request.course_id === sectionsData.main.course_id);
																	const priority = request_info.priority;

																	const student_in_section_index = sectionsData.main.student_list.findIndex(student_id => student_id === student.student_id);
																	const student_in_section = (student_in_section_index !== -1) ? true : false;

																	const student_availability_index = studentsAvailability.findIndex(student_availability => student_availability.student_id === student.student_id);
																	const student_available = (!student_in_section && student_availability_index !== -1) ? studentsAvailability[student_availability_index].availability : false;

																	const student_match_index = sections.findIndex(section => section.course_id === sectionsData.main.course_id && section.student_list.includes(student.student_id));
																	const student_matched = (student_in_section || student_match_index !== -1) ? true : false;

																	return (
																		<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px 80px auto'}}>
																			<div>
																				<div>{student.last_name}, {student.first_name} <span className='small-text purple-text'>{priority !== '1' && "- Alternate"}</span></div>
																				<div>
																					{student_labels.map((course_label, i) => {
																						const label_id = course_label.label_id;
																						const label_info = labels.find(label => label.label_id === label_id);
																						
																						if(!label_info) return;

																						const is_team = (label_info.is_team === '1') ? true : false;

																						return (
																							<div className={`edit-student-schedule-request-label ${is_team ? 'pastel-blue-text' : 'pastel-orange-text'}`} key={i}>{label_info.label}</div>
																						)
																					})}
																				</div>
																			</div>
																			<div className={`availability-hover small-text ${student_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'student', student.student_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'student', null)}>{student_available ? 'Available' : 'Unavailable'}</div>
																			<div className={`small-text ${student_matched ? 'green-text' : 'red-text'}`}>{student_in_section ? 'In this section' : student_matched ? 'Matched' : 'Not Matched'}</div>
																			<div>
																				{student_in_section ? 
																					(
																						<div className='search-add-add-btn student-search-btn red' onClick={() => updateData('remove', 'main', 'student_list', student.student_id)}>Remove</div>
																					): 
																					(
																						<div className='search-add-add-btn student-search-btn' onClick={() => updateData('add', 'main', 'student_list', student.student_id)}>Add</div>
																					)
																				}
																			</div>
																		</div>
																	)
																})}
															</div>
															</>
														}
														{searchResults.main.students.search.length !== 0 &&
															<>
															<div className='search-add-did-you-mean'>Search Results:</div>
															<div className='search-add-search-results'>
																{searchResults.main.students.search.map((student, i) => {
																	const student_in_section_index = sectionsData.main.student_list.findIndex(student_id => student_id === student.student_id);
																	const student_in_section = (student_in_section_index !== -1) ? true : false;

																	const student_availability_index = studentsAvailability.findIndex(student_availability => student_availability.student_id === student.student_id);
																	const student_available = (!student_in_section && student_availability_index !== -1) ? studentsAvailability[student_availability_index].availability : false;

																	return (
																		<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px 80px auto'}}>
																			<div>{student.last_name}, {student.first_name}</div>
																			<div className={`availability-hover small-text ${student_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'student', student.student_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'student', null)}>{student_available ? 'Available' : 'Unavailable'}</div>
																			<div className={`small-text ${student_in_section ? 'green-text' : 'white-text'}`}>{student_in_section ? 'In this section' : '.'}</div>
																			<div>
																				{student_in_section ? 
																					(
																						<div className='search-add-add-btn student-search-btn red' onClick={() => updateData('remove', 'main', 'student_list', student.student_id)}>Remove</div>
																					): 
																					(
																						<div className='search-add-add-btn student-search-btn' onClick={() => updateData('add', 'main', 'student_list', student.student_id)}>Add</div>
																					)
																				}
																			</div>
																		</div>
																	)
																})}
															</div>
															</>
														}
													</div>
												}
											</div>
											{sectionsData['main'].subsections.map((subsection_id, subsection_index) => {
												const subsection_info = sectionsData[subsection_id];
												const subsection_course_id = subsection_info.course_id;
												const subsection_student_list = subsection_info.student_list;
												const subsection_is_inclusion = (subsection_info.is_inclusion === '1');
												
												const subsection_course_info = (subsection_course_id) ? courses.find(course => course.course_id === subsection_course_id) : null;
												const subsection_course_name = (subsection_course_info) ? `${subsection_course_info.name} (${subsection_course_info.course_code})` : '';

												let is_placeholder_inclusion = false;
												if(scheduleInfo.separate_ICR_codes === '0' && sectionsData.main.course_id === subsection_course_id && subsection_is_inclusion) is_placeholder_inclusion = true;

												const subsection_students_selected = (selectedStudents.filter(student_id => subsection_student_list.includes(student_id)).length > 0);
												
												return (
													<div className='edit-section-students-inner-parent-container' key={subsection_id}>
														<div className='edit-section-students-heading fixed-heading-on-scroll dark-blue-text'>
															<div className={`edit-section-students-heading-label ${subsection_is_inclusion ? 'yellow' : 'blue'}`}>{subsection_is_inclusion && 'Inclusion'} Subsection</div>
															<div className='large-text'>{!is_placeholder_inclusion && subsection_course_name}</div>
															<div className='edit-section-students-heading-student-count'>
																<FontAwesomeIcon icon={faUserGraduate}/>
																<div>{subsection_student_list.length}</div>
															</div>
														</div>
														{subsection_student_list.length > 0 &&
															<div className='select-all-row'>
																<div className={`select-all-container ${subsection_students_selected && 'blue-text'}`} onClick={() => toggleSelectAll(subsection_id)}>
																	<FontAwesomeIcon icon={subsection_students_selected ? faMinusSquare : farSquare}/>
																	<FontAwesomeIcon className='select-all-arrow' icon={faSortDown}/>
																</div>
																{subsection_students_selected &&
																	<div className='select-all-actions-container'>
																		<div className='tooltip' data-tooltip-text='Lock students into section' onClick={() => toggleLockStudents(subsection_id, 'lock')}>
																			<FontAwesomeIcon className='select-all-action-btn tooltip' icon={faLock}/>
																		</div>
																		<div className='tooltip' data-tooltip-text='Move students to different section' onClick={() => showOtherSections(subsection_id)}>
																			<FontAwesomeIcon className='select-all-action-btn tooltip' icon={faPeopleArrows}/>
																		</div>
																		<div className='tooltip' data-tooltip-text='Remove students' onClick={() => bulkRemoveStudents(subsection_id)}>
																			<FontAwesomeIcon className='select-all-action-btn tooltip' icon={faTrashAlt}/>
																		</div>
																	</div>
																}
															</div>
														}
														<div className='edit-section-student-list-container'>
															{sectionsData[subsection_id].student_list.length === 0 &&
																<div className='edit-data-no-data-container'>No students are in this subsection yet!</div>
															}
															{subsection_student_list.map((student_id, index) => {
																const student_index = students.findIndex(student => student.student_id === student_id);
																if(student_index === -1) return null;
																const student_info = students[student_index];
																const student_selected = selectedStudents.includes(student_id);
																const student_locked = sectionsData[subsection_id].locked_students.includes(student_id);
																const opposite_locked_type = student_locked ? 'unlock' : 'lock';
																
																return (
																	<div className='edit-section-student' key={student_id}>
																		<FontAwesomeIcon className={student_locked ? 'light-gray-text dropdown_disabled' : (student_selected ? 'blue-text' : 'dark-gray-text')} icon={student_locked ? faSquare : (student_selected ? faCheckSquare : farSquare)} onClick={student_locked ? ()=>{} : () => toggleSelectStudent(student_id)}/>
																		<div>
																			<div className={`default-student ${allow_hotlinks ? 'blue-link' : 'dark-blue-text'}`} onClick={allow_hotlinks ? () => saveSection(student_id) : null}>{student_info.last_name}, {student_info.first_name} ({student_info.grade}th)</div>
																			<FontAwesomeIcon icon={faLock} className={`edit-section-lock-student-icon cursor-pointer ${student_locked ? 'bright-yellow-text' : 'gray-text'}`} onClick={() => toggleLockStudents(subsection_id, opposite_locked_type, student_id)}/>
																		</div>
																		{student_locked ?
																			(
																				<div></div>
																			):
																			(
																				<div className="x-remove" onClick={() => updateData('remove', subsection_id, 'student_list', student_id)}><FontAwesomeIcon icon={faTimes}/></div>
																			)
																		}
																	</div>
																)
															})}
														</div>
														<div className='edit-section-add-new-btn blue-link' onClick={() => toggleEditing(subsection_id, 'students')}>
															<FontAwesomeIcon icon={faPlusCircle}/>
															<div>Add Student</div>
														</div>
														{editingTracker[subsection_id].students &&
															<div className='search-add-row-container'>
																<div className='search-add-search-container'>
																	<div className='search-add-adding-input-container'>
																		<input id={`${subsection_id}-students-input`} className='search-add-adding-input' onChange={(e) => fuzzySearch(e, subsection_id, 'students')} placeholder='Search for students' autoFocus={true} onFocus={handleFocus}/>
																		<FontAwesomeIcon className='search-add-adding-input-icon' icon={faSearch}/>
																	</div>
																	<FontAwesomeIcon className='x-cancel search-add-cancel-new-btn' icon={faTimes} onClick={() => toggleEditing(subsection_id, 'students')}/>
																</div>
																{searchResults[subsection_id].students.suggested.length !== 0 &&
																	<>
																	<div className='search-add-did-you-mean'>Students Who Requested This Course:</div>
																	<div className='search-add-search-results'>
																		{searchResults[subsection_id].students.suggested.map((student, i) => {
																			if(searchResults[subsection_id].students.suggested_filter_ids.length > 0 && !searchResults[subsection_id].students.suggested_filter_ids.includes(student.student_id)) return null;
																			
																			const student_requests = student.student_requests;
																			const student_labels = student.student_labels.filter(label => label.course_id === sectionsData[subsection_id].course_id);
																			
																			const request_info = student_requests.find(request => request.course_id === sectionsData[subsection_id].course_id);
																			const priority = request_info.priority;

																			const student_in_section_index = sectionsData[subsection_id].student_list.findIndex(student_id => student_id === student.student_id);
																			const student_in_section = (student_in_section_index !== -1) ? true : false;

																			const student_availability_index = studentsAvailability.findIndex(student_availability => student_availability.student_id === student.student_id);
																			const student_available = (!student_in_section && student_availability_index !== -1) ? studentsAvailability[student_availability_index].availability : false;

																			const student_match_index = sections.findIndex(section => section.course_id === sectionsData.main.course_id && section.student_list.includes(student.student_id));
																			const student_matched = (student_in_section || student_match_index !== -1) ? true : false;
				
																			return (
																				<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px 80px auto'}}>
																					<div>
																						<div>{student.last_name}, {student.first_name} <span className='small-text purple-text'>{priority !== '1' && "- Alternate"}</span></div>
																						<div>
																							{student_labels.map((course_label, i) => {
																								const label_id = course_label.label_id;
																								const label_info = labels.find(label => label.label_id === label_id);
																								
																								if(!label_info) return null;

																								const is_team = (label_info.is_team === '1') ? true : false;

																								return (
																									<div className={`edit-student-schedule-request-label ${is_team ? 'pastel-blue-text' : 'pastel-orange-text'}`} key={i}>{label_info.label}</div>
																								)
																							})}
																						</div>
																					</div>
																					<div className={`availability-hover small-text ${student_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'student', student.student_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'student', null)}>{student_available ? 'Available' : 'Unavailable'}</div>
																					<div className={`small-text ${student_matched ? 'green-text' : 'red-text'}`}>{student_in_section ? 'In this section' : student_matched ? 'Matched' : 'Not Matched'}</div>
																					<div>
																						{student_in_section ? 
																							(
																								<div className='search-add-add-btn student-search-btn red' onClick={() => updateData('remove', subsection_id, 'student_list', student.student_id)}>Remove</div>
																							): 
																							(
																								<div className='search-add-add-btn student-search-btn' onClick={() => updateData('add', subsection_id, 'student_list', student.student_id)}>Add</div>
																							)
																						}
																					</div>
																				</div>
																			)
																		})}
																	</div>
																	</>
																}
																{searchResults[subsection_id].students.search.length !== 0 &&
																	<>
																	<div className='search-add-did-you-mean'>Search Results:</div>
																	<div className='search-add-search-results'>
																		{searchResults[subsection_id].students.search.map((student, i) => {
																			const student_in_section_index = sectionsData[subsection_id].student_list.findIndex(student_id => student_id === student.student_id);
																			const student_in_section = (student_in_section_index !== -1) ? true : false;

																			const student_availability_index = studentsAvailability.findIndex(student_availability => student_availability.student_id === student.student_id);
																			const student_available = (!student_in_section && student_availability_index !== -1) ? studentsAvailability[student_availability_index].availability : false;
				
																			return (
																				<div className='search-add-search-result' key={i} style={{gridTemplateColumns:'1fr 80px 80px auto'}}>
																					<div>{student.last_name}, {student.first_name}</div>
																					<div className={`availability-hover small-text ${student_available ? 'green-text' : 'red-text'}`} onMouseEnter={(e) => handleLoadAvailability(e, 'student', student.student_id)} onMouseLeave={(e) => handleLoadAvailability(e, 'student', null)}>{student_available ? 'Available' : 'Unavailable'}</div>
																					<div className={`small-text ${student_in_section ? 'green-text' : 'white-text'}`}>{student_in_section ? 'In this section' : '.'}</div>
																					<div>
																						{student_in_section ? 
																							(
																								<div className='search-add-add-btn student-search-btn red' onClick={() => updateData('remove', subsection_id, 'student_list', student.student_id)}>Remove</div>
																							): 
																							(
																								<div className='search-add-add-btn student-search-btn' onClick={() => updateData('add', subsection_id, 'student_list', student.student_id)}>Add</div>
																							)
																						}
																					</div>
																				</div>
																			)
																		})}
																	</div>
																	</>
																}
															</div>
														}
													</div>
												)
											})}
										</div>
									</div>
									</>
								}
							</form>
						}
					</div>
				</div>
			</div>
		</div>
	);
}