import React, { useState , useEffect } from 'react';
import { Link } from "react-router-dom";

import { loadData } from '../../../js/Helpers';
import { FileUpload } from '../../../js/upload';

import UploadModal from '../../UploadModal';
import DatabaseDataCourses from '../school_data/database_data_courses';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faQuestionCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export default function UploadCourses({ subscreenRestricted, subscreenLocked, subscreen, schoolInfo, scheduleInfo, currentSIS, coursesLoading, setCoursesLoading, courses, students, teachers, classrooms, sections, labels, setCourses, setStudents, setRolePages, handleMoveToNextScreen }) {
	const [courseDataExists, setCourseDataExists] = useState(false);

	const [alreadyUploadedChecked, setAlreadyUploadedChecked] = useState(false);
	const [alreadyUploaded, setAlreadyUploaded] = useState(false);
	
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showCourseData, setShowCourseData] = useState(false);

	const setDataExists = (value) => {
		setCourseDataExists(value);
	}

	const toggleShowCourseData = (display_value) => {
		setShowCourseData(display_value);
	}

	const handlePullNewData = () => {
		loadData({data_types:['courses'], schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id, school_id:schoolInfo.school_id}).then(database_data => {
			setCourses(database_data.courses);
		});

		setDataExists(true);
		setAlreadyUploadedChecked(true);
		setAlreadyUploaded(true);
	}
	
	////////////////////////////////
	///// USE EFFECT FUNCTIONS /////
	////////////////////////////////
	useEffect(() => {
		if(schoolInfo.current_data_version_id && schoolInfo.school_id)
		{
			async function checkUploadAlreadyProcessed() {
				const file_upload = new FileUpload(schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, scheduleInfo.school_id);
			
				// Check whether courses have been uploaded
				const running_processes = await file_upload.getSchoolProcesses(schoolInfo.current_schedule_year_id);

				// Check if there are any running processes with the same data version id
				const dv_process_index = running_processes.findIndex(process => process.process_type === 'coursedata' && process.data_version_id == schoolInfo.current_data_version_id && process.is_done);
				const sv_process_index = running_processes.findIndex(process => process.process_type === 'coursedata' && process.schedule_version_id == schoolInfo.current_schedule_version_id && process.is_done);

				// If process has already finished running then set data existing
				if(sv_process_index !== -1 || dv_process_index !== -1) setAlreadyUploaded(true);

				setAlreadyUploadedChecked(true);
			}

			checkUploadAlreadyProcessed();
		}
	}, [scheduleInfo, schoolInfo, subscreen]);

	useEffect(() => {
		if(courses.length > 0) setCourseDataExists(true);
	}, [courses]);

	return (
		<>
		{(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):
		(
			<>
			{showUploadModal &&
				<UploadModal schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} uploadTitle='Courses' uploadAPIName='coursedata' closeModal={() => setShowUploadModal(false)} handlePullNewData={handlePullNewData}/>
			}
			<h1 className='progress-main-header'>Upload Courses</h1>
			<p className='progress-subheader'>Let's import your course list from your current SIS.</p>
			<div className="upload-screen-main-upload-screen">
				<div className="upload_screen">
					<div className='upload-screen-explanation-container'>
						<h2 className="upload-screen-heading-text"><span className="ms_login_system_name capitalize">{currentSIS}</span> Course Upload</h2>
						<p className='upload-screen-description'>Let&rsquo;s start by downloading the following data from <span className="ms_login_system_name capitalize"></span>:</p>
						<div className='upload-screen-data-container'>
							<img className='upload-info-image' src={require(`../../../images/textbook.png`)} alt='courses'/>
							<div className='upload-info-name'>Courses</div>
							{courseDataExists ?
								(
									<>
									<div>
										<FontAwesomeIcon icon={faCheckCircle} className='green-text upload-complete'/>
										<div className='upload-complete-text'>Complete</div>
									</div>
									<div className='btn btn-small blue-btn' onClick={()=>{toggleShowCourseData(true)}}>See Data</div>
									</>
								):
								(
									<>
									<div>
										<FontAwesomeIcon icon={faCheckCircle} className='gray-text upload-complete'/>
										<div className='upload-complete-text gray-text'>No Data</div>
									</div>
									<div className='white-text'>.</div>
									</>
								)
							}
						</div>
						<div>
							{!alreadyUploadedChecked ?
								(
									<div className='center-text'>
										<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
									</div>
								): (alreadyUploaded) ? 
								(
									<div className='upload-screen-already-uploaded-container'>
										<div>
											<FontAwesomeIcon className='upload-screen-already-uploaded-check green-text' icon={faCheckCircle}/>
										</div>
										<div>
											<h3 className='upload-screen-already-uploaded-header'>You already uploaded Courses!</h3>
											<div>You can move on to the next step, or <span className='blue-link' onClick={() => setShowUploadModal(true)}>Reupload Courses</span>!</div>
										</div>
									</div>
								):
								(
									<div className='center-text'>
										<span className='btn turquoise-btn btn-round btn-large upload-btn' onClick={() => setShowUploadModal(true)}>Upload Courses</span>
									</div>
								)
							}
						</div>
						<p className='upload-how-to-line'><FontAwesomeIcon className='info-icon blue-text' icon={faQuestionCircle}/> <Link to={`/app/documentation/upload_data/upload_courses/${currentSIS}`} target="_blank" style={{color:'#3498db'}}>How to download courses from <span className="ms_login_system_name capitalize">{currentSIS}</span></Link></p>
					</div>
					{showCourseData ?
						(
							<div className='upload-parent-container'>
								<div className='upload-screen-close-data gray-to-dark-blue-link' onClick={() => {setShowCourseData(false)}}>
									<FontAwesomeIcon icon={faTimesCircle}/>
								</div>
								<DatabaseDataCourses schoolInfo={schoolInfo} databaseData={courses} setDatabaseData={setCourses} setStudents={setStudents} scheduleInfo={scheduleInfo} teachers={teachers} students={students} classrooms={classrooms} sections={sections} labels={labels} isLoading={coursesLoading} setIsLoading={setCoursesLoading}/>
							</div>
						):null
					}
				</div>
				{(courseDataExists) &&
					<div className='fixed-bottom-save-container'>
						<div className="btn btn-extra-large green-btn align-right move-to-next-screen-btn move-to-next-screen-btn" onClick={handleMoveToNextScreen}>Go to Next Step: Upload Teachers</div>
					</div>
				}
			</div>
			</>
		)}
		</>
	);
}