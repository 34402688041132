import React, { useState , useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { JSONexists, getJSONFromStorage, getCurrentSubscreen, checkPermissions, getNextAppScreen, isEmpty } from '../../js/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faLock } from '@fortawesome/free-solid-svg-icons';

import SchoolSetup from '../elementary/progress/school_setup';
import UploadData from '../elementary/progress/upload_data';
import SpecialsSetup from './progress/specials_setup';
import PulloutsSetup from './progress/pullouts_setup';

import '../../css/progress.css';

export default function ProgressElementary({ match, isAuth, authComplete, schoolSubscribed, rolePages, setRolePages, schoolProgressLoaded, schoolInfo, schoolInfoLoading, setSchoolInfo, schoolType, scheduleInfoLoading, scheduleInfo, setScheduleInfo, periods, setPeriods, periodsLoading, userInfo, usersLoading, appSubscreens, loadScreen, setLoadScreen, teachersLoading, setTeachersLoading, teachers, setTeachers, coursesLoading, setCoursesLoading, courses, setCourses, classroomsLoading, setClassroomsLoading, classrooms, setClassrooms, studentsLoading, setStudentsLoading, students, setStudents, blocksLoading, blocks, setBlocks, pulloutGroups, setPulloutGroups }) {
	const history = useHistory();
	
	const [isLoading, setIsLoading] = useState(true);
	const [permissionChecked, setPermissionChecked] = useState(false);
	const [currentSubscreenSet, setCurrentSubscreenSet] = useState(false);
	const [subscreenLocked, setSubscreenLocked] = useState(true);
	const [subscreenRestricted, setSubscreenRestricted]= useState(true);
	const [subscreen, setSubscreen] = useState(null);

	const [colors] = useState(['green', 'turquoise', 'blue', 'purple', 'dark-blue-grey']);

	const goToScreen = (screen) => {
		sessionStorage.clear();
		setPermissionChecked(false);
		setCurrentSubscreenSet(false);
		setSubscreen(null);
		setIsLoading(true);

		setLoadScreen('progress');
		history.push(`/app/${schoolType}/progress/${screen}/`);
	}

	const subscreenSelect = (selected_subscreen, selected_screen = null, department_id = null) => {
		const current_screen = (selected_screen) ? selected_screen : match.params.screen;

		setIsLoading(true);
		setPermissionChecked(false);
		setSubscreen(selected_subscreen);
		
		// Move the user to subscreen but show restricted/locked screen
		history.push(`/app/${schoolType}/progress/${current_screen}/${selected_subscreen}`);
		sessionStorage.setItem(`${current_screen}_subscreen`, selected_subscreen);
	}

	const handleChangeSubscreenProgressLineColor = (e, color, highlightBool) => {
		const parentDiv = e.target.closest('.progress-page-sidebar-subscreen-btn');
		const isSelected = parentDiv.classList.contains('progress-page-sidebar-subscreen-btn-selected');
		
		if(isSelected) return false;

		if(highlightBool)
		{
			e.target.closest('.progress-page-sidebar-subscreen-btn').querySelector('.progress-page-sidebar-subscreen-progress-line').classList.add(color, 'opacity-25');
		}
		else
		{
			e.target.closest('.progress-page-sidebar-subscreen-btn').querySelector('.progress-page-sidebar-subscreen-progress-line').classList.remove(color, 'opacity-25');
		}
	}

	const handleMoveToNextScreen = () => {
		getNextAppScreen({school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, completed_subscreen:subscreen, database:'elementary', callback: (result) => {
			sessionStorage.clear();
			
			// Update school progress
			const current_screen = result.current_screen;
			const subscreen_complete = result.subscreen_complete;
			const screen_complete = result.screen_complete;
			const next_screen = result.next_screen;
			const next_subscreen = result.next_subscreen;

			if(subscreen_complete)
			{
				const role_page_subscreens = rolePages[current_screen]['subscreens'];
				const subscreen_index = role_page_subscreens.findIndex(temp_subscreen => temp_subscreen.subscreen === subscreen);
				const nex_subscreen_index = role_page_subscreens.findIndex(temp_subscreen => temp_subscreen.subscreen === next_subscreen);

				if(subscreen_index !== -1) rolePages[current_screen]['subscreens'][subscreen_index] = {...rolePages[current_screen]['subscreens'][subscreen_index], ...{complete:true}};
				if(nex_subscreen_index !== -1) rolePages[current_screen]['subscreens'][nex_subscreen_index] = {...rolePages[current_screen]['subscreens'][nex_subscreen_index], ...{current:true}};
			}
			
			if(screen_complete)
			{
				// If current screen is complete and next screen is different than current screen, open up the next screen
				if(current_screen !== next_screen)
				{
					const next_screen_subscreens = rolePages[next_screen]['subscreens'].reduce((results, subscreen_info) => {
						subscreen_info.current = true;
						results.push(subscreen_info);
						return results;
					}, []);

					rolePages[next_screen]['subscreens'] = next_screen_subscreens;
				}
				
				setRolePages({...rolePages});
			}
			
			setSubscreen(next_subscreen);
			sessionStorage.setItem(`${next_screen}_subscreen`, next_subscreen);
			history.push(`/app/${schoolType}/progress/${next_screen}/${next_subscreen}`);
		}});
	}

	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////
	useEffect(() => {
		// Check if user is logged in
		// If not, send them to log in screen with a redirect to this screen
		// If yes, check if user has permission
		// If not permission, redirect to loading page
		if(authComplete && !isAuth)
		{
			history.push({
				pathname: '/app/login',
				state: {redirect: `/app/${schoolType}/${match.params.screen}`}
			});
		}
		else if(authComplete && loadScreen === 'select_school')
		{
			history.push({
				pathname: '/app/select_school',
				state: {redirect: `/app/${schoolType}/${match.params.screen}`}
			});
		}
		else if(authComplete && schoolProgressLoaded && !permissionChecked && match.params.screen && subscreen && !schoolInfoLoading)
		{
			checkPermissions({school_id:schoolInfo.school_id, subscreen:subscreen, schedule_version_id:schoolInfo.current_schedule_version_id, database:'elementary', callback:(result) => {
				history.push(`/app/${schoolType}/progress/${match.params.screen}/${subscreen}`);

				if(result.permissions !== 'locked' && result.permissions !== 'restricted')
				{
					setLoadScreen('progress');
					setSubscreenLocked(false);
					setSubscreenRestricted(false);
					sessionStorage.setItem(`${match.params.screen}_subscreen`, subscreen);
					
				}
				else if(result.permissions === 'restricted')
				{
					setSubscreenRestricted(true);
				}
				else if(result.permissions === 'locked')
				{
					setSubscreenLocked(true);
				}
				
				setIsLoading(false);
				setPermissionChecked(true);
			}});
		}
	}, [isAuth, authComplete, schoolProgressLoaded, subscreen, schoolInfoLoading, loadScreen, match.params.screen, permissionChecked]);

	useEffect(() => {
		if(!schoolInfoLoading && !currentSubscreenSet)
		{
			if(!match.params.screen)
			{
				sessionStorage.clear();
			}
			else if(match.params.subscreen)
			{
				setSubscreen(match.params.subscreen);
			}
			else if(JSONexists(`${match.params.screen}_subscreen`, 'session'))
			{
				const subscreen = getJSONFromStorage(`${match.params.screen}_subscreen`, false, 'session');
				setSubscreen(subscreen);
			}
			else
			{
				const data = {school_id: schoolInfo.school_id, schedule_version_id:schoolInfo.current_schedule_version_id, screen: match.params.screen, database:'elementary'};

				if(match.params.screen)
				{
					getCurrentSubscreen(data, (temp_subscreen) => {
						setSubscreen(temp_subscreen);
					});
				}
			}

			setCurrentSubscreenSet(true);
		}
	}, [schoolInfoLoading, loadScreen, permissionChecked, match]);
	
	useEffect(() => {
		if(!teachersLoading && !periodsLoading && !usersLoading) setIsLoading(false);
	}, [teachersLoading, periodsLoading, usersLoading]);
	
	//console.log(loadScreen);
	//console.log({subscreen, departments, departmentID, users, supervisors, usersLoading});
	//console.log({teachers, students, classrooms, courses});
	//console.log({rolePages});
	//console.log({showNextButton});
	//console.log({subscreenLocked, subscreenRestricted, currentSubscreenSet, permissionChecked});

	let last_screen = 'school_setup';
	if(!isEmpty(rolePages))
	{
		let min_incomplete_screen_order = 999;

		for (let [temp_screen, temp_screen_info] of Object.entries(rolePages)) {
			const screen_order = temp_screen_info.screen_order;

			if(screen_order > min_incomplete_screen_order) continue;

			const screen_subscreens = temp_screen_info.subscreens;
			const screen_incomplete = screen_subscreens.findIndex(temp_subscreen => !temp_subscreen.complete) !== -1;
	
			if(screen_incomplete)
			{
				min_incomplete_screen_order = screen_order;
				last_screen = temp_screen;
			}
		}
	}
	
	let step_index = 0;
	
	return (
		<div id='progress-page-main-container'>
			<div id='progress-page-sidebar'>
				<div className='secondary-sidebar-header'>Scheduling Steps</div>
				{!rolePages ?
					(
						<div className='center-text'>
							<img src={require('../../images/balls.gif')} alt='Loading...' style={{height:'25px'}}/>
						</div>
					):
					(
						<>
						{('school_setup' in rolePages) &&
							(() => {
								const screen_subscreens = (appSubscreens) ? appSubscreens.filter(screen => screen.screen === 'school_setup') : [];

								let color = colors[step_index];

								const role_page_subscreens = rolePages['school_setup']['subscreens'];
								const current = (role_page_subscreens.findIndex(subscreen_info => subscreen_info.current) !== -1) ? true : false;
								const complete = (role_page_subscreens.findIndex(subscreen_info => !subscreen_info.complete) !== -1) ? false : true;
								
								step_index++;
								
								return (
									<>
									<div className={`progress-page-sidebar-btn ${(complete || current) ? 'cursor-pointer' : ''} ${match.params.screen === 'school_setup' ? 'progress-page-sidebar-btn-selected' : ''}`} onClick={((complete || current) && match.params.screen !== 'school_setup') ? (() => goToScreen('school_setup')) : null}>
										<div className={`progress-sidebar-step-number ${color}`}>{step_index}</div>
										<div className='progress-sidebar-step-name'>School Set Up</div>
										<div className='progress-step-button-container'>
											{(complete) ?
												(
													<FontAwesomeIcon className='progress-sidebar-step-check green-text' icon={faCheckCircle}/>
												) : (current) ?
												(
													<FontAwesomeIcon className='progress-sidebar-step-check gray-text' icon={faCheckCircle}/>
												) : 
												(
													<FontAwesomeIcon className={`progress-sidebar-step-pending gray-text`} icon={faLock}/>
												)
											}
										</div>
									</div>
									{(screen_subscreens.length > 1 && match.params.screen === 'school_setup') &&
										<div className='progress-page-sidebar-subscreens-container'>
											{screen_subscreens.map((screen_info, i) => {
												const temp_subscreen = screen_info.subscreen;
												const subscreen_name = temp_subscreen.replaceAll('_', ' ').toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');

												const role_page_subscreen = role_page_subscreens.find(subscreen_info => subscreen_info.subscreen === temp_subscreen);
												const subscreen_complete = role_page_subscreen.complete;
												const subscreen_current = role_page_subscreen.current;
												const subscreen_awaiting_confirmation = role_page_subscreen.awaiting_confirmation;

												return (
													<div className={`progress-page-sidebar-subscreen-btn ${match.params.subscreen === temp_subscreen ? 'progress-page-sidebar-subscreen-btn-selected' : ''}`} key={i} onMouseEnter={(e) => handleChangeSubscreenProgressLineColor(e, color, true)}
													onMouseLeave={(e) => handleChangeSubscreenProgressLineColor(e, color, false)} onClick={match.params.subscreen !== temp_subscreen ? () => subscreenSelect(temp_subscreen) : null}>
														<div className={`progress-page-sidebar-subscreen-progress-line ${match.params.subscreen === temp_subscreen ? color : ''}`}></div>
														<div className='progress-page-sidebar-subscreen-name'>{subscreen_name}</div>
														{(subscreen_awaiting_confirmation) ? 
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check yellow-text' icon={faCheckCircle}/>
															) : (subscreen_complete) ?
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check green-text' icon={faCheckCircle}/>
															) : (subscreen_current) ?
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check gray-text' icon={faCheckCircle}/>
															) : 
															(
																<FontAwesomeIcon className={`progress-page-sidebar-subscreen-step-pending gray-text`} icon={faLock}/>
															)
														}
													</div>
												)
											})}
										</div>
									}
									</>
								)
							})()
						}
						{('upload_data' in rolePages) &&
							(() => {
								const screen_subscreens = (appSubscreens) ? appSubscreens.filter(screen => screen.screen === 'upload_data') : [];

								let color = colors[step_index];

								const role_page_subscreens = rolePages['upload_data']['subscreens'];
								const current = (role_page_subscreens.findIndex(subscreen_info => subscreen_info.current) !== -1) ? true : false;
								const complete = (role_page_subscreens.findIndex(subscreen_info => !subscreen_info.complete) !== -1) ? false : true;

								step_index++;
								
								return (
									<>
									<div className={`progress-page-sidebar-btn ${(complete || current) ? 'cursor-pointer' : ''} ${match.params.screen === 'upload_data' ? 'progress-page-sidebar-btn-selected' : ''}`} onClick={((complete || current) && match.params.screen !== 'upload_data') ? (() => goToScreen('upload_data')) : null}>
										<div className={`progress-sidebar-step-number ${color}`}>{step_index}</div>
										<div className='progress-sidebar-step-name'>Upload Data</div>
										<div className='progress-step-button-container'>
											{(complete) ?
												(
													<FontAwesomeIcon className='progress-sidebar-step-check green-text' icon={faCheckCircle}/>
												) : (current) ?
												(
													<FontAwesomeIcon className='progress-sidebar-step-check gray-text' icon={faCheckCircle}/>
												) : 
												(
													<FontAwesomeIcon className={`progress-sidebar-step-pending gray-text`} icon={faLock}/>
												)
											}
										</div>
									</div>
									{(screen_subscreens.length > 1 && match.params.screen === 'upload_data') &&
										<div className='progress-page-sidebar-subscreens-container'>
											{screen_subscreens.map((screen_info, i) => {
												const temp_subscreen = screen_info.subscreen;
												const subscreen_name = temp_subscreen.replaceAll('_', ' ').toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');

												const role_page_subscreen = role_page_subscreens.find(subscreen_info => subscreen_info.subscreen === temp_subscreen);
												const subscreen_complete = role_page_subscreen.complete;
												const subscreen_current = role_page_subscreen.current;
												const subscreen_awaiting_confirmation = role_page_subscreen.awaiting_confirmation;

												return (
													<div className={`progress-page-sidebar-subscreen-btn ${match.params.subscreen === temp_subscreen ? 'progress-page-sidebar-subscreen-btn-selected' : ''}`} key={i} onMouseEnter={(e) => handleChangeSubscreenProgressLineColor(e, color, true)}
													onMouseLeave={(e) => handleChangeSubscreenProgressLineColor(e, color, false)} onClick={match.params.subscreen !== temp_subscreen ? () => subscreenSelect(temp_subscreen) : null}>
														<div className={`progress-page-sidebar-subscreen-progress-line ${match.params.subscreen === temp_subscreen ? color : ''}`}></div>
														<div className='progress-page-sidebar-subscreen-name'>{subscreen_name}</div>
														{(subscreen_awaiting_confirmation) ? 
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check yellow-text' icon={faCheckCircle}/>
															) : (subscreen_complete) ?
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check green-text' icon={faCheckCircle}/>
															) : (subscreen_current) ?
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check gray-text' icon={faCheckCircle}/>
															) : 
															(
																<FontAwesomeIcon className={`progress-page-sidebar-subscreen-step-pending gray-text`} icon={faLock}/>
															)
														}
													</div>
												)
											})}
										</div>
									}
									</>
								)
							})()
						}
						{('specials_setup' in rolePages) &&
							(() => {
								const screen_subscreens = (appSubscreens) ? appSubscreens.filter(screen => screen.screen === 'specials_setup') : [];

								let color = colors[step_index];

								const role_page_subscreens = rolePages['specials_setup']['subscreens'];
								const current = (role_page_subscreens.findIndex(subscreen_info => subscreen_info.current) !== -1) ? true : false;
								const complete = (role_page_subscreens.findIndex(subscreen_info => !subscreen_info.complete) !== -1) ? false : true;

								step_index++;
								
								return (
									<>
									<div className={`progress-page-sidebar-btn ${(complete || current) ? 'cursor-pointer' : ''} ${match.params.screen === 'specials_setup' ? 'progress-page-sidebar-btn-selected' : ''}`} onClick={((complete || current) && match.params.screen !== 'specials_setup') ? (() => goToScreen('specials_setup')) : null}>
										<div className={`progress-sidebar-step-number ${color}`}>{step_index}</div>
										<div className='progress-sidebar-step-name'>Specials Setup</div>
										<div className='progress-step-button-container'>
											{(complete) ?
												(
													<FontAwesomeIcon className='progress-sidebar-step-check green-text' icon={faCheckCircle}/>
												) : (current) ?
												(
													<FontAwesomeIcon className='progress-sidebar-step-check gray-text' icon={faCheckCircle}/>
												) : 
												(
													<FontAwesomeIcon className={`progress-sidebar-step-pending gray-text`} icon={faLock}/>
												)
											}
										</div>
									</div>
									{(screen_subscreens.length > 1 && match.params.screen === 'specials_setup') &&
										<div className='progress-page-sidebar-subscreens-container'>
											{screen_subscreens.map((screen_info, i) => {
												const temp_subscreen = screen_info.subscreen;
												const subscreen_name = temp_subscreen.replaceAll('_', ' ').toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');

												const role_page_subscreen = role_page_subscreens.find(subscreen_info => subscreen_info.subscreen === temp_subscreen);
												const subscreen_complete = role_page_subscreen.complete;
												const subscreen_current = role_page_subscreen.current;
												const subscreen_awaiting_confirmation = role_page_subscreen.awaiting_confirmation;

												return (
													<div className={`progress-page-sidebar-subscreen-btn ${match.params.subscreen === temp_subscreen ? 'progress-page-sidebar-subscreen-btn-selected' : ''}`} key={i} onMouseEnter={(e) => handleChangeSubscreenProgressLineColor(e, color, true)}
													onMouseLeave={(e) => handleChangeSubscreenProgressLineColor(e, color, false)} onClick={match.params.subscreen !== temp_subscreen ? () => subscreenSelect(temp_subscreen) : null}>
														<div className={`progress-page-sidebar-subscreen-progress-line ${match.params.subscreen === temp_subscreen ? color : ''}`}></div>
														<div className='progress-page-sidebar-subscreen-name'>{subscreen_name}</div>
														{(subscreen_awaiting_confirmation) ? 
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check yellow-text' icon={faCheckCircle}/>
															) : (subscreen_complete) ?
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check green-text' icon={faCheckCircle}/>
															) : (subscreen_current) ?
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check gray-text' icon={faCheckCircle}/>
															) : 
															(
																<FontAwesomeIcon className={`progress-page-sidebar-subscreen-step-pending gray-text`} icon={faLock}/>
															)
														}
													</div>
												)
											})}
										</div>
									}
									</>
								)
							})()
						}
						{('pullouts_setup' in rolePages) &&
							(() => {
								const screen_subscreens = (appSubscreens) ? appSubscreens.filter(screen => screen.screen === 'pullouts_setup') : [];

								let color = colors[step_index];

								const role_page_subscreens = rolePages['pullouts_setup']['subscreens'];
								const current = (role_page_subscreens.findIndex(subscreen_info => subscreen_info.current) !== -1) ? true : false;
								const complete = (role_page_subscreens.findIndex(subscreen_info => !subscreen_info.complete) !== -1) ? false : true;

								step_index++;
								
								return (
									<>
									<div className={`progress-page-sidebar-btn ${(complete || current) ? 'cursor-pointer' : ''} ${match.params.screen === 'pullouts_setup' ? 'progress-page-sidebar-btn-selected' : ''}`} onClick={((complete || current) && match.params.screen !== 'pullouts_setup') ? (() => goToScreen('pullouts_setup')) : null}>
										<div className={`progress-sidebar-step-number ${color}`}>{step_index}</div>
										<div className='progress-sidebar-step-name'>Pullouts Setup</div>
										<div className='progress-step-button-container'>
											{(complete) ?
												(
													<FontAwesomeIcon className='progress-sidebar-step-check green-text' icon={faCheckCircle}/>
												) : (current) ?
												(
													<FontAwesomeIcon className='progress-sidebar-step-check gray-text' icon={faCheckCircle}/>
												) : 
												(
													<FontAwesomeIcon className={`progress-sidebar-step-pending gray-text`} icon={faLock}/>
												)
											}
										</div>
									</div>
									{(screen_subscreens.length > 1 && match.params.screen === 'pullouts_setup') &&
										<div className='progress-page-sidebar-subscreens-container'>
											{screen_subscreens.map((screen_info, i) => {
												const temp_subscreen = screen_info.subscreen;
												const subscreen_name = temp_subscreen.replaceAll('_', ' ').toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');

												const role_page_subscreen = role_page_subscreens.find(subscreen_info => subscreen_info.subscreen === temp_subscreen);
												const subscreen_complete = role_page_subscreen.complete;
												const subscreen_current = role_page_subscreen.current;
												const subscreen_awaiting_confirmation = role_page_subscreen.awaiting_confirmation;

												return (
													<div className={`progress-page-sidebar-subscreen-btn ${match.params.subscreen === temp_subscreen ? 'progress-page-sidebar-subscreen-btn-selected' : ''}`} key={i} onMouseEnter={(e) => handleChangeSubscreenProgressLineColor(e, color, true)}
													onMouseLeave={(e) => handleChangeSubscreenProgressLineColor(e, color, false)} onClick={match.params.subscreen !== temp_subscreen ? () => subscreenSelect(temp_subscreen) : null}>
														<div className={`progress-page-sidebar-subscreen-progress-line ${match.params.subscreen === temp_subscreen ? color : ''}`}></div>
														<div className='progress-page-sidebar-subscreen-name'>{subscreen_name}</div>
														{(subscreen_awaiting_confirmation) ? 
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check yellow-text' icon={faCheckCircle}/>
															) : (subscreen_complete) ?
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check green-text' icon={faCheckCircle}/>
															) : (subscreen_current) ?
															(
																<FontAwesomeIcon className='progress-page-sidebar-subscreen-step-check gray-text' icon={faCheckCircle}/>
															) : 
															(
																<FontAwesomeIcon className={`progress-page-sidebar-subscreen-step-pending gray-text`} icon={faLock}/>
															)
														}
													</div>
												)
											})}
										</div>
									}
									</>
								)
							})()
						}
						</>
					)
				}
			</div>
			<div id='progress-page-main-content'>
				{(() => {
					switch(match.params.screen) {
						case 'school_setup':
							return <SchoolSetup isLoading={isLoading} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreen={subscreen} schoolInfo={schoolInfo} setSchoolInfo={setSchoolInfo} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} handleMoveToNextScreen={handleMoveToNextScreen}/>;
						case 'upload_data':
							return <UploadData isLoading={isLoading} setIsLoading={setIsLoading} schoolSubscribed={schoolSubscribed} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreen={subscreen} setSubscreen={setSubscreen} schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} coursesLoading={coursesLoading} setCoursesLoading={setCoursesLoading} courses={courses} setCourses={setCourses} teachersLoading={teachersLoading} setTeachersLoading={setTeachersLoading} teachers={teachers} setTeachers={setTeachers} classroomsLoading={classroomsLoading} setClassroomsLoading={setClassroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} studentsLoading={studentsLoading} setStudentsLoading={setStudentsLoading} students={students} setStudents={setStudents} setRolePages={setRolePages} handleMoveToNextScreen={handleMoveToNextScreen}/>;
						case 'specials_setup':
							return <SpecialsSetup isLoading={isLoading} schoolSubscribed={schoolSubscribed} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreen={subscreen} periods={periods} setPeriods={setPeriods} periodsLoading={periodsLoading} schoolInfoLoading={schoolInfoLoading} schoolInfo={schoolInfo} scheduleInfoLoading={scheduleInfoLoading} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} studentsLoading={studentsLoading} students={students} setStudents={setStudents} classroomsLoading={classroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} blocksLoading={blocksLoading} blocks={blocks} setBlocks={setBlocks} handleMoveToNextScreen={handleMoveToNextScreen} setLoadScreen={setLoadScreen}/>;
						case 'pullouts_setup':
							return <PulloutsSetup isLoading={isLoading} schoolSubscribed={schoolSubscribed} subscreenRestricted={subscreenRestricted} subscreenLocked={subscreenLocked} subscreen={subscreen} periods={periods} setPeriods={setPeriods} periodsLoading={periodsLoading} schoolInfoLoading={schoolInfoLoading} schoolInfo={schoolInfo} scheduleInfoLoading={scheduleInfoLoading} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} studentsLoading={studentsLoading} students={students} setStudents={setStudents} classroomsLoading={classroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} blocksLoading={blocksLoading} blocks={blocks} setBlocks={setBlocks} pulloutGroups={pulloutGroups} setPulloutGroups={setPulloutGroups} handleMoveToNextScreen={handleMoveToNextScreen} setLoadScreen={setLoadScreen}/>;
						default:
							return (
								null
							);
					}
				})()}
			</div>
		</div>
	);
}