import React, { useState , useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCircle, faChevronDown, faChartBar, faChartLine, faChartArea } from '@fortawesome/free-solid-svg-icons';
import { faBell, faEnvelope} from '@fortawesome/free-regular-svg-icons';

import { isEmpty, useOutsideClick, timeSince, updateNotification } from '../js/Helpers';

export default function Navbar({ schoolType, schoolInfo, schoolInfoLoading, userInfo, userInfoLoading, scheduleInfo, scheduleInfoLoading, notifications, setNotifications, appSubscreens, loadScreen, setLoadScreen, handleSwitchCurrentYear, handleLogout }) {
	const location = useLocation();
	const notifications_ref = useRef();
	const current_year_ref = useRef();
	const settings_ref = useRef();
	
	const [isLoading, setIsLoading] = useState(true);
	
	const [noticationsVisible, setNotificationsVisible] = useState(false);
	const [currentYearVisible, setCurrentYearVisible] = useState(false);
	const [settingsVisible, setSettingsVisible] = useState(false);
	
	const handleIconClick = (click_type) => {
		if(click_type === 'notifications')
		{
			setNotificationsVisible(!noticationsVisible);
		}
		else if(click_type === 'current_year')
		{
			setCurrentYearVisible(!currentYearVisible);
		}
		else if(click_type === 'settings')
		{
			setSettingsVisible(!settingsVisible);
		}
	}
	
	useOutsideClick(settings_ref, () => {
		setSettingsVisible(false);
	});
	
	useOutsideClick(notifications_ref, () => {
		setCurrentYearVisible(false);
	});
	
	useOutsideClick(notifications_ref, () => {
		setNotificationsVisible(false);
	});
	
	const setSubscreen = (notification_index, screen, subscreen, department_id = null) => {
		if(screen !== subscreen) 
		{
			if(screen === 'clean_data')
			{
				sessionStorage.setItem('clean_data_subscreen', 'teacher_availability');
				sessionStorage.setItem('current_subscreen', subscreen);
			}
			else
			{
				sessionStorage.setItem(`${screen}_subscreen`, subscreen);
			}
		}
		
		if(department_id) sessionStorage.setItem('current_department_id', department_id);
		
		const notification_info = notifications[notification_index];
		const notification_id = notification_info.notification_id;
		
		// Update state and database
		updateNotification('update_seen', schoolInfo.school_id, schoolInfo.current_schedule_year_id, notification_id).then(notifications => {
			setNotifications(notifications);
		});
	}
	
	const clearNotifications = () => {
		updateNotification('update_all_seen', schoolInfo.school_id, schoolInfo.current_schedule_year_id).then(notifications => {
			setNotifications(notifications);
		});
	}
	
	const signOut = () => {
		sessionStorage.clear();
		localStorage.clear();
		
		handleLogout();
	}
	
	//////////////////////////////
	///// DO ON STATE CHANGE /////
	//////////////////////////////
	useEffect(() => {
		if(!schoolInfoLoading && !userInfoLoading && !scheduleInfoLoading) setIsLoading(false);
	}, [schoolInfoLoading, userInfoLoading, scheduleInfoLoading]);
	
	var notificationOptionsVisible = noticationsVisible ? 'fadeIn' : 'fadeOut';
	var currentYearOptionsVisible = currentYearVisible ? 'fadeIn' : 'fadeOut';
	var settingOptionsVisible = settingsVisible ? 'fadeIn' : 'fadeOut';
	
	var num_notifications = (notifications) ? notifications.filter(notification => notification.seen === '0').length : 0;

	const app_current_schedule_year_index = ('schedule_years' in scheduleInfo) ? scheduleInfo.schedule_years.findIndex(schedule_year => schedule_year.schedule_year_id === scheduleInfo.app_current_schedule_year_id) : null;
	const current_schedule_year = ('schedule_years' in scheduleInfo) ? scheduleInfo.schedule_years.find(schedule_year => schedule_year.schedule_year_id === schoolInfo.current_schedule_year_id).schedule_year : null;
	
	return (
		<div className="header-container">
			<div className='header-current-year-container' onClick={() => handleIconClick('current_year')} ref={current_year_ref}>
				<div>{isEmpty(scheduleInfo) ? '--' : current_schedule_year}</div>
				<FontAwesomeIcon className='header-current-year-arrow' icon={faChevronDown} />
				<div className={`header-dropdown-content ${currentYearOptionsVisible}`} style={{maxWidth:'150px'}}>
					{('schedule_years' in scheduleInfo) && scheduleInfo.schedule_years.map((schedule_year, index) => {
						const new_year = (scheduleInfo.schedules.findIndex(schedule => schedule.schedule_year_id === schedule_year.schedule_year_id) !== -1) ? false : true;
						const option_selected = (schedule_year.schedule_year_id === schoolInfo.current_schedule_year_id) ? true : false;

						return (
							<React.Fragment key={index}>
							{(index == app_current_schedule_year_index || (index < app_current_schedule_year_index && !new_year)) &&
								<div className={`header-dropdown-content-option ${option_selected && 'header-dropdown-content-option-selected'}`} onClick={option_selected ? null : () => handleSwitchCurrentYear({school_id: schoolInfo.school_id, selected_schedule_year_id: schedule_year.schedule_year_id, new_year:new_year})}>
									<div>{schedule_year.schedule_year}</div>
									{new_year &&
										<div className='green-text' style={{position:'absolute', top:'3px', right:'3px', fontSize:'10px'}}>New Year!</div>
									}
								</div>
							}
							</React.Fragment>
							
						)
					})}
				</div>
			</div>
			<div></div>
			<div>
				{schoolType === 'hsms' &&
					<Link className='header-notification-container' to={`/app/${schoolType}/progress/`} onClick={() => setLoadScreen('progress')}><FontAwesomeIcon className='header-notification-img' icon={faChartBar} /></Link>
				}
			</div>
			<div className='header-notification-container' onClick={isLoading ? () => {} : () => handleIconClick('notifications')} ref={notifications_ref}>
				<FontAwesomeIcon className='header-notification-img' icon={faBell} />
				{(notifications && num_notifications !== 0) &&
					<FontAwesomeIcon className='header-notification-indicator' icon={faCircle} />
				}
				<div className={`header-dropdown-content ${notificationOptionsVisible}`} style={{width:'350px'}}>
					{(!notifications || !appSubscreens) ?
						(
							<div className='header-notification-empty-container'>
								<img src={require(`../images/ajax-loader.gif`)} alt='loading' />
							</div>
						) : (notifications.length === 0) ? 
						(
							<div className='header-notification-empty-container'>No new notifications!</div>
						) :
						(
							<>
							<div id='notification-container-header-container'>
								<h3 className='notification-container-header'>Notifications</h3>
								<div className='blue-link small-text' onClick={clearNotifications}>Clear All</div>
							</div>
							{notifications.map((notification, notification_index) => {
								const department_id = notification.department_id;
								const subscreen_id = notification.subscreen_id;
								const app_subscreen_info = appSubscreens.find(subscreen => subscreen.subscreen_id === subscreen_id);

								if(!app_subscreen_info) return false;
								
								const screen = app_subscreen_info.screen;
								const subscreen = app_subscreen_info.subscreen;
								
								return (
									<Link className='notification-container' onClick={() => setSubscreen(notification_index, screen, subscreen, department_id)} to={`/app/progress/${screen}`} key={notification_index}>
										<FontAwesomeIcon className='notification-icon' icon={faEnvelope} />
										<div className='notification-content'>
											<div>{notification.notification}</div>
											<div className='gray-text'>{timeSince(notification.time_stamp)} ago</div>
										</div>
										{notification.seen === '0' &&
											<FontAwesomeIcon className='notification-indicator' icon={faCircle} />
										}
									</Link>
								)	
							})}
							</>
						)
					}
				</div>
			</div>
			<div className='header-dropdown-container' onClick={() => handleIconClick('settings')} ref={settings_ref}>
				<div className="header-account-info-container">
					{!isLoading &&
						<>
						<div className="header-school-info school-name">{schoolInfo.name}</div>
						<div className="header-user-info">
							{(userInfo.last_name && userInfo.first_name) &&
								<><span className="user-lastname">{userInfo.last_name}</span>, <span className="user-firstname">{userInfo.first_name}</span></>
							}
							
						</div>
						</>
					}
				</div>
				<div className="header-dropdown-link" data-contenttype="account"><FontAwesomeIcon style={{fontSize:"25px"}} icon={faUserCircle} /></div>
				<div className={`header-dropdown-content ${settingOptionsVisible}`}>
					<Link className="header-dropdown-content-option" to={`/app/select_school`} onClick={() => setLoadScreen('select_school')}>Switch Schools</Link>
					<Link className="header-dropdown-content-option" to={`/app/${schoolType}/documentation/get_started`} target="_blank">Help</Link>
					<Link className="header-dropdown-content-option" to={{pathname:`/app/${schoolType}/settings/account/`, redirect:location.pathname, load_screen:loadScreen}} onClick={() => setLoadScreen('settings')}>Settings</Link>
					<Link className="header-dropdown-content-option sign-out-btn" onClick={signOut} to={`/app/login`}>Sign Out</Link>
				</div>
			</div>
		</div>
	);
}