import React, { useState , useEffect } from 'react';
import { Link } from "react-router-dom";

import { loadData } from '../../../js/Helpers';
import { FileUpload } from '../../../js/upload';

import UploadModal from '../../UploadModal';
import DatabaseDataStudents from '../school_data/database_data_students';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faQuestionCircle, faCheckCircle, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export default function UploadStudents({subscreenRestricted, subscreenLocked, schoolSubscribed, subscreen, currentSIS, schoolInfo, scheduleInfo, scheduleStructureData, studentsLoading, setStudentsLoading, students, setStudents, courses, teachers, departments, sections, labels, setStudentData, handleMoveToNextScreen }) {
	const [studentDataExists, setStudentDataExists] = useState(false);
	
	const [alreadyUploadedChecked, setAlreadyUploadedChecked] = useState(false);
	const [alreadyUploaded, setAlreadyUploaded] = useState(false);

	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showStudentData, setShowStudentData] = useState(false);

	const setDataExists = (value) => {
		setStudentDataExists(value);
	}

	const handlePullNewData = () => {
		loadData({data_types:['students'], schedule_version_id:schoolInfo.current_schedule_version_id, data_version_id:schoolInfo.current_data_version_id, school_id:schoolInfo.school_id}).then(database_data => {
			setStudents(database_data.students);
		});

		setDataExists(true);
		setAlreadyUploadedChecked(true);
		setAlreadyUploaded(true);
	}

	////////////////////////////////
	///// USE EFFECT FUNCTIONS /////
	////////////////////////////////
	useEffect(() => {
		if(schoolInfo.current_data_version_id && schoolInfo.school_id)
		{
			async function checkUploadAlreadyProcessed() {
				const file_upload = new FileUpload(schoolInfo.current_data_version_id, schoolInfo.current_schedule_version_id, scheduleInfo.school_id);
			
				// Check whether courses have been uploaded
				const running_processes = await file_upload.getSchoolProcesses(schoolInfo.current_schedule_year_id);

				// Check if there are any running processes with the same data version id
				const dv_process_index = running_processes.findIndex(process => process.process_type === 'studentdata' && process.data_version_id == schoolInfo.current_data_version_id && process.is_done);
				const sv_process_index = running_processes.findIndex(process => process.process_type === 'studentdata' && process.schedule_version_id == schoolInfo.current_schedule_version_id && process.is_done);

				// If process has already finished running then set data existing
				if(sv_process_index !== -1 || dv_process_index !== -1) setAlreadyUploaded(true);

				setAlreadyUploadedChecked(true);
			}

			checkUploadAlreadyProcessed();
		}
	}, [scheduleInfo, schoolInfo, subscreen]);

	useEffect(() => {
		if(students.length > 0) setStudentDataExists(true);
	}, [students]);

	return (
		<>
		{(!schoolSubscribed) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='yellow-text modal-top-icon' style={{fontSize:'60px',margin:'10px 0px'}} icon={faUnlock}/>
				<h1>Unlock all Access</h1>
				<div>You need a paid subscription to upload students.</div>
				<div style={{margin:'6px 0px 0px 0px'}}>But don't let that stop you-- you can still skip this step and keep working!</div>	
				<div style={{fontSize:'18px', margin:'45px 0px 0px 0px'}}><strong>Please contact us at contact@edario.com to get full access in Edario.</strong></div>
				<div className="blue-link align-right" style={{maxWidth:'250px', margin:'50px 0px -30px 0px'}} onClick={handleMoveToNextScreen}>Skip to Next Step</div>
			</div>
		):(subscreenRestricted) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>No Access</h1>
				<div>You do not have access to this screen</div>	
			</div>
		):(subscreenLocked) ?
		(
			<div className='general-screen-message'>
				<FontAwesomeIcon className='dark-blue-text' style={{fontSize:'60px'}} icon={faLock}/>
				<h1>Temporarily Locked</h1>
				<div>This screen is locked until all other prior steps are completed</div>	
			</div>
		):
		(
			<>
			{showUploadModal &&
				<UploadModal schoolInfo={schoolInfo} scheduleInfo={scheduleInfo} uploadTitle='Students' uploadAPIName='studentdata' closeModal={() => setShowUploadModal(false)} handlePullNewData={handlePullNewData}/>
			}
			<h1 className='progress-main-header'>Upload Students</h1>
			<p className='progress-subheader'>Let's import your students from your current SIS.</p>
			<div className="upload_screen">
				<div className='upload-screen-explanation-container'>
					<h2 className="upload-screen-heading-text"><span className="ms_login_system_name capitalize">{currentSIS}</span> Student Upload</h2>
					<p className='upload-screen-description'>Next we&rsquo;ll need a full student list from <span className="ms_login_system_name capitalize">{currentSIS}</span>:</p>
					<div className='upload-screen-data-container'>
						<img className='upload-info-image' src={require(`../../../images/graduated.png`)} alt='students'/>
						<div className='upload-info-name'>Students</div>
						{studentDataExists ?
							(
								<>
								<div>
									<FontAwesomeIcon icon={faCheckCircle} className='green-text upload-complete'/>
									<div className='upload-complete-text'>Complete</div>
								</div>
								<div className='btn btn-small blue-btn' onClick={()=>{setShowStudentData(!showStudentData)}}>See Data</div>
								</>
							):
							(
								<>
								<div>
									<FontAwesomeIcon icon={faCheckCircle} className='gray-text upload-complete'/>
									<div className='upload-complete-text gray-text'>No Data</div>
								</div>
								<div className='white-text'>.</div>
								</>
							)
						}
					</div>
					<div>
						{!alreadyUploadedChecked ?
							(
								<div className='center-text'>
									<img src={require('../../../images/balls.gif')} alt='Loading...' style={{height:'40px'}}/>
								</div>
							): (alreadyUploaded)  ? 
							(
								<div className='upload-screen-already-uploaded-container'>
										<div>
											<FontAwesomeIcon className='upload-screen-already-uploaded-check green-text' icon={faCheckCircle}/>
										</div>
										<div>
											<h3 className='upload-screen-already-uploaded-header'>You already uploaded Students!</h3>
											<div>You can move on to the next step, or <span className='blue-link' onClick={() => setShowUploadModal(true)}>Reupload Students</span>!</div>
										</div>
									</div>
							):
							(
								<div className='center-text'>
									<span className='btn turquoise-btn btn-round btn-large upload-btn' onClick={() => setShowUploadModal(true)}>Upload Students</span>
								</div>
							)
						}
					</div>
					<p className='upload-how-to-line'><FontAwesomeIcon className='info-icon blue-text' icon={faQuestionCircle}/> <Link to={`/app/hsms/documentation/upload_data/upload_students/${currentSIS}`} target="_blank" style={{color:'#3498db'}}>How to download student data from <span className="ms_login_system_name capitalize">{currentSIS}</span></Link></p>
				</div>
				{showStudentData &&
					<div className='upload-parent-container'>
					<div className='upload-screen-close-data gray-to-dark-blue-link' onClick={() => {setShowStudentData(false)}}>
						<FontAwesomeIcon icon={faTimesCircle}/>
					</div>
					<DatabaseDataStudents schoolInfo={schoolInfo} databaseData={students} setDatabaseData={setStudentData} scheduleStructureData={scheduleStructureData} scheduleInfo={scheduleInfo} courses={courses} teachers={teachers} departments={departments} sections={sections} labels={labels} isLoading={studentsLoading} setIsLoading={setStudentsLoading}/>
					</div>
				}
			</div>
			{(studentDataExists) &&
				<div className='fixed-bottom-save-container'>
					<div className="btn btn-extra-large green-btn align-right move-to-next-screen-btn move-to-next-screen-btn" onClick={handleMoveToNextScreen}>Go to Next Step: Upload Student Requests</div>
				</div>
			}
			</>
		)}
		</>
	);
}