import React, { useEffect } from 'react';

import SchoolDataHSMS from './hsms/SchoolDataHSMS';
import SchoolDataElementary from './elementary/SchoolDataElementary';

export default function SchoolData({ match, schoolInfo, schoolInfoLoading, schoolType, scheduleInfo, periods, scheduleStructureData, setLoadScreen, departments, teachersLoading, teachers, setTeachers, coursesLoading, courses, setCourses, classroomsLoading, classrooms, setClassrooms, studentsLoading, students, setStudents, blocks, pulloutGroups, setPulloutGroups, sectionsLoading, sections, setSections, lunchInfo, labels, setLabels, handlePullNewData, setScreenAllowsUniversalSidebar, setUniversalSidebarLoadingInfo }) {

	useEffect(() => {
		setScreenAllowsUniversalSidebar(false);
		setUniversalSidebarLoadingInfo(null);
	}, []);

	return (
		<>
			{(() => {
				switch(match.params.school_type) {
					case 'hsms':
						return <SchoolDataHSMS match={match} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} schoolType={schoolType} scheduleInfo={scheduleInfo} scheduleStructureData={scheduleStructureData} setLoadScreen={setLoadScreen} departments={departments} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} studentsLoading={studentsLoading} students={students} setStudents={setStudents} sectionsLoading={sectionsLoading} sections={sections} setSections={setSections} lunchInfo={lunchInfo} labels={labels} setLabels={setLabels} handlePullNewData={handlePullNewData}/>;
					case 'elementary':
						return <SchoolDataElementary match={match} schoolInfo={schoolInfo} schoolInfoLoading={schoolInfoLoading} schoolType={schoolType} scheduleInfo={scheduleInfo} periods={periods} setLoadScreen={setLoadScreen} teachersLoading={teachersLoading} teachers={teachers} setTeachers={setTeachers} coursesLoading={coursesLoading} courses={courses} setCourses={setCourses} classroomsLoading={classroomsLoading} classrooms={classrooms} setClassrooms={setClassrooms} studentsLoading={studentsLoading} students={students} setStudents={setStudents} blocks={blocks} pulloutGroups={pulloutGroups} setPulloutGroups={setPulloutGroups} handlePullNewData={handlePullNewData}/>;
					default:
						return null;
				}
			})()}
		</>
	);
}